import React from 'react';
import {
	NonAuthPageColumn,
	NonAuthPageContainer,
	NonAuthPageContentWrapper,
} from '../../components/Wrappers';
import { MainLogo } from '../../components/Logos';
import { InfoText } from '../../components/Text';
import styled from 'styled-components';
import { Theming } from '../../theming';
import { getMobileOperatingSystem } from '../../utils/getMobileOperatingSystem';

export const MobileRedirect = () => {
	const userAgent = getMobileOperatingSystem();
	return (
		<>
			{userAgent === 'iOS' ? (
				window.location.replace('https://apps.apple.com/us/app/carefiller/id1566583226')
			) : userAgent === 'Android' ? (
				window.location.replace(
					'https://play.google.com/store/apps/details?id=com.carefiller&hl=en_US&gl=US'
				)
			) : (
				<NonAuthPageContainer>
					<NonAuthPageColumn width={60}>
						<MainLogo width={'60%'} />
					</NonAuthPageColumn>
					<NonAuthPageColumn width={40}>
						<MobileRedirectMessage />
					</NonAuthPageColumn>
				</NonAuthPageContainer>
			)}
		</>
	);
};

//extracting to allow display without logo in case of admin being logged in
export const MobileRedirectMessage = () => {
	return (
		<NonAuthPageContentWrapper>
			<MobileImage src={require('../../assets/images/mobile-duotone.svg')} alt={'mobile phone'} />
			<PageTitle>Please use mobile device</PageTitle>
			<InfoText fontSize={15}>
				This link is meant to be opened in the Carefiller Mobile App. Please click the link again
				from a mobile device or download the Carefiller Mobile App from Google Play or the Apple App
				Store to access your account.
			</InfoText>
		</NonAuthPageContentWrapper>
	);
};

const PageTitle = styled.p`
	margin: 20px auto;
	text-align: center;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.titleColor};
	font-size: 18px;
`;

const MobileImage = styled.img`
	margin: 20px auto;
	max-width: 30%;
`;
