import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

type HeaderNameType = {
	headerName: string;
	setHeaderName: Dispatch<SetStateAction<string>>;
};

export const HeaderNameContext = createContext<HeaderNameType>({
	headerName: 'string',
	setHeaderName: () => {},
});

export default function HeaderNameContextProvider({ children }: { children: ReactNode }) {
	const [headerName, setHeaderName] = useState<string>('');

	return (
		<HeaderNameContext.Provider
			value={{
				headerName,
				setHeaderName,
			}}
		>
			{children}
		</HeaderNameContext.Provider>
	);
}
