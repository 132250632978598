import React, { useState } from 'react';
import { LocationAccountForm } from './LocationAccountForm';
import { useAsync } from '../../../../../hooks/useAsync';
import LoadingSpinner from '../../../../../components/Loader';
import { ErrorMessage, SuccessMessage } from '../../../../../components/Text';
import { Modal } from '../../../../../components/Modal';
import { webApiManager } from '../../../../../network/apiManager';
import { UUID } from '../../../../../types/sharedTypes';

type AddNewLocationProps = {
	facilityId: UUID;
	onSuccess: () => void;
};

type CreateLocationData = {
	locationName: string;
	streetAddress: string;
	city: string;
	state: string;
	zipCode: string;
	facilityTypeId: UUID;
};

export const AddNewLocation = ({ facilityId, onSuccess }: AddNewLocationProps) => {
	const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

	const onSubmit = async (formData: CreateLocationData) => {
		createLocation.clearError();
		try {
			const requestData = {
				facilityId,
				location: {
					name: formData.locationName,
					street_address: formData.streetAddress,
					city: formData.city,
					state: formData.state,
					zip_code: formData.zipCode,
					facility_type_id: formData.facilityTypeId,
				},
			};
			await webApiManager.Locations.createLocation(requestData);
			setShowSuccessMessage(true);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const createLocation = useAsync<void>(
		onSubmit,
		'Error saving your data. Please check your connection and try again.'
	);

	return (
		<>
			{createLocation.pending ? (
				<LoadingSpinner />
			) : (
				<LocationAccountForm
					isEditing={true}
					onSave={formData => createLocation.execute(formData)}
					hideEdit
				/>
			)}
			{!!createLocation.error && <ErrorMessage text={createLocation.error} />}
			<Modal
				isOpen={showSuccessMessage}
				hide={() => setShowSuccessMessage(false)}
				onConfirm={onSuccess}
				title={'Successful'}
				content={<SuccessMessage text={'Facility location has been created.'} />}
				confirmButtonText={'Okay'}
				hideCancel
			/>
		</>
	);
};
