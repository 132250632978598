import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isValidEmail } from '../../../utils/validators';
import { removeWhiteSpace } from '../../../utils/removeWhiteSpace';
import { LoginData } from '../Login';
import { NonAuthInputWrapper, UncontrolledTextInput } from '../../../components/TextInputs';
import { LinkStyledButton } from '../../../components/Links';
import { NonAuthButton } from '../../../components/Buttons';
import { NonAuthFormTitle } from '../../../components/Text';
import { NonAuthRouteNames } from '../../../navigation/routeNames';

type LoginFormProps = {
	onSubmit: (loginInfo: LoginData) => void;
	pending?: boolean;
};

type FormState = {
	emailAddress: string;
	password: string;
	emailError: string;
	passwordError: string;
};

export const LoginForm = ({ onSubmit, pending }: LoginFormProps) => {
	const history = useHistory();
	const [formState, setFormState] = useState<FormState>({
		emailAddress: '',
		emailError: '',
		password: '',
		passwordError: '',
	});

	const isFormValid = () => isEmailValid() && isPasswordValid();
	const isEmailValid = () => isValidEmail(formState.emailAddress);
	const isPasswordValid = () => formState.password.length >= 8;

	const validateEmail = () => {
		const validEmail = isEmailValid();

		//if invalid email, set error
		if (!validEmail && !formState.emailError) {
			setFormState(prevState => ({
				...prevState,
				emailError: 'Please enter a valid email address.',
			}));
		} else if (validEmail && formState.emailError) {
			//clear error if valid
			setFormState(prevState => ({ ...prevState, emailError: '' }));
		}

		return validEmail;
	};

	const validatePassword = () => {
		const validPassword = isPasswordValid();

		//if invalid password, set error
		if (!validPassword && !formState.passwordError) {
			setFormState(prevState => ({
				...prevState,
				passwordError: 'Your password must be at least 8 characters.',
			}));
		} else if (validPassword && formState.passwordError) {
			//clear error if valid
			setFormState(prevState => ({ ...prevState, passwordError: '' }));
		}

		return validPassword;
	};

	const validateForm = () => {
		return validateEmail() && validatePassword();
	};

	return (
		<form
			onSubmit={event => {
				event.preventDefault();

				if (validateForm()) {
					onSubmit({
						email: formState.emailAddress,
						password: formState.password,
					});
				}
			}}
		>
			<NonAuthFormTitle>Log into Carefiller</NonAuthFormTitle>
			<NonAuthInputWrapper>
				<UncontrolledTextInput
					borderRadius={0}
					type={'email'}
					placeholder={'Email Address'}
					value={formState.emailAddress}
					onChange={e => {
						//revalidate if previous error
						if (formState.emailError) {
							validateEmail();
						}

						setFormState(prevState => {
							const newVal = removeWhiteSpace(e.target.value);
							return { ...prevState, emailAddress: newVal };
						});
					}}
					onBlur={validateEmail}
					error={formState.emailAddress ? formState.emailError : ''}
				/>
				<UncontrolledTextInput
					borderRadius={0}
					type={'password'}
					placeholder={'Password'}
					value={formState.password}
					onChange={e => {
						//revalidate if previous error
						if (formState.passwordError) {
							validatePassword();
						}

						setFormState(prevState => {
							const newVal = removeWhiteSpace(e.target.value);
							return { ...prevState, password: newVal };
						});
					}}
					onBlur={validatePassword}
					error={formState.password ? formState.passwordError : ''}
				/>
			</NonAuthInputWrapper>
			<LinkStyledButton
				type="button"
				onClick={() => history.push(NonAuthRouteNames.ForgotPassword)}
			>
				Forgot Password?
			</LinkStyledButton>
			<NonAuthButton text={'Log In'} disabled={!isFormValid()} pending={pending} />
		</form>
	);
};
