import { useEffect, useState } from 'react';
import { UseAsyncReturn } from './useAsync';
import { PaginatedList } from '../types/sharedTypes';
import _ from 'lodash';
import { usePrevious } from './usePrevious';

export default <SortType, FilterType = any>(
	state: PaginatedList<any> | null,
	fetch: UseAsyncReturn<void>,
	initialFilters?: FilterType
): any => {
	const [sortBy, setSortBy] = useState<SortType | null>(null);
	const [filters, setFilters] = useState<FilterType | undefined>(initialFilters);
	const prevFilters = usePrevious(filters);

	useEffect(() => {
		//re-fetch list when sort options change
		if (sortBy) {
			const requestOptions = filters ? { sort_by: sortBy, ...filters } : { sort_by: sortBy };
			fetch.execute(requestOptions);
		}
	}, [sortBy]);

	//re-fetch list when filter options change
	useEffect(() => {
		//prevent fetch is options unchanged and prevent multiple fetch calls on load
		if (!!filters && !_.isEqual(prevFilters, filters) && prevFilters !== undefined) {
			const requestOptions = sortBy ? { sort_by: sortBy, ...filters } : filters;
			fetch.execute(requestOptions);
		}
	}, [filters]);

	//for pagination/infinite scroll
	const getNextPage = () => {
		//prevent fetch if already on final page
		if (!!state && !state.pagination.isFinalPage) {
			fetch.execute({ page: state.pagination.nextPage });
		}
	};

	const resetFilters = () => {
		setFilters(initialFilters);
	};

	const refreshWithOptions = () => {
		//set selected options to request and re-fetch list
		let requestOptions: (FilterType & { sort_by?: SortType }) | object = sortBy
			? { sort_by: sortBy }
			: {};
		requestOptions = filters ? { ...requestOptions, ...filters } : requestOptions;
		fetch.execute(requestOptions);
	};

	return { setSortBy, getNextPage, setFilters, filters, resetFilters, refreshWithOptions };
};
