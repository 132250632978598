import styled from 'styled-components';
import { Theming } from '../theming';

export const FormTitle = styled.p`
	font-family: ${Theming.text.boldFont};
	font-size: 18px;
	color: ${Theming.text.titleColor};
	padding: 10px;
	margin: 10px 0 0;
`;

export const SidePanelFormWrapper = styled.div`
	margin: 40px auto;
`;

export const FormWrapper = styled.div`
	margin: 25px auto;
	background-color: #fff;
	padding: 20px;
	border-radius: 3px;
`;

export const FormRow = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0;
`;

export const EditButtonWrapper = styled.div`
	text-align: right;
	width: 100%;
`;

export const FormColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0;
	flex: 1;
`;

export const FormSectionTitle = styled.p`
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.titleColor};
	font-size: 15px;
	margin: 15px 10px 15px 0;
`;

export const FormHeading = styled.div`
	border-bottom: 1px solid #707070;
	padding-bottom: 3px;
	margin: 45px 8px 15px 8px;
	font-family: ${Theming.text.boldFont};
`;
