import styled from 'styled-components';
import { Theming } from '../../../theming';

export const NavTitleWrapper = styled.div<{ showMenu?: boolean }>`
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: ${({ showMenu }) => (showMenu ? `${Theming.nav.navHeaderHeight}px` : '100%')};
	width: ${({ showMenu }) => (showMenu ? '100%' : 'auto')};
	background-color: ${Theming.nav.navTitleBackground};
	padding: 0 15px; //needs to match padding for links
	min-width: 160px;
	margin: 0;
`;
// margin: ${({ showMenu }) => (showMenu ? '0 0 20px 0' : 0)};;
// border-bottom: ${({ showMenu }) => (showMenu ? `2px solid ${Theming.nav.navTitleBorder}` : 'none')};
export const NavTitle = styled.p`
	color: ${Theming.nav.navTitle};
	font-family: ${Theming.text.boldFont};
	font-size: 16px;
	margin: 0;
	padding: 0;
`;
