import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { ApiResponse } from '../types/api/SharedApiTypes';
import { GetProfessionsResponse } from '../types/api/Professions';

export class ProfessionsApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getProfessions(): Promise<GetProfessionsResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getProfessionsResponse = (await fetchWrapper(
				{
					path: `professions`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetProfessionsResponse>;

			return getProfessionsResponse.data;
		} catch (err) {
			console.error('error in professions api', JSON.stringify(err));
			throw err;
		}
	}
}
