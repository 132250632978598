import { UUID } from '../sharedTypes';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { FacilityType } from './FacilityTypes';

export interface GetFacilityInvitesRequest extends PaginatedApiRequest<FacilityInviteSortOptions> {
	include_approved?: boolean; //default false
}

export enum FacilityInviteSortOptions {
	firstName = 'first_name',
	lastName = 'last_name',
	date = 'date',
	facilityType = 'facility_type',
}

export type GetFacilityInvitesResponse = PaginatedApiResponse<FacilityInviteRequest[]>;

export type GetFacilityInviteRequest = {
	facilityId: UUID;
};

export type GetFacilityInviteResponse = FacilityInviteRequest;

export interface FacilityInviteRequest {
	id: UUID;
	attributes: {
		facility_name: string;
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
		sms_opted_in: boolean;
		submitted_at: string; //timestamp
		approved_at: string | null; //timestamp
	};
	relationships: {
		type: 'facility_requests';
		facility_types: FacilityType[];
	};
}

export interface UpdateFacilityInviteRequest {
	facilityId: UUID;
	facilityName?: string;
	firstName?: string;
	lastName?: string;
	emailAddress?: string;
	phoneNumber?: string;
	smsOptedIn?: boolean;
	facilityTypeIds?: UUID[];
}

export type UpdateFacilityInviteResponse = FacilityInviteRequest;

export interface DeleteFacilityInviteRequest {
	facilityId: UUID;
}

export interface ApproveFacilityInviteRequest {
	facilityId: UUID;
}
