import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { format, parseISO } from 'date-fns';
import IconManager, { IconType } from '../../../components/IconManager';
import { AuthRouteNames } from '../../../navigation/routeNames';
import { Link } from 'react-router-dom';
import { StatusDisplay } from '../../../components/StatusDisplay';
import { AdaptedContract } from '../../../adapters/contractAdapters';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { Modal } from '../../../components/Modal';
import { ErrorMessage } from '../../../components/Text';
import { UUID } from '../../../types/sharedTypes';

type ContractCardProps = {
	onClick: () => void;
	isActive: boolean;
	contract: AdaptedContract;
	onCancel: (contractId: UUID) => void;
	cancelPending: boolean;
	cancelError: string | null;
	clearCancelError: () => void;
};

export const ContractCard = ({
	contract,
	onClick,
	isActive,
	onCancel,
	cancelPending,
	cancelError,
	clearCancelError,
}: ContractCardProps) => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(false);

	const menuRef = useOutsideClick(() => setShowMenu(false));

	useEffect(() => {
		if (cancelError) {
			setShowWarning(false);
		}
	}, [cancelError]);

	return (
		<ContractCardWrapper isActive={isActive} onClick={onClick}>
			{showMenu && (
				<>
					<CardMenu ref={menuRef}>
						{/*//TODO: "pause" button temporarily removed until v2*/}
						{/*<CardMenuItem*/}
						{/*	onClick={() => changeStatus('pause')}*/}
						{/*>*/}
						{/*	Pause Contract*/}
						{/*</CardMenuItem>*/}
						<CardMenuItem
							// borderTop
							onClick={() => {
								setShowWarning(true);
								setShowMenu(false);
							}}
						>
							Cancel Contract
						</CardMenuItem>
					</CardMenu>
				</>
			)}
			<Modal
				isOpen={showWarning}
				hide={() => setShowWarning(false)}
				onConfirm={() => {
					onCancel(contract.id);
					setShowWarning(false);
				}}
				title={`Are you sure you want to cancel Contract #${contract.displayId}?`}
				text={'This action cannot be undone.'}
				confirmButtonColor={Theming.errorColor}
				confirmButtonText={'Cancel Contract'}
				cancelButtonText={'Go Back'}
				pending={cancelPending}
			/>
			<Modal
				isOpen={!!cancelError}
				hide={() => clearCancelError()}
				title={`Unsuccessful`}
				content={<ErrorMessage text={cancelError ?? ''} />}
				hideConfirm
				cancelButtonText={'Close'}
			/>
			<CardRow padding={'0 0 10px 0'} justifyContent={'space-between'}>
				<CardText size={'lg'}>{`Contract #${contract.displayId}`}</CardText>
				<IconWrapper onClick={() => setShowMenu(prevState => !prevState)}>
					<IconManager
						type={IconType.DOTS}
						stroke={'none'}
						fill={'#B2AFC3'}
						size={20}
						strokeWidth={1}
					/>
				</IconWrapper>
			</CardRow>
			<CardRow>
				<CardColumn>
					<CardRow>
						<CardLabel>Starts:</CardLabel>
						<CardText>{format(parseISO(contract.startDate.toString()), 'MM/dd/yy')}</CardText>
						<CardLabel>Ends:</CardLabel>
						<CardText>{format(parseISO(contract.endDate.toString()), 'MM/dd/yy')}</CardText>
					</CardRow>
					<CardRow>
						<CardLabel>Shifts:</CardLabel>
						<CardSchedule>
							{['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(
								(dayOfWeek, index) => {
									return !contract.schedule[dayOfWeek] ? null : (
										<CardRow key={index}>
											<CardScheduleItem isLabel>{dayOfWeek.substr(0, 3)}</CardScheduleItem>
											<CardScheduleItem>{`${contract.schedule[dayOfWeek].startTime} - ${contract.schedule[dayOfWeek].endTime}`}</CardScheduleItem>
										</CardRow>
									);
								}
							)}
						</CardSchedule>
					</CardRow>
					<CardRow>
						<CardLabel>Contract Status: &nbsp;</CardLabel>
						<StatusDisplay statusType={contract.contractStatus} padding={'4px 8px'} />
					</CardRow>
					<CardRow>
						<CardLabel>Payment Status: &nbsp;</CardLabel>
						<StatusDisplay statusType={contract.paymentStatus} padding={'4px 8px'} />
					</CardRow>
				</CardColumn>
				<CardColumn>
					<CardRow padding={'10px 0 10px 5px'}>
						<CardLabel size={'lg'}>F</CardLabel>
						<IconManager
							type={IconType.ARROW_RIGHT}
							stroke={Theming.icons.secondaryIconColor}
							size={16}
						/>
						<CardLink
							to={`${AuthRouteNames.ManageFacilities}/${contract.facilityId}/locations/${contract.locationId}`}
						>
							{contract.locationName}
						</CardLink>
					</CardRow>

					<CardRow padding={'10px 0 10px 5px'}>
						<CardLabel size={'lg'}>C</CardLabel>
						<IconManager
							type={IconType.ARROW_RIGHT}
							stroke={Theming.icons.secondaryIconColor}
							size={16}
						/>
						<CardLink to={`${AuthRouteNames.ManageContractors}/${contract.contractorId}`}>
							{contract.contractorName}
						</CardLink>
						<CardText>{`$${contract.hourlyRate}/hr`}</CardText>
					</CardRow>
				</CardColumn>
			</CardRow>
			<CardRow>
				<CardColumn>
					<CardRow>
						<CardLabel size={'md'}>Reports:</CardLabel>
						<CardText size={'md'}>{contract.reports}</CardText>
					</CardRow>
				</CardColumn>
				<CardColumn>
					<CardRow justifyContent={'flex-end'}>
						<CardLabel>Posted: </CardLabel>
						<CardText>{format(parseISO(contract.postedDate.toString()), 'MM/dd/yy')}</CardText>
					</CardRow>
				</CardColumn>
			</CardRow>
		</ContractCardWrapper>
	);
};

const ContractCardWrapper = styled.div<{ isActive?: boolean }>`
	border-left: ${({ isActive }) =>
			isActive ? Theming.buttons.primaryButtonColor : Theming.buttons.disabledButtonColor}
		15px solid;
	border-radius: 3px;
	padding: 15px;
	background-color: #fff;
	margin: 20px 10px;
	box-shadow: 2px 2px 5px rgba(0, 0, 41, 0.1);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	position: relative;
`;

const CardColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 50%;
	padding: 0;
`;

const CardRow = styled.div<{ justifyContent?: string; padding?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
	align-items: center;
	padding: ${({ padding }) => padding ?? '5px 0'};
	width: 100%;
`;

const CardText = styled.p<{ size?: 'sm' | 'md' | 'lg' }>`
	font-family: ${Theming.text.boldFont};
	font-size: ${({ size }) => (size === 'lg' ? 16 : size === 'md' ? 14 : 12)}px;
	color: ${({ size }) => (size === 'lg' ? Theming.text.titleColor : Theming.text.primaryTextColor)};
	padding: 0 5px;
	margin: 0;
`;

const CardLabel = styled.p<{ size?: 'sm' | 'md' | 'lg' }>`
	font-family: ${Theming.text.boldFont};
	font-size: ${({ size }) => (size === 'lg' ? 16 : size === 'md' ? 14 : 12)}px;
	color: ${Theming.text.labelColor};
	padding: 0 5px;
	margin: 0;
`;

const CardLink = styled(Link)`
	font-family: ${Theming.text.boldFont};
	font-size: 12px;
	color: ${Theming.text.primaryLinkColor};
	padding: 0 5px;
	margin: 0;
`;

const CardMenu = styled.div`
	position: absolute;
	top: 20px;
	right: 35px;
	border-radius: 3px;
	overflow: hidden;
	background-color: #ededf6;
	box-shadow: 2px 2px 3px rgba(0, 0, 41, 0.15);
`;

const CardMenuItem = styled.div<{ borderTop?: boolean }>`
	font-family: ${Theming.text.boldFont};
	color: #414141;
	font-size: 13px;
	border-top: ${({ borderTop }) => (borderTop ? `1px solid #9C99AB` : 'none')};
	padding: 14px 22px;
	text-align: center;
	margin: 0;
	:hover& {
		background-color: #bcb9cb;
		cursor: pointer;
	}
`;

const CardSchedule = styled.div`
	padding: 0 0 0 15px;
	flex: 1;
`;

const CardScheduleItem = styled.p<{ isLabel?: boolean }>`
	font-family: ${Theming.text.boldFont};
	font-size: 12px;
	color: ${Theming.text.primaryTextColor};
	padding: 0 5px;
	margin: 0;
	min-width: ${({ isLabel }) => (isLabel ? '35px' : 0)};
	text-transform: ${({ isLabel }) => (isLabel ? 'capitalize' : 'none')};
`;

const IconWrapper = styled.div`
	width: auto;
	:hover& {
		cursor: pointer;
	}
`;
