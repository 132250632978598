import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { NavHeader } from './components/NavHeader';
import { NavBar, NavLinks } from './components/NavBar';
import { SubNav } from './components/SubNav';
import styled from 'styled-components';
import { MainContentContainer } from '../../components/Wrappers';
import { AuthRouteNames } from '../../navigation/routeNames';

const getActiveLink = (currentPath): NavLinks | null => {
	return currentPath.includes(AuthRouteNames.Contractors)
		? NavLinks.CONTRACTORS
		: currentPath.includes(AuthRouteNames.Facilities)
		? NavLinks.FACILITIES
		: currentPath.includes(AuthRouteNames.Contracts)
		? NavLinks.CONTRACTS
		: currentPath.includes(AuthRouteNames.DeletedUsers)
		? NavLinks.DELETED_USERS
		: currentPath.includes(AuthRouteNames.MobileAppSettings)
		? NavLinks.MOBILE_APP_SETTINGS
		: currentPath.includes(AuthRouteNames.AdminSettings)
		? NavLinks.ADMIN_SETTINGS
		: currentPath.includes(AuthRouteNames.Account)
		? NavLinks.MY_ACCOUNT
		: null;
};

export const AppContainerWithNav = ({ children }: { children: ReactNode }) => {
	const currentPath = useLocation().pathname;
	const activeLink = getActiveLink(currentPath);

	const isSubNavOpen = activeLink === NavLinks.FACILITIES || activeLink === NavLinks.CONTRACTORS;

	return (
		<LayoutContainer>
			<LeftColumn>
				<NavBar activeLink={activeLink} />
				<SubNav title={activeLink} showMenu={isSubNavOpen} type={activeLink} />
			</LeftColumn>
			<RightColumn>
				<NavHeader subNavOpen={isSubNavOpen} title={activeLink} />
				<MainContentContainer>{children}</MainContentContainer>
			</RightColumn>
		</LayoutContainer>
	);
};

const LayoutContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	height: max(
		600px,
		100vh
	); /* allows full nav to show/scroll with window while also allowing tables to overflow to scroll independently */
`;
// height: 100vh;

const LeftColumn = styled.div.attrs({ id: 'left-nav-column' })`
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
`;

const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	flex: 1;
`;
