import { UUID } from '../types/sharedTypes';
import { Pause, PauseAction, PauseResourceType } from '../types/api/Pauses';

export type AdaptedPause = {
	id: UUID; //Pause id
	resourceType: PauseResourceType;
	resourceId: UUID;
	onActiveAction: PauseAction;
	active: boolean;
	effectiveDate: string;
};

export const adaptPauseFromApi = (pauses: Pause[] | undefined): AdaptedPause | null => {
	if (!pauses || !pauses.length) {
		return null;
	}

	return {
		id: pauses[0].id,
		resourceType: pauses[0].attributes.pausable_type,
		resourceId: pauses[0].attributes.pausable_id,
		onActiveAction: pauses[0].attributes.deactivated ? PauseAction.deactivate : PauseAction.pause,
		active: pauses[0].attributes.active,
		effectiveDate: pauses[0].attributes.effective_date,
	};
};
