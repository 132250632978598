import {
	DeletedUser,
	GetDeletedUsersResponse,
	DeletedUserType,
	DeletedUserStatus,
} from '../types/api/DeletedUsers';
import { PaginatedList, UUID } from '../types/sharedTypes';
import { adaptPaginationFromApi } from './paginationAdapter';

export type AdaptedDeletedUser = {
	id: UUID;
	userName: string;
	email: string;
	userType: DeletedUserType;
	status: DeletedUserStatus;
	date: Date;
};

export type AdaptedDeletedUsers = PaginatedList<AdaptedDeletedUser>;

export const adaptDeletedUserFromApi = (deletedUser: DeletedUser): AdaptedDeletedUser => {
	return {
		id: deletedUser.id,
		userName: `${deletedUser.attributes.first_name} ${deletedUser.attributes.last_name}`,
		email: deletedUser.attributes.email,
		userType: deletedUser.attributes.user_type,
		status: deletedUser.attributes.status,
		date: new Date(deletedUser.attributes.updated_at),
	};
};

export const adaptDeletedUsersFromApi = (
	deletedUsers: GetDeletedUsersResponse
): AdaptedDeletedUsers => {
	return {
		pagination: adaptPaginationFromApi(deletedUsers.links),
		list: deletedUsers.data
			.map(contract => adaptDeletedUserFromApi(contract))
			.sort((a, b) => b.date.valueOf() - a.date.valueOf()),
	};
};
