import { PaginatedApiResponse } from '../types/api/SharedApiTypes';

export type ListPagination = {
	currentPage: number;
	nextPage: number | null;
	numOfPages: number;
	isFinalPage: boolean;
};

export const adaptPaginationFromApi = (
	pagination: PaginatedApiResponse<object>['links']
): ListPagination => {
	if (!pagination) {
		return {
			currentPage: 1, //if null, set as page 1
			nextPage: null,
			numOfPages: 1, //if null, set as page 1
			isFinalPage: true,
		};
	}
	return {
		currentPage: pagination.current_page ?? 1, //if null, set as page 1
		nextPage: pagination.next_page,
		numOfPages: pagination.last_page ?? 1, //if null, set as page 1
		isFinalPage: pagination.current_page === pagination.last_page,
	};
};
