import React, { useEffect, useState } from 'react';
import { FlexWrapper, PageWrapperNoTabs } from '../../components/Wrappers';
import { ErrorMessage, PageTitle, SuccessMessage } from '../../components/Text';
import { BoldSeparator } from '../../components/Separator';
import { BaseButton } from '../../components/Buttons';
import { useAsync } from '../../hooks/useAsync';
import { MonthYearSelect } from '../../components/MonthYearSelect';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { webApiManager } from '../../network/apiManager';
import { CSVLink } from 'react-csv';
import {
	AdaptedMonthlyContractorReport,
	adaptMonthlyContractorReport,
} from '../../adapters/reportAdapters';
import {
	ColumnTitle,
	EndResultsMessage,
	NoResultsMessage,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../components/Table';
import LoadingSpinner from '../../components/Loader';
import { StatusDisplay } from '../../components/StatusDisplay';
import styled from 'styled-components';
import useSortAndPagination from '../../hooks/useSortFilterAndPagination';
import { adaptAdminsFromApi, AdaptedAdmin, AdaptedAdminList } from '../../adapters/adminAdapters';
import IconManager, { IconType } from '../../components/IconManager';
import { Modal } from '../../components/Modal';

enum UnlockResponse {
	success = 'Successful',
	error = 'Unsuccessful',
}

export const AdminSettings = () => {
	const [reportDate, setReportDate] = useState<Date | null>(null);
	const [csvData, setCsvData] = useState<AdaptedMonthlyContractorReport | null>(null);
	const [admins, setAdmins] = useState<AdaptedAdminList | null>(null);
	const [unlockResponseModal, setUnlockResponseModal] = useState<UnlockResponse | null>(null);

	useEffect(() => {
		//fetch list on load
		getAdmins.execute();
	}, []);

	useEffect(() => {
		//fetch report on date select
		if (reportDate) {
			getReport.execute();
		}
	}, [reportDate]);

	//TODO: may need to implement sort in future versions
	const onFetchAdmins = async (requestOptions: { page?: number } = {}) => {
		getAdmins.clearError();
		try {
			//fetch data and set to state
			const adminsResponse = adaptAdminsFromApi(
				await webApiManager.Users.getAdmins(requestOptions)
			);
			//if fetching next page, add to list and update pagination, otherwise replace
			setAdmins(prevState => {
				if (adminsResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...adminsResponse.list],
						pagination: adminsResponse.pagination,
					};
				} else {
					return adminsResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onFetchReport = async () => {
		try {
			if (reportDate) {
				const request = {
					month: getMonth(reportDate) + 1,
					year: getYear(reportDate),
				};
				//adapt data and set to state
				const reportData = adaptMonthlyContractorReport(
					await webApiManager.Reports.getMonthlyContractorReport(request)
				);
				setCsvData(reportData);
			} else {
				throw new Error('No report date selected.');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onUnlock = async (admin: AdaptedAdmin) => {
		try {
			if (admin) {
				const request = {
					userId: admin.id,
				};
				//adapt data and set to state
				const unlockUserResponse = await webApiManager.Users.unlockUser(request);
				if (unlockUserResponse) {
					setUnlockResponseModal(UnlockResponse.success);
				}
			} else {
				throw new Error('No admin selected.');
			}
		} catch (error) {
			console.error(error);
			setUnlockResponseModal(UnlockResponse.error);
			throw error;
		}
	};

	const getReport = useAsync<void>(
		onFetchReport,
		'Error getting report. Please check your connection and try again.'
	);
	const getAdmins = useAsync<void>(
		onFetchAdmins,
		'Error: could not get admin data. Please check your connection and try again.'
	);
	const handleUnlockAdmin = useAsync<void>(
		onUnlock,
		'Error: could not unlock user. Please check your connection and try again.'
	);

	//TODO: may need to implement sort in future versions
	//handle pagination for contractor list
	const { getNextPage } = useSortAndPagination(admins, getAdmins);

	return (
		<PageWrapperNoTabs padding={'0'}>
			<NonTableContent>
				<PageTitle>Monthly Insurance</PageTitle>
				<FlexWrapper>
					<MonthYearSelect
						onChange={newDate => setReportDate(newDate)}
						label={'Select Month/Year:'}
						disabled={getReport.pending}
					/>
					<CSVLink
						headers={csvData?.headers ?? []}
						data={csvData?.data ?? []}
						filename={csvData?.filename ?? ''}
						className="hidden"
						target="_blank"
					>
						<BaseButton
							text={'Download Report CSV'}
							pending={getReport.pending}
							disabled={!csvData || getReport.pending}
						/>
					</CSVLink>
				</FlexWrapper>
				{getReport.error && <ErrorMessage text={getReport.error} />}

				<BoldSeparator margin={'20px auto'} />

				{/*//TODO: Hiding admin/superAdmin permissions until needed*/}
				{/*<PageTitle>User Management</PageTitle>*/}
				{/*<InfoText textAlign={'left'} margin={'20px 5px 10px'}>*/}
				{/*	Super Admin Permissions*/}
				{/*</InfoText>*/}
				{/*<Separator margin={'0 0 20px'} />*/}
				{/*<InfoText textAlign={'left'} margin={'20px 5px 10px'}>*/}
				{/*	Admin Permissions*/}
				{/*</InfoText>*/}
				{/*<Separator margin={'0 0 20px'} />*/}
				{/*<BoldSeparator margin={'20px auto'} />*/}
			</NonTableContent>

			<Table
				tableTitle={'Admins'}
				onScrollToBottom={getNextPage}
				tableHeader={
					<>
						<ColumnTitle>Last Name</ColumnTitle>
						<ColumnTitle>First Name</ColumnTitle>
						<ColumnTitle>Email</ColumnTitle>
						<ColumnTitle>Status</ColumnTitle>
						<ColumnTitle>Manage</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!admins ? (
							<TableRow hideBorder hideHover>
								<TableItem colSpan={5}>
									{getAdmins.pending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={getAdmins.error ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{admins.list.length === 0 ? (
									<NoResultsMessage colSpan={5} />
								) : (
									<>
										{admins.list.map((admin, index) => (
											<TableRow key={index}>
												<TableItem textWrap>{admin.lastName}</TableItem>
												<TableItem textWrap>{admin.firstName}</TableItem>
												<TableItem textWrap>{admin.email}</TableItem>
												<TableItem>
													<StatusDisplay statusType={admin.status} pause={admin.pause} />
												</TableItem>
												{/*//TODO: check for locked status? */}
												<TableItem bold textWrap>
													<TableLinkWrapper onClick={() => handleUnlockAdmin.execute(admin)}>
														<TableLink>Unlock Account</TableLink>
														<IconManager
															type={IconType.CHEVRON_RIGHT}
															stroke={'#999'}
															size={14}
															strokeWidth={3}
														/>
													</TableLinkWrapper>
												</TableItem>
											</TableRow>
										))}
										{admins.pagination.isFinalPage && <EndResultsMessage colSpan={5} />}
									</>
								)}
							</>
						)}
					</>
				}
			/>
			<Modal
				isOpen={!!unlockResponseModal}
				hide={() => setUnlockResponseModal(null)}
				title={unlockResponseModal ?? ''}
				content={
					<>
						{unlockResponseModal === UnlockResponse.success ? (
							<SuccessMessage text={'Admin account has been unlocked.'} />
						) : (
							<ErrorMessage text={handleUnlockAdmin.error ?? 'Error unlocking admin account.'} />
						)}
					</>
				}
				hideConfirm
				cancelButtonText={'Close'}
			/>
		</PageWrapperNoTabs>
	);
};

const NonTableContent = styled.div`
	padding: 30px 40px 10px;
`;
