import _ from 'lodash';

export const buildQueryString = (params: { [key: string]: any }) => {
	let query = '?';
	_.forIn(params, (value, key) => {
		Array.isArray(value)
			? value.forEach(item => (query += `${key}[]=${item}&`))
			: (query += `${key}=${value}&`);
	});
	return query;
};
