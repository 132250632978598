import { AdaptedContractor } from '../../../../adapters/contractorAdapters';
import React, { useEffect, useState } from 'react';
import {
	AdaptedProfession,
	adaptProfessionsFromApi,
} from '../../../../adapters/professionsAdapter';
import { useAsync } from '../../../../hooks/useAsync';
import { webApiManager } from '../../../../network/apiManager';
import { ContractorExperienceForm } from '../../components/ContractorExperienceForm';

type ContractorApprovalDataTabProps = {
	selectedContractor: AdaptedContractor;
	refreshSelectedContractor: () => void;
	pendingRefresh: boolean;
};

export const ContractorApprovalDataTab = ({
	selectedContractor,
	refreshSelectedContractor,
	pendingRefresh,
}: ContractorApprovalDataTabProps) => {
	const [professions, setProfessions] = useState<AdaptedProfession[]>([]);

	useEffect(() => {
		getProfessions.execute();
		return () => getProfessions.clearError();
	}, []);

	const onGetProfessions = async () => {
		try {
			const professionsList = adaptProfessionsFromApi(
				await webApiManager.Professions.getProfessions()
			);

			setProfessions(professionsList);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getProfessions = useAsync<void>(onGetProfessions, 'Error getting professions');

	return (
		<ContractorExperienceForm
			onChange={() => {}}
			professions={professions}
			initialProfessions={[]}
			selectedContractor={selectedContractor}
			refreshSelectedContractor={refreshSelectedContractor}
			pendingRefresh={pendingRefresh}
		/>
	);
};
