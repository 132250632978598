import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from '../../../components/Tabs';
import { AuthRouteNames, TabHash, UrlParamsType } from '../../../navigation/routeNames';
import { ContractorOverviewTab } from './tabs/ContractorOverviewTab';
import { ContractorAccountTab } from './tabs/ContractorAccountTab';
import { ContractorMessagingTab } from './tabs/ContractorMessagingTab';
import { adaptContractorFromApi, AdaptedContractor } from '../../../adapters/contractorAdapters';
import { useAsync } from '../../../hooks/useAsync';
import LoadingSpinner from '../../../components/Loader';
import { ErrorMessage } from '../../../components/Text';
import { ReturnHomeLink } from '../../../components/Links';
import { PageWrapperNoTabs } from '../../../components/Wrappers';
import { HeaderNameContext } from '../../../context/HeaderNameContext';
import { webApiManager } from '../../../network/apiManager';
import { ContractorApprovalDataTab } from './tabs/ContractorApprovalDataTab';

export const ContractorDetails = () => {
	const { contractorId } = useParams<UrlParamsType>();
	const { setHeaderName } = useContext(HeaderNameContext);
	const [selectedContractor, setSelectedContractor] = useState<AdaptedContractor | null>(null);

	useEffect(() => {
		//fetch selected contractor on load
		getContractor.execute();

		return () => {
			setHeaderName('');
		};
	}, []);

	useEffect(() => {
		//when contractor is fetched, set contractor name in nav header
		if (selectedContractor) {
			setHeaderName(`${selectedContractor.firstName} ${selectedContractor.lastName}`);
		} else {
			setHeaderName('');
		}
	}, [selectedContractor]);

	const onLoad = async () => {
		getContractor.clearError();
		try {
			//fetch selected contractor and set to state
			const getContractorResponse = adaptContractorFromApi(
				await webApiManager.Contractors.getContractor({ contractorId })
			);
			setSelectedContractor(getContractorResponse);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getContractor = useAsync<void>(onLoad, 'Error: Could not find contractor.');

	return (
		<>
			{!selectedContractor ? (
				<PageWrapperNoTabs padding={'30px'}>
					{getContractor.pending ? (
						<LoadingSpinner />
					) : (
						<>
							<ErrorMessage text={getContractor.error ?? 'Could not find contractor'} />
							<ReturnHomeLink />
						</>
					)}
				</PageWrapperNoTabs>
			) : (
				<Tabs
					mainPath={`${AuthRouteNames.ManageContractors}/${contractorId}`}
					tabs={[
						{
							title: 'Overview',
							component: <ContractorOverviewTab selectedContractor={selectedContractor} />,
							hash: TabHash.overview,
						},
						{
							title: 'Account',
							component: (
								<ContractorAccountTab
									selectedContractor={selectedContractor}
									setSelectedContractor={setSelectedContractor}
									refreshSelectedContractor={() => getContractor.execute()}
									pendingRefresh={getContractor.pending}
								/>
							),
							hash: TabHash.account,
						},
						{
							title: 'Messages',
							component: <ContractorMessagingTab selectedContractor={selectedContractor} />,
							hash: TabHash.messages,
						},
						{
							title: 'Approval Data',
							component: (
								<ContractorApprovalDataTab
									selectedContractor={selectedContractor}
									refreshSelectedContractor={() => getContractor.execute()}
									pendingRefresh={getContractor.pending}
								/>
							),
							hash: TabHash.data,
							hasTable: true,
						},
					]}
				/>
			)}
		</>
	);
};
