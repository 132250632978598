import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';

type CheckboxProps = {
	label: string;
	onChange: (newVal: boolean) => void;
	alignLeft?: boolean;
	margin?: string;
	shouldClear?: boolean;
	initialValue?: boolean;
};

export const CheckboxInput = ({
	label,
	onChange,
	alignLeft,
	margin,
	shouldClear,
	initialValue,
	...rest
}: CheckboxProps) => {
	const [isChecked, setIsChecked] = useState<boolean>(!!initialValue);

	useEffect(() => {
		onChange(isChecked);
	}, [isChecked]);

	useEffect(() => {
		if (shouldClear) {
			setIsChecked(false);
		}
	}, [shouldClear]);

	return (
		<CheckboxInputWrapper alignLeft={alignLeft} margin={margin}>
			<StyledCheckboxInput
				type={'checkbox'}
				// defaultChecked={isChecked}
				checked={isChecked}
				onChange={() => setIsChecked(prevState => !prevState)}
				{...rest}
			/>
			<CheckboxLabel>{label}</CheckboxLabel>
		</CheckboxInputWrapper>
	);
};

const StyledCheckboxInput = styled.input`
	display: inline-block;
	height: 14px;
	width: 14px;
	padding: 0;
	margin: 0 5px 0 0;
`;

const CheckboxLabel = styled.p`
	display: inline-block;
	text-align: left;
	font-family: ${Theming.text.boldFont};
	font-size: 12px;
	width: auto;
	padding: 0;
	margin: 0 0 0 5px;
`;

const CheckboxInputWrapper = styled.label<{ alignLeft?: boolean; margin?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};
	text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
	padding: 5px;
	margin: ${({ margin }) => margin ?? '20px auto'};
	cursor: pointer;
	color: ${Theming.text.primaryTextColor};

	&:hover {
		color: ${Theming.buttons.primaryButtonColor};
	}
`;
