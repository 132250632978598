import { UUID } from '../types/sharedTypes';
import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import {
	GetContractorExperienceTagsResponse,
	DestroyContractorExperienceTagResponse,
	CreateContractorTagRequest,
	CreateContractorTagResponse,
} from '../types/api/ContractorExperienceTags';

export class ContractorExperienceTagsApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getContractorExperienceTags(): Promise<GetContractorExperienceTagsResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const response = await fetchWrapper(
				{
					path: `tags`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor experience Tags api', JSON.stringify(error));
			throw error;
		}
	}

	public async destroyContractorExperienceTag(
		id: UUID
	): Promise<DestroyContractorExperienceTagResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getContractorExperienceTagsResponse = await fetchWrapper(
				{
					path: `tags/${id}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			);

			return getContractorExperienceTagsResponse.data;
		} catch (error) {
			console.error('error in contractor experience Tags api', JSON.stringify(error));
			throw error;
		}
	}

	public async createContractorTag(
		request: CreateContractorTagRequest
	): Promise<CreateContractorTagResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				name: request.name,
			};

			const response = await fetchWrapper(
				{
					path: `tags`,
					method: 'POST',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}
}
