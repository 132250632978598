import React, { useEffect, useState } from 'react';
import { useAsync } from '../../../../../hooks/useAsync';
import LoadingSpinner from '../../../../../components/Loader';
import { ErrorMessage } from '../../../../../components/Text';
import { FacilityUserForm } from './FacilityUserForm';
import { LabeledSelect } from '../../../../../components/Select';
import { webApiManager } from '../../../../../network/apiManager';
import { AdaptedFacilityLocation } from '../../../../../adapters/locationsAdapters';
import { AdaptedFacilityUser } from '../../../../../adapters/facilityAdapters';
import {
	AdaptedLocationUser,
	adaptLocationUserFromApi,
} from '../../../../../adapters/locationUsersAdapters';
import { UUID } from '../../../../../types/sharedTypes';

type AddNewFacilityUserProps = {
	id: UUID;
	locations: AdaptedFacilityLocation[];
	facilityUsers: AdaptedFacilityUser[];
	closeSidebar: () => void;
};

type CreateFacilityUserData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
};

type LocationUserOptions = {
	value: AdaptedLocationUser;
	label: string;
};

export const AddNewFacilityUser = ({
	id,
	locations,
	facilityUsers,
	closeSidebar,
}: AddNewFacilityUserProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [selectedUser, setSelectedUser] = useState<AdaptedLocationUser | null>(null);
	const [locationUsers, setLocationUsers] = useState<LocationUserOptions[] | []>([]);

	const getAllLocationUsersForLocation = async locationId => {
		let allResults: AdaptedLocationUser[] = [];
		let page = 1;
		let isFullyLoaded = false;

		while (!isFullyLoaded) {
			const results = await webApiManager.LocationUsers.getLocationUsers({
				locationId,
				query: {
					page,
				},
			});

			if (!results.links.next_page) {
				isFullyLoaded = true;
			}

			page++;

			const users = results.data.map(adaptLocationUserFromApi);
			allResults = allResults.concat(users);
		}

		return allResults;
	};

	useEffect(() => {
		getLocationUsers.execute();
	}, []);

	useEffect(() => {
		if (selectedUser) {
			//disable editing when user is selected
			setIsEditing(false);
		}
	}, [selectedUser]);

	const onLoad = async () => {
		getLocationUsers.clearError();

		try {
			const promises = locations.map(location => {
				return async () => getAllLocationUsersForLocation(location.locationId);
			});

			const getUsers = async () => {
				const locationUsers = await Promise.all(
					promises.map(fn => (fn ? fn() : Promise.resolve()))
				);

				const usersById = {};
				locationUsers.flat().forEach(locationUser => {
					const u = locationUser as AdaptedLocationUser;

					if (facilityUsers.some(user => user.userId === u.userId)) {
						return;
					}

					usersById[u.id] = {
						label: !!u.firstName && !!u.lastName ? `${u.lastName}, ${u.firstName}` : u.email,
						value: u,
					};
				});

				setLocationUsers(Object.values(usersById));
			};

			await getUsers();
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onAdd = async () => {
		addFacilityUser.clearError();
		setIsEditing(false);
		try {
			if (selectedUser) {
				const request = {
					facilityId: id,
					userId: selectedUser.userId,
				};
				await webApiManager.Facilities.addFacilityUser(request);
				//close sidebar on success
				closeSidebar();
			}
		} catch (error) {
			setIsEditing(true);
			console.error(error);
			throw error;
		}
	};

	const onCreate = async (formData: CreateFacilityUserData) => {
		createFacilityUser.clearError();
		setIsEditing(false);
		try {
			const request = {
				facilityId: id,
				facilityUser: {
					first_name: formData.firstName,
					last_name: formData.lastName,
					email: formData.email,
					phone: formData.phone,
				},
			};
			await webApiManager.Facilities.createFacilityUser(request);
			closeSidebar();
		} catch (error) {
			setIsEditing(true);
			console.error(error);
			throw error;
		}
	};

	const getLocationUsers = useAsync<void>(
		onLoad,
		'Error: could not find users for this facility. Please check your connection and try again.'
	);
	const addFacilityUser = useAsync<void>(
		onAdd,
		'Error adding user to facility. Please check your connection and try again.'
	);
	const createFacilityUser = useAsync<void>(
		onCreate,
		'Error creating user for facility. Please check your connection and try again.'
	);

	return (
		<>
			{getLocationUsers.pending || addFacilityUser.pending || createFacilityUser.pending ? (
				<LoadingSpinner />
			) : (
				<>
					<LabeledSelect
						label={'Select user to add as a facility user'}
						options={locationUsers}
						onChange={newVal => setSelectedUser(newVal)}
						isClearable
					/>
					{getLocationUsers.error && <ErrorMessage text={getLocationUsers.error} />}
					<FacilityUserForm
						key={JSON.stringify(selectedUser)}
						selectedFacilityUser={selectedUser}
						isEditing={isEditing}
						setIsEditing={setIsEditing}
						onSave={formData =>
							selectedUser
								? addFacilityUser.execute(formData)
								: createFacilityUser.execute(formData)
						}
						hideEdit
					/>
					{addFacilityUser.error && <ErrorMessage text={addFacilityUser.error} />}
					{createFacilityUser.error && <ErrorMessage text={createFacilityUser.error} />}
				</>
			)}
		</>
	);
};
