import React, { useEffect, useState } from 'react';
import { AdaptedProfession } from '../../../adapters/professionsAdapter';
import styled from 'styled-components';
import { BaseButton } from '../../../components/Buttons';
import useOutsideClick from '../../../hooks/useOutsideClick';
import IconManager, { IconType } from '../../../components/IconManager';
import { Theming } from '../../../theming';

type AddJobMenuProps = {
	availableJobTypes: AdaptedProfession[];
	disabled?: boolean;
	addNewProfessionCard: (profession: AdaptedProfession) => void;
};

export const AddJobMenu = ({
	availableJobTypes,
	disabled,
	addNewProfessionCard,
}: AddJobMenuProps) => {
	const [showMenu, setShowMenu] = useState<boolean>(false);

	//to close menu when clicking anywhere else in window
	const menuRef = useOutsideClick(() => setShowMenu(false));

	// Collapse menu if there's nothing left:
	useEffect(() => {
		if (availableJobTypes.length === 0) {
			setShowMenu(false);
		}
	}, [availableJobTypes]);

	return (
		<MenuContainer ref={menuRef}>
			<BaseButton
				text={'+ ADD JOB TYPE'}
				width={100}
				disabled={disabled || availableJobTypes.length === 0}
				onClick={() => setShowMenu(prevState => !prevState)}
			/>
			{showMenu && (
				<MenuContentWrapper>
					{availableJobTypes.map(jobType => (
						<MenuItem
							key={jobType.name}
							onClick={() => {
								addNewProfessionCard(jobType);
								// setShowMenu(false);
							}}
						>
							{jobType.name}
							<IconWrapper>
								<IconManager
									type={IconType.PLUS_SQUARE}
									fill={Theming.icons.primaryIconColor}
									stroke={'#FFF'}
									size={20}
								/>
							</IconWrapper>
						</MenuItem>
					))}
				</MenuContentWrapper>
			)}
		</MenuContainer>
	);
};

const MenuContainer = styled.div`
	position: relative;
	min-width: 30%;
	overflow: visible;
	z-index: 200;
`;

const MenuContentWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 95%;
	width: 100%;
	max-height: 200px;
	overflow: auto;
	border-radius: 5px;
	box-shadow: 2px 2px 5px 2px rgba(100, 100, 100, 0.15);
	background-color: #fff;
`;

const MenuItem = styled.div`
	user-select: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	margin: 0;
	background-color: transparent;
	font-family: ${Theming.text.regularFont};
	color: ${Theming.text.labelColor};
	font-weight: bold;
	:hover& {
		background-color: #f4f4f4;
		cursor: pointer;
	}
`;

const IconWrapper = styled.div`
	width: 20px;
`;
