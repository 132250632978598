import { FacilityType } from '../types/api/FacilityTypes';
import { UUID } from '../types/sharedTypes';

export type AdaptedFacilityType = {
	id: UUID;
	name: string;
};

export const adaptFacilityTypeFromApi = (facilityType: FacilityType): AdaptedFacilityType => {
	return {
		id: facilityType.id,
		name: facilityType.attributes.name,
	};
};

export const adaptFacilityTypesFromApi = (facilityTypes: FacilityType[]): AdaptedFacilityType[] => {
	return facilityTypes.map(facilityType => {
		return adaptFacilityTypeFromApi(facilityType);
	});
};
