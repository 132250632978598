import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { StatusType, UUID } from '../sharedTypes';
import { FacilityType } from './FacilityTypes';
import { UserDetails } from './Auth';
import { FacilityLocation } from './Locations';
import { Kudos, Rating } from './Reviews';
import { Pause } from './Pauses';

export enum FacilitySortOptions {
	name = 'name',
	numLocations = 'number_of_locations',
	numUsers = 'number_of_users',
	status = 'status',
}

export type GetFacilitiesRequest = PaginatedApiRequest<FacilitySortOptions>;

export type GetFacilitiesResponse = PaginatedApiResponse<Facility[]>;

export interface GetFacilityRequest {
	facilityId: UUID;
}

export enum FacilityUserSortOptions {}
// lastName = 'last_name',
// firstName = 'first_name',
// email = 'email',
// status = 'status',
// roles = 'roles',

export type GetFacilityResponse = Facility;

export interface Facility {
	id: UUID;
	type: 'facilities';
	meta: {
		number_of_locations: number;
		number_of_users: number;
		kudos: Kudos;
		rating: Rating;
	};
	attributes: {
		name: string;
		status: StatusType;
	};
	relationships: {
		facility_types: FacilityType[];
		facility_users: FacilityUser[];
		locations?: FacilityLocation[];
	};
}

export interface FacilityUser {
	id: UUID;
	type: 'facility_users';
	attributes: {
		facility_id: UUID;
		user_id: UUID;
	};
	relationships: {
		user: UserDetails;
		resource_pauses?: Pause[];
	};
}

export interface GetFacilityUsersRequest {
	facilityId: UUID;
}

export type GetFacilityUsersResponse = UserDetails[];

export interface GetFacilityUserRequest {
	facilityUserId: UUID;
}

export type GetFacilityUserResponse = FacilityUser;

export interface UpdateFacilityUserRequest {
	facilityUserId: UUID;
	updates: {
		first_name?: string;
		last_name?: string;
		email?: string;
		phone?: string;
	};
}

export type UpdateFacilityUserResponse = FacilityUser;

export interface CreateFacilityUserRequest {
	facilityId: UUID;
	facilityUser: {
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
	};
}

export type CreateFacilityUserResponse = FacilityUser;

export interface AddFacilityUserRequest {
	facilityId: UUID;
	userId: UUID;
}

export type AddFacilityUserResponse = FacilityUser;
