import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import UserContextProvider from './context/UserContext';
import HeaderNameContextProvider from './context/HeaderNameContext';

(async () => {
	render(
		<UserContextProvider>
			<HeaderNameContextProvider>
				<Router>
					<App />
				</Router>
			</HeaderNameContextProvider>
		</UserContextProvider>,
		document.getElementById('app')
	);
})();
