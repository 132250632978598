import React, { ReactNode, useState } from 'react';
import IconManager, { IconType } from '../../../components/IconManager';
import { Theming } from '../../../theming';
import { Separator } from '../../../components/Separator';
import styled from 'styled-components';

export const FilterSection = ({ title, children }: { title: string; children: ReactNode }) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	return (
		<FilterSectionWrapper>
			<FilterSectionHeader>
				<FilterSectionTitle>{title}</FilterSectionTitle>
				<IconManager
					type={isOpen ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
					size={18}
					stroke={Theming.text.placeholderColor}
					onClick={() => {
						setIsOpen(prevState => !prevState);
					}}
				/>
			</FilterSectionHeader>
			<FilterSectionContent isOpen={isOpen}>{children}</FilterSectionContent>
			<Separator margin={'5px auto'} />
		</FilterSectionWrapper>
	);
};

const FilterSectionWrapper = styled.div`
	padding: 15px 0;
	width: 100%;
`;

const FilterSectionHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	margin: 0;
`;

export const FilterSectionTitle = styled.p`
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	color: ${Theming.text.titleColor};
	padding: 0;
	margin: 0;
`;

const FilterSectionContent = styled.div<{ isOpen: boolean }>`
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	padding: 15px 0;
	margin: 0;
`;
