export const getFileNameFromHeaders = contentDispositionHeaders => {
	let filename = '';

	if (contentDispositionHeaders && contentDispositionHeaders.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(contentDispositionHeaders);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}

	return filename;
};
