import { getFileNameFromHeaders } from '../utils/getFileNameFromHeaders';

const slashifyUrlStart = (url: string): string => (url[0] === '/' ? url : `/${url}`);
const prependApiUrl = (endpoint: string, url: string) => `${url}${slashifyUrlStart(endpoint)}`;

type headers = Record<string, unknown>;
type cache = 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
type method = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
type mode = 'same-origin' | 'no-cors' | 'cors' | 'navigate';
type redirect = 'follow' | 'manual' | 'error';
type referrer = 'client' | 'no-referrer';

interface GenericRequest {
	path: string;
	params?: Record<string, unknown>;
	body?: any;
	token?: string | null | undefined;
	headers?: headers;
	mode?: mode;
	method?: method;
	cache?: cache;
	redirect?: redirect;
	referrer?: referrer;
}

interface FetchConfig {
	headers: any;
	cache: cache;
	method: method;
	mode: mode;
	redirect: redirect;
	referrer: referrer;
	body?: string; //expects it to be result of JSON.stringify()
}

const FetchWrapper = async (
	request: GenericRequest,
	baseApiUrl: string,
	options: { accept?: string; responseType?: 'json' | 'blob' } = {
		accept: 'application/json',
		responseType: 'json',
	}
) => {
	const {
		path,
		body,
		method = 'GET',
		headers: suppliedHeaders = {},
		cache = 'default',
		mode = 'cors',
		token = null,
		redirect = 'follow',
		referrer = 'client',
	} = request;

	if (!path) {
		throw new Error(`'path' property not specified for fetch call`);
	}
	const url: string = prependApiUrl(path, baseApiUrl);

	const fetchConfig: Partial<FetchConfig> = {
		headers: buildRequestHeaders(suppliedHeaders, token, options.accept),
		cache,
		method,
		mode,
		redirect,
		referrer,
	};

	if (method !== 'GET') {
		fetchConfig.body = JSON.stringify(body);
	}

	const response = await fetch(url, fetchConfig);

	if (response.ok) {
		if (options.responseType === 'json') {
			return response.json();
		} else if (options.responseType === 'blob') {
			const responseObj = {
				filename: getFileNameFromHeaders(response.headers.get('content-disposition')),
				blob: await response.blob(),
			};
			return responseObj;
		}
	}
	throw await response.json();
};

function buildRequestHeaders(
	additionalHeaders: any,
	token: string | null,
	accept = 'application/json'
): any {
	const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
	return {
		Accept: accept,
		'Content-Type': 'application/json',
		...authHeaders,
		...additionalHeaders,
	};
}

export default FetchWrapper;
