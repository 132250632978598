import React, { useContext } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import { Link } from 'react-router-dom';
import { NonAuthRouteNames } from '../navigation/routeNames';
import IconManager, { IconType } from './IconManager';
import { UserContext } from '../context/UserContext';

export const ReturnHomeLink = () => {
	const { userAuthorized } = useContext(UserContext);

	return (
		<InternalLink to={NonAuthRouteNames.Login}>
			<IconManager
				type={IconType.BACK_ARROW_CIRCLE}
				size={16}
				stroke={Theming.text.primaryLinkColor}
				fill={'none'}
			/>
			&nbsp; Return {userAuthorized ? 'Home' : 'to Login'}
		</InternalLink>
	);
};

export const LinkStyledButton = styled.button`
	display: inline-block;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.secondaryLinkColor};
	font-size: 14px;
	text-decoration: none;
	padding: 5px;
	margin: 0;
	border: none;
	background-color: transparent;
	:hover& {
		cursor: pointer;
	}
`;

export const FormLink = styled.button<{ secondary?: boolean; margin?: string; color?: string }>`
	display: inline-block;
	font-family: ${Theming.text.regularFont};
	color: ${({ color, secondary }) =>
		color ?? (!secondary ? Theming.text.primaryLinkColor : Theming.text.secondaryLinkColor)};
	font-size: 15px;
	font-weight: bold;
	text-decoration: underline;
	padding: 0;
	margin: ${({ margin }) => margin ?? '10px'};
	width: auto;
	border: none;
	background-color: transparent;
	:hover& {
		cursor: pointer;
	}
`;

export const InternalLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${Theming.text.regularFont};
	font-size: 16px;
	color: ${Theming.text.primaryLinkColor};
	text-decoration: none;
	padding: 15px;
`;
