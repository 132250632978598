import React, { useContext } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { Login } from './pages/auth/Login';
import './App.css';
import { AuthRoute, NonAuthRoute } from './navigation/RouteComponents';
import { Unauthorized } from './pages/Unauthorized';
import { Offline } from './pages/Offline';
import { AuthRouteNames, NonAuthRouteNames } from './navigation/routeNames';
import { NotFound } from './pages/NotFound';
import { ContractorList } from './pages/contractors/manageContractors/ContractorList';
import { Contracts } from './pages/contracts/Contracts';
import { FacilityList } from './pages/facilities/manageFacilities/FacilityList';
import { AppContainerWithNav } from './pages/nav/AppContainerWithNav';
import { AppContainerNoNav } from './components/Wrappers';
import { ManageContractorInvites } from './pages/contractors/manageInvites/ManageContractorInvites';
import { ManageFacilityInvites } from './pages/facilities/manageInvites/ManageFacilityInvites';
import { CreateAccount } from './pages/auth/CreateAccount';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { MobileRedirect, MobileRedirectMessage } from './pages/auth/MobileRedirect';
import { UserContext } from './context/UserContext';
import LoadingSpinner from './components/Loader';
import { MobileAppSettings } from './pages/mobileAppSettings/MobileAppSettings';
import { AdminSettings } from './pages/adminSettings/AdminSettings';
import { Account } from './pages/account/Account';
import { FacilityDetails } from './pages/facilities/manageFacilities/FacilityDetails';
import { ContractorDetails } from './pages/contractors/manageContractors/ContractorDetails';
import { DeletedUsers } from './pages/deletedUsers/DeletedUsers';
import { ResetPassword } from './pages/auth/ResetPassword';
import { Location } from './pages/facilities/manageFacilities/Location';
import { IdleTimeoutWrapper } from './components/IdleTimeoutWrapper';
import { webApiManager } from './network/apiManager';
import { minutesToMS } from './utils/minutesToMS';
import 'react-datepicker/dist/react-datepicker.css';

export default function App() {
	const { userLoading, userAuthorized, currentUser, setCurrentUser } = useContext(UserContext);
	const history = useHistory();

	return (
		<>
			{!userAuthorized || !currentUser ? (
				<>
					{userLoading ? (
						<LoadingSpinner />
					) : (
						<AppContainerNoNav>
							<Switch>
								<NonAuthRoute path={NonAuthRouteNames.Login} exact component={<Login />} />
								<NonAuthRoute
									path={NonAuthRouteNames.CreateAccount}
									component={<CreateAccount />}
								/>
								<NonAuthRoute
									path={NonAuthRouteNames.ForgotPassword}
									component={<ForgotPassword />}
								/>
								<NonAuthRoute
									path={NonAuthRouteNames.ResetPassword}
									component={<ResetPassword />}
								/>
								<NonAuthRoute
									path={NonAuthRouteNames.MobileRedirect}
									component={<MobileRedirect />}
								/>
								<NonAuthRoute path={NonAuthRouteNames.Offline} exact component={<Offline />} />
								<NonAuthRoute component={<Unauthorized />} />
							</Switch>
						</AppContainerNoNav>
					)}
				</>
			) : (
				<IdleTimeoutWrapper
					onLogout={async () =>
						await webApiManager.Auth.logout(setCurrentUser, () =>
							history.push(NonAuthRouteNames.Login)
						)
					}
					onReset={() => webApiManager.resetTokenExpiration()}
					countdownTime={minutesToMS(19)}
				>
					<AppContainerWithNav>
						<Switch>
							<AuthRoute
								path={'/'}
								component={<Redirect to={AuthRouteNames.Contracts} />}
								requiredRoles={[]}
								exact
							/>
							<AuthRoute
								path={AuthRouteNames.ManageContractors}
								component={<ContractorList />}
								requiredRoles={[]}
								exact
							/>
							<AuthRoute
								path={AuthRouteNames.ContractorDetails}
								component={<ContractorDetails />}
								requiredRoles={[]}
							/>
							<AuthRoute
								path={AuthRouteNames.ContractorInvites}
								component={<ManageContractorInvites />}
								requiredRoles={[]}
							/>
							<AuthRoute
								path={AuthRouteNames.Contracts}
								component={<Contracts />}
								requiredRoles={[]}
								exact
							/>
							<AuthRoute
								path={AuthRouteNames.ManageFacilities}
								component={<FacilityList />}
								requiredRoles={[]}
								exact
							/>
							<AuthRoute
								path={AuthRouteNames.FacilityInvites}
								component={<ManageFacilityInvites />}
								requiredRoles={[]}
							/>
							<AuthRoute
								path={AuthRouteNames.FacilityDetails}
								component={<FacilityDetails />}
								requiredRoles={[]}
								exact
							/>
							<AuthRoute
								path={AuthRouteNames.ManageLocation}
								component={<Location />}
								requiredRoles={[]}
							/>
							<AuthRoute
								path={AuthRouteNames.DeletedUsers}
								component={<DeletedUsers />}
								requiredRoles={[]}
							/>
							<AuthRoute
								path={AuthRouteNames.MobileAppSettings}
								component={<MobileAppSettings />}
								requiredRoles={[]}
							/>
							<AuthRoute
								path={AuthRouteNames.AdminSettings}
								component={<AdminSettings />}
								requiredRoles={[]}
							/>
							<AuthRoute path={AuthRouteNames.Account} component={<Account />} requiredRoles={[]} />
							<AuthRoute
								path={NonAuthRouteNames.MobileRedirect}
								component={<MobileRedirectMessage />}
								requiredRoles={[]}
							/>
							<AuthRoute component={<NotFound />} requiredRoles={[]} />
						</Switch>
					</AppContainerWithNav>
				</IdleTimeoutWrapper>
			)}
		</>
	);
}
