import React from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';

export default function LoadingSpinner({ size }: { size?: number }) {
	return <PageSpinner size={size} />;
}

const PageSpinner = styled.div<{ size?: number }>`
	border: ${({ size }) => (size ? size / 7.5 : 16)}px solid #f3f3f3; /* Light grey */
	border-top: ${({ size }) => (size ? size / 7.5 : 16)}px solid ${Theming.gradients.mediumGradient};
	border-radius: 50%;
	width: ${({ size }) => size ?? 120}px;
	height: ${({ size }) => size ?? 120}px;
	animation: spin 2s linear infinite;
	margin: 8px auto;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export function SmallLoadingSpinner() {
	return <SmallSpinner />;
}

const SmallSpinner = styled.div`
	border: 15px solid #f3f3f3; /* Light grey */
	border-top: 15px solid ${Theming.gradients.mediumGradient};
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 2s linear infinite;
	margin: 8px auto;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export function MiniLoadingSpinner() {
	return <MiniSpinner />;
}

const MiniSpinner = styled.div`
	display: inline-block;
	border: 5px solid #d7e4fd; /* Light grey */
	border-top: 5px solid #849ebf;
	border-radius: 50%;
	width: 26px;
	height: 26px;
	animation: spin 2s linear infinite;
	margin: 0px auto;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
