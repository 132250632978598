import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { AuthRouteNames } from '../../../navigation/routeNames';
import { NavLinks } from './NavBar';
import { NavTitle, NavTitleWrapper } from './NavTitle';

type SubNavProps = {
	title: NavLinks | null;
	showMenu: boolean;
	type: NavLinks | null;
};

export const SubNav = ({ showMenu, title, type }: SubNavProps) => {
	const history = useHistory();
	const currentPath = useLocation().pathname;

	const handleClick = (path: string) => {
		history.push(path);
	};

	return (
		<SubNavWrapper showMenu={showMenu}>
			{showMenu && (
				<>
					<NavTitleWrapper showMenu={showMenu}>
						<NavTitle>{title ?? 'Home'}</NavTitle>
					</NavTitleWrapper>
					{type === NavLinks.FACILITIES ? (
						<NavLinkWrapper>
							<SubNavLink
								active={currentPath.includes(AuthRouteNames.ManageFacilities)}
								onClick={() => handleClick(AuthRouteNames.ManageFacilities)}
							>
								Manage Facilities
							</SubNavLink>
							<SubNavLink
								active={currentPath.includes(AuthRouteNames.FacilityInvites)}
								onClick={() => handleClick(AuthRouteNames.FacilityInvites)}
							>
								Manage Invites
							</SubNavLink>
						</NavLinkWrapper>
					) : type === NavLinks.CONTRACTORS ? (
						<NavLinkWrapper>
							<SubNavLink
								active={currentPath.includes(AuthRouteNames.ManageContractors)}
								onClick={() => handleClick(AuthRouteNames.ManageContractors)}
							>
								Manage Contractors
							</SubNavLink>
							<SubNavLink
								active={currentPath.includes(AuthRouteNames.ContractorInvites)}
								onClick={() => handleClick(AuthRouteNames.ContractorInvites)}
							>
								Manage Invites
							</SubNavLink>
						</NavLinkWrapper>
					) : (
						<>Not found</>
					)}
				</>
			)}
		</SubNavWrapper>
	);
};

const NavLinkWrapper = styled.div`
	padding: 20px 0;
	border-top: 2px solid ${Theming.nav.navTitleBorder};
`;

const SubNavWrapper = styled.div<{ showMenu: boolean }>`
	box-sizing: border-box;
	min-width: ${({ showMenu }) => (showMenu ? 160 : 0)}px;
	background-color: ${Theming.nav.subNavBackground};
	width: auto;
	padding: 0;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
	clip-path: inset(0px -4px 0px 0px);
`;

const SubNavLink = styled.p<{ active?: boolean }>`
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	margin: 0 auto;
	padding: 15px; //needs to match padding for title
	text-align: left;
	color: ${({ active }) => (active ? Theming.nav.subNavActiveLink : Theming.nav.subNavLink)};
	background-color: ${({ active }) =>
		active ? Theming.nav.subNavActiveLinkBackground : 'transparent'};
	cursor: pointer;
`;
