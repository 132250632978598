import React from 'react';
import styled from 'styled-components';

import { Theming } from '../theming';
import { ErrorMessage } from './Text';

const newLineRegex = /\n/g;

export enum ToggleEditableFieldElementType {
	INPUT = 'input',
	TEXTAREA = 'textarea',
}

type ToggleEditableFieldProps = {
	isEditing?: boolean;
	error?: string;
	placeholder?: string;
	value: any;
	onChange: (v: any) => void;
	elementType: ToggleEditableFieldElementType;
	renderText?: (value: any) => string | Element;
	[key: string]: any;
};

const getElementFromType = (elementType: ToggleEditableFieldElementType): React.ElementType => {
	switch (elementType) {
		case ToggleEditableFieldElementType.TEXTAREA:
			return StyledTextArea;

		case ToggleEditableFieldElementType.INPUT:
		default:
			return StyledInput;
	}
};

export const ToggleEditableField = ({
	isEditing,
	error,
	value,
	onChange,
	placeholder,
	elementType,
	renderText,
	...rest
}: ToggleEditableFieldProps) => {
	if (isEditing) {
		const Element = getElementFromType(elementType);

		return (
			<>
				<Element
					value={value}
					isError={!!error}
					onChange={event => onChange(event.target.value.replace(newLineRegex, ''))}
					placeholder={placeholder}
					{...rest}
				/>
				{error ? <ErrorMessage text={error} /> : null}
			</>
		);
	}

	if (typeof value === 'undefined' || value === null || value === '') {
		return <Placeholder>{placeholder}</Placeholder>;
	}

	return renderText ? renderText(value) : value;
};

export const Placeholder = styled.span`
	color: ${Theming.text.placeholderColor};
`;

const StyledInput = styled.input<{ isError?: boolean }>`
	font-family: ${Theming.text.regularFont};
	width: 100%;
	height: 100%;
	border-radius: 5px;
	padding: 5px;
	box-shadow: inset 0px 1px 3px rgb(0 0 0 / 15%);
	border: ${({ isError }) => `1px solid ${isError ? Theming.errorColor : 'rgba(0, 0, 0, 0.25)'}`};
`;

const StyledTextArea = styled.textarea<{ isError?: boolean }>`
	font-family: ${Theming.text.regularFont};
	width: 100%;
	height: 100%;
	border-radius: 5px;
	padding: 5px;
	box-shadow: inset 0px 1px 3px rgb(0 0 0 / 15%);
	min-height: 60px;
	border: ${({ isError }) => `1px solid ${isError ? Theming.errorColor : 'rgba(0, 0, 0, 0.25)'}`};
`;
