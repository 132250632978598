import React from 'react';
import { PageWrapperNoTabs } from '../components/Wrappers';
import { InfoText, NonAuthFormTitle } from '../components/Text';
import { ReturnHomeLink } from '../components/Links';

export const NotFound = () => {
	return (
		<PageWrapperNoTabs padding={'25px 0'}>
			<NonAuthFormTitle>Not Found</NonAuthFormTitle>
			<InfoText fontSize={16} margin={'15px auto 30px'}>
				Could not find page. Please check URL and try again.
			</InfoText>
			<ReturnHomeLink />
		</PageWrapperNoTabs>
	);
};
