import React from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import IconManager, { IconType } from './IconManager';

type RatingProps = {
	kudos?: boolean;
	rating: number | string;
};

export const Rating = ({ kudos, rating }: RatingProps) => {
	return (
		<RatingWrapper>
			<IconManager
				type={kudos ? IconType.TROPHY : IconType.POINTY_STAR}
				size={kudos ? 14 : 13}
				fill={!!kudos && Number(rating) < 0 ? Theming.errorColor : Theming.icons.primaryIconColor}
				stroke={'none'}
			/>
			<RatingText size={kudos ? 13 : 12} spaceBetween={kudos ? 10 : 4}>
				{rating}
			</RatingText>
		</RatingWrapper>
	);
};

const RatingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0;
	margin: 0 0 0 8px;
`;

const RatingText = styled.p<{ spaceBetween?: number; size?: number }>`
	display: inline-block;
	font-family: ${Theming.text.boldFont};
	font-size: ${({ size }) => size ?? 14}px;
	color: ${Theming.text.primaryTextColor};
	text-align: left;
	padding: 0;
	margin: ${({ spaceBetween }) => `0 0 0 ${spaceBetween === undefined ? 10 : spaceBetween}`}px;
	min-width: 2em;
`;
