import React, { ReactNode, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Theming } from '../theming';
import { TabHash } from '../navigation/routeNames';

type TabProps = {
	mainPath: string;
	tabs: Tab[];
};

type Tab = {
	title: string;
	component: ReactNode;
	hash: TabHash;
	onClick?: () => void;
	hasTable?: boolean;
};

export const Tabs = ({ tabs, mainPath }: TabProps) => {
	const history = useHistory();
	const currentHash = useLocation().hash;

	useEffect(() => {
		//on initial load, if no hash present, set first tab as active
		if (!currentHash) {
			history.push({ pathname: mainPath, hash: tabs[0].hash });
		}
	}, []);

	const isActive = (hash: TabHash) => {
		return hash === currentHash;
	};

	return (
		<TabContainer>
			<TabTitleRow>
				{tabs.map((tab, index) => (
					<TabTitle
						key={index}
						active={isActive(tab.hash)}
						onClick={() => history.push({ pathname: mainPath, hash: tab.hash })}
					>
						{tab.title}
					</TabTitle>
				))}
			</TabTitleRow>
			{tabs.map((tab, index) => (
				<TabContentWrapper key={index} active={isActive(tab.hash)} hasTable={tab.hasTable}>
					{tab.component}
				</TabContentWrapper>
			))}
		</TabContainer>
	);
};

const TabContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	background-color: ${Theming.backgrounds.mainBackground};
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: max(calc(100vh - ${Theming.nav.navHeaderHeight}px), 100%);
`;

const TabTitleRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 50px;
`;

const TabTitle = styled.p<{ active: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 3px 0 0;
	padding: 10px;
	min-width: 20%;
	font-family: ${Theming.text.boldFont};
	font-size: 15px;
	color: ${Theming.text.titleColor};
	background-color: ${({ active }) =>
		active ? Theming.backgrounds.tabActive : Theming.backgrounds.tabInactive};
	:hover& {
		cursor: pointer;
	}
`;

const TabContentWrapper = styled.div<{ active: boolean; hasTable?: boolean }>`
	display: ${({ active }) => (!active ? 'none' : 'block')};
	width: 100%;
	height: 92%;
	background-color: ${Theming.backgrounds.tabActive};
	margin: 0;
	padding: ${({ hasTable }) => (hasTable ? 0 : '20px 30px')};
	flex: 1;
	overflow: auto;
`;
// overflow: ${({ hasTable }) => (hasTable ? 'hidden' : 'auto')}; //allows tab content to scroll independently of window if no table, while allowing tables to scroll independently

export const TabWithTableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	height: 100%;
`;
