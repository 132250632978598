import React from 'react';
import { Tabs } from '../../components/Tabs';
import { AuthRouteNames, TabHash } from '../../navigation/routeNames';
import { ManageMessaging } from './tabs/ManageMessaging';
import { ManageContractorTypes } from './tabs/ManageContractorTypes';
import { ManageFacilityTypes } from './tabs/ManageFacilityTypes';
import { ManageProfessionSettings } from './tabs/ManageProfessionSettings';

export const MobileAppSettings = () => {
	return (
		<Tabs
			mainPath={AuthRouteNames.MobileAppSettings}
			tabs={[
				{
					title: 'Manage Messaging',
					component: <ManageMessaging />,
					hash: TabHash.messaging,
				},
				{
					title: 'Contractor Types',
					component: <ManageContractorTypes />,
					hash: TabHash.contractor,
				},
				{
					title: 'Facility Types',
					component: <ManageFacilityTypes />,
					hash: TabHash.facility,
				},
				{
					title: 'Type Settings',
					component: <ManageProfessionSettings />,
					hash: TabHash.settings,
				},
			]}
		/>
	);
};
