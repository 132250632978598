import styled from 'styled-components';
import { Theming } from '../theming';

export const Separator = styled.div<{ margin?: string }>`
	width: 100%;
	border-bottom: 1px solid ${Theming.separatorColor};
	margin: ${({ margin }) => margin ?? 0};
`;

export const BoldSeparator = styled.div<{ margin?: string }>`
	width: 100%;
	border-bottom: 1px solid #979797;
	margin: ${({ margin }) => margin ?? 0};
`;
