import { MutableRefObject, useEffect, useRef } from 'react';

export default (onOutsideClick: (args?: any) => any): MutableRefObject<any> => {
	const ref = useRef<any>();

	const handleOutsideClick = (e: MouseEvent) => {
		if (ref && ref.current && ref.current.contains(e.target)) {
			return;
		}
		onOutsideClick();
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	return ref;
};
