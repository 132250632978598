import React from 'react';
import { PageSectionItem, PageSectionText } from './PageSection';
import { FlexWrapper } from './Wrappers';
import DateFNSFormat from 'date-fns/format';
import { Rating } from './Rating';
import { Theming } from '../theming';
import { UserLabel } from './UserLabel';
import { AdaptedReview } from '../adapters/ratingsAdapters';

export const Reviews = ({ reviews }: { reviews: AdaptedReview[] }) => {
	return (
		<>
			{reviews.map((review, index) => (
				<PageSectionItem key={index} borderTop={index > 0}>
					<FlexWrapper>
						<PageSectionText bold>
							{DateFNSFormat(new Date(review.date), 'MMMM yyyy')}
						</PageSectionText>
						<Rating rating={review.score} />
					</FlexWrapper>
					<PageSectionText fontSize={12} color={Theming.text.subTextColor}>
						{review.review}
					</PageSectionText>
					<UserLabel userType={review.reviewerType} name={review.name} />
				</PageSectionItem>
			))}
		</>
	);
};
