import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader, PageWrapperNoTabs } from '../../../components/Wrappers';
import {
	ColumnTitle,
	EndResultsMessage,
	NoResultsMessage,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../../components/Table';
import { ErrorMessage, PageTitle } from '../../../components/Text';
import { AuthRouteNames } from '../../../navigation/routeNames';
import { LabeledSelect } from '../../../components/Select';
import { ContractorSortOptions } from '../../../types/api/Contractors';
import { useAsync } from '../../../hooks/useAsync';
import LoadingSpinner from '../../../components/Loader';
import IconManager, { IconType } from '../../../components/IconManager';
import {
	adaptContractorsFromApi,
	AdaptedContractorList,
} from '../../../adapters/contractorAdapters';
import { webApiManager } from '../../../network/apiManager';
import useSortAndPagination from '../../../hooks/useSortFilterAndPagination';
import { StatusDisplay } from '../../../components/StatusDisplay';

export function ContractorList() {
	const history = useHistory();
	const [contractors, setContractors] = useState<AdaptedContractorList | null>(null);

	useEffect(() => {
		//fetch list on load
		getContractors.execute();
	}, []);

	const onFetch = async (
		requestOptions: { page?: number; sort_by?: ContractorSortOptions } = {}
	) => {
		getContractors.clearError();
		try {
			//fetch data and set to state
			const contractorsResponse = adaptContractorsFromApi(
				await webApiManager.Contractors.getContractors(requestOptions)
			);
			//if fetching next page, add to list and update pagination, otherwise replace
			setContractors(prevState => {
				if (contractorsResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...contractorsResponse.list],
						pagination: contractorsResponse.pagination,
					};
				} else {
					return contractorsResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getContractors = useAsync<void>(
		onFetch,
		'Error: could not get contractor data. Please check your connection and try again.'
	);

	//handle sort and pagination for contractor list
	const { setSortBy, getNextPage } = useSortAndPagination<ContractorSortOptions>(
		contractors,
		getContractors
	);

	return (
		<PageWrapperNoTabs>
			<PageHeader>
				<PageTitle>Manage Contractors</PageTitle>
				<LabeledSelect
					label={'Sort by:'}
					options={
						contractors
							? [
									{ value: ContractorSortOptions.lastName, label: 'Last Name' },
									{ value: ContractorSortOptions.firstName, label: 'First Name' },
									{ value: ContractorSortOptions.email, label: 'Email' },
									{ value: ContractorSortOptions.profession, label: 'Profession' },
									{ value: ContractorSortOptions.status, label: 'Status' },
							  ]
							: []
					}
					onChange={newVal => setSortBy(newVal)}
				/>
			</PageHeader>
			<Table
				tableTitle={'Contractors'}
				onScrollToBottom={getNextPage}
				tableHeader={
					<>
						<ColumnTitle>Last Name</ColumnTitle>
						<ColumnTitle>First Name</ColumnTitle>
						<ColumnTitle>Email</ColumnTitle>
						<ColumnTitle>Profession</ColumnTitle>
						<ColumnTitle>Status</ColumnTitle>
						<ColumnTitle>{''}</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!contractors ? (
							<TableRow hideBorder hideHover>
								<TableItem colSpan={6}>
									{getContractors.pending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={getContractors.error ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{contractors.list.length === 0 ? (
									<NoResultsMessage colSpan={6} />
								) : (
									<>
										{contractors.list.map((contractor, index) => (
											<TableRow key={index}>
												<TableItem textWrap>{contractor.lastName}</TableItem>
												<TableItem textWrap>{contractor.firstName}</TableItem>
												<TableItem textWrap>{contractor.emailAddress}</TableItem>
												<TableItem textWrap>
													{[
														...new Set(
															contractor.professions.map(
																professionExperience => professionExperience.professionCode
															)
														),
													].join(', ')}
												</TableItem>
												<TableItem>
													<StatusDisplay statusType={contractor.status} pause={contractor.pause} />
												</TableItem>
												<TableItem bold textWrap>
													<TableLinkWrapper
														onClick={() =>
															history.push(`${AuthRouteNames.ManageContractors}/${contractor.id}`)
														}
													>
														<TableLink>View Details</TableLink>
														<IconManager
															type={IconType.CHEVRON_RIGHT}
															stroke={'#999'}
															size={14}
															strokeWidth={3}
														/>
													</TableLinkWrapper>
												</TableItem>
											</TableRow>
										))}
										{contractors.pagination.isFinalPage && <EndResultsMessage colSpan={6} />}
									</>
								)}
							</>
						)}
					</>
				}
			/>
		</PageWrapperNoTabs>
	);
}
