import { authTokenKey, refreshTokenKey, tokenKey, userIdKey } from '../constants/localStorageKeys';
import { minutesToMS } from '../utils/minutesToMS';
import { nowInUnixEpochTime } from '../utils/nowInUnixEpochTime';
import jwtDecode from 'jwt-decode';
import { environmentConfig } from '../../environmentConfig';
import { AuthApi } from './authApi';
import { LoginType } from '../types/api/Auth';
import { ProfessionsApi } from './professionsApi';
import { FacilityTypeProfessionExperienceRulesApi } from './facilityTypeProfessionExperienceRulesApi';
import { ContractorExperienceSpecializationApi } from './contractorExperienceSpecializationApi';
import { ContractorSpecializationExperiencesApi } from './contractorSpecializationExperiencesApi';
import { ContractorExperienceTagsApi } from './contractorExperienceTagsApi';
import { ContractorInviteRequestsApi } from './contractorInviteRequestsApi';
import { FacilityInviteRequestsApi } from './facilityInviteRequestsApi';
import { FacilityTypesApi } from './facilityTypesApi';
import { UsersApi } from './usersApi';
import { ContractorsApi } from './contractorsApi';
import { FacilitiesApi } from './facilitiesApi';
import { LocationsApi } from './locationsApi';
import { LocationUsersApi } from './locationUsersApi';
import { DeletedUsersApi } from './deletedUsersApi';
import { ReviewsApi } from './reviewsApi';
import { ContractsApi } from './contractsApi';
import { ReportsApi } from './reportsApi';
import { PausesApi } from './pausesApi';

class WebApiManager {
	rootApiUrl = '';
	loginType: LoginType | null; //TODO: for use when we implement admin/super admin roles (v2?)

	Auth = new AuthApi(this);
	Professions = new ProfessionsApi(this);
	FacilityTypeProfessionExperienceRules = new FacilityTypeProfessionExperienceRulesApi(this);
	ContractorExperienceSpecialization = new ContractorExperienceSpecializationApi(this);
	ContractorSpecializationExperiences = new ContractorSpecializationExperiencesApi(this);
	ContractorExperienceTags = new ContractorExperienceTagsApi(this);
	ContractorInviteRequests = new ContractorInviteRequestsApi(this);
	FacilityInviteRequests = new FacilityInviteRequestsApi(this);
	FacilityTypes = new FacilityTypesApi(this);
	Users = new UsersApi(this);
	Contractors = new ContractorsApi(this);
	Facilities = new FacilitiesApi(this);
	Locations = new LocationsApi(this);
	LocationUsers = new LocationUsersApi(this);
	DeletedUsers = new DeletedUsersApi(this);
	Reviews = new ReviewsApi(this);
	Contracts = new ContractsApi(this);
	Reports = new ReportsApi(this);
	PausesApi = new PausesApi(this);

	constructor(rootApiUrl: string, loginType: LoginType | null) {
		this.rootApiUrl = rootApiUrl;
		this.loginType = loginType;
	}

	getRefreshToken(): string | null {
		//get token from local storage
		const tokenString = localStorage.getItem(refreshTokenKey);

		//if no token found, return null
		if (!tokenString) {
			return null;
		} else {
			const tokenWithExpiry = JSON.parse(tokenString);
			const now = new Date();

			//if token is expired, clear all tokens from local storage and return null
			if (now.getTime() > tokenWithExpiry.expiry) {
				this.clearTokens();
				return null;
			} else {
				return tokenWithExpiry.value;
			}
		}
	}

	setRefreshToken(newToken: string): void {
		//if clearing token, no need to set expiration
		if (!newToken) {
			localStorage.setItem(refreshTokenKey, newToken);
		} else {
			//set expiration time on token
			const now = new Date();
			const tokenWithExpiry = {
				value: newToken,
				//idle timeout set to 20 minutes, extra 2 minutes used as buffer to allow user to cancel timeout and reset expiration
				expiry: now.getTime() + minutesToMS(22),
			};
			//stringify token with expiry and set to local storage
			localStorage.setItem(refreshTokenKey, JSON.stringify(tokenWithExpiry));
		}
	}

	removeRefreshToken(): void {
		localStorage.removeItem(refreshTokenKey);
	}

	getToken(): string | null {
		return localStorage.getItem(tokenKey);
	}

	setToken(newToken: string): void {
		localStorage.setItem(tokenKey, newToken);
	}

	removeToken(): void {
		localStorage.removeItem(tokenKey);
	}

	getAuthToken(): string | null {
		return localStorage.getItem(authTokenKey);
	}

	setAuthToken(newToken: string): void {
		localStorage.setItem(authTokenKey, newToken);
	}

	removeAuthToken(): void {
		localStorage.removeItem(authTokenKey);
	}

	getUserId(): string | null {
		const userId = localStorage.getItem(userIdKey);
		return userId ? JSON.parse(userId) : userId;
	}

	setUserId(userId: string): void {
		localStorage.setItem(userIdKey, userId);
	}

	removeUserId(): void {
		localStorage.removeItem(userIdKey);
	}

	// Protected methods
	protected tokenIsValid = (token: string): boolean => {
		try {
			return nowInUnixEpochTime() < jwtDecode<{ exp: number }>(token).exp;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	public async getValidToken(): Promise<string> {
		try {
			let token = this.getToken();
			const isValid = token ? this.tokenIsValid(token) : false;

			if (!!token && isValid) {
				return token;
			} else {
				await this.Auth.refresh({});
				token = this.getToken();

				if (token) {
					return token;
				}
				throw new Error('Tokens are invalid, login again');
			}
		} catch (err) {
			console.error(err);
			throw err;
		}
	}

	//removes all but "remember me" 2 factor auth token
	public clearTokens(): void {
		this.removeToken();
		this.removeRefreshToken();
		this.removeUserId();
	}

	//to reset expiration time on refresh token
	resetTokenExpiration(): void {
		const refreshToken = this.getRefreshToken();
		if (refreshToken) {
			this.setRefreshToken(refreshToken);
		}
	}
}

export const webApiManager = new WebApiManager(environmentConfig.apiUrl, null);
