import React, { Dispatch, SetStateAction } from 'react';
import {
	EditButtonWrapper,
	FormColumn,
	FormHeading,
	FormRow,
	FormTitle,
} from '../../../components/Form';
import { AdaptedContractorInviteRequest } from '../../../adapters/contractorInvitesAdapters';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { FormLink } from '../../../components/Links';
import { ControlledTextInput } from '../../../components/TextInputs';
import { AdaptedContractor } from '../../../adapters/contractorAdapters';
import { stripSpecialChars } from '../../../utils/stripSpecialCharacters';
import { UpdateContractorData } from '../../../types/sharedTypes';
import _ from 'lodash';

type ContractorInviteRequestFormProps = {
	contractorData: AdaptedContractorInviteRequest | AdaptedContractor;
	isEditing: boolean;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
	updateContractorData: (data: UpdateContractorData) => void;
	formTitle?: string;
};

type FormValues = {
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
};

export const UpdateContractorForm = ({
	contractorData,
	updateContractorData,
	isEditing,
	setIsEditing,
	formTitle,
}: ContractorInviteRequestFormProps) => {
	//all other form fields
	const initialFormState = {
		firstName: contractorData.firstName ?? '',
		lastName: contractorData.lastName ?? '',
		emailAddress: contractorData.emailAddress ?? '',
		phoneNumber: formatPhoneNumber(contractorData.phoneNumber) ?? '',
	};

	const formMethods = useForm<FormValues>({
		defaultValues: initialFormState,
	});

	return (
		<>
			{formTitle && <FormTitle>{formTitle}</FormTitle>}
			<FormRow>
				<StretchFormColumn>
					<FormHeading>Account Information</FormHeading>
					<EditButtonWrapper>
						{isEditing ? (
							<>
								<FormLink
									onClick={() => {
										//on cancel, reset formState back to saved invite
										formMethods.reset();
										//toggle editing status
										setIsEditing(prevState => !prevState);
									}}
									secondary={isEditing} //controls link color
								>
									Cancel
								</FormLink>
								<FormLink
									onClick={formMethods.handleSubmit((data: FormValues) => {
										//remove special characters from phone number and add '1' prefix if needed
										const strippedPhone = stripSpecialChars(data.phoneNumber);
										const phoneWithPrefix =
											strippedPhone[0] === '1' ? strippedPhone : `1${strippedPhone}`;

										updateContractorData({
											...data,
											phoneNumber: phoneWithPrefix,
											contractorId: contractorData.id,
										});
									})}
								>
									Save
								</FormLink>
							</>
						) : (
							<FormLink
								onClick={() => {
									//toggle editing status
									setIsEditing(prevState => !prevState);
								}}
								secondary={isEditing} //controls link color
							>
								Edit
							</FormLink>
						)}
					</EditButtonWrapper>
					<ControlledTextInput
						register={() => formMethods.register('firstName', { required: 'Required' })}
						placeholder={'First Name'}
						disabled={!isEditing}
						error={formMethods.formState.errors.firstName?.message}
					/>
					<ControlledTextInput
						register={() => formMethods.register('lastName', { required: 'Required' })}
						placeholder={'Last Name'}
						disabled={!isEditing}
						error={formMethods.formState.errors.lastName?.message}
					/>
					<ControlledTextInput
						register={() =>
							formMethods.register('emailAddress', {
								required: 'Required',
								pattern: {
									//prettier-ignore
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Invalid email address',
								},
							})
						}
						placeholder={'Email Address'}
						disabled={!isEditing}
						error={formMethods.formState.errors.emailAddress?.message}
					/>
					<ControlledTextInput
						register={() =>
							formMethods.register('phoneNumber', {
								required: 'Required',
								pattern: {
									value: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
									message: 'Invalid phone number',
								},
							})
						}
						placeholder={'Phone Number'}
						disabled={!isEditing}
						error={formMethods.formState.errors.phoneNumber?.message}
					/>
				</StretchFormColumn>
			</FormRow>
		</>
	);
};

const StretchFormColumn = styled(FormColumn)`
	align-items: stretch;
`;
