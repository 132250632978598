import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	NonAuthPageColumn,
	NonAuthPageContainer,
	NonAuthPageContentWrapper,
} from '../../components/Wrappers';
import { MainLogo } from '../../components/Logos';
import { ErrorMessage, NonAuthFormTitle, SuccessMessage } from '../../components/Text';
import { ControlledTextInput } from '../../components/TextInputs';
import { NonAuthButton } from '../../components/Buttons';
import { useAsync } from '../../hooks/useAsync';
import { UrlParamsType } from '../../navigation/routeNames';
import { useForm } from 'react-hook-form';
import { FormRow } from '../../components/Form';
import styled from 'styled-components';
import { webApiManager } from '../../network/apiManager';
import { ReturnHomeLink } from '../../components/Links';

type FormValues = {
	newPassword: string;
	confirmPassword: string;
};

export const ResetPassword = () => {
	const params = useParams<UrlParamsType>();
	const passwordToken = decodeURI(params.passwordToken);

	const [success, setSuccess] = useState<boolean>(false);

	const initialFormState = {
		newPassword: '',
		confirmPassword: '',
	};

	const formMethods = useForm<FormValues>({
		defaultValues: initialFormState,
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: FormValues) => {
		try {
			const request = {
				auth_token: passwordToken,
				password: data.newPassword,
				password_confirmation: data.confirmPassword,
			};
			await webApiManager.Auth.resetPassword(request);
			setSuccess(true);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const resetPassword = useAsync<void>(
		onSubmit,
		'Error resetting password. Please check your connection and try again.'
	);

	return (
		<NonAuthPageContainer>
			<NonAuthPageColumn width={60}>
				<MainLogo width={'60%'} />
			</NonAuthPageColumn>

			<NonAuthPageColumn width={40}>
				<NonAuthPageContentWrapper>
					<NonAuthFormTitle>Reset Password</NonAuthFormTitle>
					{success ? (
						<FormRow>
							<SuccessMessage text={'Your password has been successfully updated.'} />
						</FormRow>
					) : (
						<>
							<ControlledTextInput
								borderRadius={0}
								type={'password'}
								register={() =>
									formMethods.register('newPassword', {
										required: 'Required',
										minLength: { value: 8, message: 'Password must be at least 8 characters.' },
										pattern: {
											value: /^\S*$/,
											message: 'Password must not contain any spaces.',
										},
									})
								}
								label={'New Password'}
								placeholder={'Enter New Password'}
								disabled={resetPassword.pending}
								error={formMethods.formState.errors.newPassword?.message}
							/>
							<ControlledTextInput
								borderRadius={0}
								type={'password'}
								register={() =>
									formMethods.register('confirmPassword', {
										required: 'Required',
										validate: value =>
											value === formMethods.getValues().newPassword ||
											'The passwords do not match.',
									})
								}
								label={'Confirm Password'}
								placeholder={'Confirm Password'}
								disabled={resetPassword.pending}
								error={formMethods.formState.errors.confirmPassword?.message}
							/>
							<ButtonWrapper>
								<NonAuthButton
									text={'Submit'}
									disabled={
										!!formMethods.formState.errors.confirmPassword?.message ||
										!!formMethods.formState.errors.newPassword?.message
									}
									pending={resetPassword.pending}
									onClick={formMethods.handleSubmit((data: FormValues) => {
										resetPassword.execute(data);
									})}
								/>
								{resetPassword.error && <ErrorMessage text={resetPassword.error} />}
							</ButtonWrapper>
						</>
					)}
					<ReturnHomeLink />
				</NonAuthPageContentWrapper>
			</NonAuthPageColumn>
		</NonAuthPageContainer>
	);
};

const ButtonWrapper = styled.div`
	width: 100%;
	padding: 10px;
`;
