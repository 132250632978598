import { UUID } from '../sharedTypes';
import { FacilityLocation } from './Locations';
import { Profession } from './Professions';
import { Contractor } from './Contractors';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { UserDetails } from './Auth';

export enum ContractStatus {
	inProgress = 'in_progress',
	booked = 'booked',
	canceled = 'cancelled',
	completed = 'completed',
}

export enum PaymentStatus {
	notPaid = 'not_paid',
	paid = 'fully_paid',
	partialPaid = 'partially_paid',
}

export interface PaymentStatusDetails {
	amount: number;
	charge_number: number;
	status: string; //ex. "succeeded" (NOT a PaymentStatus)
}

export enum ContractSortOptions {
	facility = 'facility',
	contractor = 'contractor',
	postedDate = 'posted_date',
	endDate = 'end_date',
	startDate = 'start_date',
}

export enum ContractFilterType {
	contractStatus = 'statuses',
	location = 'city_or_state_or_zip_code',
	reported = 'is_reported',
	contractId = 'display_id',
	paymentStatus = 'payment_status',
}

export type ContractFilterOptions = {
	[ContractFilterType.contractStatus]: ContractStatus[];
	[ContractFilterType.location]: string;
	[ContractFilterType.reported]: boolean | undefined;
	[ContractFilterType.contractId]: string;
	[ContractFilterType.paymentStatus]: PaymentStatus[];
};

export interface Contract {
	id: UUID;
	type: 'contract_instance';
	attributes: {
		contract_template_tier: string;
		start_date: string; //Date ex "2021-05-13"
		end_date: string; //Date ex "2021-05-13"
		hourly_rate: string | number; //ex "50.5"
		tier: ContractTier;
		display_id: string;
		cancelation_note: string | null;
		special_instructions: string | null;
		pubnub_channel_name: string;
	};
	relationships: {
		contract_instance_times: ContractTime[];
		contract_template: ContractTemplate;
		contractor: Contractor;
		location: FacilityLocation;
		profession: Profession;
		reported_problems?: ReportedProblem[]; //will not be returned if no problems reported
	};
	meta: {
		posted_at: string; //timestamp
		payment_status_details: PaymentStatusDetails[];
		status: ContractStatus;
		contractor_score: number | null;
		location_score: number | null;
		payment_status: PaymentStatus;
	};
}

export interface ContractTemplate {
	id: UUID;
	type: 'contract_templates';
	attributes: {
		contractor_limit: number;
		start_date: string; //date ex "2021-05-13"
		end_date: string; //date ex "2021-05-13"
		tier: ContractTier;
		hourly_rate_tier_experienced: string | number; //ex rates "45.0"
		hourly_rate_tier_preferred: string | number;
		hourly_rate_tier_specialist: string | number;
		hourly_rate_tier_standard: string | number;
		hourly_rate_tier_try_new: string | number;
	};
	relationships: {
		location: FacilityLocation;
		profession: Profession;
		contract_template_times: ContractTime[];
	};
}

export interface ContractTime {
	id: UUID;
	type: 'contract_instance_time' | 'contract_template_time';
	attributes: {
		end_time: string | number; //ex "17.0"
		shift_date: string; //Date ex "2021-05-11"
		start_time: string | number; //ex "9.0"
	};
}

export enum ContractTier {
	specialist = 'specialist',
	experienced = 'experienced',
	preferred = 'preferred',
	standard = 'standard',
	tryNew = 'try_new',
}

export interface ReportedProblem {
	id: UUID;
	type: 'reported_problems';
	attributes: {
		other_reason?: string;
	};
	relationships: {
		contract_instance: Contract;
		posted_as: Contractor | Location;
		problem_reason: ProblemReason;
		user: UserDetails;
	};
}

export interface ProblemReason {
	id: UUID;
	type: 'problem_reasons';
	attributes: {
		name: string;
	};
}

export interface GetContractsRequest
	extends PaginatedApiRequest<ContractSortOptions>,
		Partial<ContractFilterOptions> {
	location_id?: UUID;
	contractor_id?: UUID;
	facility_id?: UUID;
	type?: 'past' | 'current';
}

export type GetContractsResponse = PaginatedApiResponse<Contract[]>;

export interface GetContractRequest {
	contractId: UUID;
}

export type GetContractResponse = Contract;

export interface CancelContractRequest {
	contractId: UUID;
}

export type CancelContractResponse = Contract;
