import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AdaptedFacility } from '../../../../adapters/facilityAdapters';
import { FlexWrapper, PageColumn, PageRow } from '../../../../components/Wrappers';
import { PageSection, PageSectionItem, SummaryItem } from '../../../../components/PageSection';
import { FacilityBuilding } from '../../../../components/FacilityBuilding';
import { PageTitle } from '../../../../components/Text';
import { StatusType } from '../../../../types/sharedTypes';
import { capitalizeString } from '../../../../utils/capitalizeString';
import { AdaptedFacilityLocation } from '../../../../adapters/locationsAdapters';
import { StatusDisplay } from '../../../../components/StatusDisplay';
import {
	ColumnTitle,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
	NoResultsMessage,
} from '../../../../components/Table';
import IconManager, { IconType } from '../../../../components/IconManager';
import { BaseButton } from '../../../../components/Buttons';
import { SidePanel } from '../../../../components/SidePanel';
import { AddNewFacilityUser } from './components/AddNewFacilityUser';
import { FacilityUserDetails } from './components/FacilityUserDetails';
import { UUID } from '../../../../types/sharedTypes';
import { Theming } from '../../../../theming';

type FacilityOverviewProps = {
	selectedFacility: AdaptedFacility;
	loadFacility: () => void;
};

export const FacilityOverviewTab = ({ selectedFacility, loadFacility }: FacilityOverviewProps) => {
	type LocationsByStatusType = {
		active: AdaptedFacilityLocation[];
		inactive: AdaptedFacilityLocation[];
	};
	const [selectedFacilityUserId, setSelectedFacilityUserId] = useState<UUID | null>(null);
	const [locationsByStatus, setLocationsByStatus] = useState<LocationsByStatusType | null>(null);
	const [showAddNewUser, setShowAddNewUser] = useState<boolean>(false);

	useEffect(() => {
		//sort locations by status
		const sortedLocations = selectedFacility.locations.reduce(
			(
				sortedLocations: { active: AdaptedFacilityLocation[]; inactive: AdaptedFacilityLocation[] },
				location
			) => {
				location.status === StatusType.active
					? sortedLocations.active.push(location)
					: sortedLocations.inactive.push(location);
				return sortedLocations;
			},
			{ active: [], inactive: [] }
		);
		setLocationsByStatus(sortedLocations);
	}, []);

	return (
		<>
			<PageRow>
				<PageColumn width={45}>
					<PageSection>
						<FacilityBuilding />
						<PageSectionItem label={'Status'}>
							<StatusDisplay statusType={selectedFacility.status} />
						</PageSectionItem>
						<PageSectionItem borderTop>
							<PageTitle>{capitalizeString(selectedFacility.name)}</PageTitle>
						</PageSectionItem>
					</PageSection>
				</PageColumn>
				<PageColumn width={55}>
					<PageSection>
						<FlexWrapper>
							<SummaryItem label={'Locations'} amount={selectedFacility.numLocations} />
							<SummaryItem label={'Total Users'} amount={selectedFacility.numUsers} borderLeft />
							<SummaryItem
								label={'Active Locations'}
								amount={locationsByStatus?.active.length ?? 'N/A'}
								borderLeft
							/>
							<SummaryItem
								label={'Inactive Locations'}
								amount={locationsByStatus?.inactive.length ?? 'N/A'}
								borderLeft
							/>
						</FlexWrapper>
					</PageSection>
				</PageColumn>
			</PageRow>
			<PageRow>
				<FacilityUsersContainer>
					<PageColumn width={100}>
						<ButtonContainer>
							<BaseButton
								text={'+ NEW FACILITY USER'}
								onClick={() => setShowAddNewUser(true)}
								margin={'0'}
							/>
						</ButtonContainer>
						<Table
							tableTitle="Facility Users"
							tableHeader={
								<>
									<ColumnTitle>Last Name</ColumnTitle>
									<ColumnTitle>First Name</ColumnTitle>
									<ColumnTitle>Email Address</ColumnTitle>
									<ColumnTitle>Status</ColumnTitle>
									<ColumnTitle>{''}</ColumnTitle>
								</>
							}
							tableBody={
								<>
									{selectedFacility.facilityUsers.length === 0 ? (
										<NoResultsMessage colSpan={5} />
									) : (
										selectedFacility.facilityUsers.map(user => (
											<TableRow key={user.id}>
												<TableItem>{user.lastName}</TableItem>
												<TableItem>{user.firstName}</TableItem>
												<TableItem>{user.email}</TableItem>
												<TableItem>
													<StatusDisplay statusType={user.status} pause={user.pause} />
												</TableItem>
												<TableItem bold textWrap>
													<TableLinkWrapper
														//navigate to details page for selected location
														onClick={() => setSelectedFacilityUserId(user.facilityUserId)}
													>
														<TableLink>View Details</TableLink>
														<IconManager
															type={IconType.CHEVRON_RIGHT}
															stroke={'#999'}
															size={14}
															strokeWidth={3}
														/>
													</TableLinkWrapper>
												</TableItem>
											</TableRow>
										))
									)}
								</>
							}
						/>
						<SidePanel
							isOpen={!!selectedFacilityUserId}
							hide={() => {
								setSelectedFacilityUserId(null);
								loadFacility();
							}}
							closeButtonText={selectedFacility.name.toUpperCase()}
						>
							<FacilityUserDetails
								selectedFacilityUserId={selectedFacilityUserId}
								setSelectedFacilityUserId={setSelectedFacilityUserId}
							/>
						</SidePanel>
						<SidePanel
							isOpen={showAddNewUser}
							hide={() => {
								setShowAddNewUser(false);
								loadFacility();
							}}
							closeButtonText={selectedFacility.name.toUpperCase()}
						>
							<AddNewFacilityUser
								{...selectedFacility}
								closeSidebar={() => {
									setShowAddNewUser(false);
									loadFacility();
								}}
							/>
						</SidePanel>
					</PageColumn>
				</FacilityUsersContainer>
			</PageRow>
		</>
	);
};

const FacilityUsersContainer = styled.div`
	background: ${Theming.backgrounds.mainBackground};
	width: 100%;
	position: relative;
`;

const ButtonContainer = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
`;
