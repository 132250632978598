import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { ContractorProfessionExperiences } from './ContractorProfessionExperiences';
import { StatusType, USStates, UUID } from '../sharedTypes';
import { UserDetails } from './Auth';
import { Kudos, Rating } from './Reviews';
import { Profession } from './Professions';
import { Pause } from './Pauses';
import { ContractorSpecializationExperiences } from './ContractorSpecializationExperiences';

export enum ContractorSortOptions {
	lastName = 'last_name',
	firstName = 'first_name',
	email = 'email',
	profession = 'profession',
	status = 'status',
}

export type GetContractorsRequest = PaginatedApiRequest<ContractorSortOptions>;

export type GetContractorsResponse = PaginatedApiResponse<Contractor[]>;

export interface ContractorRequestProfession {
	id: UUID;
	type: 'contractor_request_professions';
	attributes: {
		profession_id: UUID;
	};
	relationships: {
		profession: Profession;
	};
}

export interface Contractor {
	id: UUID;
	type: 'contractors';
	attributes: {
		user_id: UUID;
		status: StatusType;
		contract_count: number;
		stripe_account_id: string;
		pubnub_channel_name: string;
	};
	relationships: {
		user: UserDetails;
		contractor_profession_experiences: ContractorProfessionExperiences[];
		contractor_specialization_experiences?: ContractorSpecializationExperiences[];
		resource_pauses?: Pause[];
	};
	meta: {
		kudos: Kudos;
		rating: Rating;
		completed_contracts_count: number;
		cancelled_contracts_count: number;
	};
}

export interface GetContractorRequest {
	contractorId: UUID;
}

export type GetContractorResponse = Contractor;

export interface UpdateContractorProfessionRequest {
	professionId: UUID;
	facilityTypeId: UUID;
	experienceMonths: number;
	reciprocityState: USStates;
	notes: string;
}

export interface UpdateContractorSpecializationRequest {
	specializationId: UUID;
	reciprocityState: USStates;
	isSpecialized: boolean;
}

export interface UpdateContractorRequest {
	contractorId: UUID;
	firstName?: string;
	lastName?: string;
	emailAddress?: string;
	phoneNumber?: string;
	smsOptedIn?: boolean;
	professions?: UpdateContractorProfessionRequest[];
	specializations?: UpdateContractorSpecializationRequest[];
}

export type UpdateContractorResponse = Contractor;

export interface SetContractorStatusRequest {
	contractorId: UUID;
	statusType: StatusType;
}

export type SetContractorStatusResponse = Contractor;
