import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import { MiniLoadingSpinner } from './Loader';
import IconManager, { IconType } from './IconManager';

type CustomButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	text: string | ReactNode;
	width?: number;
	height?: number;
	disabled?: boolean;
	margin?: string;
	backgroundColor?: string;
	pending?: boolean;
	color?: string;
};

export const BaseButton = ({ text, pending, disabled, ...props }: CustomButtonProps) => {
	return (
		<StyledBaseButton disabled={disabled || pending} pending={pending} {...props}>
			{pending ? <MiniLoadingSpinner /> : text}
		</StyledBaseButton>
	);
};

export const NonAuthButton = ({ text, pending, disabled, ...props }: CustomButtonProps) => {
	return (
		<StyledLoginButton disabled={disabled || pending} pending={pending} {...props}>
			{''}
			{pending ? <MiniLoadingSpinner /> : text}
		</StyledLoginButton>
	);
};

export const FilterButton = ({ text, pending, disabled, ...props }: CustomButtonProps) => {
	return (
		<StyledFilterButton disabled={disabled || pending} pending={pending} {...props}>
			{pending ? <MiniLoadingSpinner /> : text}
		</StyledFilterButton>
	);
};

const StyledBaseButton = styled.button<{
	width?: number;
	height?: number;
	disabled?: boolean;
	margin?: string;
	backgroundColor?: string;
	pending?: boolean;
	color?: string;
}>`
	border-radius: 5px;
	border: none;
	background-color: ${({ disabled, backgroundColor }) =>
		disabled
			? Theming.buttons.disabledButtonColor
			: backgroundColor ?? Theming.buttons.primaryButtonColor};
	color: ${({ color, disabled }) =>
		color && !disabled ? color : Theming.buttons.primaryButtonTextColor};
	font-family: ${Theming.text.boldFont};
	text-transform: uppercase;
	font-size: 14px;
	padding: ${({ pending }) => (pending ? '4px 40px' : '10px 40px')};
	width: ${({ width }) => (!width ? 'auto' : width + '%')};
	height: ${({ height }) => (!height ? 'auto' : height + 'px')};
	margin: ${({ margin }) => margin ?? '20px auto'};
	:hover& {
		cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
	}
`;

export const StyledLoginButton = styled.button<{ disabled?: boolean; pending?: boolean }>`
	width: 100%;
	text-transform: uppercase;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.buttons.primaryButtonTextColor};
	background-color: ${({ disabled }) =>
		disabled ? Theming.buttons.disabledButtonColor : Theming.buttons.primaryButtonColor};
	padding: ${({ pending }) => (pending ? '5px 10px' : '10px')};
	font-size: 16px;
	border: none;
	margin: 20px auto;
	:hover& {
		cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
	}
`;

export const StyledFilterButton = styled.button<{
	width?: number;
	disabled?: boolean;
	margin?: string;
	backgroundColor?: string;
	pending?: boolean;
}>`
	border: none;
	background-color: ${({ disabled, backgroundColor }) =>
		disabled
			? Theming.buttons.disabledButtonColor
			: backgroundColor ?? Theming.buttons.primaryButtonColor};
	color: ${Theming.buttons.primaryButtonTextColor};
	font-family: ${Theming.text.boldFont};
	font-size: 15px;
	padding: ${({ pending }) => (pending ? '5px 10px' : '10px')};
	width: 100%;
	margin: 0 auto 15px;
	text-align: center;
	:hover& {
		cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
	}
`;

export const EditButton = styled(props => (
	<button {...props}>
		<IconManager
			type={IconType.EDIT_INSIDE_SQUARE}
			size={16}
			fill={Theming.buttons.primaryButtonColor}
			stroke={'none'}
		/>
	</button>
))`
	border: none;
	background: none;
	cursor: pointer;
	opacity: 0.8;
	:hover& {
		opacity: 1;
	}
	:disabled& {
		opacity: 0.25;
		cursor: default;
	}
`;
