import { webApiManager } from './apiManager';
import { buildQueryString } from '../utils/buildQueryString';
import fetchWrapper from './fetchWrapper';
import {
	GetFacilitiesRequest,
	GetFacilitiesResponse,
	GetFacilityRequest,
	GetFacilityResponse,
	GetFacilityUsersRequest,
	GetFacilityUsersResponse,
	GetFacilityUserRequest,
	GetFacilityUserResponse,
	CreateFacilityUserRequest,
	CreateFacilityUserResponse,
	AddFacilityUserRequest,
	AddFacilityUserResponse,
	UpdateFacilityUserRequest,
	UpdateFacilityUserResponse,
} from '../types/api/Facilities';
import { ApiResponse } from '../types/api/SharedApiTypes';

export class FacilitiesApi {
	constructor(public apiManager: typeof webApiManager) {}

	//get list of all facilities
	public async getFacilities(request: GetFacilitiesRequest): Promise<GetFacilitiesResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getFacilitiesResponse = (await fetchWrapper(
				{
					path: `facilities${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetFacilitiesResponse;

			return getFacilitiesResponse;
		} catch (error) {
			console.error('error in facilities api', JSON.stringify(error));
			throw error;
		}
	}

	//get single facility
	public async getFacility(request: GetFacilityRequest): Promise<GetFacilityResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getFacilityResponse = (await fetchWrapper(
				{
					path: `facilities/${request.facilityId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetFacilityResponse>;

			return getFacilityResponse.data;
		} catch (error) {
			console.error('error in facilities api', JSON.stringify(error));
			throw error;
		}
	}

	//gets all users for a facility and its locations
	public async getFacilityUsers(
		request: GetFacilityUsersRequest
	): Promise<GetFacilityUsersResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getFacilityUsersResponse = (await fetchWrapper(
				{
					path: `facilities/${request.facilityId}/all_users`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetFacilityUsersResponse>;

			return getFacilityUsersResponse.data;
		} catch (error) {
			console.error('error in facilities api', JSON.stringify(error));
			throw error;
		}
	}

	//get single facility user
	public async getFacilityUser(request: GetFacilityUserRequest): Promise<GetFacilityUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getFacilityUserResponse = (await fetchWrapper(
				{
					path: `/facility_users/${request.facilityUserId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetFacilityUserResponse>;

			return getFacilityUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	public async createFacilityUser(
		request: CreateFacilityUserRequest
	): Promise<CreateFacilityUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const createFacilityUserResponse = (await fetchWrapper(
				{
					path: `/facilities/${request.facilityId}/create_user`,
					method: 'POST',
					token,
					body: {
						facility_user: request.facilityUser,
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<CreateFacilityUserResponse>;

			return createFacilityUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	//add existing location user to facility
	public async addFacilityUser(request: AddFacilityUserRequest): Promise<AddFacilityUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const addFacilityUserResponse = (await fetchWrapper(
				{
					path: `/facilities/${request.facilityId}/add_user`,
					method: 'POST',
					token,
					body: {
						facility_user: {
							user_id: request.userId,
						},
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<AddFacilityUserResponse>;

			return addFacilityUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	public async updateFacilityUser(
		request: UpdateFacilityUserRequest
	): Promise<UpdateFacilityUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const updateFacilityUserResponse = (await fetchWrapper(
				{
					path: `/facility_users/${request.facilityUserId}`,
					method: 'PATCH',
					token,
					body: {
						facility_user: request.updates,
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateFacilityUserResponse>;

			return updateFacilityUserResponse.data;
		} catch (error) {
			console.error('error in facility api', JSON.stringify(error));
			throw error;
		}
	}
}
