import React, { Dispatch, SetStateAction, useState } from 'react';
import { AdaptedFacilityInviteRequest } from '../../../../adapters/facilityInvitesAdapters';
import {
	EditButtonWrapper,
	FormRow,
	FormTitle,
	SidePanelFormWrapper,
} from '../../../../components/Form';
import { FormLink } from '../../../../components/Links';
import { ControlledTextInput, InputLabel } from '../../../../components/TextInputs';
import { MultiSelect, SelectOption } from '../../../../components/Select';
import { useForm } from 'react-hook-form';
import { AdaptedFacilityType } from '../../../../adapters/facilityTypesAdapter';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { stripSpecialChars } from '../../../../utils/stripSpecialCharacters';
import { UUID } from '../../../../types/sharedTypes';

type FacilityInviteRequestFormProps = {
	invite: AdaptedFacilityInviteRequest;
	facilityTypeOptions: SelectOption[];
	updateInvite: (data: FormValues & { facilityTypeIds: UUID[] }) => void;
	isEditing: boolean;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
};

type FormValues = {
	facilityName: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
};

type FacilityTypeFormState = {
	selectedFacilityTypeIds: AdaptedFacilityType['id'][];
	formError: string;
};

export const FacilityInviteRequestForm = ({
	invite,
	facilityTypeOptions,
	updateInvite,
	isEditing,
	setIsEditing,
}: FacilityInviteRequestFormProps) => {
	const [facilityTypeFormState, setFacilityTypeFormState] = useState<FacilityTypeFormState>({
		selectedFacilityTypeIds: invite.facilityTypes.map(facilityType => facilityType.id),
		formError: '',
	});

	const initialFormState = {
		facilityName: invite.facilityName ?? '',
		firstName: invite.firstName ?? '',
		lastName: invite.lastName ?? '',
		emailAddress: invite.emailAddress ?? '',
		phoneNumber: formatPhoneNumber(invite.phoneNumber) ?? '',
	};

	const formMethods = useForm<FormValues>({
		defaultValues: initialFormState,
		reValidateMode: 'onChange',
	});

	return (
		<SidePanelFormWrapper>
			<FormTitle>Invite Request</FormTitle>
			<EditButtonWrapper>
				{isEditing ? (
					<>
						<FormLink
							onClick={() => {
								//on cancel, reset formState back to saved invite
								formMethods.reset();
								//toggle editing status
								setIsEditing(prevState => !prevState);
							}}
							secondary={isEditing} //controls link color
						>
							Cancel
						</FormLink>
						<FormLink
							onClick={formMethods.handleSubmit((data: FormValues) => {
								//validate uncontrolled select
								if (!facilityTypeFormState.selectedFacilityTypeIds.length) {
									setFacilityTypeFormState(prevState => {
										return { ...prevState, formError: 'Required' };
									});
								} else {
									//remove special characters from phone number and add '1' prefix if needed
									const strippedPhone = stripSpecialChars(data.phoneNumber);
									const phoneWithPrefix =
										strippedPhone[0] === '1' ? strippedPhone : `1${strippedPhone}`;
									updateInvite({
										...data,
										phoneNumber: phoneWithPrefix,
										facilityTypeIds: facilityTypeFormState.selectedFacilityTypeIds,
									});
								}
							})}
						>
							Save
						</FormLink>
					</>
				) : (
					<FormLink
						onClick={() => {
							//toggle editing status
							setIsEditing(prevState => !prevState);
						}}
						secondary={isEditing} //controls link color
					>
						Edit
					</FormLink>
				)}
			</EditButtonWrapper>
			<FormRow>
				<ControlledTextInput
					register={() => formMethods.register('facilityName', { required: 'Required' })}
					label={'Facility Name'}
					placeholder={'Facility Name'}
					disabled={!isEditing}
					error={formMethods.formState.errors.facilityName?.message}
				/>
				<MultiSelect
					label={'Facility Type'}
					placeholder={'Select...'}
					options={facilityTypeOptions}
					onChange={newVal =>
						setFacilityTypeFormState(prevState => {
							return { ...prevState, selectedFacilityTypeIds: newVal, formError: '' };
						})
					}
					disabled={!isEditing}
					initialValues={invite.facilityTypes.map(facilityType => facilityType.id)}
				/>
			</FormRow>
			<InputLabel padding={'15px 11px 0'}>Account Information</InputLabel>
			<FormRow>
				<ControlledTextInput
					register={() => formMethods.register('firstName', { required: 'Required' })}
					placeholder={'First Name'}
					disabled={!isEditing}
					error={formMethods.formState.errors.firstName?.message}
				/>
				<ControlledTextInput
					register={() => formMethods.register('lastName', { required: 'Required' })}
					placeholder={'Last Name'}
					disabled={!isEditing}
					error={formMethods.formState.errors.lastName?.message}
				/>
			</FormRow>
			<FormRow>
				<ControlledTextInput
					register={() =>
						formMethods.register('emailAddress', {
							required: 'Required',
							pattern: {
								//prettier-ignore
								value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'Invalid email address',
							},
						})
					}
					placeholder={'Email Address'}
					disabled={!isEditing}
					error={formMethods.formState.errors.emailAddress?.message}
				/>
				<ControlledTextInput
					register={() =>
						formMethods.register('phoneNumber', {
							required: 'Required',
							pattern: {
								value: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
								message: 'Invalid phone number',
							},
						})
					}
					placeholder={'Phone Number'}
					disabled={!isEditing}
					error={formMethods.formState.errors.phoneNumber?.message}
				/>
			</FormRow>
		</SidePanelFormWrapper>
	);
};
