import React, { useContext, useState } from 'react';
import { MainLogo } from '../../components/Logos';
import { LoginForm } from './components/LoginForm';
import { useAsync } from '../../hooks/useAsync';
import { ErrorMessage } from '../../components/Text';
import { VerifyAccountForm } from './components/VerifyAccountForm';
import {
	NonAuthPageColumn,
	NonAuthPageContainer,
	NonAuthPageContentWrapper,
} from '../../components/Wrappers';
import { webApiManager } from '../../network/apiManager';
import { UserContext } from '../../context/UserContext';
import { SendCodeMethod } from '../../types/api/Auth';

export type LoginData = {
	email: string;
	password: string;
};

export const Login = () => {
	const { currentUser, setCurrentUser, setUserAuthorized } = useContext(UserContext);
	const [verificationCodeSent, setVerificationCodeSent] = useState<boolean>(false);
	const [codeValidated, setCodeValidated] = useState<boolean>(false);

	//login with email/password
	const onSubmit = async (loginData: LoginData) => {
		submitLogin.clearError();
		try {
			await webApiManager.Auth.loginWithPassword(loginData, setCurrentUser, setUserAuthorized);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	//send verification code to user's email or phone
	const onSend = async (type: SendCodeMethod) => {
		sendCode.clearError();
		try {
			const sendCodeResponse = await webApiManager.Auth.twoFactorAuthRequest({
				notification_method: type,
			});
			if (sendCodeResponse.success) {
				setVerificationCodeSent(true);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	//validate account with verification code
	const onVerify = async (code: string) => {
		verifyLogin.clearError();
		try {
			const validateCodeResponse = await webApiManager.Auth.twoFactorAuthValidation({ code });

			if (validateCodeResponse.success) {
				setCodeValidated(true);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	//on successful login/account verification, save "remember device" preference and continue to dashboard
	const onContinue = async (rememberUser: boolean) => {
		savePreference.clearError();
		try {
			if (rememberUser) {
				await webApiManager.Auth.rememberCurrentUser({}, setUserAuthorized);
			} else {
				setUserAuthorized(true);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const submitLogin = useAsync(
		loginInfo => onSubmit(loginInfo),
		'Could not login. Please check your email and password and try again.'
	);
	const sendCode = useAsync(
		type => onSend(type),
		'Error sending verification code. Please check your connection and try again.'
	);
	const verifyLogin = useAsync(code => onVerify(code), 'Could not verify account');
	const savePreference = useAsync(preference => onContinue(preference), 'Could not verify account');

	return (
		<NonAuthPageContainer>
			<NonAuthPageColumn width={60}>
				<MainLogo width={'60%'} />
			</NonAuthPageColumn>

			<NonAuthPageColumn width={40}>
				<NonAuthPageContentWrapper>
					{!currentUser ? (
						<>
							<LoginForm onSubmit={submitLogin.execute} pending={submitLogin.pending} />
							{!!submitLogin.error && <ErrorMessage text={submitLogin.error} />}
						</>
					) : (
						<VerifyAccountForm
							codeSent={verificationCodeSent}
							setCodeSent={setVerificationCodeSent}
							onSend={sendCode.execute}
							onVerify={verifyLogin.execute}
							onContinue={savePreference.execute}
							pending={sendCode.pending || verifyLogin.pending || savePreference.pending}
							success={codeValidated} //reset when finished setting up success page
							error={sendCode.error || verifyLogin.error || savePreference.error || ''}
						/>
					)}
				</NonAuthPageContentWrapper>
			</NonAuthPageColumn>
		</NonAuthPageContainer>
	);
};
