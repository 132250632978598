import { GetMonthlyContractorReportResponse } from '../types/api/Reports';

export type AdaptedMonthlyContractorReport = {
	headers: string[];
	data: Array<string[]>;
	filename: string;
};

export const adaptMonthlyContractorReport = (
	reportData: GetMonthlyContractorReportResponse
): AdaptedMonthlyContractorReport => {
	const dataHeadersOnly = reportData.results[0] ?? [];
	const dataWithoutHeaders = reportData.results.length > 1 ? reportData.results.slice(1) : [];
	return {
		headers: dataHeadersOnly,
		data: dataWithoutHeaders,
		filename: `contractor-insurance-report-${reportData.month}-${reportData.year}.csv`,
	};
};
