import { ContractorProfessionExperience } from '../types/api/ContractorProfessionExperiences';
import { USStates, UUID } from '../types/sharedTypes';

export type AdaptedContractorProfessionExperience = {
	professionId: UUID;
	professionName: string;
	professionCode: string;
	reciprocityState: USStates;
	experienceMonths: number;
	approvalExpirationDate: string;
	facilityTypeProfessionExperienceRuleId: UUID;
	facilityTypeId: UUID;
	isExperienced: boolean;
};

export const adaptContractorProfessionExperiencesFromApi = (
	contractorProfessionExperiences: ContractorProfessionExperience[]
): AdaptedContractorProfessionExperience[] => {
	if (!contractorProfessionExperiences) {
		return [];
	}

	return contractorProfessionExperiences.map(professionExperience => ({
		approvalExpirationDate: professionExperience.attributes.approval_expiration_date,
		experienceMonths: professionExperience.attributes.experience_months,
		reciprocityState: professionExperience.attributes.reciprocity_state,
		notes: professionExperience.attributes.notes,
		facilityTypeProfessionExperienceRuleId:
			professionExperience.attributes.facility_type_profession_experience_rule_id,
		professionId:
			professionExperience.relationships.facility_type_profession_experience_rule.attributes
				.profession_id,
		facilityTypeId:
			professionExperience.relationships.facility_type_profession_experience_rule.attributes
				.facility_type_id,
		professionName:
			professionExperience.relationships.facility_type_profession_experience_rule.relationships
				.profession.attributes.name,
		professionCode:
			professionExperience.relationships.facility_type_profession_experience_rule.relationships
				.profession.attributes.code,
		isExperienced:
			professionExperience.attributes.experience_months >=
			professionExperience.relationships.facility_type_profession_experience_rule.attributes
				.experience_threshold,
	}));
};
