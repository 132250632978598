import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { ApiResponse, SuccessResponse } from '../types/api/SharedApiTypes';
import {
	UpdatePasswordRequest,
	UpdateProfileRequest,
	UpdateProfileResponse,
	DeleteUserRequest,
	DeleteUserResponse,
	ForcePasswordResetRequest,
	ForcePasswordResetResponse,
	UnlockUserRequest,
	UnlockUserResponse,
} from '../types/api/Users';
import { buildQueryString } from '../utils/buildQueryString';
import { GetAdminsRequest, GetAdminsResponse } from '../types/api/Auth';

export class UsersApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async updateProfile(request: UpdateProfileRequest): Promise<UpdateProfileResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const updateUserResponse = (await fetchWrapper(
				{
					path: `update_profile`,
					method: 'PATCH',
					body: request,
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateProfileResponse>;

			return updateUserResponse.data;
		} catch (error) {
			console.error('error in users api', JSON.stringify(error));
			throw error;
		}
	}

	public async updatePassword(request: UpdatePasswordRequest): Promise<SuccessResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const updatePasswordResponse = (await fetchWrapper(
				{
					path: `update_password`,
					method: 'PATCH',
					body: request,
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<SuccessResponse>;

			return updatePasswordResponse.data;
		} catch (error) {
			console.error('error in users api', JSON.stringify(error));
			throw error;
		}
	}

	public async deleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const deleteUserResponse = (await fetchWrapper(
				{
					path: `users/${request.userId}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<DeleteUserResponse>;

			return deleteUserResponse.data;
		} catch (error) {
			console.error('error in users api', JSON.stringify(error));
			throw error;
		}
	}

	public async forcePasswordReset(
		request: ForcePasswordResetRequest
	): Promise<ForcePasswordResetResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const forcePasswordResetResponse = (await fetchWrapper(
				{
					path: `users/${request.userId}/force_password_reset`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<ForcePasswordResetResponse>;

			return forcePasswordResetResponse.data;
		} catch (error) {
			console.error('error in users api', JSON.stringify(error));
			throw error;
		}
	}

	//list of all admin users
	public async getAdmins(request: GetAdminsRequest): Promise<GetAdminsResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getAdminsResponse = (await fetchWrapper(
				{
					path: `users/admins${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetAdminsResponse;

			return getAdminsResponse;
		} catch (error) {
			console.error('error in admins api', JSON.stringify(error));
			throw error;
		}
	}

	public async unlockUser(request: UnlockUserRequest): Promise<UnlockUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const unlockUserResponse = (await fetchWrapper(
				{
					path: `users/${request.userId}/unlock_user`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UnlockUserResponse>;

			return unlockUserResponse.data;
		} catch (error) {
			console.error('error in users api', JSON.stringify(error));
			throw error;
		}
	}
}
