export const environmentConfig: {
	apiUrl: string;
} = {
	apiUrl: process.env.API_URL || '',
};

export const pubNubKeys: {
	publishKey: string;
	subscribeKey: string;
} = {
	publishKey: process.env.PUBLISH_KEY || '',
	subscribeKey: process.env.SUBSCRIBE_KEY || '',
};

export const isProd = process.env.STAGE === 'production';
