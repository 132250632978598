import React, { useEffect, useState } from 'react';
import { useAsync } from '../../../hooks/useAsync';
import { Tabs } from '../../../components/Tabs';
import { AuthRouteNames, TabHash } from '../../../navigation/routeNames';
import { SidePanel } from '../../../components/SidePanel';
import { ContractorInviteList } from './tabs/ContractorInviteList';
import { ContractorInviteRequest } from './components/ContractorInviteRequest';
import { webApiManager } from '../../../network/apiManager';
import {
	adaptContractorInvitesFromApi,
	AdaptedContractorInviteList,
	AdaptedContractorInviteRequest,
} from '../../../adapters/contractorInvitesAdapters';
import { ContractorInviteSortOptions } from '../../../types/api/ContractorInviteRequests';
import useSortAndPagination from '../../../hooks/useSortFilterAndPagination';

export const ManageContractorInvites = () => {
	const [invites, setInvites] = useState<AdaptedContractorInviteList | null>(null);
	const [selectedInviteRequestId, setSelectedInviteRequestId] = useState<
		AdaptedContractorInviteRequest['id'] | null
	>(null);

	useEffect(() => {
		//fetch list on load
		getInviteList.execute();
	}, []);

	const onFetch = async (
		requestOptions: { page?: number; sort_by?: ContractorInviteSortOptions } = {}
	) => {
		getInviteList.clearError();
		try {
			const getInviteListResponse = adaptContractorInvitesFromApi(
				await webApiManager.ContractorInviteRequests.getInviteRequests(requestOptions)
			);

			//if fetching next page, add to list and update pagination, otherwise replace
			setInvites(prevState => {
				if (getInviteListResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...getInviteListResponse.list],
						pagination: getInviteListResponse.pagination,
					};
				} else {
					return getInviteListResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getInviteList = useAsync<void>(onFetch, 'No results found.');

	//handle sort and pagination for contractor list
	const { setSortBy, getNextPage } = useSortAndPagination<ContractorInviteSortOptions>(
		invites,
		getInviteList
	);

	useEffect(() => {
		//refresh list when sidebar closes to get any updates
		if (!!invites && !selectedInviteRequestId) {
			getInviteList.execute();
		}
	}, [selectedInviteRequestId]);

	return (
		<>
			<Tabs
				mainPath={AuthRouteNames.ContractorInvites}
				tabs={[
					{
						title: 'Invites',
						component: (
							<ContractorInviteList
								setSelectedInviteRequestId={setSelectedInviteRequestId}
								inviteList={invites?.list}
								requestError={getInviteList.error}
								requestPending={getInviteList.pending}
								setSortBy={setSortBy}
								getNextPage={getNextPage}
								showEndResultsMessage={!!invites?.pagination.isFinalPage}
							/>
						),
						hash: TabHash.list,
						hasTable: true,
					},
				]}
			/>
			<SidePanel isOpen={!!selectedInviteRequestId} hide={() => setSelectedInviteRequestId(null)}>
				<ContractorInviteRequest
					selectedInviteId={selectedInviteRequestId}
					setSelectedInviteId={setSelectedInviteRequestId}
				/>
			</SidePanel>
		</>
	);
};
