import React, { useEffect, useState } from 'react';
import { Tabs } from '../../../components/Tabs';
import { AuthRouteNames, TabHash } from '../../../navigation/routeNames';
import { FacilityInviteList } from './tabs/FacilityInviteList';
import { SidePanel } from '../../../components/SidePanel';
import { FacilityInviteRequest } from './components/FacilityInviteRequest';
import { useAsync } from '../../../hooks/useAsync';
import { webApiManager } from '../../../network/apiManager';
import {
	AdaptedFacilityInviteList,
	AdaptedFacilityInviteRequest,
	adaptFacilityInvitesFromApi,
} from '../../../adapters/facilityInvitesAdapters';
import { FacilityInviteSortOptions } from '../../../types/api/FacilityInviteRequests';
import useSortAndPagination from '../../../hooks/useSortFilterAndPagination';

export const ManageFacilityInvites = () => {
	const [invites, setInvites] = useState<AdaptedFacilityInviteList | null>(null);
	const [selectedInviteRequestId, setSelectedInviteRequestId] = useState<
		AdaptedFacilityInviteRequest['id'] | null
	>(null);

	useEffect(() => {
		//fetch list on load
		getInviteList.execute();
	}, []);

	const onFetch = async (
		requestOptions: { page?: number; sort_by?: FacilityInviteSortOptions } = {}
	) => {
		getInviteList.clearError();
		try {
			const getInviteListResponse = adaptFacilityInvitesFromApi(
				await webApiManager.FacilityInviteRequests.getInviteRequests(requestOptions)
			);

			//if fetching next page, add to list and update pagination, otherwise replace
			setInvites(prevState => {
				if (getInviteListResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...getInviteListResponse.list],
						pagination: getInviteListResponse.pagination,
					};
				} else {
					return getInviteListResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getInviteList = useAsync<void>(onFetch, 'No results found.');

	//handle sort and pagination for invite list
	const { setSortBy, getNextPage } = useSortAndPagination<FacilityInviteSortOptions>(
		invites,
		getInviteList
	);

	useEffect(() => {
		//refresh list when sidebar closes to get any updates
		if (!!invites && !selectedInviteRequestId) {
			getInviteList.execute();
		}
	}, [selectedInviteRequestId]);

	return (
		<>
			<Tabs
				mainPath={AuthRouteNames.FacilityInvites}
				tabs={[
					{
						title: 'Invites',
						component: (
							<FacilityInviteList
								setSelectedInviteRequestId={setSelectedInviteRequestId}
								inviteList={invites?.list}
								requestError={getInviteList.error}
								requestPending={getInviteList.pending}
								setSortBy={setSortBy}
								getNextPage={getNextPage}
								showEndResultsMessage={!!invites?.pagination.isFinalPage}
							/>
						),
						hash: TabHash.list,
						hasTable: true,
					},
				]}
			/>
			<SidePanel isOpen={!!selectedInviteRequestId} hide={() => setSelectedInviteRequestId(null)}>
				<FacilityInviteRequest
					selectedInviteId={selectedInviteRequestId}
					setSelectedInviteId={setSelectedInviteRequestId}
				/>
			</SidePanel>
		</>
	);
};
