import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { GetReviewsRequest, GetReviewsResponse } from '../types/api/Reviews';
import { buildQueryString } from '../utils/buildQueryString';

export class ReviewsApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getReviews(request: GetReviewsRequest): Promise<GetReviewsResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const params = buildQueryString(request);

			const getReviewsResponse = (await fetchWrapper(
				{
					path: `contract_instance_reviews${params}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetReviewsResponse;

			return getReviewsResponse;
		} catch (err) {
			console.error('error in reviews api', JSON.stringify(err));
			throw err;
		}
	}
}
