import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import {
	ForgotPasswordRequest,
	LoginType,
	RememberCurrentUserResponse,
	ResetPasswordRequest,
	ResetPasswordResponse,
	SignInRequest,
	SignInResponse,
	TwoFactorAuthRequest,
	TwoFactorAuthValidate,
	UserDetails,
} from '../types/api/Auth';
import { ApiResponse, SuccessResponse } from '../types/api/SharedApiTypes';
import { CurrentUser } from '../context/UserContext';
import { adaptUserFromApi } from '../adapters/userAdapter';

export class AuthApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async loginWithPassword(
		request: SignInRequest,
		setCurrentUser: (newUser: CurrentUser) => void,
		setUserAuthorized: (isAuthenticated: boolean) => void
	): Promise<void> {
		try {
			const authToken = await this.apiManager.getAuthToken();

			//send login to server and get login response
			const loginResponse = (await fetchWrapper(
				{
					path: `authenticate`,
					method: 'POST',
					body: {
						email: request.email,
						password: request.password,
						remember_me_auth_token: authToken,
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<SignInResponse>;

			// Throw an error if this user isn't an admin:
			if (!loginResponse.data.relationships.user.data.meta?.security.is_employee) {
				const notAdminError = Error(
					'This login is for employees of Carefiller only. Log into the mobile app instead.'
				);
				// Defining this year will cause useAsync to set the error string correctly:
				(notAdminError as any).errors = {
					base: [
						'This login is for employees of Carefiller only. Log into the mobile app instead.',
					],
				};
				throw notAdminError;
			}

			// save the auth token, refresh token, and user id
			this.apiManager.setToken(loginResponse.data.attributes.auth_token);
			this.apiManager.setRefreshToken(loginResponse.data.attributes.refresh_token);
			this.apiManager.setUserId(JSON.stringify(loginResponse.data.relationships.user.data.id));

			//TODO: set login type based on user role (v2?)
			this.apiManager.loginType = LoginType.SUPER_ADMIN;

			//save user data to context
			setCurrentUser(adaptUserFromApi(loginResponse.data.relationships.user.data));
			if (loginResponse.data.attributes.twofa_authenticated) {
				setUserAuthorized(true);
			}
		} catch (err) {
			console.error('login error in auth api', JSON.stringify(err));
			throw err;
		}
	}

	public async twoFactorAuthRequest(request: TwoFactorAuthRequest): Promise<SuccessResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			//send 2fa request to server
			const twoFactorResponse = (await fetchWrapper(
				{
					path: `twofa_request`,
					method: 'POST',
					body: {
						notification_method: request.notification_method,
					},
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<SuccessResponse>;

			return twoFactorResponse.data;
		} catch (err) {
			console.error('2fa error in auth api', JSON.stringify(err));
			throw err;
		}
	}

	public async twoFactorAuthValidation(request: TwoFactorAuthValidate): Promise<SuccessResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			//send 2fa validation code to server
			const twoFactorResponse = (await fetchWrapper(
				{
					path: `twofa_validate`,
					method: 'POST',
					body: {
						code: request.code,
					},
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<SuccessResponse>;

			return twoFactorResponse.data;
		} catch (err) {
			console.error('2fa error in auth api', JSON.stringify(err));
			throw err;
		}
	}

	public async getCurrentUser(
		request: any,
		setCurrentUser: (newUser: CurrentUser) => void,
		setUserAuthorized: (isAuthorized: boolean) => void
	): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();
			//send token to server to get current user data
			const getUserResponse = (await fetchWrapper(
				{
					path: `whoami`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UserDetails>;

			// This will cause the app to display the Unauthorized page:
			if (!getUserResponse.data.meta?.security.is_employee) {
				throw Error('You are not authorized to view this page');
			}

			//TODO: set login type based on user role (v2?)
			this.apiManager.loginType = LoginType.SUPER_ADMIN;

			setCurrentUser(adaptUserFromApi(getUserResponse.data));
			setUserAuthorized(true);
		} catch (err) {
			console.error('get user error in auth api', JSON.stringify(err));
			throw err;
		}
	}

	public async rememberCurrentUser(
		request: any,
		setUserAuthorized: (isAuthorized: boolean) => void
	): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();
			//send token to server to get current user data
			const rememberUserResponse = (await fetchWrapper(
				{
					path: `generate_remember_me_token`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<RememberCurrentUserResponse>;

			await this.apiManager.setAuthToken(rememberUserResponse.data.remember_me_auth_token);
			setUserAuthorized(true);
		} catch (err) {
			console.error('get user error in auth api', JSON.stringify(err));
			throw err;
		}
	}

	public async logout(
		setCurrentUser: (newUser: CurrentUser) => void,
		callBack: () => void
	): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();
			await fetchWrapper(
				{
					path: `signout`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			);

			//remove tokens from local storage
			await this.apiManager.clearTokens();
			//clear user from context
			setCurrentUser(undefined);
			//reroute to login
			callBack();
		} catch (error) {
			console.error('Logout error', error);
			//even if server fails to logout user, still clear tokens & user context, and reroute to login
			await this.apiManager.clearTokens();
			setCurrentUser(undefined);
			callBack();
			throw error;
		}
	}

	public async forgotPassword(request: ForgotPasswordRequest): Promise<void> {
		try {
			await fetchWrapper(
				{
					path: `password_resets`,
					method: 'POST',
					body: request,
				},
				this.apiManager.rootApiUrl
			);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	public async resetPassword(request: ResetPasswordRequest): Promise<ResetPasswordResponse> {
		try {
			const resetPasswordResponse = (await fetchWrapper(
				{
					path: `password_resets`,
					method: 'PUT',
					body: request,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<ResetPasswordResponse>;

			return resetPasswordResponse.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	public async refresh({
		setCurrentUser,
		setUserAuthorized,
	}: {
		setCurrentUser?: (newUser: CurrentUser) => void;
		setUserAuthorized?: (isAuthorized: boolean) => void;
	}): Promise<void> {
		try {
			const refreshToken = this.apiManager.getRefreshToken();
			const userId = this.apiManager.getUserId();

			if (!refreshToken || !userId) {
				if (setCurrentUser) {
					setCurrentUser(null);
				}
				throw new Error('missing parameters; login again');
			} else {
				const refreshResponse = (await fetchWrapper(
					{
						path: `refresh_token`,
						method: 'POST',
						body: {
							refresh_token: refreshToken,
							user_id: userId,
						},
					},
					this.apiManager.rootApiUrl
				)) as ApiResponse<SignInResponse>;

				this.apiManager.setToken(refreshResponse.data.attributes.auth_token);
				this.apiManager.setRefreshToken(refreshResponse.data.attributes.refresh_token);

				if (setCurrentUser && setUserAuthorized) {
					//TODO: check whether admin or super admin and set login type on api (v2?)
					this.apiManager.loginType = LoginType.SUPER_ADMIN;

					setCurrentUser(adaptUserFromApi(refreshResponse.data.relationships.user.data));
					setUserAuthorized(true);
				}
			}
		} catch (err) {
			console.error(err);
			this.apiManager.clearTokens();
			throw err;
		}
	}
}
