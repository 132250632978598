import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import { FlexWrapper } from './Wrappers';
import { UserLabel } from './UserLabel';
import formatDate from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { useLocation } from 'react-router-dom';
import { TabHash } from '../navigation/routeNames';
import { AdaptedMessage } from '../types/PubNubTypes';

type MessageProps = {
	messages: AdaptedMessage[];
	noMaxHeight?: boolean;
	shouldScrollToBottom?: boolean;
	reverseOrder?: boolean;
};

export const Messages = ({
	messages,
	noMaxHeight,
	shouldScrollToBottom,
	reverseOrder,
}: MessageProps) => {
	const orderedMessages = !reverseOrder ? messages : [...messages].reverse();
	const messagesEndRef = useRef<HTMLDivElement | null>(null);
	const currentHash = useLocation().hash;

	//auto scroll to bottom of message window
	const scrollToBottom = () => {
		messagesEndRef?.current?.scrollIntoView({ behavior: 'auto' });
	};

	useEffect(() => {
		if (shouldScrollToBottom) {
			scrollToBottom();
		}
	}, [messages]);

	useEffect(() => {
		if (currentHash === TabHash.messages && shouldScrollToBottom) {
			scrollToBottom();
		}
	}, [currentHash]);

	return (
		<MessagesContainer noMaxHeight={noMaxHeight}>
			{orderedMessages.length === 0 ? (
				<MessageInfo>No messages found.</MessageInfo>
			) : (
				<>
					{orderedMessages.map((message, index, array) => {
						const shouldShowDate = index === 0 || !isSameDay(message.time, array[index - 1].time);
						return (
							<MessageWrapper key={index}>
								{shouldShowDate && (
									<MessageDateLabel>{formatDate(message.time, 'MMMM dd, yyyy')}</MessageDateLabel>
								)}
								<FlexWrapper justifyContent={'flex-end'} margin={'5px 0 0'}>
									<MessageTimeLabel>{formatDate(message.time, 'h:mm aa')}</MessageTimeLabel>
								</FlexWrapper>
								<FlexWrapper justifyContent={'space-between'} margin={'5px 0 10px'}>
									<UserLabel
										padding={'0'}
										name={message.name}
										color={
											message.name === 'Carefiller'
												? Theming.icons.tertiaryIconColor
												: Theming.icons.primaryIconColor
										}
									/>
								</FlexWrapper>
								<MessageText>{message.text}</MessageText>
							</MessageWrapper>
						);
					})}
				</>
			)}
			<HiddenScrollToDiv ref={messagesEndRef} />
		</MessagesContainer>
	);
};

const HiddenScrollToDiv = styled.div`
	margin: 0;
	padding: 0;
	visibility: hidden;
`;

const MessagesContainer = styled.div<{ noMaxHeight?: boolean }>`
	padding: 10px;
	margin: 0;
	overflow-y: auto;
	max-height: ${({ noMaxHeight }) => (noMaxHeight ? 'none' : 'calc(100vh - 250px)')};
`;

const MessageWrapper = styled.div`
	margin: 0 auto;
	padding: 10px 0;
	width: 100%;
`;

const MessageDateLabel = styled.p`
	text-align: center;
	font-family: ${Theming.text.boldFont};
	font-size: 12px;
	color: ${Theming.text.labelColor};
	margin: 0;
	padding: 15px 0;
`;

const MessageTimeLabel = styled.p`
	text-align: center;
	font-family: ${Theming.text.regularFont};
	font-size: 12px;
	color: ${Theming.text.labelColor};
	white-space: nowrap;
	padding: 0;
	margin: 0;
`;

const MessageText = styled.p`
	text-align: left;
	font-family: ${Theming.text.regularFont};
	font-size: 13px;
	color: ${Theming.text.primaryTextColor};
	margin: 0 0 0 31px;
	padding: 10px;
	background-color: ${Theming.backgrounds.inputBackground};
	border-radius: 0 8px 8px 8px;
`;

export const MessageInfo = styled.p`
	text-align: center;
	font-family: ${Theming.text.regularFont};
	font-size: 16px;
	color: ${Theming.text.placeholderColor};
	margin: 0;
	padding: 0 10px;
	font-style: italic;
`;
