export const useScrollToBottom = (onScrollToBottom?: () => void) => {
	//for infinite scroll
	const handleScroll = event => {
		const target = event.target;
		if (target.scrollHeight - target.scrollTop === target.clientHeight && !!onScrollToBottom) {
			onScrollToBottom();
		}
	};

	return handleScroll;
};
