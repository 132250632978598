import React from 'react';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { FlexWrapper } from '../../../components/Wrappers';
import { MessageInfo } from '../../../components/Messages';
import IconManager, { IconType } from '../../../components/IconManager';
import { AdaptedContract } from '../../../adapters/contractAdapters';
import { PubNubMessaging } from '../../../components/PubNubMessaging';

type ContractMessagesProps = {
	selectedContract: AdaptedContract | null;
};

export const ContractMessages = ({ selectedContract }: ContractMessagesProps) => {
	return (
		<MessagesContainer isActive={!!selectedContract}>
			{!selectedContract ? (
				<FlexWrapper margin={'20px auto'}>
					<IconManager
						type={IconType.ARROW_LEFT}
						stroke={Theming.icons.secondaryIconColor}
						size={24}
					/>
					<MessageInfo>Select a contract to view message log.</MessageInfo>
				</FlexWrapper>
			) : (
				<>
					<MessagesTitle>Contract #{selectedContract.displayId}</MessagesTitle>
					<PubNubMessaging
						channelName={selectedContract.channelName}
						readOnly
						reverseOrder
						showRefresh
						noMaxHeight
					/>
				</>
			)}
		</MessagesContainer>
	);
};

const MessagesContainer = styled.div<{ isActive?: boolean }>`
	border-top: ${({ isActive }) =>
			isActive ? Theming.buttons.primaryButtonColor : Theming.buttons.disabledButtonColor}
		15px solid;
	background-color: #fff;
	margin: 0;
	padding: 20px;
	width: 100%;
	height: 100%;
	text-align: center;
	overflow: auto;
`;

export const MessagesTitle = styled.p`
	text-align: center;
	font-family: ${Theming.text.boldFont};
	font-size: 16px;
	color: ${Theming.text.titleColor};
	margin: 10px auto;
	padding: 0;
`;
