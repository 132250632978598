import { StatusType, UUID } from '../sharedTypes';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { FacilityType } from './FacilityTypes';
import { LocationUser } from './LocationUsers';
import { LocationKudos, Rating } from './Reviews';
import { Pause } from './Pauses';

export interface FacilityLocation {
	id: UUID;
	type: 'locations';
	attributes: {
		facility_id: UUID;
		name: string;
		status: StatusType;
		city: string;
		state: string;
		zip_code: string;
		street_address: string;
		pubnub_channel_name: string;
	};
	relationships?: {
		type: 'locations';
		location_users: LocationUser[];
		facility_type: FacilityType;
		resource_pauses?: Pause[];
	};
	meta: {
		kudos: LocationKudos;
		rating: Rating;
		completed_contracts_count: number;
		cancelled_contracts_count: number;
		payment_methods_count: number;
	};
}

export enum LocationSortOptions {
	name = 'name',
	state = 'state',
	city = 'city',
	zipCode = 'zip_code',
	status = 'status',
}

export interface GetLocationsRequest {
	facilityId: UUID;
	query: PaginatedApiRequest<LocationSortOptions>;
}

export type GetLocationsResponse = PaginatedApiResponse<FacilityLocation[]>;

export interface GetLocationRequest {
	locationId: UUID;
}

export type GetLocationResponse = FacilityLocation;

export interface UpdateLocationRequest {
	locationId: UUID;
	updates: Partial<FacilityLocation['attributes'] & { facility_type_id: string }>;
}

export type UpdateLocationResponse = FacilityLocation;

export interface SetLocationStatusRequest {
	locationId: UUID;
	status: StatusType;
}

export type SetLocationStatusResponse = FacilityLocation;

export interface CreateLocationRequest {
	facilityId: UUID;
	location: {
		name: string;
		facility_type_id: UUID;
		street_address: string;
		city: string;
		state: string; //needs to be state abbreviation
		zip_code: string;
		status?: StatusType;
	};
}

export type CreateLocationResponse = FacilityLocation;
