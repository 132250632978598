import React from 'react';
import { AdaptedFacilityLocation } from '../../../../adapters/locationsAdapters';
import { PageColumn, PageRow } from '../../../../components/Wrappers';
import { MultiLineText, PageSection, PageSectionItem } from '../../../../components/PageSection';
import { StatusDisplay } from '../../../../components/StatusDisplay';
import { PubNubMessaging } from '../../../../components/PubNubMessaging';

type LocationMessagesProps = {
	selectedLocation: AdaptedFacilityLocation;
};

export const LocationMessagesTab = ({ selectedLocation }: LocationMessagesProps) => {
	return (
		<PageRow>
			<PageColumn width={35}>
				<PageSection title={selectedLocation.name}>
					<PageSectionItem label={'Status'}>
						<StatusDisplay statusType={selectedLocation.status} />
					</PageSectionItem>
					<PageSectionItem label={'Address'} flex justifyContent={'flex-start'}>
						<MultiLineText>
							{selectedLocation.streetAddress}
							<br />
							{`${selectedLocation.city}, ${selectedLocation.state} ${selectedLocation.zipCode}`}
						</MultiLineText>
					</PageSectionItem>
					<PageSectionItem label={'Facility Type'}>
						{selectedLocation.facilityType?.name ?? 'N/A'}
					</PageSectionItem>
				</PageSection>
			</PageColumn>
			<PageColumn width={65}>
				<PageSection margin={'0 auto'}>
					<PubNubMessaging channelName={selectedLocation.channelName} shouldScrollToBottom />
				</PageSection>
			</PageColumn>
		</PageRow>
	);
};
