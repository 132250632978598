import React, { useContext, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { isProd, pubNubKeys } from '../../environmentConfig';
import { UserContext } from '../context/UserContext';
import LoadingSpinner from './Loader';
import { ErrorMessage } from './Text';
import { Messages } from './Messages';
import { MessageInput } from './MessageInput';
import { AdaptedMessage, PubNubEnvelope } from '../types/PubNubTypes';
import { convertTimeToken } from '../utils/convertTimeToken';
import { FormLink } from './Links';

type PubNubProps = {
	channelName: string;
	readOnly?: boolean;
	reverseOrder?: boolean;
	showRefresh?: boolean;
	noMaxHeight?: boolean;
	shouldScrollToBottom?: boolean;
};

export const PubNubMessaging = ({
	channelName,
	readOnly,
	reverseOrder,
	showRefresh,
	noMaxHeight,
	shouldScrollToBottom,
}: PubNubProps) => {
	const { currentUser } = useContext(UserContext);

	const pubnub = new PubNub({
		publishKey: pubNubKeys.publishKey,
		subscribeKey: pubNubKeys.subscribeKey,
		uuid: currentUser?.pubNubUserId ?? '',
	});

	return (
		<PubNubProvider client={pubnub}>
			<PubNubChat
				channelName={channelName}
				readOnly={readOnly}
				reverseOrder={reverseOrder}
				showRefresh={showRefresh}
				noMaxHeight={noMaxHeight}
				shouldScrollToBottom={shouldScrollToBottom}
			/>
		</PubNubProvider>
	);
};

export const PubNubChat = ({
	channelName,
	readOnly,
	reverseOrder,
	showRefresh,
	noMaxHeight,
	shouldScrollToBottom,
}: PubNubProps) => {
	const pubnub = usePubNub();

	const [messages, setMessages] = useState<AdaptedMessage[]>([]);
	const [pending, setPending] = useState<boolean>(false);
	const [pubnubError, setPubnubError] = useState<string | null>(null);
	const [clearInput, setClearInput] = useState<boolean>(false);

	//when channel changes, clear message log/input, resubscribe & re-fetch messages
	useEffect(() => {
		setMessages([]);
		setClearInput(true);
		if (channelName) {
			connectToPubnub();
		}
	}, [channelName]);

	//reset pending status when messages are set
	useEffect(() => {
		setPending(false);
	}, [messages]);

	//reset boolean after input clears
	useEffect(() => {
		if (clearInput) {
			setClearInput(false);
		}
	}, [clearInput]);

	//subscribe to channel, fetch previous messages, and set listener for new messages
	const connectToPubnub = () => {
		setPending(true);
		setPubnubError(null);

		// Add the listener to pubnub instance and subscribe to `chat` channel.
		pubnub.addListener({ message: handleMessage });
		pubnub.subscribe({ channels: [channelName] });
		pubnub.fetchMessages(
			{
				channels: [channelName],
				count: 100,
			},
			(status, response) => {
				if (!response) {
					console.error('pubnub error', status?.errorData);
					//eslint-disable-next-line
					//@ts-ignore - error_message may exist on errorData
					setPubnubError(status?.errorData?.error_message ?? 'Error connecting to pubnub');
				} else {
					const channel = response.channels[channelName];
					// handle response
					const adaptedMessages = !channel ? [] : channel.map(envelope => adaptMessage(envelope));
					setMessages(adaptedMessages);
				}
			}
		);
	};

	//handle new messages that are added to channel
	const handleMessage = envelope => {
		const newMessage = adaptMessage(envelope);
		setMessages(prevState => [...prevState, newMessage]);
	};

	//format data for display
	const adaptMessage = (envelope: PubNubEnvelope): AdaptedMessage => {
		return {
			name: envelope.message.name,
			time: convertTimeToken(envelope.timetoken),
			text: envelope.message.content,
			messageId: envelope.message.id,
		};
	};

	//send new message to channel
	const sendMessage = async (messageText: string) => {
		try {
			if (messageText) {
				// Create the message with random `id`, set 'name' property, and add push notification data. (** This must match how mobile app is setting & reading message objects **)
				const message = {
					content: messageText,
					id: Math.random().toString(16).substr(2),
					name: 'Carefiller', //all admin messages should show as coming from 'Carefiller'
					//push notification data (for use on mobile app)
					pn_apns: {
						aps: {
							alert: {
								title: 'Carefiller',
								body: messageText,
							},
						},
						pn_push: [
							{
								push_type: 'alert',
								auth_method: 'token',
								targets: [
									{
										environment: isProd ? 'production' : 'development',
										topic: 'com.carefiller.marketplace',
									},
								],
								version: 'v2',
							},
						],
					},
					pn_gcm: {
						notification: {
							title: 'Carefiller',
							body: messageText,
							sound: 'default',
						},
					},
				};
				await pubnub.publish({ channel: channelName, message });
			}
		} catch (error) {
			console.error('error sending message', error);
			setPubnubError(error as string);
			throw error;
		}
	};

	return (
		<>
			{pending ? (
				<LoadingSpinner />
			) : (
				<>
					{!!pubnubError && <ErrorMessage text={pubnubError} />}
					<Messages
						messages={messages}
						noMaxHeight={noMaxHeight}
						shouldScrollToBottom={shouldScrollToBottom}
						reverseOrder={reverseOrder}
					/>
					{!readOnly && (
						<MessageInput
							onSubmit={newMessage => sendMessage(newMessage)}
							clearInput={clearInput}
						/>
					)}
					{showRefresh && <FormLink onClick={connectToPubnub}>Refresh</FormLink>}
				</>
			)}
		</>
	);
};
