import React, { ReactNode, useRef, useEffect } from 'react';
import styled from 'styled-components';
import useViewport from '../hooks/useViewport';
import { getModalPortalContainer } from '../utils/getModalPortalContainer';
import ReactModal from 'react-modal';
import { Theming } from '../theming';
import LoadingSpinner from './Loader';
import { BaseButton } from './Buttons';

export enum ModalStyle {
	DEFAULT = 'default',
	LARGE = 'large',
}

interface ModalContainerProps {
	children: ReactNode;
	isOpen: boolean;
	onRequestClose?: () => void;
	closeButton?: boolean;
	style?: ModalStyle;
}

export interface ModalProps {
	isOpen: boolean;
	hide: () => void;
	style?: ModalStyle;
	text?: string;
	onConfirm?: any;
	title?: string | React.ReactElement;
	confirmButtonText?: string;
	cancelButtonText?: string;
	hideCancel?: boolean;
	showTitle?: boolean;
	content?: React.ReactElement;
	showCloseButton?: boolean;
	hideConfirm?: boolean;
	confirmButtonColor?: string;
	pending?: boolean;
}

interface ModalButtonsProps {
	hide: () => void;
	onConfirm?: any;
	confirmButtonText?: string;
	cancelButtonText?: string;
	hideCancel?: boolean;
	hideConfirm?: boolean;
	confirmButtonColor?: string;
	style?: ModalStyle;
}

export const Modal = ({
	isOpen,
	hide,
	text,
	onConfirm,
	title,
	confirmButtonText,
	cancelButtonText,
	hideCancel = false,
	showTitle = true,
	content,
	showCloseButton = true,
	hideConfirm = false,
	confirmButtonColor,
	pending = false,
	style = ModalStyle.DEFAULT,
}: ModalProps) => {
	// Make ESC trigger the hide function (assuming showCloseButton is false):
	useEffect(() => {
		if (!showCloseButton || !isOpen) {
			return;
		}

		const handleKeyUp = event => {
			switch (event.key) {
				case 'Escape':
					hide();
					break;
			}
		};

		window.addEventListener('keyup', handleKeyUp);
		return () => window.removeEventListener('keyup', handleKeyUp);
	}, [isOpen]);

	return (
		<ModalContainer
			style={style}
			isOpen={isOpen}
			onRequestClose={hide}
			closeButton={showCloseButton}
		>
			<ModalContent>
				{pending ? (
					<LoadingSpinner />
				) : (
					<>
						{showTitle && <ModalTitle modalStyle={style}>{title ?? 'Are you sure?'}</ModalTitle>}
						<ModalText>{text}</ModalText>
						{content ?? ''}
						<ModalButtons
							style={style}
							hideCancel={hideCancel}
							hideConfirm={hideConfirm}
							hide={hide}
							onConfirm={onConfirm}
							cancelButtonText={cancelButtonText}
							confirmButtonText={confirmButtonText}
							confirmButtonColor={confirmButtonColor}
						/>
					</>
				)}
			</ModalContent>
		</ModalContainer>
	);
};

const getButtonElementsFromRef = ref => {
	if (ref && ref.current && ref.current.querySelectorAll) {
		return [...ref.current.querySelectorAll('button')];
	}

	return [];
};

export const ModalButtons = ({
	hide,
	onConfirm,
	confirmButtonText,
	cancelButtonText,
	hideCancel = false,
	hideConfirm = false,
	confirmButtonColor,
	style,
}: ModalButtonsProps) => {
	const selfRef = useRef<HTMLDivElement>(null);

	switch (style) {
		case ModalStyle.LARGE:
			// Automatically focus on the last button
			useEffect(() => {
				const buttons = getButtonElementsFromRef(selfRef);

				if (buttons.length > 0) {
					buttons[0].focus();
				}
			}, []);

			return (
				<ModalButtonWrapper ref={selfRef} modalStyle={style}>
					{!hideConfirm && (
						<LargeButton
							onClick={onConfirm}
							backgroundColor={confirmButtonColor}
							text={confirmButtonText ?? 'Yes'}
						/>
					)}{' '}
					{!hideCancel && (
						<LargeButton
							onClick={hide}
							backgroundColor={Theming.buttons.cancelButtonColor}
							text={cancelButtonText ?? 'No'}
						/>
					)}
				</ModalButtonWrapper>
			);

		case ModalStyle.DEFAULT:
		default:
			// Automatically focus on the first button
			useEffect(() => {
				const buttons = getButtonElementsFromRef(selfRef);

				if (buttons.length > 0) {
					buttons[buttons.length - 1].focus();
				}
			}, []);

			return (
				<ModalButtonWrapper ref={selfRef} modalStyle={style}>
					{!hideCancel && (
						<BaseButton
							onClick={hide}
							backgroundColor={Theming.buttons.cancelButtonColor}
							text={cancelButtonText ?? 'No'}
						/>
					)}
					{!hideConfirm && (
						<BaseButton
							onClick={onConfirm}
							backgroundColor={confirmButtonColor}
							text={confirmButtonText ?? 'Yes'}
						/>
					)}
				</ModalButtonWrapper>
			);
	}
};

const ModalContainer: React.FC<ModalContainerProps> = ({
	children,
	isOpen,
	onRequestClose = () => {},
	closeButton = true,
	style = ModalStyle.DEFAULT,
}) => {
	useViewport();

	const styles = {
		overlay: {
			zIndex: 9999,
			padding: 0,
		},
		content: {
			background: 'transparent',
			textAlign: 'center',
			border: 'none',
			padding: '0 8px',
			margin: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontFamily: Theming.text.regularFont,
		},
	};

	let Wrapper;
	switch (style) {
		case ModalStyle.DEFAULT:
			Wrapper = ModalWrapperDefault;
			break;

		case ModalStyle.LARGE:
			Wrapper = ModalWrapperLarge;
			break;
	}

	return !isOpen ? null : (
		<ReactModal
			isOpen={isOpen}
			contentLabel="Modal"
			//eslint-disable-next-line
			//@ts-ignore
			style={styles}
			parentSelector={getModalPortalContainer}
			ariaHideApp={false}
			shouldFocusAfterRender={true}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}
		>
			<Wrapper>
				{closeButton && (
					<CloseButton modalStyle={style} aria-label="Close dialog" onClick={onRequestClose}>
						&times;
					</CloseButton>
				)}
				{children}
			</Wrapper>
		</ReactModal>
	);
};

const ModalContent = styled.div`
	margin: 0 auto;
	text-align: center;
	max-width: 400px;
`;

const ModalText = styled.p`
	color: ${Theming.text.primaryTextColor};
	font-family: ${Theming.text.regularFont};
	text-align: center;
	font-size: 16px;
`;

const ModalTitle = styled.h4<{ modalStyle?: string }>`
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.titleColor};
	font-size: ${({ modalStyle }) => (modalStyle === ModalStyle.LARGE ? '32px' : '18px')};
	margin: ${({ modalStyle }) => (modalStyle === ModalStyle.LARGE ? 0 : '1.5em')};
	font-weight: bold;
	text-align: center;
`;

const ModalButtonWrapper = styled.div<{ modalStyle?: string }>`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	padding: 15px 0;
	gap: 20px;
	flex-direction: ${({ modalStyle }) => (modalStyle === ModalStyle.LARGE ? 'column' : 'row')};
`;

const ModalWrapperDefault = styled.div`
	position: relative;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 4px;
	padding: 15px;
	box-shadow: 2px 2px 5px 2px rgba(100, 100, 100, 0.15);
	margin: 0 auto;
	width: 40%;
	word-wrap: break-word;
	@media (max-width: ${Theming.breakpoints.smallScreen}px) {
		min-width: 70%;
		max-width: 90%;
	}
`;

const ModalWrapperLarge = styled.div`
	position: relative;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 4px;
	padding: 15px;
	box-shadow: 2px 2px 5px 2px rgba(100, 100, 100, 0.15);
	margin: 0 auto;
	word-wrap: break-word;
	display: flex;
	align-items: center;
	padding: 150px 350px;
	padding: min(10%, 150px) min(20%, 350px);
	max-height: 95%;
	justify-content: center;
`;

const CloseButton = styled.button<{ modalStyle?: string }>`
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	background: transparent;
	padding-bottom: 6px;
	color: ${Theming.buttons.cancelButtonColor};
	font-family: ${Theming.text.regularFont};
	font-size: ${({ modalStyle }) => (modalStyle === ModalStyle.LARGE ? '42px' : '22px')};
	width: ${({ modalStyle }) => (modalStyle === ModalStyle.LARGE ? '60px' : '40px')};
	height: ${({ modalStyle }) => (modalStyle === ModalStyle.LARGE ? '60px' : '40px')};
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 6px;

	&:hover {
		cursor: pointer;
		color: ${Theming.buttons.primaryButtonColor};
	}
`;

const LargeButton = styled(BaseButton)`
	margin: 0;
	padding: 20px;
	width: 250px;
`;
