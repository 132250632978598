import React, { useEffect, useState } from 'react';
import { LabeledSelect } from '../../../../components/Select';
import {
	ColumnTitle,
	NoResultsMessage,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../../../components/Table';
import { PageHeader } from '../../../../components/Wrappers';
import { BaseButton } from '../../../../components/Buttons';
import { TabWithTableWrapper } from '../../../../components/Tabs';
import { AdaptedFacilityLocation } from '../../../../adapters/locationsAdapters';
import { useAsync } from '../../../../hooks/useAsync';
import { webApiManager } from '../../../../network/apiManager';
import useSortAndPagination from '../../../../hooks/useSortFilterAndPagination';
import { LocationUserSortOptions } from '../../../../types/api/LocationUsers';
import { ErrorMessage } from '../../../../components/Text';
import IconManager, { IconType } from '../../../../components/IconManager';
import LoadingSpinner from '../../../../components/Loader';
import {
	AdaptedLocationUser,
	AdaptedLocationUserList,
	adaptLocationUsersFromApi,
	getLocationUserRoleName,
} from '../../../../adapters/locationUsersAdapters';
import { SidePanel } from '../../../../components/SidePanel';
import { AddNewLocationUser } from './components/AddNewLocationUser';
import { LocationUserDetails } from './components/LocationUserDetails';
import { StatusDisplay } from '../../../../components/StatusDisplay';

type LocationPermissionsProps = {
	selectedLocation: AdaptedFacilityLocation;
};

export const LocationPermissionsTab = ({ selectedLocation }: LocationPermissionsProps) => {
	const [locationUsers, setLocationUsers] = useState<AdaptedLocationUserList | null>(null);
	const [selectedLocationUserId, setSelectedLocationUserId] = useState<
		AdaptedLocationUser['id'] | null
	>(null);
	const [showAddNewUser, setShowAddNewUser] = useState<boolean>(false);

	useEffect(() => {
		//fetch locations on load
		getLocationUsers.execute();
	}, []);

	useEffect(() => {
		//refresh list when sidebar closes to get any updates
		if (!!locationUsers && !selectedLocationUserId) {
			getLocationUsers.execute();
		}
	}, [selectedLocationUserId]);

	useEffect(() => {
		//refresh list when sidebar closes to get any updates
		if (!!locationUsers && !showAddNewUser) {
			getLocationUsers.execute();
		}
	}, [showAddNewUser]);

	const onFetch = async (
		requestOptions: { page?: number; sort_by?: LocationUserSortOptions } = {}
	) => {
		getLocationUsers.clearError();
		try {
			const adaptedLocationUsers = adaptLocationUsersFromApi(
				await webApiManager.LocationUsers.getLocationUsers({
					locationId: selectedLocation.locationId,
					query: requestOptions,
				})
			);
			//if fetching next page, add to list and update pagination, otherwise replace
			setLocationUsers(prevState => {
				if (adaptedLocationUsers.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...adaptedLocationUsers.list],
						pagination: adaptedLocationUsers.pagination,
					};
				} else {
					return adaptedLocationUsers;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getLocationUsers = useAsync<void>(
		onFetch,
		'Error getting user data. Please check your connection and try again.'
	);

	//handle sort and pagination for locations list
	const { setSortBy, getNextPage } = useSortAndPagination<LocationUserSortOptions>(
		locationUsers,
		getLocationUsers
	);

	return (
		<TabWithTableWrapper>
			<PageHeader>
				<LabeledSelect
					label={'Sort by:'}
					options={[
						{ value: LocationUserSortOptions.lastName, label: 'Last Name' },
						{ value: LocationUserSortOptions.firstName, label: 'First Name' },
						{ value: LocationUserSortOptions.email, label: 'Email Address' },
						{ value: LocationUserSortOptions.roles, label: 'Permissions' },
						{ value: LocationUserSortOptions.status, label: 'Status' },
					]}
					onChange={newVal => setSortBy(newVal)}
				/>
				<BaseButton text={'+ NEW USER'} onClick={() => setShowAddNewUser(true)} margin={'20px'} />
			</PageHeader>
			<Table
				tableTitle={'Roles/Permissions'}
				onScrollToBottom={getNextPage}
				tableHeader={
					<>
						<ColumnTitle>Last Name</ColumnTitle>
						<ColumnTitle>First Name</ColumnTitle>
						<ColumnTitle>Email Address</ColumnTitle>
						<ColumnTitle>Permissions</ColumnTitle>
						<ColumnTitle>Status</ColumnTitle>
						<ColumnTitle>{''}</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!locationUsers ? (
							<TableRow hideBorder hideHover>
								<TableItem colSpan={6}>
									{getLocationUsers.pending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={getLocationUsers.error ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{locationUsers.list.length === 0 ? (
									<NoResultsMessage colSpan={6} />
								) : (
									<>
										{locationUsers.list.map(user => (
											<TableRow key={user.id}>
												<TableItem>{user.lastName}</TableItem>
												<TableItem>{user.firstName}</TableItem>
												<TableItem>{user.email}</TableItem>
												<TableItem textWrap>
													{user.roles.map((role, index) => {
														return index === 0
															? getLocationUserRoleName(role)
															: `, ${getLocationUserRoleName(role)}`;
													})}
												</TableItem>
												<TableItem>
													<StatusDisplay statusType={user.status} pause={user.pause} />
												</TableItem>
												<TableItem bold textWrap>
													<TableLinkWrapper
														//navigate to details page for selected location
														onClick={() => setSelectedLocationUserId(user.id)}
													>
														<TableLink>View Details</TableLink>
														<IconManager
															type={IconType.CHEVRON_RIGHT}
															stroke={'#999'}
															size={14}
															strokeWidth={3}
														/>
													</TableLinkWrapper>
												</TableItem>
											</TableRow>
										))}
									</>
								)}
							</>
						)}
					</>
				}
			/>

			<SidePanel
				isOpen={!!selectedLocationUserId}
				hide={() => setSelectedLocationUserId(null)}
				closeButtonText={selectedLocation.name.toUpperCase()}
			>
				<LocationUserDetails
					selectedLocationUserId={selectedLocationUserId}
					setSelectedLocationUserId={setSelectedLocationUserId}
					selectedLocation={selectedLocation}
				/>
			</SidePanel>
			<SidePanel
				isOpen={showAddNewUser}
				hide={() => setShowAddNewUser(false)}
				closeButtonText={selectedLocation.name.toUpperCase()}
			>
				<AddNewLocationUser
					selectedLocationId={selectedLocation.locationId}
					selectedFacilityId={selectedLocation.facilityId}
					closeSidebar={() => setShowAddNewUser(false)}
				/>
			</SidePanel>
		</TabWithTableWrapper>
	);
};
