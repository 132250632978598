import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthRouteNames, TabHash, UrlParamsType } from '../../../navigation/routeNames';
import { HeaderNameContext } from '../../../context/HeaderNameContext';
import { AdaptedFacilityLocation, adaptLocationFromApi } from '../../../adapters/locationsAdapters';
import { webApiManager } from '../../../network/apiManager';
import { useAsync } from '../../../hooks/useAsync';
import { PageWrapperNoTabs } from '../../../components/Wrappers';
import LoadingSpinner from '../../../components/Loader';
import { ErrorMessage } from '../../../components/Text';
import { ReturnHomeLink } from '../../../components/Links';
import { LocationPermissionsTab } from './locationTabs/LocationPermissionsTab';
import { LocationMessagesTab } from './locationTabs/LocationMessagesTab';
import { Tabs } from '../../../components/Tabs';
import { LocationOverviewTab } from './locationTabs/LocationOverviewTab';
import { LocationAccountTab } from './locationTabs/LocationAccountTab';

export const Location = () => {
	const { locationId } = useParams<UrlParamsType>();
	const { setHeaderName } = useContext(HeaderNameContext);

	const [selectedLocation, setSelectedLocation] = useState<AdaptedFacilityLocation | null>(null);

	useEffect(() => {
		//fetch selected location on load
		getLocation.execute();

		//clear header on unmount
		return () => {
			setHeaderName('');
		};
	}, []);

	useEffect(() => {
		//when location is fetched, set location name in nav header & facility name/path in back link
		if (selectedLocation) {
			setHeaderName(selectedLocation.name);
		} else {
			setHeaderName('');
		}
	}, [selectedLocation]);

	const onLoad = async () => {
		getLocation.clearError();
		try {
			const getLocationResponse = adaptLocationFromApi(
				await webApiManager.Locations.getLocation({ locationId })
			);
			setSelectedLocation(getLocationResponse);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getLocation = useAsync<void>(
		onLoad,
		'Error getting location data. Please check your connection and try again.'
	);

	return (
		<>
			{!selectedLocation ? (
				<PageWrapperNoTabs padding={'30px'}>
					{getLocation.pending ? (
						<LoadingSpinner />
					) : (
						<>
							<ErrorMessage text={getLocation.error ?? 'Could not find facility location'} />
							<ReturnHomeLink />
						</>
					)}
				</PageWrapperNoTabs>
			) : (
				<Tabs
					mainPath={`${AuthRouteNames.ManageFacilities}/${selectedLocation.facilityId}/locations/${selectedLocation.locationId}`}
					tabs={[
						{
							title: 'Overview',
							component: <LocationOverviewTab selectedLocation={selectedLocation} />,
							hash: TabHash.overview,
						},
						{
							title: 'Account',
							component: (
								<LocationAccountTab
									selectedLocation={selectedLocation}
									refreshLocation={() => getLocation.execute()}
									isPending={getLocation.pending}
								/>
							),
							hash: TabHash.account,
						},
						{
							title: 'Roles/Permissions',
							component: <LocationPermissionsTab selectedLocation={selectedLocation} />,
							hash: TabHash.permissions,
							hasTable: true,
						},
						{
							title: 'Messages',
							component: <LocationMessagesTab selectedLocation={selectedLocation} />,
							hash: TabHash.messages,
						},
					]}
				/>
			)}
		</>
	);
};
