import styled from 'styled-components';
import { Theming } from '../theming';
import IconManager, { IconType } from './IconManager';
import React from 'react';
import { UserType } from '../adapters/userAdapter';

type UserLabelProps = {
	userType?: UserType;
	name: string;
	color?: string;
	size?: number;
	padding?: string;
};

export const UserLabel = ({ userType, name, color, size, padding }: UserLabelProps) => {
	return (
		<UserLabelWrapper padding={padding}>
			{userType === UserType.facilityUser || userType === UserType.facilityLocationUser ? (
				<IconManager
					type={IconType.HOSPITAL}
					fill={'#FFF'}
					stroke={color ?? Theming.icons.secondaryIconColor}
					size={size ?? 24}
				/>
			) : (
				<>
					<CircleIconWrapper size={size} color={color}>
						{name.charAt(0)}
					</CircleIconWrapper>
				</>
			)}
			<UserLabelText>{name}</UserLabelText>
		</UserLabelWrapper>
	);
};

const CircleIconWrapper = styled.p<{ size?: number; color?: string }>`
	display: inline-block;
	text-align: center;
	vertical-align: center;
	background-color: ${({ color }) => color ?? Theming.icons.secondaryIconColor};
	border-radius: 50%;
	color: #fff;
	font-size: ${({ size }) => size ?? 14}px;
	font-family: ${Theming.text.boldFont};
	padding: 0;
	width: ${({ size }) => (size ? size + 10 : 24)}px;
	line-height: ${({ size }) => (size ? size + 10 : 24)}px;
	margin: 0 5px 0 0;
`;

const UserLabelWrapper = styled.div<{ padding?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: ${({ padding }) => padding ?? '10px 0'};
	margin: 0;
`;

const UserLabelText = styled.p`
	display: inline-block;
	color: ${Theming.text.primaryTextColor};
	text-align: left;
	padding: 5px;
	margin: 0;
	font-family: ${Theming.text.boldFont};
	font-size: 13px;
`;
