import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader, PageWrapperNoTabs } from '../../../components/Wrappers';
import { ErrorMessage, PageTitle } from '../../../components/Text';
import {
	ColumnTitle,
	EndResultsMessage,
	NoResultsMessage,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../../components/Table';
import { AuthRouteNames } from '../../../navigation/routeNames';
import { LabeledSelect } from '../../../components/Select';
import { useAsync } from '../../../hooks/useAsync';
import { webApiManager } from '../../../network/apiManager';
import { AdaptedFacilityList, adaptFacilitiesFromApi } from '../../../adapters/facilityAdapters';
import LoadingSpinner from '../../../components/Loader';
import IconManager, { IconType } from '../../../components/IconManager';
import { FacilitySortOptions } from '../../../types/api/Facilities';
import useSortAndPagination from '../../../hooks/useSortFilterAndPagination';
import { StatusDisplay } from '../../../components/StatusDisplay';

export function FacilityList() {
	const history = useHistory();
	const [facilities, setFacilities] = useState<AdaptedFacilityList | null>(null);

	//fetch facilities list on load
	useEffect(() => {
		getFacilities.execute();
	}, []);

	const onFetch = async (requestOptions: { page?: number; sort_by?: FacilitySortOptions } = {}) => {
		getFacilities.clearError();
		try {
			const getFacilitiesResponse = adaptFacilitiesFromApi(
				await webApiManager.Facilities.getFacilities(requestOptions)
			);
			//if fetching next page, add to list and update pagination, otherwise replace
			setFacilities(prevState => {
				if (getFacilitiesResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...getFacilitiesResponse.list],
						pagination: getFacilitiesResponse.pagination,
					};
				} else {
					return getFacilitiesResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getFacilities = useAsync<void>(
		onFetch,
		'Error getting list of facilities. Please check your connection and try again.'
	);

	//handle sort and pagination for contractor list
	const { setSortBy, getNextPage } = useSortAndPagination<FacilitySortOptions>(
		facilities,
		getFacilities
	);

	return (
		<PageWrapperNoTabs>
			<PageHeader>
				<PageTitle>Manage Facilities</PageTitle>
				<LabeledSelect
					label={'Sort by:'}
					options={
						facilities
							? [
									{ value: FacilitySortOptions.name, label: 'Name' },
									{ value: FacilitySortOptions.numLocations, label: '# of Locations' },
									{ value: FacilitySortOptions.numUsers, label: '# of Users' },
									{ value: FacilitySortOptions.status, label: 'Status' },
							  ]
							: []
					}
					onChange={newVal => setSortBy(newVal)}
				/>
			</PageHeader>
			<Table
				tableTitle={'Facility Groups'}
				onScrollToBottom={getNextPage}
				tableHeader={
					<>
						<ColumnTitle>Name</ColumnTitle>
						<ColumnTitle>(#) of Locations</ColumnTitle>
						<ColumnTitle>(#) of Total Users</ColumnTitle>
						<ColumnTitle>Status</ColumnTitle>
						<ColumnTitle>{''}</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!facilities ? (
							<TableRow hideBorder hideHover>
								<TableItem colSpan={6}>
									{getFacilities.pending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={getFacilities.error ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{facilities.list.length === 0 ? (
									<NoResultsMessage colSpan={6} />
								) : (
									<>
										{facilities.list.map((facility, index) => (
											<TableRow key={index}>
												<TableItem textWrap>{facility.name}</TableItem>
												<TableItem>{facility.numLocations}</TableItem>
												<TableItem>{facility.numUsers}</TableItem>
												<TableItem>
													<StatusDisplay statusType={facility.status} />
												</TableItem>
												<TableItem bold textWrap>
													<TableLinkWrapper
														onClick={() =>
															history.push(`${AuthRouteNames.ManageFacilities}/${facility.id}`)
														}
													>
														<TableLink>View Details</TableLink>
														<IconManager
															type={IconType.CHEVRON_RIGHT}
															stroke={'#999'}
															size={14}
															strokeWidth={3}
														/>
													</TableLinkWrapper>
												</TableItem>
											</TableRow>
										))}
										{facilities.pagination.isFinalPage && <EndResultsMessage colSpan={6} />}
									</>
								)}
							</>
						)}
					</>
				}
			/>
		</PageWrapperNoTabs>
	);
}
