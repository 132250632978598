import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from '../../../components/Tabs';
import { AuthRouteNames, TabHash, UrlParamsType } from '../../../navigation/routeNames';
import { FacilityLocationsTab } from './facilityTabs/FacilityLocationsTab';
import { FacilityOverviewTab } from './facilityTabs/FacilityOverviewTab';
import { HeaderNameContext } from '../../../context/HeaderNameContext';
import { AdaptedFacility, adaptFacilityFromApi } from '../../../adapters/facilityAdapters';
import { useAsync } from '../../../hooks/useAsync';
import { webApiManager } from '../../../network/apiManager';
import LoadingSpinner from '../../../components/Loader';
import { ErrorMessage } from '../../../components/Text';
import { ReturnHomeLink } from '../../../components/Links';
import { PageWrapperNoTabs } from '../../../components/Wrappers';

export const FacilityDetails = () => {
	const { facilityId } = useParams<UrlParamsType>();
	const { setHeaderName } = useContext(HeaderNameContext);
	const [selectedFacility, setSelectedFacility] = useState<AdaptedFacility | null>(null);

	useEffect(() => {
		//fetch selected facility on load
		getFacility.execute({ facilityId });

		//clear header on unmount
		return () => {
			setHeaderName('');
		};
	}, []);

	useEffect(() => {
		//when facility is fetched, set facility name in nav header
		if (selectedFacility) {
			setHeaderName(selectedFacility.name);
		} else {
			setHeaderName('');
		}
	}, [selectedFacility]);

	const onLoad = async () => {
		getFacility.clearError();
		try {
			const getFacilityResponse = adaptFacilityFromApi(
				await webApiManager.Facilities.getFacility({ facilityId })
			);
			setSelectedFacility(getFacilityResponse);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getFacility = useAsync<void>(onLoad, 'Error: Could not find facility.');

	return (
		<>
			{!selectedFacility || getFacility.pending ? (
				<PageWrapperNoTabs padding={'30px'}>
					{getFacility.pending ? (
						<LoadingSpinner />
					) : (
						<>
							<ErrorMessage text={getFacility.error ?? 'Could not find facility'} />
							<ReturnHomeLink />
						</>
					)}
				</PageWrapperNoTabs>
			) : (
				<Tabs
					mainPath={`${AuthRouteNames.ManageFacilities}/${facilityId}`}
					tabs={[
						{
							title: 'Overview',
							component: (
								<FacilityOverviewTab
									selectedFacility={selectedFacility}
									loadFacility={() => {
										getFacility.execute({ facilityId });
									}}
								/>
							),
							hash: TabHash.overview,
						},
						{
							title: 'Locations',
							component: <FacilityLocationsTab selectedFacility={selectedFacility} />,
							hash: TabHash.locations,
							hasTable: true,
						},
					]}
				/>
			)}
		</>
	);
};
