import { AdaptedContractor } from '../../../adapters/contractorAdapters';
import React, { useEffect, useState } from 'react';
import { FlexWrapper, PageHeader } from '../../../components/Wrappers';
import { TabWithTableWrapper } from '../../../components/Tabs';
import { ColumnTitle, EndResultsMessage, NoResultsMessage, Table } from '../../../components/Table';
import { ProfessionCard, ExperienceUpdate } from './ProfessionCard';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { AddJobMenu } from './AddJobMenu';
import { AdaptedProfession } from '../../../adapters/professionsAdapter';
import { AdaptedContractorProfessionExperience } from '../../../adapters/contractorProfessionExperiencesAdapter';
import {
	adaptFacilityTypesFromApi,
	AdaptedFacilityType,
} from '../../../adapters/facilityTypesAdapter';
import { useAsync } from '../../../hooks/useAsync';
import { webApiManager } from '../../../network/apiManager';
import LoadingSpinner from '../../../components/Loader';
import {
	adaptContractorExperienceSpecializationFromApi,
	AdaptedContractorExperienceSpecialization,
} from '../../../adapters/contractorExperienceSpecializationAdapter';
import {
	adaptFacilityTypeProfessionExperienceRulesFromApi,
	AdaptedFacilityTypeProfessionExperienceRule,
} from '../../../adapters/facilityTypeProfessionExperienceRulesAdapters';
import { AdaptedContractorSpecializationExperience } from '../../../adapters/contractorSpecializationExperiencesAdapter';
import { FormSelect } from '../../../components/Select';
import _ from 'lodash';
import { USStates, UUID } from '../../../types/sharedTypes';
import {
	UpdateContractorProfessionRequest,
	UpdateContractorSpecializationRequest,
} from '../../../types/api/Contractors';
import { Modal, ModalStyle } from '../../../components/Modal';

type ContractorExperienceFormProps = {
	isInviteMode?: boolean;
	initialProfessions?: AdaptedProfession[];
	professions: AdaptedProfession[];
	selectedContractor: AdaptedContractor;
	refreshSelectedContractor: () => void;
	pendingRefresh: boolean;
	onChange: (experienceUpdates: ExperienceUpdate[]) => void;
};

type ProfessionToShow = AdaptedProfession & {
	isAddedByJobMenu?: boolean;
};

const USStateOptionValues = Object.values(USStates).map(state => ({ value: state, label: state }));

const convertContractorsProfessionExperienceToFormState = (
	professionExperiences: AdaptedContractorProfessionExperience[]
) => {
	const newFormState = {};

	professionExperiences.forEach(professionExperience => {
		if (!professionExperience.reciprocityState) {
			return;
		}

		if (!newFormState[professionExperience.professionId]) {
			newFormState[professionExperience.professionId] = {
				professionId: professionExperience.professionId,
				professionName: professionExperience.professionName,
				code: professionExperience.professionCode,
				byState: {},
			};
		}

		if (
			!newFormState[professionExperience.professionId].byState[
				professionExperience.reciprocityState
			]
		) {
			newFormState[professionExperience.professionId].byState[
				professionExperience.reciprocityState
			] = {
				stateName: professionExperience.reciprocityState,
				byFacilityTypeId: {},
			};
		}

		if (
			!newFormState[professionExperience.professionId].byState[
				professionExperience.reciprocityState
			].byFacilityTypeId[professionExperience.facilityTypeId]
		) {
			newFormState[professionExperience.professionId].byState[
				professionExperience.reciprocityState
			].byFacilityTypeId[professionExperience.facilityTypeId] = {
				facilityTypeId: professionExperience.facilityTypeId,
				professionId: professionExperience.professionId,
				facilityTypeProfessionExperienceRuleId:
					professionExperience.facilityTypeProfessionExperienceRuleId,
				experienceMonths: professionExperience.experienceMonths,
				approvalExpirationDate: professionExperience.approvalExpirationDate,
				notes: professionExperience.notes,
			};
		}
	});

	return newFormState;
};

const convertContractorsSpecializationExperienceToFormState = (
	allSpecializations: AdaptedContractorExperienceSpecialization[],
	specializationExperiences: AdaptedContractorSpecializationExperience[]
) => {
	if (!specializationExperiences) {
		return {};
	}

	const newFormState = {};

	specializationExperiences.forEach(specializationExperience => {
		const professionId = specializationExperience.profession.id;
		const facilityTypeId = specializationExperience.facilityType.id;

		if (!newFormState[professionId]) {
			newFormState[professionId] = {
				professionId: professionId,
				byState: {},
			};
		}

		if (!newFormState[professionId].byState[specializationExperience.reciprocityState]) {
			newFormState[professionId].byState[specializationExperience.reciprocityState] = {
				stateName: specializationExperience.reciprocityState,
				byFacilityTypeId: {},
			};
		}

		if (
			!newFormState[professionId].byState[specializationExperience.reciprocityState]
				.byFacilityTypeId[facilityTypeId]
		) {
			newFormState[professionId].byState[
				specializationExperience.reciprocityState
			].byFacilityTypeId[facilityTypeId] = {
				facilityTypeId: facilityTypeId,
				specializationIds: [],
				tagsBySpecializationId: {},
			};
		}

		newFormState[professionId].byState[specializationExperience.reciprocityState].byFacilityTypeId[
			facilityTypeId
		].specializationIds.push(specializationExperience.specializationId);
		newFormState[professionId].byState[specializationExperience.reciprocityState].byFacilityTypeId[
			facilityTypeId
		].tagsBySpecializationId[specializationExperience.specializationId] =
			specializationExperience.tags.map(tag => tag.id);
	});

	return newFormState;
};

export const ContractorExperienceForm = ({
	isInviteMode = false,
	professions,
	initialProfessions = [],
	selectedContractor,
	refreshSelectedContractor,
	pendingRefresh,
	onChange,
}: ContractorExperienceFormProps) => {
	const [editingProfessionId, setEditingProfessionId] = useState<UUID | null>(null);
	const [facilityTypes, setFacilityTypes] = useState<AdaptedFacilityType[] | null>(null);
	const [selectedState, setSelectedState] = useState<USStates>(USStateOptionValues[0].label);
	const [professionsToShow, setProfessionsToShow] = useState<ProfessionToShow[]>([]);
	const [allSpecializations, setAllSpecializations] = useState<
		AdaptedContractorExperienceSpecialization[]
	>([]);
	const [facilityTypeProfessionRules, setFacilityTypeProfessionRules] = useState<
		AdaptedFacilityTypeProfessionExperienceRule[] | null
	>(null);
	const [professionToDelete, setProfessionToDelete] = useState<AdaptedProfession | null>(null);
	const [currentExperienceFormStateByProfessionId, setCurrentExperienceFormStateByProfessionId] =
		useState({});
	const [
		currentSpecializationsFormStateByProfessionId,
		setCurrentSpecializationsFormStateByProfessionId,
	] = useState({});

	useEffect(() => {
		getFacilityTypes.execute();
		getFacilityTypeProfessionExperienceRules.execute();
		getContractorExperienceSpecializations.execute();
	}, []);

	useEffect(() => {
		onChange(convertCurrentExperienceFormStateToContractorExperienceObjects());
	}, [currentExperienceFormStateByProfessionId]);

	useEffect(() => {
		if (!Array.isArray(professions) || !Array.isArray(allSpecializations)) {
			return;
		}

		if (pendingRefresh) {
			return;
		}

		const selectedContractorsProfessionIds = selectedContractor.professions.map(
			profession => profession.professionId
		);
		const initialProfessionIds = initialProfessions.map(profession => profession.id);

		const newProfessionsToShow = professions
			.filter(profession => {
				return (
					selectedContractorsProfessionIds.indexOf(profession.id) > -1 ||
					initialProfessionIds.indexOf(profession.id) > -1 ||
					professionsToShow.find(shownProfession => shownProfession.id === profession.id)
						?.isAddedByJobMenu
				);
			})
			.map(profession => {
				return professionsToShow.find(shownProfession => shownProfession.id === profession.id)
					?.isAddedByJobMenu
					? { ...profession, isAddedByJobMenu: true }
					: profession;
			});

		const newExperienceFormState = convertContractorsProfessionExperienceToFormState(
			selectedContractor.professions
		);
		const currentExperienceForShownProfessions = {};
		Object.keys(currentExperienceFormStateByProfessionId).forEach(professionId => {
			if (
				newProfessionsToShow.find(
					profession => profession.id.toString() === professionId.toString()
				)
			) {
				currentExperienceForShownProfessions[professionId] =
					currentExperienceFormStateByProfessionId[professionId];
			}
		});

		setCurrentExperienceFormStateByProfessionId(
			_.merge(newExperienceFormState, currentExperienceForShownProfessions)
		);

		setCurrentSpecializationsFormStateByProfessionId(
			convertContractorsSpecializationExperienceToFormState(
				allSpecializations,
				selectedContractor.specializations
			)
		);

		// Only show the contractor's professions, any passed in initial professions or any
		// professions added via the AddJob menu
		setProfessionsToShow(newProfessionsToShow);

		// Find contractor's experience
	}, [professions, selectedContractor, initialProfessions, allSpecializations, pendingRefresh]);

	//filter list of professions to find only types that aren't already saved to contractor
	const getAvailableProfessions = (): AdaptedProfession[] => {
		const availableProfessions = professions?.filter(
			profession =>
				!professionsToShow.find(professionToShow => profession.id === professionToShow.id)
		);

		return availableProfessions ?? [];
	};

	const getFacilityTypes = useAsync<void>(async () => {
		const types = adaptFacilityTypesFromApi(await webApiManager.FacilityTypes.getFacilityTypes());
		const sortedTypes = types.sort((a, b) => a.name.localeCompare(b.name));
		setFacilityTypes(sortedTypes);
	}, 'Error getting facility types. Please check your connection and try again.');

	const getFacilityTypeProfessionExperienceRules = useAsync<void>(async () => {
		const rules = adaptFacilityTypeProfessionExperienceRulesFromApi(
			await webApiManager.FacilityTypeProfessionExperienceRules.getFacilityTypeProfessionExperienceRules()
		);
		setFacilityTypeProfessionRules(rules);
	}, 'Error getting facility type profession experience rules and contractor specializations. Please check your connection and try again.');

	const getContractorExperienceSpecializations = useAsync<void>(async () => {
		const specializations = adaptContractorExperienceSpecializationFromApi(
			await webApiManager.ContractorExperienceSpecialization.getContractorExperienceSpecializations()
		);

		setAllSpecializations(specializations);
	}, 'Error getting contractor experience specializations. Please check your connection and try again.');

	const onEditProfession = profession => {
		setEditingProfessionId(profession.id);
	};

	const onExperienceValueUpdate = (experienceUpdate: ExperienceUpdate) => {
		const profession = professions.find(
			profession => profession.id === experienceUpdate.professionId
		);

		const { professionId, reciprocityState, facilityTypeId } = experienceUpdate;
		const formState = currentExperienceFormStateByProfessionId;

		setCurrentExperienceFormStateByProfessionId({
			...formState,
			[professionId]: {
				...formState[professionId],
				professionId: professionId,
				professionName: profession?.name,
				code: profession?.code,
				byState: {
					...formState[professionId]?.byState,
					[reciprocityState]: {
						...formState[professionId]?.byState[reciprocityState],
						stateName: reciprocityState,
						byFacilityTypeId: {
							...formState[professionId]?.byState[reciprocityState]?.byFacilityTypeId,
							[facilityTypeId]: {
								...formState[professionId]?.byState[reciprocityState]?.byFacilityTypeId[
									facilityTypeId
								],
								...experienceUpdate,
							},
						},
					},
				},
			},
		});
	};

	const onSpecializationsValueUpdate = specializationsUpdate => {
		const profession = professions.find(
			profession => profession.id === specializationsUpdate.professionId
		);

		// Need to remove any associated tags for any specializations that have also been removed
		const getFilteredTagsBySpecializationId = (currentFacility, specializationUpdate) => {
			if (!currentFacility) {
				return {};
			}

			const specializationIds =
				specializationUpdate.specializationIds || currentFacility.specializationIds;
			const tagsBySpecializationId =
				specializationUpdate.tagsBySpecializationId || currentFacility.tagsBySpecializationId;

			const filteredTagsBySpecializationId = {};
			specializationIds.forEach(specializationId => {
				filteredTagsBySpecializationId[specializationId] = tagsBySpecializationId[specializationId];
			});

			return filteredTagsBySpecializationId;
		};

		const { professionId, reciprocityState, specializationIds, facilityTypeId } =
			specializationsUpdate;
		const formState = currentSpecializationsFormStateByProfessionId;

		setCurrentSpecializationsFormStateByProfessionId({
			...formState,
			[professionId]: {
				...formState[professionId],
				professionId: professionId,
				professionName: profession?.name,
				code: profession?.code,
				byState: {
					...formState[professionId]?.byState,
					[reciprocityState]: {
						...formState[professionId]?.byState[reciprocityState],
						stateName: reciprocityState,
						byFacilityTypeId: {
							...formState[professionId]?.byState[reciprocityState]?.byFacilityTypeId,
							[facilityTypeId]: {
								...formState[professionId]?.byState[reciprocityState]?.byFacilityTypeId[
									facilityTypeId
								],
								...(specializationIds ? { specializationIds: specializationIds } : {}),
								tagsBySpecializationId: getFilteredTagsBySpecializationId(
									formState[specializationsUpdate.professionId]?.byState[
										specializationsUpdate.reciprocityState
									]?.byFacilityTypeId[specializationsUpdate.facilityTypeId],
									specializationsUpdate
								),
							},
						},
					},
				},
			},
		});
	};

	const onSaveProfession = () => {
		const preEditProfessionExperiences = convertContractorsProfessionExperienceToFormState(
			selectedContractor.professions
		);
		const postEditProfessionExperiences =
			convertCurrentExperienceFormStateToContractorExperienceObjects();

		const preEditSpecializationExperiences =
			convertSpecializationsFormStateToContractorSpecializationObjects(
				convertContractorsSpecializationExperienceToFormState(
					allSpecializations,
					selectedContractor.specializations
				)
			);
		const postEditSpecializationExperiences =
			convertSpecializationsFormStateToContractorSpecializationObjects(
				currentSpecializationsFormStateByProfessionId
			);

		// Compare any changes and commit those on the contractor
		const professionUpdates = postEditProfessionExperiences
			.filter(postEditProfessionExperience => {
				const preEditProfessionExperience =
					preEditProfessionExperiences[postEditProfessionExperience.professionId]?.byState[
						postEditProfessionExperience.reciprocityState
					]?.byFacilityTypeId[postEditProfessionExperience.facilityTypeId];

				// If this is a new record that didn't exist before then we want it
				if (!preEditProfessionExperience) {
					return true;
				}

				// Otherwise, we want the record if it's in anyway changed
				return (
					postEditProfessionExperience.approvalExpirationDate !==
						preEditProfessionExperience.approvalExpirationDate ||
					postEditProfessionExperience.experienceMonths !==
						preEditProfessionExperience.experienceMonths ||
					postEditProfessionExperience.notes !== preEditProfessionExperience.notes
				);
			})
			.map(professionExperience => ({
				profession_id: professionExperience.professionId,
				reciprocity_state: professionExperience.reciprocityState,
				facility_type_id: professionExperience.facilityTypeId,
				experience_months:
					professionExperience.experienceMonths === -1
						? null
						: professionExperience.experienceMonths,
				notes: professionExperience.notes,
				approval_expiration_date: professionExperience.approvalExpirationDate,
			}));

		// We want to remove anything unchanged
		const toRemove = preEditSpecializationExperiences
			.filter(
				preEditExperience =>
					!postEditSpecializationExperiences.some(
						experience => experience.specializationId === preEditExperience.specializationId
					)
			)
			.map(preEditExperience => ({
				specialization_id: preEditExperience.specializationId,
				reciprocity_state: preEditExperience.reciprocityState,
				is_specialized: false,
			}));
		const toAdd = postEditSpecializationExperiences
			.filter(
				postEditExperience =>
					!preEditSpecializationExperiences.some(
						experience =>
							experience.specializationId === postEditExperience.specializationId &&
							experience.tagIds.sort().toString() === postEditExperience.tagIds.sort().toString()
					)
			)
			.map(postEditExperience => ({
				specialization_id: postEditExperience.specializationId,
				reciprocity_state: postEditExperience.reciprocityState,
				is_specialized: true,
				tag_ids: postEditExperience.tagIds,
			}));

		const specializationUpdates = [...toRemove, ...toAdd];

		if (professionUpdates.length > 0 || specializationUpdates.length > 0) {
			updateContractor.execute({
				professionUpdates,
				specializationUpdates,
			});
		}

		setEditingProfessionId(null);
	};

	const updateContractor = useAsync<void>(
		async ({
			professionUpdates = [],
			specializationUpdates = [],
		}: {
			professionUpdates: UpdateContractorProfessionRequest[];
			specializationUpdates: UpdateContractorSpecializationRequest[];
		}) => {
			await webApiManager.Contractors.updateContractor({
				contractorId: selectedContractor.id,
				...(professionUpdates.length > 0 ? { professions: professionUpdates } : {}),
				...(specializationUpdates.length > 0 ? { specializations: specializationUpdates } : {}),
			});

			refreshSelectedContractor();
		},
		'Error updating contractor. Please check your connection and try again.'
	);

	const onAttemptDeleteProfession = (profession: AdaptedProfession) => {
		const contractorProfessionExperience = selectedContractor.professions.find(
			professionExperience => professionExperience.professionId === profession.id
		);

		if (contractorProfessionExperience) {
			setProfessionToDelete(profession);
			return;
		}

		// remove the card
		setProfessionsToShow(
			professionsToShow.filter(professionToShow => professionToShow.id !== profession.id)
		);
	};

	const convertCurrentExperienceFormStateToContractorExperienceObjects = () =>
		Object.values(currentExperienceFormStateByProfessionId).flatMap(profession =>
			Object.values(profession.byState).flatMap(state =>
				Object.values(state.byFacilityTypeId).map(facility => ({
					facilityTypeId: facility.facilityTypeId,
					professionId: profession.professionId,
					reciprocityState: state.stateName,
					...(typeof facility.experienceMonths !== 'undefined'
						? { experienceMonths: facility.experienceMonths }
						: {}),
					...(typeof facility.approvalExpirationDate !== 'undefined'
						? { approvalExpirationDate: facility.approvalExpirationDate }
						: {}),
					...(typeof facility.notes !== 'undefined' ? { notes: facility.notes } : {}),
				}))
			)
		);

	const convertSpecializationsFormStateToContractorSpecializationObjects = formState =>
		Object.values(formState).flatMap(profession =>
			Object.values(profession.byState).flatMap(state =>
				Object.values(state.byFacilityTypeId).flatMap(facility => {
					return (
						facility?.specializationIds?.map(id => ({
							reciprocityState: state.stateName,
							specializationId: id,
							isSpecialized: true,
							tagIds: facility.tagsBySpecializationId[id] || [],
						})) || []
					);
				})
			)
		);

	const onConfirmDeleteProfessionWithExperience = () => {
		if (!professionToDelete) {
			return;
		}

		const contractorProfessionExperience = selectedContractor.professions.find(
			professionExperience => professionExperience.professionId === professionToDelete.id
		);

		if (!contractorProfessionExperience) {
			return;
		}

		const professionUpdates = convertCurrentExperienceFormStateToContractorExperienceObjects()
			.filter(experience => experience.professionId === contractorProfessionExperience.professionId)
			.map(experience => ({
				facility_type_id: experience.facilityTypeId,
				profession_id: experience.professionId,
				reciprocity_state: experience.reciprocityState,
				experience_months: 0,
			}));

		updateContractor.execute({ professionUpdates });

		setProfessionsToShow(
			professionsToShow.filter(profession => profession.id !== professionToDelete.id)
		);
		setProfessionToDelete(null);
	};

	if (!facilityTypes || !professions || !facilityTypeProfessionRules) {
		return <LoadingSpinner />;
	}

	const isLoading = pendingRefresh || updateContractor.pending;

	return (
		<TabWithTableWrapper>
			{!isInviteMode ? (
				<PageHeader>
					<FlexWrapper justifyContent={'flex-start'}>
						<HeaderLabel
							label={'Contractor'}
							text={`${selectedContractor.firstName} ${selectedContractor.lastName}`}
						/>
					</FlexWrapper>
				</PageHeader>
			) : null}

			<TableContainer minWidth={isInviteMode ? null : 960} margin={isInviteMode ? 0 : 20}>
				<ControlsContainer>
					<StyledFormSelect
						initialValue={selectedState}
						label={'Settings for state:'}
						onChange={setSelectedState}
						options={USStateOptionValues}
						disabled={editingProfessionId !== null || isLoading}
					/>
					{!isInviteMode ? (
						<AddJobMenu
							availableJobTypes={getAvailableProfessions()}
							disabled={editingProfessionId !== null || isLoading}
							addNewProfessionCard={profession =>
								setProfessionsToShow([
									...professionsToShow,
									{ ...profession, isAddedByJobMenu: true },
								])
							}
						/>
					) : null}
				</ControlsContainer>
				<StyledTable
					fixed
					useHorizontalPadding
					isLoading={isLoading}
					tableHeader={
						!isInviteMode ? (
							<>
								<ColumnTitle>{''}</ColumnTitle>
								<ColumnTitle textWrap>{'Experience \n(Months)'}</ColumnTitle>
								<ColumnTitle>Approval Expiration</ColumnTitle>
								<ColumnTitle>Specialist</ColumnTitle>
								<ColumnTitle>Tags</ColumnTitle>
								<ColumnTitle>Notes</ColumnTitle>
							</>
						) : null
					}
					tableBody={
						<>
							{professionsToShow.length === 0 ? (
								<NoResultsMessage colSpan={!isInviteMode ? 6 : 2} />
							) : (
								<>
									{professionsToShow.map(profession => {
										const professionExperience =
											currentExperienceFormStateByProfessionId[profession.id];

										const specializationExperience =
											currentSpecializationsFormStateByProfessionId[profession.id] || null;

										return (
											<ProfessionCard
												specializations={allSpecializations.filter(
													specialization => specialization.professionId === profession.id
												)}
												specializationExperience={specializationExperience}
												isInviteMode={isInviteMode}
												selectedState={selectedState}
												facilityTypes={facilityTypes}
												key={profession.id}
												profession={profession}
												professionExperience={professionExperience}
												isEditing={editingProfessionId === profession.id}
												isEditingAvailable={editingProfessionId === null}
												onEdit={onEditProfession}
												onChange={onExperienceValueUpdate}
												onChangeSpecializations={onSpecializationsValueUpdate}
												onSave={onSaveProfession}
												onDelete={() => onAttemptDeleteProfession(profession)}
											/>
										);
									})}
									<EndResultsMessage colSpan={!isInviteMode ? 6 : 2} />
								</>
							)}
						</>
					}
				/>
				{isLoading ? (
					<CenteredDiv>
						<LoadingSpinner />
					</CenteredDiv>
				) : null}
			</TableContainer>
			<Modal
				style={ModalStyle.LARGE}
				isOpen={!!professionToDelete}
				hide={() => setProfessionToDelete(null)}
				onConfirm={onConfirmDeleteProfessionWithExperience}
				title={'Wait!'}
				content={
					<p>
						This contractor has recorded experience as a {professionToDelete?.name}. Are you sure
						you want to delete all of their experience for this profession (including experience for
						all states)?
					</p>
				}
				cancelButtonText={'Cancel'}
				confirmButtonText={'Yes, Delete Experience'}
				confirmButtonColor={Theming.errorColor}
			/>
		</TabWithTableWrapper>
	);
};

const HeaderLabel = ({ label, text }: { label: string; text: string }) => {
	return (
		<FlexWrapper justifyContent={'flex-start'}>
			<StyledLabel>
				{label}
				{': '}
			</StyledLabel>
			<StyledLabel bold>{text}</StyledLabel>
		</FlexWrapper>
	);
};

const TableContainer = styled.div<{ minWidth?: number; margin: number }>`
	position: relative;
	background: ${Theming.backgrounds.mainBackground};
	border-radius: 20px;
	margin: ${({ margin }) => `0 ${margin}px`};
	width: ${({ margin }) => `calc(100% - ${margin * 2}px)`};
	min-width: ${({ minWidth }) => `${minWidth || 0}px`};
`;

const StyledLabel = styled.div<{ bold?: boolean }>`
	font-family: ${({ bold }) => (bold ? Theming.text.boldFont : Theming.text.regularFont)};
	font-size: 14px;
	color: ${Theming.text.primaryTextColor};
	margin: 0;
	padding: 5px;
`;

const StyledTable = styled(Table)<{ isLoading?: boolean }>`
	opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;

const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 0 20px;
	width: calc(100% - 40px);
	min-height: 70px;
	border-bottom: 2px solid #dbdbdb;

	& > div {
		display: flex;
		gap: 20px;
		align-items: center;
		padding: 0;
	}
`;

const StyledFormSelect = styled(FormSelect)`
	min-width: 300px;

	& > div {
		background-color: ${Theming.backgrounds.mainBackground};
		border: 1px solid ${Theming.buttons.primaryButtonColor};
	}
`;

const CenteredDiv = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;
