import { StatusType } from '../types/sharedTypes';
import { ContractStatus, PaymentStatus } from '../types/api/Contracts';
import { Theming } from '../theming';
import React from 'react';
import styled from 'styled-components';
import { PauseAction } from '../types/api/Pauses';
import { AdaptedPause } from '../adapters/pauseAdapter';

const getStatusString = statusType => {
	if (!statusType) {
		return 'n/a';
	}

	switch (statusType) {
		case ContractStatus.canceled:
			return 'Canceled';

		case 'paused':
			return 'Paused';

		default:
			return statusType.replace('_', ' ');
	}
};

const getPauseString = pause => {
	const dateTokens = pause.effectiveDate.split('-');
	const formattedDate = `${dateTokens[1]}/${dateTokens[2]}/${dateTokens[0]}`;

	switch (pause.onActiveAction) {
		case PauseAction.pause:
			return `Pauses ${formattedDate}`;

		case PauseAction.deactivate:
			return `Deactivates ${formattedDate}`;

		default:
			return '';
	}
};

export const StatusDisplay = ({
	statusType,
	pause,
	padding,
}: {
	statusType?: StatusType | ContractStatus | PaymentStatus;
	pause?: AdaptedPause | null | undefined;
	padding?: string;
}) => {
	if (!statusType) {
		return null;
	}

	return (
		<StyledStatus backgroundColor={Theming.status[statusType]} padding={padding}>
			<Status>{getStatusString(statusType)}</Status>
			{pause ? <PausedAt>{getPauseString(pause)}</PausedAt> : null}
		</StyledStatus>
	);
};

export const StyledStatus = styled.p<{ backgroundColor?: string; padding?: string }>`
	display: inline-block;
	width: auto;
	color: #fff;
	background-color: ${({ backgroundColor }) => backgroundColor ?? Theming.text.placeholderColor};
	border-radius: 5px;
	font-size: 14px;
	padding: ${({ padding }) => padding ?? '5px 10px'};
	margin: 0;
	text-transform: capitalize;
`;

export const Status = styled.span`
	display: block;
	font-family: ${Theming.text.boldFont};
`;

export const PausedAt = styled.span`
	display: block;
	font-size: 12px;
	font-family: ${Theming.text.regularFont};
`;
