import React from 'react';
import { PageColumn, PageRow } from '../../../../components/Wrappers';
import { PageSection, PageSectionItem } from '../../../../components/PageSection';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { AdaptedContractor } from '../../../../adapters/contractorAdapters';
import { StatusDisplay } from '../../../../components/StatusDisplay';
import { PubNubMessaging } from '../../../../components/PubNubMessaging';

type ContractorMessagingProps = {
	selectedContractor: AdaptedContractor;
};

export const ContractorMessagingTab = ({ selectedContractor }: ContractorMessagingProps) => {
	return (
		<PageRow>
			<PageColumn width={35}>
				<PageSection title={`${selectedContractor.firstName} ${selectedContractor.lastName}`}>
					<PageSectionItem label={'Status'}>
						<StatusDisplay
							statusType={selectedContractor.status}
							pause={selectedContractor.pause}
						/>
					</PageSectionItem>
					<PageSectionItem label={'Email'} borderTop>
						{selectedContractor.emailAddress}
					</PageSectionItem>
					<PageSectionItem label={'Phone Number'} borderTop>
						{formatPhoneNumber(selectedContractor.phoneNumber)}
					</PageSectionItem>
				</PageSection>
			</PageColumn>
			<PageColumn width={65}>
				<PageSection margin={'0 auto'}>
					<PubNubMessaging channelName={selectedContractor.channelName} shouldScrollToBottom />
				</PageSection>
			</PageColumn>
		</PageRow>
	);
};
