import {
	GetLocationUsersResponse,
	LocationUser,
	LocationUserRole,
} from '../types/api/LocationUsers';
import { AdaptedUser, adaptUserFromApi, UserType } from './userAdapter';
import { PaginatedList, StatusType, UUID } from '../types/sharedTypes';
import { adaptPaginationFromApi } from './paginationAdapter';
import { AdaptedPause, adaptPauseFromApi } from './pauseAdapter';

export type AdaptedLocationUser = AdaptedUser & {
	userId: UUID;
	locationId: UUID;
	roles: LocationUserRole[];
	status: StatusType;
	pause: AdaptedPause | null;
	userType: UserType.facilityLocationUser;
};

export type AdaptedLocationUserList = PaginatedList<AdaptedLocationUser>;

export const adaptLocationUserFromApi = (locationUser: LocationUser): AdaptedLocationUser => {
	return {
		...adaptUserFromApi(locationUser.relationships.user),
		id: locationUser.id,
		userId: locationUser.relationships.user.id,
		locationId: locationUser.attributes.location_id,
		roles: locationUser.attributes.roles,
		status: locationUser.relationships.user.attributes.status,
		pause: adaptPauseFromApi(locationUser.relationships.resource_pauses),
		userType: UserType.facilityLocationUser,
	};
};

export const adaptLocationUsersFromApi = (
	locationUsers: GetLocationUsersResponse
): AdaptedLocationUserList => {
	return {
		pagination: adaptPaginationFromApi(locationUsers.links),
		list: locationUsers.data.map(user => adaptLocationUserFromApi(user)),
	};
};

export const getLocationUserRoleName = (role: LocationUserRole): string => {
	return role === LocationUserRole.biller
		? 'Billing'
		: role === LocationUserRole.manager
		? 'Manager'
		: LocationUserRole.scheduler
		? 'Scheduler'
		: 'N/A';
};
