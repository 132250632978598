import React, { useState, useEffect, ReactNode } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { Theming } from '../theming';
import { InputLabel, InputWrapper } from './TextInputs';
import { ErrorMessage } from './Text';
import _ from 'lodash';
import { components } from 'react-select';
import { FlexWrapper } from './Wrappers';

export type SelectOption = {
	label: string | ReactNode;
	value: any;
};

export type SelectProps = {
	options: SelectOption[];
	onChange: (newVal: SelectOption['value'] | null) => void;
	margin?: string;
	label?: string;
	disabled?: boolean;
	initialValue?: SelectOption['value'];
	pending?: boolean;
	error?: string;
	placeholder?: string | ReactNode;
	isClearable?: boolean;
};

type MultiSelectProps = Omit<SelectProps, 'initialValue' | 'onChange'> & {
	onChange: (newVal: Array<SelectOption['value']>) => void;
	initialValues?: SelectOption['value'][];
	resetValues?: SelectOption['value'][]; //optional values to override initial values on cancel/disable
};

type TableMultiSelectProps = MultiSelectProps & {
	value?: SelectOption['value'][]; //optional uncontrolled values
};

export const UnlabeledSelect = ({ options, onChange, ...rest }: SelectProps) => {
	const [selectedValue, setSelectedValue] = useState<SelectOption['value'] | null>(null);

	useEffect(() => {
		onChange(selectedValue);
	}, [selectedValue]);

	const customStyles = {
		control: provided => ({
			...provided,
			minWidth: 200,
			fontFamily: Theming.text.regularFont,
			borderRadius: 0,
			fontSize: '14px',
			cursor: 'pointer',
		}),
		menu: provided => ({
			...provided,
			fontFamily: Theming.text.regularFont,
			fontSize: '14px',
		}),
	};

	const customTheme = provided => ({
		...provided,
		colors: {
			...provided.colors,
			neutral20: Theming.gradients.darkGradient,
			neutral30: Theming.gradients.mediumGradient,
			neutral40: Theming.gradients.mediumGradient,
			neutral50: '#414141',
			primary: Theming.gradients.mediumGradient,
		},
	});

	return (
		<ReactSelect
			styles={customStyles}
			options={options}
			theme={customTheme}
			onChange={selectedOption => setSelectedValue(selectedOption?.value ?? selectedOption)}
			{...rest}
		/>
	);
};

export const LabeledSelect = ({ options, onChange, label, margin, ...rest }: SelectProps) => {
	return (
		<SelectWrapper margin={margin}>
			<SelectLabel>{label}</SelectLabel>
			<UnlabeledSelect options={options} onChange={onChange} {...rest} />
		</SelectWrapper>
	);
};

export const MultiSelect = ({
	options,
	onChange,
	label,
	disabled,
	initialValues,
	resetValues,
	error,
	...rest
}: MultiSelectProps) => {
	//compare array of values with array of select options, return matching options
	const getDefaultOptions = (valuesArray: Array<any> | undefined) => {
		const initialOptions: SelectOption[] = [];
		options.forEach(option => {
			valuesArray?.forEach(value => {
				if (option.value === value) {
					initialOptions.push(option);
				}
			});
		});
		return initialOptions;
	};

	//pull values from array of select options
	const getCurrentValues = (currentSelectedOptions: SelectOption[]) => {
		return currentSelectedOptions.map(option => option.value);
	};

	const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
		getDefaultOptions(initialValues)
	);

	useEffect(() => {
		onChange(getCurrentValues(selectedOptions));
	}, [selectedOptions]);

	useEffect(() => {
		//reset to initial or reset values on cancel
		const isUnchanged = _.isEqual(
			getCurrentValues(selectedOptions).sort(),
			resetValues?.sort() || initialValues?.sort()
		);
		if (disabled && !isUnchanged) {
			setSelectedOptions(getDefaultOptions(resetValues ?? initialValues));
		}
	}, [disabled]);

	const customStyles = {
		control: styles => ({
			...styles,
			minWidth: 200,
			fontFamily: Theming.text.regularFont,
			border: 'none',
			borderRadius: 5,
			fontSize: '14px',
			backgroundColor: Theming.backgrounds.inputBackground,
			cursor: 'pointer',
		}),
		menu: styles => ({
			...styles,
			fontFamily: Theming.text.regularFont,
			fontSize: '14px',
		}),
		multiValue: styles => {
			return {
				...styles,
				backgroundColor: '#CFCDDA',
			};
		},
		multiValueLabel: styles => ({
			...styles,
			color: '#000',
			fontFamily: Theming.text.boldFont,
		}),
		multiValueRemove: styles => ({
			...styles,
			visibility: disabled ? 'hidden' : 'visible',
		}),
		dropdownIndicator: styles => ({
			...styles,
			color: disabled ? Theming.text.placeholderColor : styles.color,
		}),
		placeholder: styles => ({
			...styles,
			color: disabled ? Theming.text.placeholderColor : styles.color,
		}),
	};

	const customTheme = provided => ({
		...provided,
		colors: {
			...provided.colors,
			neutral20: Theming.gradients.darkGradient,
			neutral30: Theming.gradients.mediumGradient,
			neutral40: Theming.gradients.mediumGradient,
			neutral50: '#414141',
			primary: Theming.gradients.mediumGradient,
		},
	});

	return (
		<InputWrapper>
			{label && <InputLabel>{label}</InputLabel>}
			<ReactSelect
				isMulti
				isDisabled={disabled}
				styles={customStyles}
				options={options}
				theme={customTheme}
				value={selectedOptions}
				onChange={newValue => setSelectedOptions([...newValue])}
				{...rest}
			/>
			{!!error && !disabled && <ErrorMessage text={error} />}
		</InputWrapper>
	);
};

export const FormSelect = ({
	options,
	onChange,
	label,
	disabled,
	initialValue,
	error,
	...rest
}: SelectProps) => {
	const getDefaultOption = (): SelectOption | null => {
		const defaultOption = options.find(option => option.value == initialValue);
		return defaultOption ?? null;
	};

	const [selectedOption, setSelectedOption] = useState<SelectOption | null>(getDefaultOption());

	useEffect(() => {
		onChange(selectedOption?.value ?? null);
	}, [selectedOption]);

	useEffect(() => {
		//reset to initial value on cancel
		if (disabled && selectedOption?.value !== initialValue) {
			setSelectedOption(getDefaultOption());
		}
	}, [disabled]);

	const customStyles = {
		control: styles => ({
			...styles,
			minWidth: 200,
			fontFamily: disabled && !!selectedOption ? Theming.text.boldFont : Theming.text.regularFont,
			border: 'none',
			borderRadius: 5,
			fontSize: '14px',
			backgroundColor: Theming.backgrounds.inputBackground,
			opacity: disabled ? 0.5 : 1,
			cursor: 'pointer',
		}),
		menu: styles => ({
			...styles,
			fontFamily: Theming.text.regularFont,
			fontSize: '14px',
		}),
		dropdownIndicator: styles => ({
			...styles,
			color: disabled ? Theming.text.placeholderColor : styles.color,
		}),
		placeholder: styles => ({
			...styles,
			color: !selectedOption ? Theming.text.placeholderColor : Theming.text.primaryTextColor,
		}),
	};

	const customTheme = provided => ({
		...provided,
		colors: {
			...provided.colors,
			neutral20: Theming.gradients.darkGradient,
			neutral30: Theming.gradients.mediumGradient,
			neutral40: '#000',
			neutral50: '#414141',
			primary: Theming.gradients.mediumGradient,
		},
	});

	return (
		<InputWrapper>
			{label && <InputLabel>{label}</InputLabel>}
			<ReactSelect
				isDisabled={disabled}
				styles={customStyles}
				options={options}
				theme={customTheme}
				value={selectedOption}
				onChange={newValue => setSelectedOption(newValue)}
				{...rest}
			/>
			{!!error && !disabled && <ErrorMessage text={error} />}
		</InputWrapper>
	);
};

export const TableMultiSelect = ({
	options,
	onChange,
	disabled,
	initialValues,
	resetValues,
	value,
	...rest
}: TableMultiSelectProps) => {
	const isUncontrolled = typeof value !== 'undefined';

	//compare array of values with array of select options, return matching options
	const getDefaultOptions = (valuesArray: Array<any> | undefined) => {
		const initialOptions: SelectOption[] = [];
		options.forEach(option => {
			valuesArray?.forEach(value => {
				if (option.value === value) {
					initialOptions.push(option);
				}
			});
		});
		return initialOptions;
	};

	//pull values from array of select options
	const getOptionValues = (currentSelectedOptions: SelectOption[]) => {
		return currentSelectedOptions.map(option => option.value);
	};

	const getOptionsFromValues = values => {
		return options.filter(option => {
			return values.some(value => option.value === value);
		});
	};

	const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
		isUncontrolled
			? options.filter(option => {
					return value.some(v => option.value === v);
			  })
			: getDefaultOptions(initialValues)
	);

	useEffect(() => {
		if (isUncontrolled) {
			return;
		}

		//reset to initial or reset values on cancel
		const isUnchanged = _.isEqual(
			getOptionValues(selectedOptions).sort(),
			resetValues?.sort() || initialValues?.sort()
		);
		if (disabled && !isUnchanged) {
			setSelectedOptions(getDefaultOptions(resetValues ?? initialValues));
		}
	}, [disabled]);

	const customStyles = {
		control: styles => ({
			...styles,
			width: 150,
			fontFamily: Theming.text.regularFont,
			border: '#909090 solid 1px',
			borderRadius: 5,
			fontSize: '14px',
			backgroundColor: '#FFF',
			minHeight: 30,
			textAlign: 'left',
			cursor: 'pointer',
		}),
		menu: styles => ({
			...styles,
			fontFamily: Theming.text.regularFont,
			fontSize: '14px',
		}),
		multiValue: styles => {
			return {
				...styles,
			};
		},
		multiValueLabel: styles => ({
			...styles,
			color: '#000',
			fontFamily: Theming.text.boldFont,
		}),
		multiValueRemove: styles => ({
			...styles,
			visibility: 'hidden',
		}),
		dropdownIndicator: styles => ({
			...styles,
			color: disabled ? Theming.text.placeholderColor : styles.color,
			paddingTop: 4,
			paddingBottom: 4,
		}),
		placeholder: styles => ({
			...styles,
			color: disabled ? Theming.text.placeholderColor : styles.color,
		}),
		valueContainer: (provided, state) => ({
			...provided,
			textOverflow: 'ellipsis',
			maxWidth: '90%',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			display: 'initial',
			height: '1.6em',
			color: state.isDisabled ? Theming.text.placeholderColor : Theming.text.primaryTextColor,
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
			color: Theming.text.labelColor,
		}),
	};

	const CustomMultiValueContainer = ({ selectProps, data }) => {
		const label = data.label;
		const allSelected = selectProps.value;
		const index = allSelected.findIndex(selected => selected.label === label);
		const isLastSelected = index === allSelected.length - 1;
		const labelSuffix = isLastSelected ? '' : ', ';
		const val = `${label}${labelSuffix}`;
		return <span>{val}</span>;
	};

	const CustomOption = props => {
		return (
			<components.Option {...props}>
				<FlexWrapper justifyContent={'space-between'}>
					<label>{props.label}</label>
					<PseudoCheckbox isChecked={props.isSelected} />
				</FlexWrapper>
			</components.Option>
		);
	};

	return (
		<ReactSelect
			isMulti
			isDisabled={disabled}
			styles={customStyles}
			options={options}
			components={{
				MultiValueContainer: CustomMultiValueContainer,
				Option: CustomOption,
				IndicatorSeparator: () => null,
			}}
			value={isUncontrolled ? getOptionsFromValues(value) : selectedOptions}
			onChange={newValue => {
				isUncontrolled
					? onChange(getOptionValues([...newValue]))
					: setSelectedOptions([...newValue]);
			}}
			isSearchable={false}
			closeMenuOnSelect={false}
			isClearable={false}
			hideSelectedOptions={false}
			{...rest}
		/>
	);
};

const SelectWrapper = styled.div<{ margin?: string }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin: ${({ margin }) => margin ?? 0};
	cursor: pointer;
`;

const SelectLabel = styled.p`
	font-family: ${Theming.text.regularFont};
	font-size: 14px;
	color: ${Theming.text.primaryTextColor};
	margin-right: 20px;
`;

const PseudoCheckbox = styled.div<{ isChecked?: boolean }>`
	width: 14px;
	height: 14px;
	background-color: ${({ isChecked }) => (isChecked ? '#3373f3' : '#FFF')};
	border-radius: 2px;
	border: ${({ isChecked }) => (isChecked ? 'none' : '1px solid #909090')};
`;
