import React from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import { ErrorMessage } from './Text';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	error?: string;
	borderRadius?: number;
	label?: string;
	width?: string;
	margin?: string;
	disabled?: boolean;
	padding?: string;
}

export const ControlledTextInput = ({
	error,
	label,
	width,
	register,
	disabled,
	margin,
	...props
}: TextInputProps & { register: any }) => {
	return (
		<InputWrapper width={width} padding={margin}>
			{label && <InputLabel>{label}</InputLabel>}
			<StyledInput error={!!error} disabled={disabled} {...register()} {...props} />
			{!!error && !disabled && <ErrorMessage text={error} textAlign={'left'} />}
		</InputWrapper>
	);
};

export const UncontrolledTextInput = ({ error, ...props }: TextInputProps) => {
	return (
		<>
			<StyledInput borderRadius={0} margin={'20px auto 0'} error={!!error} {...props} />
			{error && <ErrorMessage text={error} />}
		</>
	);
};

const StyledInput = styled.input<{
	error?: boolean;
	borderRadius?: number;
	width?: string;
	disabled?: boolean;
	margin?: string;
	padding?: string;
}>`
	box-sizing: border-box;
	font-family: ${({ disabled }) => (!disabled ? Theming.text.regularFont : Theming.text.boldFont)};
	color: ${Theming.text.primaryTextColor};
	border: ${({ error }) => (!error ? '2px solid transparent' : `1px solid ${Theming.errorColor}`)};
	border-radius: ${({ borderRadius }) => borderRadius ?? 5}px;
	font-size: 14px;
	padding: ${({ padding }) => padding ?? '10px'};
	background-color: ${Theming.backgrounds.inputBackground};
	margin: ${({ margin }) => margin ?? '0'};
	width: ${({ width }) => width ?? '100%'};
	::placeholder {
		color: ${Theming.text.placeholderColor};
	}
	&:focus {
		outline: none;
		border: ${({ error }) =>
			`2px solid ${error ? Theming.errorColor : Theming.gradients.darkGradient}`};
	}
`;

export const InputLabel = styled.p<{ padding?: string }>`
	color: ${Theming.text.labelColor};
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	margin: 0;
	padding: ${({ padding }) => padding ?? '10px 0'};
`;

export const InputWrapper = styled.div<{ width?: string; padding?: string }>`
	text-align: left;
	padding: ${({ padding }) => padding ?? '10px'};
	width: ${({ width }) => width ?? '100%'};
`;

export const NonAuthInputWrapper = styled.div`
	margin: 20px auto;
	width: 100%;
`;

//@TODO: Merge with ToggleEditableInput.StyledInput
export const TableInput = styled.input<{
	maxWidth?: string;
	textAlign?: string;
	disabled?: boolean;
}>`
	box-sizing: border-box;
	font-family: ${Theming.text.regularFont};
	color: ${({ disabled }) =>
		disabled ? Theming.text.placeholderColor : Theming.text.primaryTextColor};
	border: 1px solid #909090;
	border-radius: 5px;
	font-size: 14px;
	padding: 5px 10px;
	background-color: #fff;
	margin: 0;
	max-width: ${({ maxWidth }) => maxWidth ?? '150px'};
	text-align: ${({ textAlign }) => textAlign ?? 'left'};
	box-shadow: inset 0px 2px 4px 1px rgba(100, 100, 100, 0.15);
	::placeholder {
		color: ${Theming.text.placeholderColor};
	}
`;
