import ReactModal from 'react-modal';

//for Modal component
export const getModalPortalContainer = (() => {
	let portalContainer: HTMLDivElement | null = null;
	return (): HTMLDivElement => {
		// Check if singleton exists, if so, return
		if (portalContainer) {
			return portalContainer;
		}
		// Initialize singleton and save to holder variable
		portalContainer = document.createElement('div');
		portalContainer.className = 'modal-content';
		document.querySelector('body')?.prepend(portalContainer);

		// Initialize React Modal setup for container
		ReactModal.setAppElement(portalContainer);

		return portalContainer;
	};
})();
