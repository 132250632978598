import React from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import IconManager, { IconType } from './IconManager';

export const Info = ({ children }) => {
	return (
		<InfoWrapper>
			<IconWrapper>
				<IconManager
					type={IconType.INFO_CIRCLE}
					size={18}
					fill={Theming.text.infoTextColor}
					stroke={'none'}
				/>
			</IconWrapper>
			{children}
		</InfoWrapper>
	);
};

const InfoWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 6px;
	padding: 0;
	padding-top: 1px;
	margin: 0 0 0 8px;

	font-family: ${Theming.text.boldFont};
	font-size: 9pt;
	color: ${Theming.text.infoTextColor};
`;

const IconWrapper = styled.span`
	flex: 0 0 20px;
	margin-top: -1px;
`;
