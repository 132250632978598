import React, { useState } from 'react';
import styled from 'styled-components';
import formatDate from 'date-fns/format';
import { StatusType } from '../types/sharedTypes';
import { PageSectionItem } from './PageSection';
import { MiniLoadingSpinner } from './Loader';
import { ErrorMessage } from './Text';
import { FormLink } from './Links';
import { Modal, ModalStyle } from './Modal';
import { capitalizeString } from '../utils/capitalizeString';
import { Theming } from '../theming';
import { StatusDisplay } from './StatusDisplay';
import { PauseAction, PauseResourceType } from '../types/api/Pauses';
import { AdaptedPause } from '../adapters/pauseAdapter';
import { UserType } from '../adapters/userAdapter';
import { webApiManager } from '../network/apiManager';
import { AdaptedFacilityUser } from '../adapters/facilityAdapters';
import { AdaptedLocationUser } from '../adapters/locationUsersAdapters';
import { AdaptedContractor } from '../adapters/contractorAdapters';
import { useAsync } from '../hooks/useAsync';
import { AdaptedFacilityLocation } from '../adapters/locationsAdapters';

type EditStatusBaseProps = {
	isPending?: boolean;
	onChange: (action: EditStatusAction, newStatusType?: StatusType) => void;
};

type EditStatusLocationProps = EditStatusBaseProps & {
	location?: AdaptedFacilityLocation;
	user?: never;
};

type EditStatusUserProps = EditStatusBaseProps & {
	location?: never;
	user?: AdaptedContractor | AdaptedLocationUser | AdaptedFacilityUser;
};

export enum EditStatusAction {
	statusChange = 'statusChange',
	resetPassword = 'resetPassword',
	removePause = 'removePause',
}

export enum StatusChange {
	reactivate = 'reactivate',
	unpause = 'unpause',
	deactivate = 'deactivate',
	pause = 'pause',
	delete = 'delete',
}

const getStatusType = (statusChange: StatusChange) => {
	switch (statusChange) {
		case StatusChange.pause:
			return StatusType.paused;

		case StatusChange.deactivate:
			return StatusType.deactivated;

		case StatusChange.delete:
			return StatusType.deleted;

		default:
			return StatusType.active;
	}
};

const getUserTypeString = (userType: UserType) => {
	switch (userType) {
		case UserType.contractor:
			return 'Contractor';

		case UserType.facilityUser:
			return 'Facility User';

		case UserType.facilityLocationUser:
			return 'Location User';

		default:
			return 'User';
	}
};

const getStatusModalTitle = (statusChangeRequest, resourceTypeString) => {
	switch (statusChangeRequest) {
		case StatusChange.pause:
			return `Pause ${resourceTypeString}?`;

		case StatusChange.unpause:
			return `Unpause ${resourceTypeString}?`;

		case StatusChange.deactivate:
			return `Deactivate ${resourceTypeString}?`;

		case StatusChange.reactivate:
			return `Reactivate ${resourceTypeString}?`;

		case StatusChange.delete:
			return `Delete ${resourceTypeString}?`;

		default:
			return '';
	}
};

const getStatusModalButtonColor = statusChangeRequest => {
	switch (statusChangeRequest) {
		case StatusChange.deactivate:
		case StatusChange.delete:
			return Theming.errorColor;

		default:
			return Theming.buttons.primaryButtonColor;
	}
};

const getFormattedPauseDate = (pause?: AdaptedPause | null) => {
	if (!pause) {
		return '';
	}

	const dateTokens = pause.effectiveDate.split('-');
	return `${dateTokens[1]}/${dateTokens[2]}/${dateTokens[0]}`;
};

const getPauseResourceType = (userType: UserType) => {
	switch (userType) {
		case UserType.contractor:
			return PauseResourceType.contractor;

		case UserType.facilityUser:
			return PauseResourceType.facilityUser;

		case UserType.facilityLocationUser:
		default:
			return PauseResourceType.locationUser;
	}
};

const getPauseResourceId = user => {
	switch (user.userType) {
		case UserType.facilityUser:
			return user.facilityUserId;

		default:
			return user.id;
	}
};

export const EditStatus = ({
	user,
	location,
	onChange,
	isPending,
}: EditStatusUserProps | EditStatusLocationProps) => {
	const [statusChangeRequest, setStatusChangeRequest] = useState<StatusChange | null>(null);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [showClearPauseModal, setShowClearPauseModal] = useState(false);
	const [pauseDate, setPauseDate] = useState(formatDate(Date.now(), 'yyyy-MM-dd'));

	const userTypeString = user ? getUserTypeString(user.userType) : 'Location';

	const formattedPauseDate = getFormattedPauseDate(user ? user.pause : location?.pause);

	const getStatusModalContent = (statusChangeRequest, resourceTypeString) => {
		switch (statusChangeRequest) {
			case StatusChange.pause:
				return (
					<div>
						<p>
							{resourceTypeString === 'Location'
								? `You will still be able to accept contracts as long as they end before the pause date. Any contracts which fall on or after the pause date will be cancelled.`
								: `The ${resourceTypeString.toLowerCase()} will not be able to request new contacts while paused. The ${resourceTypeString.toLowerCase()} will still have access to current contracts, messages, and account
						information.`}
						</p>
						<StartDateContainer>
							<span>Start Date</span>
							<input
								type="date"
								value={pauseDate}
								onChange={event => {
									setPauseDate(event.target.value);
								}}
							/>
						</StartDateContainer>
					</div>
				);

			case StatusChange.unpause:
				return (
					<p>
						{resourceTypeString === 'Location'
							? `This location will be available again for new contracts`
							: `The ${resourceTypeString.toLowerCase()} will be able to request new contracts again.`}
					</p>
				);

			case StatusChange.deactivate:
				return (
					<p>
						{resourceTypeString === 'Location'
							? `This location will be unavailable for any contracts`
							: `The ${resourceTypeString.toLowerCase()} will no longer be able to access their account.`}
					</p>
				);

			case StatusChange.reactivate:
				return (
					<p>
						{resourceTypeString === 'Location'
							? `This location will be reinstated and will be available again for contracts`
							: `The ${resourceTypeString.toLowerCase()} will regain access their account.`}
					</p>
				);

			case StatusChange.delete:
				return (
					<div>
						<p>{`The ${resourceTypeString.toLowerCase()}'s account will be deleted permanently and they will no longer be access their account. `}</p>
						<Important>This action is irreversible and cannot be undone!</Important>
					</div>
				);

			default:
				return <p>&nbsp;</p>;
		}
	};

	const onStatusChange = async ({ statusType, pauseDate }) => {
		updateStatus.clearError();

		const target = user ? user : location;

		if (!target) {
			return;
		}

		try {
			switch (statusType) {
				case StatusType.deleted:
					if (!user) {
						return;
					}

					if (user.pause) {
						await webApiManager.PausesApi.deletePause({
							id: user.pause.id,
						});
					}

					await webApiManager.Users.deleteUser({
						userId: user.userId,
					});
					break;

				case StatusType.paused: {
					const resourceType = user
						? getPauseResourceType(user.userType)
						: PauseResourceType.location;

					if (!resourceType) {
						return;
					}

					const resourceId = user ? getPauseResourceId(user) : location?.locationId;

					await webApiManager.PausesApi.createPause({
						resourceId,
						resourceType,
						effectiveDate: pauseDate,
						onActiveAction: PauseAction.pause,
					});

					break;
				}

				case StatusType.deactivated:
					if (user) {
						await webApiManager.PausesApi.createPause({
							resourceId: getPauseResourceId(user),
							resourceType: getPauseResourceType(user.userType),
							onActiveAction: PauseAction.deactivate,
						});
					} else if (location) {
						await webApiManager.PausesApi.createPause({
							resourceId: location.locationId,
							resourceType: PauseResourceType.location,
							onActiveAction: PauseAction.deactivate,
						});
					}

					break;

				case StatusType.active:
					if (target.pause) {
						await webApiManager.PausesApi.deletePause({
							id: target.pause.id,
						});
					}

					if (user) {
						await webApiManager.PausesApi.createPause({
							resourceId: getPauseResourceId(user),
							resourceType: getPauseResourceType(user.userType),
							onActiveAction: PauseAction.activate,
						});
					} else if (location) {
						await webApiManager.PausesApi.createPause({
							resourceId: location.locationId,
							resourceType: PauseResourceType.location,
							onActiveAction: PauseAction.activate,
						});
					}

					break;
			}

			onChange(EditStatusAction.statusChange, statusType);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onResetPassword = async () => {
		if (!user) {
			return;
		}

		resetPassword.clearError();

		try {
			await webApiManager.Users.forcePasswordReset({
				userId: user.userId,
			});

			onChange(EditStatusAction.resetPassword);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onCancelPause = async () => {
		const target = user ? user : location;

		if (!target || !target.pause || !target.pause.id) {
			return;
		}

		cancelPause.clearError();

		try {
			await webApiManager.PausesApi.deletePause({
				id: target.pause.id,
			});

			onChange(EditStatusAction.removePause);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};
	//user is 9, loc_user_id = 10
	const updateStatus = useAsync<void>(
		onStatusChange,
		'Error updating status. Please check your connection and try again.'
	);
	const resetPassword = useAsync<void>(
		onResetPassword,
		'Error sending reset password link. Please check your connection and try again.'
	);
	const cancelPause = useAsync<void>(
		onCancelPause,
		'Error cancelling pause. Please check your connection and try again.'
	);

	const isLoading =
		updateStatus.pending || resetPassword.pending || cancelPause.pending || isPending;

	const status = user ? user.status : location?.status;
	const pause = user ? user.pause : location?.pause;

	return (
		<>
			<PageSectionItem label={'Status'}>
				{isLoading ? (
					<MiniLoadingSpinner />
				) : (
					<>
						<StatusDisplay statusType={status} pause={pause} />
						<StatusForm
							currentStatus={status}
							pause={pause}
							userTypeString={userTypeString}
							setShowResetPasswordModal={setShowResetPasswordModal}
							setStatusChangeRequest={setStatusChangeRequest}
							setShowClearPauseModal={setShowClearPauseModal}
						/>
					</>
				)}
			</PageSectionItem>
			{!!updateStatus.error && <ErrorMessage text={updateStatus.error} textAlign={'center'} />}
			<Modal
				style={ModalStyle.LARGE}
				isOpen={!!statusChangeRequest}
				hide={() => setStatusChangeRequest(null)}
				onConfirm={() => {
					if (statusChangeRequest) {
						const updatedStatusType = getStatusType(statusChangeRequest);
						updateStatus.execute({ statusType: updatedStatusType, pauseDate });
						setStatusChangeRequest(null);
					}
				}}
				pending={updateStatus.pending}
				title={getStatusModalTitle(statusChangeRequest, userTypeString)}
				content={getStatusModalContent(statusChangeRequest, userTypeString)}
				cancelButtonText={'Cancel'}
				confirmButtonText={
					statusChangeRequest
						? `Yes, ${capitalizeString(statusChangeRequest)} ${userTypeString}`
						: ''
				}
				confirmButtonColor={getStatusModalButtonColor(statusChangeRequest)}
			/>
			<Modal
				style={ModalStyle.LARGE}
				isOpen={showResetPasswordModal}
				hide={() => setShowResetPasswordModal(false)}
				onConfirm={() => {
					setShowResetPasswordModal(false);
					resetPassword.execute();
				}}
				pending={false}
				title="Reset Password"
				content={
					<p>The user will be sent an email with instructions on how to set a new password</p>
				}
				cancelButtonText="Cancel"
				confirmButtonText="Send reset link"
			/>
			<Modal
				style={ModalStyle.LARGE}
				isOpen={showClearPauseModal}
				hide={() => setShowClearPauseModal(false)}
				onConfirm={() => {
					setShowClearPauseModal(false);
					cancelPause.execute();
				}}
				pending={false}
				title="Remove Pause Date"
				content={
					<div>
						<p>
							This {userTypeString.toLowerCase()} is set to be paused automatically on{' '}
							{formattedPauseDate}. Do you want to remove this upcoming pause?
						</p>
						<p>You will have the chance to set a new pause date after this one is removed.</p>
					</div>
				}
				cancelButtonText="Cancel"
				confirmButtonText="Yes, Remove Pause"
			/>
		</>
	);
};

const StatusForm = ({
	currentStatus,
	pause,
	userTypeString,
	setShowResetPasswordModal,
	setShowClearPauseModal,
	setStatusChangeRequest,
}) => {
	if (currentStatus === StatusType.deleted) {
		return null;
	}

	return (
		<div>
			{currentStatus === StatusType.active && userTypeString !== 'Location' ? (
				<FormLink onClick={() => setShowResetPasswordModal(true)}>Reset password</FormLink>
			) : null}
			<StatusPauseSubForm
				currentStatus={currentStatus}
				pause={pause}
				userTypeString={userTypeString}
				setStatusChangeRequest={setStatusChangeRequest}
				setShowClearPauseModal={setShowClearPauseModal}
			/>
			<StatusDeactivateSubForm
				isDeactivated={currentStatus === StatusType.deactivated}
				pause={pause}
				userTypeString={userTypeString}
				setStatusChangeRequest={setStatusChangeRequest}
				setShowClearPauseModal={setShowClearPauseModal}
			/>
			{userTypeString !== 'Location' ? (
				<FormLink onClick={() => setStatusChangeRequest(StatusChange.delete)}>
					Delete {userTypeString}
				</FormLink>
			) : null}
		</div>
	);
};

const StatusPauseSubForm = ({
	currentStatus,
	pause,
	userTypeString,
	setStatusChangeRequest,
	setShowClearPauseModal,
}) => {
	switch (currentStatus) {
		case StatusType.paused:
			return (
				<FormLink onClick={() => setStatusChangeRequest(StatusChange.unpause)}>
					Unpause {userTypeString}
				</FormLink>
			);

		case StatusType.deactivated:
			return null;

		default:
			if (pause && pause.onActiveAction === PauseAction.pause) {
				return <FormLink onClick={() => setShowClearPauseModal(true)}>Remove Pause</FormLink>;
			}

			return (
				<FormLink onClick={() => setStatusChangeRequest(StatusChange.pause)}>
					Pause {userTypeString}
				</FormLink>
			);
	}
};

const StatusDeactivateSubForm = ({
	isDeactivated,
	pause,
	userTypeString,
	setStatusChangeRequest,
	setShowClearPauseModal,
}) => {
	if (isDeactivated) {
		return (
			<FormLink onClick={() => setStatusChangeRequest(StatusChange.reactivate)}>
				Reactivate {userTypeString}
			</FormLink>
		);
	}

	if (pause && pause.onActiveAction === PauseAction.deactivate) {
		return (
			<FormLink onClick={() => setShowClearPauseModal(true)}>Cancel Upcoming Deactivation</FormLink>
		);
	}

	return (
		<FormLink onClick={() => setStatusChangeRequest(StatusChange.deactivate)}>
			Deactivate {userTypeString}
		</FormLink>
	);
};

const Important = styled.p`
	text-transform: uppercase;
`;

const StartDateContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: stretch;
	justify-content: center;
	margin: 30px;
`;
