import { ListPagination } from '../adapters/paginationAdapter';

export type RFC<P = Record<string, unknown>> = (props: P) => JSX.Element;

export type UUID = string | number;

export enum StatusType {
	active = 'active',
	paused = 'paused',
	deactivated = 'deactivated',
	deleted = 'deleted',
}

export enum ResourceType {
	contractor = 'Contractor',
	facility = 'Facility',
	location = 'Location',
	facilityUser = 'FacilityUser',
	locationUser = 'User',
}

export type UpdateContractorData = {
	contractorId: UUID;
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
};

export type PaginatedList<T> = {
	list: T[];
	pagination: ListPagination;
};

export enum USStates {
	Alabama = 'Alabama',
	Alaska = 'Alaska',
	Arkansas = 'Arkansas',
	Arizona = 'Arizona',
	California = 'California',
	Colorado = 'Colorado',
	Connecticut = 'Connecticut',
	DistrictOfColumbia = 'District of Columbia',
	Delaware = 'Delaware',
	Florida = 'Florida',
	Georgia = 'Georgia',
	Hawaii = 'Hawaii',
	Iowa = 'Iowa',
	Idaho = 'Idaho',
	Illinois = 'Illinois',
	Indiana = 'Indiana',
	Kansas = 'Kansas',
	Kentucky = 'Kentucky',
	Louisiana = 'Louisiana',
	Massachusetts = 'Massachusetts',
	Maryland = 'Maryland',
	Maine = 'Maine',
	Michigan = 'Michigan',
	Minnesota = 'Minnesota',
	Missouri = 'Missouri',
	Mississippi = 'Mississippi',
	Montana = 'Montana',
	NorthCarolina = 'North Carolina',
	NorthDakota = 'North Dakota',
	Nebraska = 'Nebraska',
	NewHampshire = 'New Hampshire',
	NewJersey = 'New Jersey',
	NewMexico = 'New Mexico',
	Nevada = 'Nevada',
	NewYork = 'New York',
	Oklahoma = 'Oklahoma',
	Ohio = 'Ohio',
	Oregon = 'Oregon',
	Pennsylvania = 'Pennsylvania',
	RhodeIsland = 'Rhode Island',
	SouthCarolina = 'South Carolina',
	SouthDakota = 'South Dakota',
	Tennessee = 'Tennessee',
	Texas = 'Texas',
	Utah = 'Utah',
	Virginia = 'Virginia',
	Vermont = 'Vermont',
	Washington = 'Washington',
	Wisconsin = 'Wisconsin',
	WestVirginia = 'WestVirginia',
	Wyoming = 'Wyoming',
}
