import { UUID } from '../sharedTypes';

export enum PauseResourceType {
	contractor = 'Contractor',
	location = 'Location',
	facilityUser = 'FacilityUser',
	locationUser = 'LocationUser',
}

export enum PauseAction {
	pause = 'pause',
	deactivate = 'deactivate',
	activate = 'activate',
}

export interface CreatePauseRequest {
	resourceType: PauseResourceType;
	resourceId: UUID;
	effectiveDate?: string;
	onActiveAction: PauseAction;
}

export interface UpdatePauseRequest {
	resourceType: PauseResourceType;
	resourceId: UUID;
	effectiveDate?: string;
	onActiveAction: PauseAction;
	active?: boolean;
}

export interface DeletePauseRequest {
	id: UUID;
}

export interface Pause {
	type: 'resource_pauses';
	id: UUID;
	attributes: {
		pausable_id: UUID;
		pausable_type: PauseResourceType;
		effective_date: string;
		active: boolean;
		deactivated: boolean;
	};
}

export type CreatePauseResponse = Pause;
export type UpdatePauseResponse = Pause;
export type DeletePauseResponse = Pause;
