import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import SmallLogo from '../../../assets/logos/Carefiller_Icon_White.png';
import { NavLink } from './NavLink';
import { AuthRouteNames, NonAuthRouteNames } from '../../../navigation/routeNames';
import { useAsync } from '../../../hooks/useAsync';
import { webApiManager } from '../../../network/apiManager';
import { UserContext } from '../../../context/UserContext';

export enum NavLinks {
	FACILITIES = 'Facilities',
	CONTRACTORS = 'Contractors',
	CONTRACTS = 'Contracts',
	MOBILE_APP_SETTINGS = 'Mobile App Settings',
	ADMIN_SETTINGS = 'Admin Settings',
	DELETED_USERS = 'Deleted Users',
	MY_ACCOUNT = 'My Account',
	LOG_OUT = 'Log Out',
}

type NavBarProps = {
	activeLink: NavLinks | null;
};

export const NavBar = ({ activeLink }: NavBarProps) => {
	const history = useHistory();
	const currentPath = useLocation().pathname;
	const { setCurrentUser } = useContext(UserContext);

	const handleClick = (path: string) => {
		if (!currentPath.includes(path)) {
			history.push(path);
		}
	};

	const onLogout = async () => {
		try {
			//logout on server, clear user in context, and reroute to login page
			await webApiManager.Auth.logout(setCurrentUser, () => history.push(NonAuthRouteNames.Login));
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const logout = useAsync(
		onLogout,
		'Error logging out. Please check your connection and try again.'
	);

	return (
		<NavBarWrapper>
			<NavBarContent>
				<LogoImage src={SmallLogo} onClick={() => history.push('/')} />
				<NavSectionWrapper>
					<NavSectionTitle>Monitor</NavSectionTitle>
					<NavLink
						name={NavLinks.CONTRACTS}
						isActive={activeLink === NavLinks.CONTRACTS}
						onClick={() => handleClick(AuthRouteNames.Contracts)}
					/>
					<NavLink
						name={NavLinks.FACILITIES}
						isActive={activeLink === NavLinks.FACILITIES}
						onClick={() => handleClick(AuthRouteNames.ManageFacilities)}
					/>
					<NavLink
						name={NavLinks.CONTRACTORS}
						isActive={activeLink === NavLinks.CONTRACTORS}
						onClick={() => {
							handleClick(AuthRouteNames.ManageContractors);
						}}
					/>
					<NavLink
						name={NavLinks.DELETED_USERS}
						isActive={activeLink === NavLinks.DELETED_USERS}
						onClick={() => handleClick(AuthRouteNames.DeletedUsers)}
					/>
				</NavSectionWrapper>

				<NavSectionWrapper>
					<NavSectionTitle>Manage</NavSectionTitle>
					<NavLink
						name={NavLinks.MOBILE_APP_SETTINGS}
						isActive={activeLink === NavLinks.MOBILE_APP_SETTINGS}
						onClick={() => handleClick(AuthRouteNames.MobileAppSettings)}
					/>
					<NavLink
						name={NavLinks.ADMIN_SETTINGS}
						isActive={activeLink === NavLinks.ADMIN_SETTINGS}
						onClick={() => handleClick(AuthRouteNames.AdminSettings)}
					/>
				</NavSectionWrapper>

				<NavSectionWrapper alignToBottom>
					<NavLink
						name={NavLinks.MY_ACCOUNT}
						isActive={activeLink === NavLinks.MY_ACCOUNT}
						onClick={() => handleClick(AuthRouteNames.Account)}
					/>
					<NavLink
						name={NavLinks.LOG_OUT}
						isActive={activeLink === NavLinks.LOG_OUT}
						onClick={logout.execute}
					/>
				</NavSectionWrapper>
			</NavBarContent>
		</NavBarWrapper>
	);
};

const NavBarWrapper = styled.div`
	background-color: ${Theming.nav.primaryNavBackground};
`;

export const NavBarContent = styled.div`
	background-image: linear-gradient(
		${Theming.gradients.lightGradient},
		${Theming.gradients.darkGradient}
	);
	box-sizing: border-box;
	padding: 20px 0;
	min-width: 160px;
	width: auto;
`;

const LogoImage = styled.img`
	box-sizing: border-box;
	width: 40%;
	margin: 20px;
	:hover& {
		cursor: pointer;
	}
`;

const NavSectionWrapper = styled.div<{ alignToBottom?: boolean }>`
	box-sizing: border-box;
	text-align: left;
	white-space: nowrap;
	width: 100%;
	justify-self: ${({ alignToBottom }) => (alignToBottom ? 'flex-end' : 'flex-start')};
	padding: 0;
	margin: 30px 0;
`;

const NavSectionTitle = styled.p`
	font-family: ${Theming.text.semiBoldFont};
	font-size: 12px;
	color: ${Theming.nav.primaryNavTitle};
	padding: 5px 20px;
	margin: 0;
`;
