import { UUID } from '../sharedTypes';
import { ContractorProfession } from './Professions';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { AdaptedContractorProfession } from '../../adapters/professionsAdapter';
import { UpdateContractorProfessionRequest } from './Contractors';

export interface GetContractorInvitesRequest
	extends PaginatedApiRequest<ContractorInviteSortOptions> {
	include_approved?: boolean; //default false
}

export enum ContractorInviteSortOptions {
	firstName = 'first_name',
	lastName = 'last_name',
	date = 'date',
	profession = 'profession',
}

export type GetContractorInvitesResponse = PaginatedApiResponse<ContractorInviteRequest[]>;

export type GetContractorInviteRequest = {
	contractorId: UUID;
};

export type GetContractorInviteResponse = ContractorInviteRequest;

export interface ContractorInviteRequest {
	id: UUID;
	attributes: {
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
		sms_opted_in: boolean;
		submitted_at: string; //timestamp
		approved_at: string | null; //timestamp
	};
	relationships: {
		type: 'contractor_requests';
		contractor_request_professions: ContractorProfession[];
	};
}

export interface UpdateContractorInviteRequest {
	contractorId: UUID;
	firstName?: string;
	lastName?: string;
	emailAddress?: string;
	phoneNumber?: string;
	smsOptedIn?: boolean;
	professions?: Array<Pick<AdaptedContractorProfession, 'professionId' | 'isExperienced'>>;
}

export type UpdateContractorInviteResponse = ContractorInviteRequest;

export interface DeleteContractorInviteRequest {
	contractorId: UUID;
}

export interface ApproveContractorInviteRequest {
	contractorId: UUID;
	professions: UpdateContractorProfessionRequest[];
}
