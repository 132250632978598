//for testing loaders/pending logic on async calls
export const simulateAsyncPending = async (string, ms) => {
	const delay = ms => new Promise(res => setTimeout(res, ms));
	await delay(ms);
	//eslint-disable-next-line
	console.log(string);
};

//for testing error handling on async calls
export const simulateAsyncError = () => {
	throw new Error('Test Async Error');
};
