import React, { useState } from 'react';
import {
	AdaptedFacilityLocation,
	adaptLocationFromApi,
} from '../../../../adapters/locationsAdapters';
import { ErrorMessage, PageTitle } from '../../../../components/Text';
import { EditStatus } from '../../../../components/EditStatus';
import { useAsync } from '../../../../hooks/useAsync';
import { webApiManager } from '../../../../network/apiManager';
import { Separator } from '../../../../components/Separator';
import { UpdateLocationRequest } from '../../../../types/api/Locations';
import LoadingSpinner from '../../../../components/Loader';
import { LocationAccountForm } from './components/LocationAccountForm';

type LocationAccountProps = {
	selectedLocation: AdaptedFacilityLocation;
	refreshLocation: () => void;
	isPending: boolean;
};

type UpdateLocationFormData = {
	locationName: string;
	streetAddress: string;
	city: string;
	state: string;
	zipCode: string;
	facilityTypeId: string;
};

export const LocationAccountTab = ({
	selectedLocation,
	refreshLocation,
	isPending,
}: LocationAccountProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const onUpdate = async (formData: UpdateLocationFormData) => {
		updateLocation.clearError();
		try {
			const updateRequest: UpdateLocationRequest = {
				locationId: selectedLocation.locationId,
				updates: {
					name: formData.locationName,
					street_address: formData.streetAddress,
					city: formData.city,
					state: formData.state,
					zip_code: formData.zipCode,
					facility_type_id: formData.facilityTypeId,
				},
			};
			adaptLocationFromApi(await webApiManager.Locations.updateLocation(updateRequest));
			await refreshLocation();
		} catch (error) {
			console.error(error);
			throw error;
		}
		setIsEditing(false);
	};

	const updateLocation = useAsync<void>(
		onUpdate,
		'Error updating location. Please check your connection and try again.'
	);

	return (
		<>
			<PageTitle>Edit Account Status</PageTitle>
			<EditStatus location={selectedLocation} onChange={refreshLocation} isPending={isPending} />
			<Separator />
			{updateLocation.pending ? (
				<LoadingSpinner />
			) : (
				<LocationAccountForm
					location={selectedLocation}
					isEditing={isEditing}
					setIsEditing={setIsEditing}
					onSave={formData => updateLocation.execute(formData)}
				/>
			)}
			{!!updateLocation.error && <ErrorMessage text={updateLocation.error} />}
		</>
	);
};
