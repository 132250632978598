import { webApiManager } from './apiManager';
import { buildQueryString } from '../utils/buildQueryString';
import fetchWrapper from './fetchWrapper';
import { GetDeletedUsersRequest, GetDeletedUsersResponse } from '../types/api/DeletedUsers';

export class DeletedUsersApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getDeletedUsers(request: GetDeletedUsersRequest): Promise<GetDeletedUsersResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getDeletedUsersResponse = (await fetchWrapper(
				{
					path: `deleted_users${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetDeletedUsersResponse;

			return getDeletedUsersResponse;
		} catch (error) {
			console.error('error in deleted users api', JSON.stringify(error));
			throw error;
		}
	}
}
