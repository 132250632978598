import React, { useEffect, useState } from 'react';
import { LocationUserRole } from '../../../../../types/api/LocationUsers';
import { useAsync } from '../../../../../hooks/useAsync';
import LoadingSpinner from '../../../../../components/Loader';
import { ErrorMessage } from '../../../../../components/Text';
import { LocationUserForm } from './LocationUserForm';
import { LabeledSelect, SelectOption } from '../../../../../components/Select';
import { webApiManager } from '../../../../../network/apiManager';
import { AdaptedUser, adaptUsersFromApi } from '../../../../../adapters/userAdapter';
import _ from 'lodash';
import { adaptLocationUserFromApi } from '../../../../../adapters/locationUsersAdapters';
import { UUID } from '../../../../../types/sharedTypes';

type AddNewLocationUserProps = {
	selectedLocationId: UUID;
	selectedFacilityId: UUID;
	closeSidebar: () => void;
};

type CreateLocationUserData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	roles: LocationUserRole[];
};

export const AddNewLocationUser = ({
	selectedLocationId,
	selectedFacilityId,
	closeSidebar,
}: AddNewLocationUserProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [userOptions, setUserOptions] = useState<SelectOption[]>([]);
	const [selectedUser, setSelectedUser] = useState<AdaptedUser | null>(null);

	//fetch all facility users
	useEffect(() => {
		getFacilityUsers.execute();
		return () => getFacilityUsers.clearError();
	}, []);

	useEffect(() => {
		if (selectedUser) {
			//disable editing when user is selected
			setIsEditing(false);
		}
	}, [selectedUser]);

	const onLoad = async () => {
		getFacilityUsers.clearError();
		try {
			const allFacilityUsers = adaptUsersFromApi(
				await webApiManager.Facilities.getFacilityUsers({ facilityId: selectedFacilityId })
			);
			//format into select option and filter out users already belonging to selected location
			const filteredUsers = allFacilityUsers
				.filter(user => !_.some(user.locations, { locationId: selectedLocationId }))
				.map(user => {
					return {
						label:
							!!user.firstName && !!user.lastName
								? `${user.lastName}, ${user.firstName}`
								: user.email,
						value: user,
					};
				});
			setUserOptions(filteredUsers);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onAdd = async (formData: CreateLocationUserData) => {
		addLocationUser.clearError();
		setIsEditing(false);
		try {
			if (selectedUser) {
				const request = {
					locationId: selectedLocationId,
					roles: formData.roles,
					userId: selectedUser.id,
				};
				adaptLocationUserFromApi(await webApiManager.LocationUsers.addLocationUser(request));
				//close sidebar on success
				closeSidebar();
			}
		} catch (error) {
			setIsEditing(true);

			console.error(error);
			throw error;
		}
	};

	const onCreate = async (formData: CreateLocationUserData) => {
		createLocationUser.clearError();
		setIsEditing(false);
		try {
			const request = {
				locationId: selectedLocationId,
				locationUser: {
					first_name: formData.firstName,
					last_name: formData.lastName,
					email: formData.email,
					phone: formData.phone,
					roles: formData.roles,
				},
			};
			await webApiManager.LocationUsers.createLocationUser(request);
			closeSidebar();
		} catch (error) {
			setIsEditing(true);

			console.error(error);
			throw error;
		}
	};

	const getFacilityUsers = useAsync<void>(
		onLoad,
		'Error: could not find users for this facility. Please check your connection and try again.'
	);
	const addLocationUser = useAsync<void>(
		onAdd,
		'Error adding user to location. Please check your connection and try again.'
	);
	const createLocationUser = useAsync<void>(
		onCreate,
		'Error creating user for location. Please check your connection and try again.'
	);

	return (
		<>
			{getFacilityUsers.pending || addLocationUser.pending || createLocationUser.pending ? (
				<LoadingSpinner />
			) : (
				<>
					<LabeledSelect
						label={'Select user to add to location'}
						options={userOptions}
						onChange={newVal => setSelectedUser(newVal)}
						isClearable
					/>
					{getFacilityUsers.error && <ErrorMessage text={getFacilityUsers.error} />}
					<LocationUserForm
						key={JSON.stringify(selectedUser)}
						selectedLocationUser={selectedUser}
						isEditing={isEditing}
						setIsEditing={setIsEditing}
						onSave={formData =>
							selectedUser
								? addLocationUser.execute(formData)
								: createLocationUser.execute(formData)
						}
						hideEdit
					/>
					{addLocationUser.error && <ErrorMessage text={addLocationUser.error} />}
					{createLocationUser.error && <ErrorMessage text={createLocationUser.error} />}
				</>
			)}
		</>
	);
};
