import React, { Dispatch, SetStateAction } from 'react';
import { ScrollWrapper } from '../../../components/Wrappers';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { SearchInput } from '../../../components/SearchInput';
import { debounce } from 'lodash';
import { ContractCard } from '../components/ContractCard';
import { AdaptedContract, AdaptedContractList } from '../../../adapters/contractAdapters';
import { ErrorMessage, InfoText } from '../../../components/Text';
import LoadingSpinner from '../../../components/Loader';
import { ContractFilterOptions, ContractFilterType } from '../../../types/api/Contracts';
import { useAsync } from '../../../hooks/useAsync';
import { webApiManager } from '../../../network/apiManager';
import { Modal } from '../../../components/Modal';

type ContractListProps = {
	selectedContract: AdaptedContract | null;
	setSelectedContract: Dispatch<SetStateAction<AdaptedContract | null>>;
	getNextPage: () => void;
	contracts: AdaptedContractList | null;
	pending: boolean;
	error: string | null;
	shouldClearFilters: boolean;
	setFilters: Dispatch<SetStateAction<ContractFilterOptions>>;
	refreshList: () => void;
};

export const ContractList = ({
	selectedContract,
	setSelectedContract,
	getNextPage,
	contracts,
	error,
	pending,
	shouldClearFilters,
	setFilters,
	refreshList,
}: ContractListProps) => {
	const handleSearch = searchQuery => {
		setFilters(prevState => {
			return { ...prevState, [ContractFilterType.contractId]: searchQuery };
		});
	};

	const onCancel = async (contractId: string) => {
		try {
			await webApiManager.Contracts.cancelContract({ contractId });
			//on success, update contract list
			refreshList();
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const cancelContract = useAsync<void>(
		onCancel,
		'Error canceling contract. Please check your connection and try again.'
	);

	return (
		<>
			<SearchWrapper>
				<SearchInput
					placeholder={'Search Contract #'}
					onSubmit={debounce(newVal => handleSearch(newVal), 500)}
					shouldClear={shouldClearFilters}
				/>
			</SearchWrapper>
			<ScrollWrapper padding={'0 10px'} onScrollToBottom={getNextPage}>
				{!contracts ? (
					<>
						{pending ? (
							<LoadingSpinner />
						) : (
							<ErrorMessage text={error ?? 'Error getting contract data.'} />
						)}
					</>
				) : (
					<>
						{contracts.list.length === 0 ? (
							<InfoText color={Theming.text.placeholderColor} margin={'20px auto'}>
								No contracts found. Try clearing filters to get more results.
							</InfoText>
						) : (
							<>
								{contracts.list.map((contract, index) => {
									const isActive = contract.id === selectedContract?.id;
									return (
										<ContractCard
											key={index}
											onClick={() => (!isActive ? setSelectedContract(contract) : {})}
											isActive={isActive}
											contract={contract}
											onCancel={contractId => cancelContract.execute(contractId)}
											cancelPending={cancelContract.pending}
											cancelError={selectedContract?.id === contract.id ? cancelContract.error : ''}
											clearCancelError={() => cancelContract.clearError()}
										/>
									);
								})}
								{/*{pending && <FlexWrapper justifyContent={'center'}><MiniLoadingSpinner /></FlexWrapper>}*/}
								{contracts.pagination.isFinalPage && (
									<InfoText color={Theming.text.placeholderColor} margin={'20px auto'}>
										End of results
									</InfoText>
								)}
							</>
						)}
					</>
				)}
			</ScrollWrapper>
			<Modal
				isOpen={!!contracts && pending}
				hide={() => {}}
				showCloseButton={false}
				showTitle={false}
				content={<LoadingSpinner />}
				hideConfirm
				hideCancel
			/>
		</>
	);
};

const SearchWrapper = styled.div`
	width: 100%;
	padding: 5px 15px;
	margin: 0 auto;
	background-color: ${Theming.backgrounds.tabActive};
`;
