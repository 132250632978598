import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAsync } from '../../../../hooks/useAsync';
import { ErrorMessage } from '../../../../components/Text';
import LoadingSpinner from '../../../../components/Loader';
import { BaseButton } from '../../../../components/Buttons';
import { Theming } from '../../../../theming';
import { Modal } from '../../../../components/Modal';
import { webApiManager } from '../../../../network/apiManager';
import {
	adaptContractorInviteFromApi,
	AdaptedContractorInviteRequest,
} from '../../../../adapters/contractorInvitesAdapters';
import { UpdateContractorData } from '../../../../types/sharedTypes';
import { UpdateContractorForm } from '../../components/UpdateContractorForm';
import { ContractorExperienceForm } from '../../components/ContractorExperienceForm';
import { MultiSelect } from '../../../../components/Select';
import {
	AdaptedProfession,
	adaptProfessionsFromApi,
} from '../../../../adapters/professionsAdapter';
import { Info } from '../../../../components/Info';
import styled from 'styled-components';
import { FormHeading } from '../../../../components/Form';
import { ExperienceUpdate } from '../../components/ProfessionCard';

type ContractorInviteRequestProps = {
	selectedInviteId: AdaptedContractorInviteRequest['id'] | null;
	setSelectedInviteId: Dispatch<SetStateAction<AdaptedContractorInviteRequest['id'] | null>>;
};

export const ContractorInviteRequest = ({
	selectedInviteId,
	setSelectedInviteId,
}: ContractorInviteRequestProps) => {
	const [invite, setInvite] = useState<AdaptedContractorInviteRequest | null>(null);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(false);
	const [professions, setProfessions] = useState<AdaptedProfession[] | null>(null);
	const [initialProfessions, setInitialProfessions] = useState<AdaptedProfession[]>([]);
	const [experienceUpdates, setExperienceUpdates] = useState<ExperienceUpdate[]>([]);

	//fetch selected invite and professions list on load
	useEffect(() => {
		getSelectedInvite.execute();
		return () => getSelectedInvite.clearError();
	}, []);

	useEffect(() => {
		getProfessions.execute();
		return () => getProfessions.clearError();
	}, []);

	useEffect(() => {
		//clear any update or submit errors when re-editing
		if (isEditing) {
			updateInviteRequest.clearError();
			setupAccount.clearError();
		}
	}, [isEditing]);

	const onExperienceChange = (experienceUpdates: ExperienceUpdate[]) => {
		setExperienceUpdates(experienceUpdates);
	};

	const onLoad = async () => {
		getSelectedInvite.clearError();
		try {
			if (selectedInviteId) {
				//get selected invite
				const selectedInvite = adaptContractorInviteFromApi(
					await webApiManager.ContractorInviteRequests.getInviteRequest({
						contractorId: selectedInviteId,
					})
				);

				//set adapted data to state
				setInvite(selectedInvite);

				setInitialProfessions(selectedInvite.professions);
			} else {
				throw new Error('Could not find selected invite request.');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onUpdate = async (data: UpdateContractorData) => {
		updateInviteRequest.clearError();
		try {
			//disable form
			setIsEditing(false);
			//send updates to server and update state on success
			const updatedInvite = adaptContractorInviteFromApi(
				await webApiManager.ContractorInviteRequests.updateInviteRequest(data)
			);
			setInvite(updatedInvite);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onSubmit = async () => {
		setupAccount.clearError();
		try {
			if (invite) {
				await webApiManager.ContractorInviteRequests.approveInviteRequest({
					contractorId: invite.id,
					professions: experienceUpdates,
				});
				//close sidebar
				setSelectedInviteId(null);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onDelete = async () => {
		deleteRequest.clearError();
		try {
			if (invite) {
				//send delete request to server
				await webApiManager.ContractorInviteRequests.deleteInviteRequest({
					contractorId: invite.id,
				});
				//close modal and sidebar
				setShowWarning(false);
				setSelectedInviteId(null);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onGetProfessions = async () => {
		try {
			const professionsList = adaptProfessionsFromApi(
				await webApiManager.Professions.getProfessions()
			);

			setProfessions(professionsList);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getProfessions = useAsync<void>(onGetProfessions, 'Error getting professions');

	const getSelectedInvite = useAsync<void>(
		onLoad,
		'Error getting form data. Please check your connection and try again.'
	);
	const updateInviteRequest = useAsync<void>(
		(data: UpdateContractorData) => onUpdate(data),
		'Error: Data could not be saved.'
	);
	const setupAccount = useAsync<void>(onSubmit, 'Error: Account setup failed.');
	const deleteRequest = useAsync<void>(onDelete, 'Error: Could not delete invite request.');

	return (
		<>
			{getSelectedInvite.pending ||
			updateInviteRequest.pending ||
			setupAccount.pending ||
			getProfessions.pending ? (
				<LoadingSpinner />
			) : (
				<>
					{!invite ? (
						<ErrorMessage text={'Error: Invite request not found.'} />
					) : (
						<>
							<UpdateContractorForm
								contractorData={invite}
								formTitle={'Invite Request'}
								isEditing={isEditing}
								setIsEditing={setIsEditing}
								updateContractorData={(formData: UpdateContractorData) =>
									updateInviteRequest.execute(formData)
								}
							/>
							<FormHeading>Contractor's Professions</FormHeading>
							<MultiSelect
								initialValues={invite.professions.map(profession => profession.id)}
								options={professions?.map(profession => ({
									label: profession.name,
									value: profession.id,
								}))}
								onChange={professionIds => {
									setInitialProfessions(
										professions?.filter(profession => professionIds.indexOf(profession.id) > -1)
									);
								}}
							/>
							<Info>
								These were self-selected on sign up. You may change these if they're incorrect.
							</Info>
							<FormHeading>Itemized Experience</FormHeading>
							<Info>
								Specify this contractor's months of professional experience per state and facility
								type. You'll have the option to set additional details (such as expiration dates and
								specializations) after creating this account.
							</Info>
							<ExperienceFormContainer>
								<ContractorExperienceForm
									isInviteMode
									selectedContractor={invite}
									professions={professions}
									initialProfessions={initialProfessions}
									onChange={onExperienceChange}
								/>
							</ExperienceFormContainer>
							{updateInviteRequest.error && <ErrorMessage text={updateInviteRequest.error} />}
							{setupAccount.error && <ErrorMessage text={setupAccount.error} />}
							{deleteRequest.error && <ErrorMessage text={deleteRequest.error} />}
							<BaseButton
								text={'DELETE REQUEST'}
								margin={'40px 10px'}
								backgroundColor={Theming.errorColor}
								onClick={() => setShowWarning(true)}
							/>
							<BaseButton
								text={'SETUP ACCOUNT'}
								margin={'40px 10px'}
								disabled={isEditing}
								onClick={setupAccount.execute}
							/>
							<Modal
								isOpen={showWarning}
								hide={() => setShowWarning(false)}
								onConfirm={deleteRequest.execute}
								title={`Delete invite request for ${invite.firstName} ${invite.lastName}?`}
								text={'This action cannot be undone.'}
								cancelButtonText={'Cancel'}
								confirmButtonText={'Confirm Delete'}
								confirmButtonColor={Theming.errorColor}
								pending={deleteRequest.pending}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

const ExperienceFormContainer = styled.div`
	border: 2px solid #dbdbdb;
	border-radius: 10px;
	margin: 20px 8px;
	padding-bottom: 10px;
`;
