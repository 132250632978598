import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import {
	GetContractorExperienceSpecializationsResponse,
	CreateContractorExperienceSpecializationResponse,
	UpdateContractorExperienceSpecializationResponse,
	DestroyContractorExperienceSpecializationResponse,
	UpdateContractorSpecializationRequest,
	CreateContractorSpecializationRequest,
} from '../types/api/ContractorExperienceSpecialization';

export class ContractorExperienceSpecializationApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getContractorExperienceSpecializations(): Promise<GetContractorExperienceSpecializationsResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const response = await fetchWrapper(
				{
					path: `specializations`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor experience specializations api', JSON.stringify(error));
			throw error;
		}
	}

	public async destroyContractorExperienceSpecialization(
		id
	): Promise<DestroyContractorExperienceSpecializationResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const response = await fetchWrapper(
				{
					path: `specializations/${id}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor experience specializations api', JSON.stringify(error));
			throw error;
		}
	}

	public async createContractorSpecialization(
		request: CreateContractorSpecializationRequest
	): Promise<CreateContractorExperienceSpecializationResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				specialization: {
					abbreviation: request.abbreviation,
					description: request.description,
					profession_id: request.professionId,
					facility_type_id: request.facilityTypeId,
					experience_threshold: 0,
					tag_ids: request.tagIds.map(s => s.toString()),
				},
			};

			const response = await fetchWrapper(
				{
					path: `specializations`,
					method: 'POST',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	public async updateContractorSpecialization(
		request: UpdateContractorSpecializationRequest
	): Promise<UpdateContractorExperienceSpecializationResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				specialization: {
					abbreviation: request.abbreviation,
					description: request.description,
					experience_threshold: 0,
					tag_ids: request.tagIds.map(s => s.toString()),
				},
			};

			const response = await fetchWrapper(
				{
					path: `specializations/${request.id}`,
					method: 'PATCH',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}
}
