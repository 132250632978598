import React, { ReactNode, useEffect, useState } from 'react';
import IconManager, { IconType } from './IconManager';
import { Theming } from '../theming';
import { ErrorMessage } from './Text';
import styled from 'styled-components';

interface SearchInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onSubmit'> {
	error?: string;
	disabled?: boolean;
	onSubmit: (newVal: string) => void;
	placeholder?: string;
	margin?: string;
	icon?: ReactNode;
	iconPadding?: string;
	shouldClear?: boolean;
}

export const SearchInput = ({
	error,
	disabled,
	onSubmit,
	placeholder,
	margin,
	icon,
	iconPadding,
	shouldClear,
	...rest
}: SearchInputProps) => {
	const [searchQuery, setSearchQuery] = useState<string>('');

	useEffect(() => {
		onSubmit(searchQuery);
	}, [searchQuery]);

	useEffect(() => {
		if (shouldClear) {
			setSearchQuery('');
		}
	}, [shouldClear]);

	return (
		<>
			<SearchInputWrapper margin={margin}>
				<SearchInputIcon alignTo={'left'} padding={iconPadding}>
					{icon ?? (
						<IconManager
							type={IconType.SEARCH}
							size={24}
							fill={'none'}
							stroke={Theming.text.placeholderColor}
						/>
					)}
				</SearchInputIcon>
				<StyledSearchInput
					placeholder={placeholder ?? 'Search...'}
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
					error={!!error}
					disabled={!!disabled}
					{...rest}
				/>
			</SearchInputWrapper>
			{error && <ErrorMessage text={error} />}
		</>
	);
};

const SearchInputWrapper = styled.div<{ error?: boolean; margin?: string }>`
	position: relative;
	width: 100%;
	border: none;
	background-color: transparent;
	padding: 0;
	margin: ${({ margin }) => margin ?? '20px auto'};
`;

const StyledSearchInput = styled.input<{ error?: boolean }>`
	width: 100%;
	border: 1px solid #c9c8cf;
	border-radius: 3px;
	background-color: #fff;
	padding: 10px 40px;
	color: ${Theming.text.primaryTextColor};
	font-size: 15px;
	font-family: ${Theming.text.regularFont};
	::placeholder {
		color: ${Theming.text.placeholderColor};
	}
	&:focus {
		outline: none;
		border: ${({ error }) =>
			`1px solid ${error ? Theming.errorColor : Theming.gradients.mediumGradient}`};
	}
`;

const SearchInputIcon = styled.div<{ alignTo: 'left' | 'right'; padding?: string }>`
	position: absolute;
	top: 0px;
	left: ${({ alignTo }) => (alignTo === 'left' ? 0 : 'auto')};
	right: ${({ alignTo }) => (alignTo === 'right' ? 0 : 'auto')};
	bottom: 0;
	padding: ${({ padding }) => padding ?? '8px'};
`;
