import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AdaptedFacilityLocation } from '../../../../../adapters/locationsAdapters';
import { FormRow, FormSectionTitle, FormWrapper } from '../../../../../components/Form';
import { ControlledTextInput } from '../../../../../components/TextInputs';
import { FormSelect, SelectOption } from '../../../../../components/Select';
import { FlexWrapper } from '../../../../../components/Wrappers';
import { FormLink } from '../../../../../components/Links';
import { BoldSeparator } from '../../../../../components/Separator';
import { useForm } from 'react-hook-form';
import {
	AdaptedFacilityType,
	adaptFacilityTypesFromApi,
} from '../../../../../adapters/facilityTypesAdapter';
import { webApiManager } from '../../../../../network/apiManager';
import { useAsync } from '../../../../../hooks/useAsync';
import { MiniLoadingSpinner } from '../../../../../components/Loader';
import { ErrorMessage } from '../../../../../components/Text';
import { BaseButton } from '../../../../../components/Buttons';
import { UUID } from '../../../../../types/sharedTypes';
import { StateAddressSelect } from '../../../../../components/StateAddressSelect';

type LocationAccountFormProps = {
	location?: AdaptedFacilityLocation;
	isEditing: boolean;
	setIsEditing?: Dispatch<SetStateAction<boolean>>;
	onSave: (formData: FormValues & { facilityTypeId: UUID }) => void;
	hideEdit?: boolean;
};

type FormValues = {
	locationName: string;
	streetAddress: string;
	city: string;
	state: string;
	zipCode: string;
};

type FacilityTypeFormState = {
	selectedFacilityTypeId: AdaptedFacilityType['id'];
	formError: string;
};

type StateAddressFormState = {
	selectedState: string;
	formError: string;
};

export const LocationAccountForm = ({
	location,
	isEditing,
	setIsEditing,
	onSave,
	hideEdit,
}: LocationAccountFormProps) => {
	const [facilityTypeOptions, setFacilityTypeOptions] = useState<SelectOption[]>([]);
	const [facilityTypeFormState, setFacilityTypeFormState] = useState<FacilityTypeFormState>({
		selectedFacilityTypeId: location?.facilityType?.id ?? '',
		formError: '',
	});
	const [stateAddressFormState, setStateAddressFormState] = useState<StateAddressFormState>({
		selectedState: location?.state ?? '',
		formError: '',
	});

	const initialFormState = {
		locationName: location?.name ?? '',
		streetAddress: location?.streetAddress ?? '',
		city: location?.city ?? '',
		zipCode: location?.zipCode ?? '',
	};

	const formMethods = useForm<FormValues>({
		defaultValues: initialFormState,
		reValidateMode: 'onChange',
	});

	//fetch facility types on load
	useEffect(() => {
		getFacilityTypes.execute();
	}, []);

	const onLoad = async () => {
		getFacilityTypes.clearError();
		try {
			//get list of facility types
			const facilityTypesList = adaptFacilityTypesFromApi(
				await webApiManager.FacilityTypes.getFacilityTypes()
			);

			//format facility types into select options
			const facilityTypeSelectOptions = facilityTypesList.map(facilityType => {
				return {
					label: facilityType.name,
					value: facilityType.id,
				};
			});

			//set options to state
			setFacilityTypeOptions(facilityTypeSelectOptions);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const saveData = (formData: FormValues) => {
		//validate uncontrolled selects
		if (!!facilityTypeFormState.selectedFacilityTypeId && !!stateAddressFormState.selectedState) {
			onSave({
				...formData,
				facilityTypeId: facilityTypeFormState.selectedFacilityTypeId,
				state: stateAddressFormState.selectedState,
			});
		} else {
			if (!facilityTypeFormState.selectedFacilityTypeId) {
				setFacilityTypeFormState(prevState => {
					return { ...prevState, formError: 'Required' };
				});
			}
			if (!stateAddressFormState.selectedState) {
				setStateAddressFormState(prevState => {
					return { ...prevState, formError: 'Required' };
				});
			}
		}
	};

	const getFacilityTypes = useAsync<void>(
		onLoad,
		'Error getting facility types. Please check your connection and try again.'
	);

	return (
		<FormWrapper>
			<FlexWrapper>
				<FormSectionTitle>Account Information</FormSectionTitle>
				{!hideEdit && !!setIsEditing && (
					<>
						{isEditing ? (
							<>
								<FormLink
									onClick={() => {
										//on cancel, reset formState back to saved invite
										formMethods.reset();
										//toggle editing status
										setIsEditing(prevState => !prevState);
									}}
									secondary={isEditing} //controls link color
								>
									Cancel
								</FormLink>
								<FormLink
									onClick={formMethods.handleSubmit((formData: FormValues) => saveData(formData))}
								>
									Save
								</FormLink>
							</>
						) : (
							<FormLink
								onClick={() => {
									//toggle editing status
									setIsEditing(prevState => !prevState);
								}}
								secondary={isEditing} //controls link color
							>
								Edit
							</FormLink>
						)}
					</>
				)}
			</FlexWrapper>
			<BoldSeparator margin={'0 0 5px'} />
			<FormRow>
				<ControlledTextInput
					register={() => formMethods.register('locationName', { required: 'Required' })}
					placeholder={'Location Name'}
					disabled={!isEditing}
					error={formMethods.formState.errors.locationName?.message}
				/>
			</FormRow>
			<FormRow>
				<ControlledTextInput
					register={() => formMethods.register('streetAddress', { required: 'Required' })}
					placeholder={'Address'}
					disabled={!isEditing}
					error={formMethods.formState.errors.streetAddress?.message}
				/>
			</FormRow>
			<FormRow>
				<ControlledTextInput
					register={() => formMethods.register('city', { required: 'Required' })}
					placeholder={'City'}
					disabled={!isEditing}
					error={formMethods.formState.errors.city?.message}
				/>
				<StateAddressSelect
					onChange={newVal =>
						setStateAddressFormState(prevState => {
							return { ...prevState, selectedState: newVal, formError: '' };
						})
					}
					disabled={!isEditing}
					initialValue={location?.state ?? ''}
					error={stateAddressFormState.formError}
				/>
				<ControlledTextInput
					register={() =>
						formMethods.register('zipCode', {
							required: 'Required',
							pattern: { value: /^\d{5}((?:[-\s]\d{4})|(\d{4}))?$/, message: 'Invalid zip code' },
						})
					}
					placeholder={'Zip Code'}
					disabled={!isEditing}
					error={formMethods.formState.errors.zipCode?.message}
				/>
			</FormRow>
			<FormRow>
				{getFacilityTypes.pending ? (
					<MiniLoadingSpinner />
				) : (
					<>
						<FormSelect
							placeholder={'Facility Type'}
							options={facilityTypeOptions}
							onChange={newVal =>
								setFacilityTypeFormState(prevState => {
									return { ...prevState, selectedFacilityTypeId: newVal, formError: '' };
								})
							}
							disabled={!isEditing}
							initialValue={location?.facilityType?.id ?? ''}
							error={facilityTypeFormState.formError}
						/>
						{getFacilityTypes.error && <ErrorMessage text={getFacilityTypes.error} />}
					</>
				)}
			</FormRow>
			{!!hideEdit && (
				<BaseButton
					text={'CREATE LOCATION'}
					margin={'40px 10px'}
					onClick={formMethods.handleSubmit((formData: FormValues) => saveData(formData))}
				/>
			)}
		</FormWrapper>
	);
};
