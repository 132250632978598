import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { ApiResponse } from '../types/api/SharedApiTypes';
import {
	ApproveFacilityInviteRequest,
	DeleteFacilityInviteRequest,
	GetFacilityInviteRequest,
	GetFacilityInviteResponse,
	GetFacilityInvitesRequest,
	GetFacilityInvitesResponse,
	UpdateFacilityInviteRequest,
	UpdateFacilityInviteResponse,
} from '../types/api/FacilityInviteRequests';
import { buildQueryString } from '../utils/buildQueryString';

export class FacilityInviteRequestsApi {
	constructor(public apiManager: typeof webApiManager) {}

	//list of all facility invite requests (default returns only unapproved requests- optionally can return all)
	public async getInviteRequests(
		request: GetFacilityInvitesRequest
	): Promise<GetFacilityInvitesResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getInvitesResponse = (await fetchWrapper(
				{
					path: `facility_requests${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetFacilityInvitesResponse;

			return getInvitesResponse;
		} catch (error) {
			console.error('error in facility invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//single facility invite request
	public async getInviteRequest(
		request: GetFacilityInviteRequest
	): Promise<GetFacilityInviteResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getInviteResponse = (await fetchWrapper(
				{
					path: `facility_requests/${request.facilityId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetFacilityInviteResponse>;

			return getInviteResponse.data;
		} catch (error) {
			console.error('error in facility invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//update data from invite request before approval
	public async updateInviteRequest(
		request: UpdateFacilityInviteRequest
	): Promise<UpdateFacilityInviteResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const updateInviteResponse = (await fetchWrapper(
				{
					path: `facility_requests/${request.facilityId}`,
					method: 'PUT',
					body: {
						facility_request: {
							first_name: request.firstName,
							last_name: request.lastName,
							email: request.emailAddress,
							phone: request.phoneNumber,
							facility_type_ids: request.facilityTypeIds,
							facility_name: request.facilityName,
						},
					},
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateFacilityInviteResponse>;

			return updateInviteResponse.data;
		} catch (error) {
			console.error('error in facility invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	// deny/remove invite request
	public async deleteInviteRequest(request: DeleteFacilityInviteRequest): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();

			await fetchWrapper(
				{
					path: `facility_requests/${request.facilityId}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			);
		} catch (error) {
			console.error('error in facility invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//approve invite request (sends notification to user)
	public async approveInviteRequest(request: ApproveFacilityInviteRequest): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();

			await fetchWrapper(
				{
					path: `facility_requests/${request.facilityId}/approve`,
					method: 'POST',
					token,
				},
				this.apiManager.rootApiUrl
			);
		} catch (error) {
			console.error('error in facility invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//resend invite approval notification
}
