import React from 'react';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { NavLinks } from './NavBar';

type NavLinkProps = {
	name: NavLinks;
	isActive: boolean;
	onClick: () => void;
};

export const NavLink = ({ name, isActive, onClick }: NavLinkProps) => {
	return (
		<StyledNavLink active={isActive} onClick={onClick}>
			{name}
		</StyledNavLink>
	);
};

const StyledNavLink = styled.p<{ active?: boolean }>`
	font-family: ${Theming.text.boldFont};
	font-size: 16px;
	color: ${Theming.nav.primaryNavLink};

	padding: 10px 20px;
	margin: 0;
	:hover& {
		cursor: ${({ active }) => (!active ? 'pointer' : 'default')};
		background-color: ${({ active }) =>
			!active ? 'rgba(15, 50, 175, .1)' : 'rgba(15, 50, 175, .2)'};
	}

	background-color: ${({ active }) => (!active ? 'transparent' : 'rgba(15, 50, 175, .2)')};
`;
