import { UUID } from '../sharedTypes';
import { UserDetails } from './Auth';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { Pause } from './Pauses';

export interface LocationUser {
	id: UUID;
	type: 'location_users';
	attributes: {
		location_id: UUID;
		user_id: UUID;
		roles: LocationUserRole[];
	};
	relationships: {
		type: 'location_users';
		user: UserDetails;
		resource_pauses?: Pause[];
	};
}

export enum LocationUserRole {
	manager = 'manager',
	biller = 'biller',
	scheduler = 'scheduler',
}

export enum LocationUserSortOptions {
	lastName = 'last_name',
	firstName = 'first_name',
	email = 'email',
	status = 'status',
	roles = 'roles',
}

export interface GetLocationUsersRequest {
	locationId: UUID;
	query: PaginatedApiRequest<LocationUserSortOptions>;
}

export type GetLocationUsersResponse = PaginatedApiResponse<LocationUser[]>;

export interface GetLocationUserRequest {
	locationUserId: UUID;
}

export type GetLocationUserResponse = LocationUser;

export interface UpdateLocationUserRequest {
	locationUserId: UUID;
	updates: {
		first_name?: string;
		last_name?: string;
		email?: string;
		phone?: string;
		roles?: LocationUserRole[];
	};
}

export type UpdateLocationUserResponse = LocationUser;

export interface CreateLocationUserRequest {
	locationId: UUID;
	locationUser: {
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
		roles: LocationUserRole[];
	};
}

export type CreateLocationUserResponse = LocationUser;

export interface RemoveLocationUserRequest {
	locationId: UUID;
	userId: UUID;
}

export type RemoveLocationUserResponse = Record<string, never>;

export interface AddLocationUserRequest {
	locationId: UUID;
	roles: LocationUserRole[];
	userId: UUID;
}

export type AddLocationUserResponse = LocationUser;
