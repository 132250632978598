import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NonAuthInputWrapper, UncontrolledTextInput } from '../../components/TextInputs';
import {
	NonAuthPageColumn,
	NonAuthPageContainer,
	NonAuthPageContentWrapper,
} from '../../components/Wrappers';
import { MainLogo } from '../../components/Logos';
import { ErrorMessage, NonAuthFormTitle } from '../../components/Text';
import { removeWhiteSpace } from '../../utils/removeWhiteSpace';
import { useAsync } from '../../hooks/useAsync';
import { NonAuthRouteNames } from '../../navigation/routeNames';
import { NonAuthButton } from '../../components/Buttons';
import LoadingSpinner from '../../components/Loader';
import { simulateAsyncPending } from '../../utils/simulateAsync';

type FormState = {
	password: string;
	confirmPassword: string;
	passwordError: string;
	confirmPasswordError: string;
};

//TODO: no create account functionality on v1, finish setup on v2?
export const CreateAccount = () => {
	const history = useHistory();
	//TODO: get email address from url
	const email = 'test@email.com';

	const [formState, setFormState] = useState<FormState>({
		password: '',
		confirmPassword: '',
		passwordError: '',
		confirmPasswordError: '',
	});

	useEffect(() => {
		validateForm();
	}, [formState]);

	const validateForm = () => {
		//TODO: find out password requirements
		const validPassword = formState.password.length >= 8;
		const validConfirmPassword = formState.confirmPassword === formState.password;

		//if invalid password, set error
		if (!validPassword && !formState.passwordError) {
			setFormState(prevState => {
				return { ...prevState, passwordError: 'Your password must be at least 8 characters.' };
			});
		} else if (validPassword && formState.passwordError) {
			//clear error if valid
			setFormState(prevState => {
				return { ...prevState, passwordError: '' };
			});
		}

		//if passwords don't match, set error
		if (!validConfirmPassword && !formState.confirmPasswordError) {
			setFormState(prevState => {
				return { ...prevState, confirmPasswordError: 'Passwords must match.' };
			});
		} else if (validConfirmPassword && formState.confirmPasswordError) {
			//clear error if valid
			setFormState(prevState => {
				return { ...prevState, confirmPasswordError: '' };
			});
		}

		return validPassword && validConfirmPassword;
	};

	const onSubmit = async () => {
		createAccount.clearError();
		try {
			await simulateAsyncPending('create account async', 3000);
			// simulateAsyncError();
			//TODO: hook up when api is ready
			//redirect to home
			history.push(NonAuthRouteNames.Login);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const createAccount = useAsync<void>(
		onSubmit,
		'Error creating account. Please check your connection and try again.'
	);

	return (
		<NonAuthPageContainer>
			<NonAuthPageColumn width={60}>
				<MainLogo width={'60%'} />
			</NonAuthPageColumn>

			<NonAuthPageColumn width={40}>
				<NonAuthPageContentWrapper>
					{createAccount.pending ? (
						<LoadingSpinner />
					) : (
						<>
							<NonAuthFormTitle>Create Account</NonAuthFormTitle>
							<NonAuthInputWrapper>
								<UncontrolledTextInput disabled={true} value={email} />
								<UncontrolledTextInput
									type={'password'}
									placeholder={'Enter Password'}
									value={formState.password}
									onChange={e => {
										setFormState(prevState => {
											const newVal = removeWhiteSpace(e.target.value);
											return { ...prevState, password: newVal };
										});
									}}
									error={formState.password ? formState.passwordError : ''}
								/>
								<UncontrolledTextInput
									type={'password'}
									placeholder={'Confirm Password'}
									value={formState.confirmPassword}
									onChange={e => {
										setFormState(prevState => {
											const newVal = removeWhiteSpace(e.target.value);
											return { ...prevState, confirmPassword: newVal };
										});
									}}
									error={formState.confirmPassword ? formState.confirmPasswordError : ''}
								/>
							</NonAuthInputWrapper>

							<NonAuthButton
								text={'Submit'}
								disabled={!validateForm()}
								onClick={createAccount.execute}
							/>
							{createAccount.error && <ErrorMessage text={createAccount.error} />}
						</>
					)}
				</NonAuthPageContentWrapper>
			</NonAuthPageColumn>
		</NonAuthPageContainer>
	);
};
