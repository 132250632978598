import { webApiManager } from './apiManager';
import { buildQueryString } from '../utils/buildQueryString';
import fetchWrapper from './fetchWrapper';
import {
	GetContractorRequest,
	GetContractorResponse,
	GetContractorsRequest,
	GetContractorsResponse,
	UpdateContractorRequest,
	UpdateContractorResponse,
} from '../types/api/Contractors';
import { ApiResponse } from '../types/api/SharedApiTypes';

export class ContractorsApi {
	constructor(public apiManager: typeof webApiManager) {}

	//list of all contractors
	public async getContractors(request: GetContractorsRequest): Promise<GetContractorsResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getContractorsResponse = (await fetchWrapper(
				{
					path: `contractors${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetContractorsResponse;

			return getContractorsResponse;
		} catch (error) {
			console.error('error in contractors api', JSON.stringify(error));
			throw error;
		}
	}

	//single contractor details
	public async getContractor(request: GetContractorRequest): Promise<GetContractorResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getContractorResponse = (await fetchWrapper(
				{
					path: `contractors/${request.contractorId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetContractorResponse>;

			return getContractorResponse.data;
		} catch (error) {
			console.error('error in contractors api', JSON.stringify(error));
			throw error;
		}
	}

	//update contractor data
	public async updateContractor(
		request: UpdateContractorRequest
	): Promise<UpdateContractorResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				contractor: {
					first_name: request.firstName,
					last_name: request.lastName,
					email: request.emailAddress,
					phone: request.phoneNumber,
					professions: request.professions,
					specializations: request.specializations,
				},
			};
			const updateContractorResponse = (await fetchWrapper(
				{
					path: `contractors/${request.contractorId}`,
					method: 'PATCH',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateContractorResponse>;

			return updateContractorResponse.data;
		} catch (error) {
			console.error('error in contractor api', JSON.stringify(error));
			throw error;
		}
	}
}
