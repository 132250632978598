import {
	GetContractorInviteResponse,
	GetContractorInvitesResponse,
} from '../types/api/ContractorInviteRequests';
import { adaptPaginationFromApi } from './paginationAdapter';
import { PaginatedList, UUID } from '../types/sharedTypes';
import { ContractorRequestProfession } from '../types/api/Contractors';
import { adaptProfessionFromApi, AdaptedProfession } from '../adapters/professionsAdapter';

export type AdaptedContractorInviteRequest = {
	id: UUID;
	date: Date | string;
	professions: AdaptedProfession[];
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
	smsOptedIn: boolean;
};

export type AdaptedContractorInviteList = PaginatedList<AdaptedContractorInviteRequest>;

//adapts single contractor invite request from api response
export const adaptContractorInviteFromApi = (
	invite: GetContractorInviteResponse
): AdaptedContractorInviteRequest => {
	return {
		id: invite.id,
		date: invite.attributes.submitted_at,
		professions: adaptContractorRequestProfessionsFromApi(
			invite.relationships.contractor_request_professions
		),
		firstName: invite.attributes.first_name,
		lastName: invite.attributes.last_name,
		emailAddress: invite.attributes.email,
		phoneNumber: invite.attributes.phone,
		smsOptedIn: invite.attributes.sms_opted_in,
	};
};

export const adaptContractorRequestProfessionsFromApi = (
	requestedProfessions: ContractorRequestProfession[]
): AdaptedProfession[] => {
	//remove any contractor professions that don't have relationship to profession
	//undefined preventing data from displaying
	return requestedProfessions
		.filter(requestedProfession => !!requestedProfession?.relationships?.profession)
		.map(requestedProfession =>
			adaptProfessionFromApi(requestedProfession?.relationships?.profession)
		);
};

//adapts list of contractor invite requests from api response
export const adaptContractorInvitesFromApi = (
	invites: GetContractorInvitesResponse
): AdaptedContractorInviteList => {
	return {
		pagination: adaptPaginationFromApi(invites.links),
		list: invites.data.map(invite => adaptContractorInviteFromApi(invite)),
	};
};
