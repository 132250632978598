import { ContractorSpecializationExperiences } from '../types/api/ContractorSpecializationExperiences';
import { USStates, UUID } from '../types/sharedTypes';
import {
	adaptContractorExperienceTagsFromApi,
	AdaptedContractorExperienceTag,
} from './contractorExperienceTagsAdapter';
import { AdaptedFacilityType, adaptFacilityTypeFromApi } from './facilityTypesAdapter';
import { AdaptedProfession, adaptProfessionFromApi } from './professionsAdapter';

export type AdaptedContractorSpecializationExperience = {
	id: UUID;
	specializationId: UUID;
	contractorId: UUID;
	reciprocityState: USStates;
	abbreviation: string;
	description: string;
	profession: AdaptedProfession;
	facilityType: AdaptedFacilityType;
	tags: AdaptedContractorExperienceTag[];
};

export const adaptContractorSpecializationExperiencesFromApi = (
	contractorSpecializationExperiences?: ContractorSpecializationExperiences[]
): AdaptedContractorSpecializationExperience[] => {
	if (!contractorSpecializationExperiences) {
		return [];
	}

	return contractorSpecializationExperiences
		.filter(specializationExperience => !!specializationExperience.relationships?.specialization)
		.map(specializationExperience => ({
			id: specializationExperience.id,
			specializationId: specializationExperience.attributes.specialization_id,
			contractorId: specializationExperience.attributes.contractor_id,
			reciprocityState: specializationExperience.attributes.reciprocity_state,
			abbreviation: specializationExperience.relationships.specialization.attributes.abbreviation,
			description: specializationExperience.relationships.specialization.attributes.description,
			profession: adaptProfessionFromApi(
				specializationExperience.relationships.specialization.relationships.profession
			),
			facilityType: adaptFacilityTypeFromApi(
				specializationExperience.relationships.specialization.relationships.facility_type
			),
			tags: adaptContractorExperienceTagsFromApi(
				specializationExperience.relationships.specialization.relationships.tags || []
			),
		}));
};
