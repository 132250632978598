import React, { useState } from 'react';
import { useAsync } from '../../hooks/useAsync';
import {
	NonAuthPageColumn,
	NonAuthPageContainer,
	NonAuthPageContentWrapper,
} from '../../components/Wrappers';
import { MainLogo } from '../../components/Logos';
import { ErrorMessage, InfoText, NonAuthFormTitle, SuccessMessage } from '../../components/Text';
import { NonAuthInputWrapper, UncontrolledTextInput } from '../../components/TextInputs';
import { removeWhiteSpace } from '../../utils/removeWhiteSpace';
import { NonAuthButton } from '../../components/Buttons';
import { isValidEmail } from '../../utils/validators';
import { ReturnHomeLink } from '../../components/Links';
import { webApiManager } from '../../network/apiManager';

type FormState = {
	emailAddress: string;
	error: string;
};

export const ForgotPassword = () => {
	const [formState, setFormState] = useState<FormState>({
		emailAddress: '',
		error: '',
	});
	const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

	const validateForm = () => {
		const validEmail = isValidEmail(formState.emailAddress);

		//if invalid password, set error
		if (!validEmail && !formState.error) {
			setFormState(prevState => {
				return { ...prevState, error: 'Please enter a valid email address.' };
			});
		} else if (validEmail && formState.error) {
			//clear error if valid
			setFormState(prevState => {
				return { ...prevState, error: '' };
			});
		}

		return validEmail;
	};

	const onSubmit = async () => {
		sendResetLink.clearError();
		try {
			await webApiManager.Auth.forgotPassword({ email: formState.emailAddress });
			setShowSuccessMessage(true);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const sendResetLink = useAsync<void>(
		onSubmit,
		'Error submitting request. Please check your connection and try again.'
	);

	return (
		<NonAuthPageContainer>
			<NonAuthPageColumn width={60}>
				<MainLogo width={'60%'} />
			</NonAuthPageColumn>

			<NonAuthPageColumn width={40}>
				<NonAuthPageContentWrapper>
					<NonAuthFormTitle>Forgot Password</NonAuthFormTitle>
					{showSuccessMessage ? (
						<SuccessMessage
							text={
								'Thank you, your request has been submitted. If the email address entered is associated with an account, you will receive an email with a link to reset your password.'
							}
						/>
					) : (
						<>
							<InfoText>
								{'Please enter your email address to receive a link to reset your password.'}
							</InfoText>
							<NonAuthInputWrapper>
								<UncontrolledTextInput
									type={'email'}
									placeholder={'Enter Email Address'}
									value={formState.emailAddress}
									onChange={e => {
										//revalidate if previous error
										if (formState.error) {
											validateForm();
										}
										setFormState(prevState => {
											const newVal = removeWhiteSpace(e.target.value);
											return { ...prevState, emailAddress: newVal };
										});
									}}
									onBlur={() => validateForm()}
									error={formState.emailAddress ? formState.error : ''}
									disabled={sendResetLink.pending}
								/>
							</NonAuthInputWrapper>
							<NonAuthButton
								text={'Submit'}
								disabled={!formState.emailAddress}
								onClick={() => {
									const formIsValid = validateForm();
									if (formIsValid) {
										sendResetLink.execute();
									}
								}}
								pending={sendResetLink.pending}
							/>
							{sendResetLink.error && <ErrorMessage text={sendResetLink.error} />}
						</>
					)}
					<ReturnHomeLink />
				</NonAuthPageContentWrapper>
			</NonAuthPageColumn>
		</NonAuthPageContainer>
	);
};
