import React, { ReactNode } from 'react';
import styled from 'styled-components';
import IconManager, { IconType } from './IconManager';
import { Theming } from '../theming';

type SidePanelProps = {
	isOpen: boolean;
	hide: () => void;
	closeButtonText?: string;
	children: ReactNode;
};

export const SidePanel = ({ isOpen, hide, closeButtonText, children }: SidePanelProps) => {
	return (
		<>
			<Overlay show={isOpen} onClick={hide} />
			<Panel show={isOpen}>
				<CloseButton onClick={hide}>
					<IconManager type={IconType.CHEVRON_LEFT} stroke={Theming.icons.primaryIconColor} />
					{!!closeButtonText && <SidePanelTitle>{closeButtonText}</SidePanelTitle>}
				</CloseButton>
				{isOpen && children}
			</Panel>
		</>
	);
};

const CloseButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: none;
	background: transparent;
	padding: 0;
	font-size: 1.1rem;
	margin-bottom: 20px;
	:hover& {
		cursor: pointer;
	}
`;

const Overlay = styled.div<{ show: boolean }>`
	display: ${({ show }) => (show ? 'block' : 'none')};
	background-color: rgba(0, 0, 0, 0.55);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 5;
`;

const Panel = styled.div<{ show: boolean }>`
	position: fixed;
	right: ${({ show }) => (show ? 0 : '-150vw')};
	top: 0;
	height: 100%;
	width: 50%;
	min-width: 400px;
	background-color: #fff;
	padding: 30px 40px;
	transition: all 0.3s ease-out;
	z-index: 10;
	overflow: auto;
`;

const SidePanelTitle = styled.p`
	font-family: ${Theming.text.boldFont};
	font-size: 18px;
	color: ${Theming.text.titleColor};
	padding: 0;
	margin: 0 0 0 10px;
`;
