import React from 'react';
import { PageSectionItem, PageSectionText } from './PageSection';
import { Rating } from './Rating';
import { AdaptedKudos, AdaptedPositiveFacilityLocationKudos } from '../adapters/ratingsAdapters';
import { PositiveFacilityLocationKudos, PositiveKudos } from '../types/api/Reviews';

export const Kudos = ({ kudos }: { kudos: AdaptedKudos | null }) => {
	return (
		<>
			<PageSectionItem flex>
				<PageSectionText>{PositiveKudos.caring}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveKudos.caring] : 0} kudos />
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveKudos.experienced}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveKudos.experienced] : 0} kudos />
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveKudos.professional}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveKudos.professional] : 0} kudos />
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveKudos.problemSolver}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveKudos.problemSolver] : 0} kudos />
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveKudos.strongCommunication}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveKudos.strongCommunication] : 0} kudos />
			</PageSectionItem>
		</>
	);
};

export const LocationKudos = ({
	kudos,
}: {
	kudos: AdaptedPositiveFacilityLocationKudos | null;
}) => {
	return (
		<>
			<PageSectionItem flex>
				<PageSectionText>{PositiveFacilityLocationKudos.strongManagement}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveFacilityLocationKudos.strongManagement] : 0} kudos />
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveFacilityLocationKudos.professionalEnvironment}</PageSectionText>
				<Rating
					rating={kudos ? kudos[PositiveFacilityLocationKudos.professionalEnvironment] : 0}
					kudos
				/>
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveFacilityLocationKudos.strongCommunication}</PageSectionText>
				<Rating
					rating={kudos ? kudos[PositiveFacilityLocationKudos.strongCommunication] : 0}
					kudos
				/>
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveFacilityLocationKudos.adequateResources}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveFacilityLocationKudos.adequateResources] : 0} kudos />
			</PageSectionItem>
			<PageSectionItem flex borderTop>
				<PageSectionText>{PositiveFacilityLocationKudos.friendlyStaff}</PageSectionText>
				<Rating rating={kudos ? kudos[PositiveFacilityLocationKudos.friendlyStaff] : 0} kudos />
			</PageSectionItem>
		</>
	);
};
