import { webApiManager } from './apiManager';
import { buildQueryString } from '../utils/buildQueryString';
import fetchWrapper from './fetchWrapper';
import {
	AddLocationUserRequest,
	AddLocationUserResponse,
	CreateLocationUserRequest,
	CreateLocationUserResponse,
	GetLocationUserRequest,
	GetLocationUserResponse,
	GetLocationUsersRequest,
	GetLocationUsersResponse,
	RemoveLocationUserRequest,
	RemoveLocationUserResponse,
	UpdateLocationUserRequest,
	UpdateLocationUserResponse,
} from '../types/api/LocationUsers';
import { ApiResponse } from '../types/api/SharedApiTypes';

export class LocationUsersApi {
	constructor(public apiManager: typeof webApiManager) {}

	//get all users for a location
	public async getLocationUsers(
		request: GetLocationUsersRequest
	): Promise<GetLocationUsersResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request.query);

			const getLocationUsersResponse = (await fetchWrapper(
				{
					path: `locations/${request.locationId}/location_users${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetLocationUsersResponse;

			return getLocationUsersResponse;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	//get single location user
	public async getLocationUser(request: GetLocationUserRequest): Promise<GetLocationUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getLocationUserResponse = (await fetchWrapper(
				{
					path: `/location_users/${request.locationUserId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetLocationUserResponse>;

			return getLocationUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	//update location user data
	public async updateLocationUser(
		request: UpdateLocationUserRequest
	): Promise<UpdateLocationUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const updateLocationUserResponse = (await fetchWrapper(
				{
					path: `/location_users/${request.locationUserId}`,
					method: 'PATCH',
					token,
					body: {
						location_user: request.updates,
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateLocationUserResponse>;

			return updateLocationUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	//create new location user
	public async createLocationUser(
		request: CreateLocationUserRequest
	): Promise<CreateLocationUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const createLocationUserResponse = (await fetchWrapper(
				{
					path: `/locations/${request.locationId}/location_users`,
					method: 'POST',
					token,
					body: {
						location_user: request.locationUser,
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<CreateLocationUserResponse>;

			return createLocationUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	//remove user from location
	public async removeLocationUser(
		request: RemoveLocationUserRequest
	): Promise<RemoveLocationUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const removeLocationUserResponse = (await fetchWrapper(
				{
					path: `/locations/${request.locationId}/location_users/remove_user`,
					method: 'DELETE',
					token,
					body: {
						location_user: {
							user_id: request.userId,
						},
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<RemoveLocationUserResponse>;

			return removeLocationUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}

	//add existing facility user to new location
	public async addLocationUser(request: AddLocationUserRequest): Promise<AddLocationUserResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const addLocationUserResponse = (await fetchWrapper(
				{
					path: `/locations/${request.locationId}/location_users/add_user`,
					method: 'POST',
					token,
					body: {
						location_user: {
							user_id: request.userId,
							roles: request.roles,
						},
					},
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<AddLocationUserResponse>;

			return addLocationUserResponse.data;
		} catch (error) {
			console.error('error in location users api', JSON.stringify(error));
			throw error;
		}
	}
}
