import {
	GetFacilityInviteResponse,
	GetFacilityInvitesResponse,
} from '../types/api/FacilityInviteRequests';
import { AdaptedFacilityType, adaptFacilityTypesFromApi } from './facilityTypesAdapter';
import { adaptPaginationFromApi } from './paginationAdapter';
import { PaginatedList, UUID } from '../types/sharedTypes';

export type AdaptedFacilityInviteRequest = {
	id: UUID;
	date: Date | string;
	facilityName: string;
	facilityTypes: AdaptedFacilityType[];
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
	smsOptedIn: boolean;
};

export type AdaptedFacilityInviteList = PaginatedList<AdaptedFacilityInviteRequest>;

//adapts single facility invite request
export const adaptFacilityInviteFromApi = (
	invite: GetFacilityInviteResponse
): AdaptedFacilityInviteRequest => {
	return {
		id: invite.id,
		date: invite.attributes.submitted_at,
		facilityName: invite.attributes.facility_name,
		facilityTypes: adaptFacilityTypesFromApi(invite.relationships.facility_types),
		firstName: invite.attributes.first_name,
		lastName: invite.attributes.last_name,
		emailAddress: invite.attributes.email,
		phoneNumber: invite.attributes.phone,
		smsOptedIn: invite.attributes.sms_opted_in,
	};
};

//adapts facility invite request list
export const adaptFacilityInvitesFromApi = (
	invites: GetFacilityInvitesResponse
): AdaptedFacilityInviteList => {
	return {
		pagination: adaptPaginationFromApi(invites.links),
		list: invites.data.map(invite => adaptFacilityInviteFromApi(invite)),
	};
};
