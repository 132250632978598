import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { ApiResponse } from '../types/api/SharedApiTypes';
import { GetFacilityTypesResponse } from '../types/api/FacilityTypes';

export class FacilityTypesApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getFacilityTypes(): Promise<GetFacilityTypesResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getFacilityTypesResponse = (await fetchWrapper(
				{
					path: `facility_types`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetFacilityTypesResponse>;

			return getFacilityTypesResponse.data;
		} catch (err) {
			console.error('error in facility types api', JSON.stringify(err));
			throw err;
		}
	}
}
