import { StatusType } from './types/sharedTypes';
import { ContractStatus, PaymentStatus } from './types/api/Contracts';

export const Theming = {
	gradients: {
		lightGradient: '#1AD6FD',
		darkGradient: '#1D62F0',
		mediumGradient: '#2098F5',
	},

	//Backgrounds
	backgrounds: {
		mainBackground: '#FFF',
		tabActive: '#F7F7F7',
		tabInactive: '#EBEAF5',
		pageBackgroundNoTabs: '#f7f7f7',
		inputBackground: '#F2F2F9',
		slidingDrawerBackground: '#FFF',
		tableHover: '#DCEAFD',
		pageSection: '#FFF',
		sectionTitle: '#DCEAFD',
	},

	//Buttons
	buttons: {
		primaryButtonColor: '#1f78f2',
		primaryButtonTextColor: '#FFF',
		secondaryButtonColor: '#1AD6FD',
		disabledButtonColor: '#93B6F9',
		cancelButtonColor: '#B2AFC3',
	},

	//Icons
	icons: {
		primaryIconColor: '#1f78f2',
		secondaryIconColor: '#B2B2B2',
		tertiaryIconColor: '#7AE6FE',
	},

	//Text
	text: {
		primaryTextColor: '#000',
		secondaryTextColor: '#2098f5',
		placeholderColor: '#A6A6A6',
		titleColor: '#414141',
		infoTextColor: '#818181',
		primaryLinkColor: '#2098F5',
		secondaryLinkColor: '#000',
		labelColor: '#6A6A6A',
		subTextColor: '#414141',

		//Fonts
		regularFont: 'LatoLight',
		boldFont: 'LatoBold',
		lightFont: 'LatoThin',
		semiBoldFont: 'OpenSans-SemiBold',
	},

	//Breakpoints
	breakpoints: {
		smallScreen: 600,
	},

	//Nav
	nav: {
		primaryNavBackground: '#1D62F0', //light/dark gradient set in image
		primaryNavLink: '#FFF',
		primaryNavTitle: '#FFF',
		subNavBackground: '#EBEAF5',
		subNavLink: '#1D62F0',
		subNavActiveLinkBackground: '#f5f4fa',
		subNavActiveLink: '#1D1B37',
		navTitleBackground: '#B2AFC3',
		navHeaderBackground: '#1D62F0',
		navTitleBorder: '#FFF',
		navTitle: '#000',
		navHeaderLink: '#FFF',
		navHeaderHeight: 50,
	},

	status: {
		[StatusType.active]: '#79D598',
		[StatusType.paused]: '#CA242D',
		[StatusType.deactivated]: '#6E6E6E',
		[ContractStatus.inProgress]: '#79D598',
		[ContractStatus.booked]: '#F4D03F',
		[ContractStatus.canceled]: '#CA242D',
		[ContractStatus.completed]: '#6E6E6E',
		[PaymentStatus.paid]: '#79D598',
		[PaymentStatus.notPaid]: '#CA242D',
		[PaymentStatus.partialPaid]: '#F4D03F',
	},

	//Table
	table: {
		cardHeaderColor: '#EBEAF5',
		cardBackgroundColor: '#F2F2F2',
		cardTextColor: '#000',
	},

	//Misc
	logoColor: '#FFF',
	errorColor: '#CA242D',
	warningColor: '#F4D03F',
	successColor: '#10B247',
	separatorColor: '#E6E5ED',
};
