import React from 'react';
import { PageTitle } from '../../../components/Text';
import { PageWrapper } from '../../../components/Wrappers';

export const ManageMessaging = () => {
	return (
		<PageWrapper>
			<PageTitle fontSize={14}>Manage Permissions</PageTitle>
		</PageWrapper>
	);
};
