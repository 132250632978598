import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import {
	GetContractorSpecializationExperiencesResponse,
	CreateContractorSpecializationExperiencesResponse,
	UpdateContractorSpecializationExperiencesResponse,
	DestroyContractorSpecializationExperiencesResponse,
	UpdateContractorSpecializationExperienceRequest,
	CreateContractorSpecializationExperienceRequest,
} from '../types/api/ContractorSpecializationExperiences';

export class ContractorSpecializationExperiencesApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getContractorSpecializationExperiences(): Promise<GetContractorSpecializationExperiencesResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const response = await fetchWrapper(
				{
					path: `contractor_specialization_experiences`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor experience specializations api', JSON.stringify(error));
			throw error;
		}
	}

	public async destroyContractorSpecializationExperiences(
		id
	): Promise<DestroyContractorSpecializationExperiencesResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const response = await fetchWrapper(
				{
					path: `contractor_specialization_experiences/${id}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor experience specializations api', JSON.stringify(error));
			throw error;
		}
	}

	public async createContractorSpecializationExperience(
		request: CreateContractorSpecializationExperienceRequest
	): Promise<CreateContractorSpecializationExperiencesResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				specialization_id: request.specializationId,
				experience_months: request.experienceMonths,
				contractor_id: request.contractorId,
			};

			const response = await fetchWrapper(
				{
					path: `contractor_specialization_experiences`,
					method: 'POST',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	public async updateContractorSpecializationExperience(
		request: UpdateContractorSpecializationExperienceRequest
	): Promise<UpdateContractorSpecializationExperiencesResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				specialization_id: request.specializationId,
				experience_months: request.experienceMonths,
				contractor_id: request.contractorId,
			};

			const response = await fetchWrapper(
				{
					path: `contractor_specialization_experiences/${request.id}`,
					method: 'PATCH',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}
}
