import { PaginatedList, StatusType, UUID } from '../types/sharedTypes';
import { AdaptedFacilityType, adaptFacilityTypesFromApi } from './facilityTypesAdapter';
import { AdaptedUser, adaptUserFromApi, UserType } from './userAdapter';
import { GetFacilitiesResponse, GetFacilityResponse, FacilityUser } from '../types/api/Facilities';
import { adaptPaginationFromApi } from './paginationAdapter';
import { AdaptedFacilityLocation, adaptLocationFromApi } from './locationsAdapters';
import { AdaptedPause, adaptPauseFromApi } from './pauseAdapter';

export type AdaptedFacility = {
	id: UUID;
	numLocations: number;
	numUsers: number;
	name: string;
	status: StatusType;
	facilityTypes: AdaptedFacilityType[];
	locations: AdaptedFacilityLocation[];
	facilityUsers: AdaptedFacilityUser[];
};

export type AdaptedFacilityList = PaginatedList<AdaptedFacility>;

export type AdaptedFacilityUser = AdaptedUser & {
	facilityId: UUID;
	userId: UUID;
	facilityUserId: UUID;
	pause: AdaptedPause | null;
	userType: UserType.facilityUser;
};

export const adaptFacilityFromApi = (facility: GetFacilityResponse): AdaptedFacility => {
	return {
		id: facility.id,
		name: facility.attributes.name,
		numLocations: facility.meta.number_of_locations,
		numUsers: facility.meta.number_of_users,
		status: facility.attributes.status,
		facilityTypes: adaptFacilityTypesFromApi(facility.relationships.facility_types),
		facilityUsers: facility.relationships.facility_users.map(adaptFacilityUserFromApi),
		locations:
			facility.relationships.locations?.map(location => adaptLocationFromApi(location)) ?? [],
	};
};

export const adaptFacilityUserFromApi = (facilityUser: FacilityUser): AdaptedFacilityUser => {
	return {
		facilityId: facilityUser.attributes.facility_id,
		userId: facilityUser.attributes.user_id,
		facilityUserId: facilityUser.id,
		pause: adaptPauseFromApi(facilityUser.relationships.resource_pauses),
		userType: UserType.facilityUser,
		...adaptUserFromApi(facilityUser.relationships.user),
	};
};

export const adaptFacilitiesFromApi = (facilities: GetFacilitiesResponse): AdaptedFacilityList => {
	return {
		pagination: adaptPaginationFromApi(facilities.links),
		list: facilities.data.map(facility => adaptFacilityFromApi(facility)),
	};
};
