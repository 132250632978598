import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import IconManager, { IconType } from '../../../components/IconManager';

export type BackLinkProps = {
	path: string;
	name: string;
};

export const BackLink = ({ path, name }: BackLinkProps) => {
	return (
		<Link to={path} style={{ textDecoration: 'none' }}>
			<BackLinkWrapper>
				<IconManager type={IconType.BACK_ARROW} size={16} stroke={'none'} fill={'#FFF'} />
				<StyledBackLink>{`Back to ${name}`}</StyledBackLink>
			</BackLinkWrapper>
		</Link>
	);
};

const BackLinkWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: auto;
	height: 100%;
	padding: 0 20px;
`;

const StyledBackLink = styled.p`
	color: #fff;
	font-size: 14px;
	font-family: ${Theming.text.regularFont};
	font-weight: bold;
	text-decoration: none;
	padding: 0 8px;
`;
