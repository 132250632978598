import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PageHeader } from '../../../../components/Wrappers';
import { LabeledSelect } from '../../../../components/Select';
import {
	ColumnTitle,
	EndResultsMessage,
	NoResultsMessage,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../../../components/Table';
import { formatDate } from '../../../../utils/formatDate';
import IconManager, { IconType } from '../../../../components/IconManager';
import LoadingSpinner from '../../../../components/Loader';
import { ErrorMessage } from '../../../../components/Text';
import { AdaptedContractorInviteRequest } from '../../../../adapters/contractorInvitesAdapters';
import { ContractorInviteSortOptions } from '../../../../types/api/ContractorInviteRequests';
import { TabWithTableWrapper } from '../../../../components/Tabs';

type ContractorInviteListProps = {
	setSelectedInviteRequestId: (inviteRequest: AdaptedContractorInviteRequest['id']) => void;
	inviteList: AdaptedContractorInviteRequest[] | undefined;
	requestPending: boolean;
	requestError: string | null;
	setSortBy: Dispatch<SetStateAction<ContractorInviteSortOptions | null>>;
	getNextPage: () => void;
	showEndResultsMessage: boolean;
};

export const ContractorInviteList = ({
	setSelectedInviteRequestId,
	inviteList,
	requestError,
	requestPending,
	setSortBy,
	getNextPage,
	showEndResultsMessage,
}: ContractorInviteListProps) => {
	const [tableData, setTableData] = useState<AdaptedContractorInviteRequest[] | undefined>(
		inviteList
	);

	useEffect(() => {
		//reset table data when changes are made to invite list
		setTableData(inviteList);
	}, [inviteList]);

	return (
		<TabWithTableWrapper>
			<PageHeader>
				<LabeledSelect
					label={'Sort by:'}
					options={
						tableData
							? [
									{ value: ContractorInviteSortOptions.date, label: 'Date' },
									{ value: ContractorInviteSortOptions.lastName, label: 'Last Name' },
									{ value: ContractorInviteSortOptions.firstName, label: 'First Name' },
									{ value: ContractorInviteSortOptions.profession, label: 'Profession' },
							  ]
							: []
					}
					onChange={newVal => setSortBy(newVal)}
				/>
			</PageHeader>
			<Table
				tableTitle={'Contractor Invite Requests'}
				tableHeader={
					<>
						<ColumnTitle>Date</ColumnTitle>
						<ColumnTitle>Last Name</ColumnTitle>
						<ColumnTitle>First Name</ColumnTitle>
						<ColumnTitle>Profession</ColumnTitle>
						<ColumnTitle>{''}</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!tableData ? (
							<TableRow hideBorder>
								<TableItem colSpan={5}>
									{requestPending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={requestError ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{tableData.length === 0 ? (
									<NoResultsMessage colSpan={5} />
								) : (
									<>
										{tableData.map((item, index) => {
											return (
												<TableRow key={index}>
													<TableItem>{formatDate(item.date)}</TableItem>
													<TableItem textWrap>{item.lastName}</TableItem>
													<TableItem textWrap>{item.firstName}</TableItem>
													<TableItem textWrap>
														{[
															...new Set(
																item.professions.map(
																	professionExperience => professionExperience.code
																)
															),
														].join(', ')}
													</TableItem>
													<TableItem bold>
														<TableLinkWrapper onClick={() => setSelectedInviteRequestId(item.id)}>
															<TableLink>Review</TableLink>
															<IconManager
																type={IconType.CHEVRON_RIGHT}
																stroke={'#999'}
																size={14}
																strokeWidth={3}
															/>
														</TableLinkWrapper>
													</TableItem>
												</TableRow>
											);
										})}
										{showEndResultsMessage && <EndResultsMessage colSpan={5} />}
									</>
								)}
							</>
						)}
					</>
				}
				onScrollToBottom={getNextPage}
			/>
		</TabWithTableWrapper>
	);
};
