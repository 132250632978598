import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FacilityUserForm } from './FacilityUserForm';
import { useAsync } from '../../../../../hooks/useAsync';
import LoadingSpinner from '../../../../../components/Loader';
import { webApiManager } from '../../../../../network/apiManager';
import { ErrorMessage } from '../../../../../components/Text';
import { EditStatus } from '../../../../../components/EditStatus';
import { FormSectionTitle, SidePanelFormWrapper } from '../../../../../components/Form';
import { BoldSeparator } from '../../../../../components/Separator';
import { StatusType, UUID } from '../../../../../types/sharedTypes';
import {
	AdaptedFacilityUser,
	adaptFacilityUserFromApi,
} from '../../../../../adapters/facilityAdapters';

type FacilityUserDetailsProps = {
	selectedFacilityUserId: UUID | null;
	setSelectedFacilityUserId: Dispatch<SetStateAction<UUID | null>>;
};

type UpdateFacilityUserData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
};

export const FacilityUserDetails = ({ selectedFacilityUserId }: FacilityUserDetailsProps) => {
	const [selectedFacilityUser, setSelectedFacilityUser] = useState<AdaptedFacilityUser | null>(
		null
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	//fetch selected user
	useEffect(() => {
		getSelectedUser.execute();
		return () => getSelectedUser.clearError();
	}, []);

	useEffect(() => {
		//clear any update or submit errors when re-editing
		if (isEditing) {
			updateUser.clearError();
		}
	}, [isEditing]);

	const onLoad = async () => {
		getSelectedUser.clearError();
		try {
			if (selectedFacilityUserId) {
				//get selected user
				const selectedUser = adaptFacilityUserFromApi(
					await webApiManager.Facilities.getFacilityUser({
						facilityUserId: selectedFacilityUserId,
					})
				);
				//set adapted data to state
				setSelectedFacilityUser(selectedUser);
			} else {
				throw new Error('Could not find selected invite request.');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onUpdate = async (formData: UpdateFacilityUserData) => {
		setIsEditing(false);
		try {
			if (selectedFacilityUser) {
				const updateRequest = {
					facilityUserId: selectedFacilityUser.facilityUserId,
					updates: {
						first_name: formData.firstName,
						last_name: formData.lastName,
						email: formData.email,
						phone: formData.phone,
					},
				};
				const updatedUser = adaptFacilityUserFromApi(
					await webApiManager.Facilities.updateFacilityUser(updateRequest)
				);
				setSelectedFacilityUser(updatedUser);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getSelectedUser = useAsync<void>(
		onLoad,
		'Error getting user data. Please check your connection and try again.'
	);
	const updateUser = useAsync<void>(
		onUpdate,
		'Error updating data. Please check your connection and try again.'
	);

	return (
		<SidePanelFormWrapper>
			{getSelectedUser.pending ? (
				<LoadingSpinner />
			) : (
				<>
					{!selectedFacilityUser ? (
						<ErrorMessage text={getSelectedUser.error ?? 'Could not find user.'} />
					) : (
						<>
							<FormSectionTitle>Edit Account Status</FormSectionTitle>
							<BoldSeparator />
							<EditStatus user={selectedFacilityUser} onChange={() => getSelectedUser.execute()} />
							<FacilityUserForm
								selectedFacilityUser={selectedFacilityUser}
								isEditing={isEditing}
								setIsEditing={setIsEditing}
								onSave={formData => updateUser.execute(formData)}
								hideButton
								hideEdit={selectedFacilityUser.status === StatusType.deleted}
							/>
							{updateUser.error && <ErrorMessage text={updateUser.error} />}
						</>
					)}
				</>
			)}
		</SidePanelFormWrapper>
	);
};
