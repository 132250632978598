//capitalizes first letter of each word in a string
export const capitalizeString = (sentence: string) => {
	if (!sentence) {
		return '';
	}
	const words = sentence
		.trim()
		.split(' ')
		.filter(s => s);

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0]?.toUpperCase() + words[i].slice(1);
	}

	return words.join(' ');
};
