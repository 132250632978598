import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { Theming } from '../theming';

type MonthYearSelectProps = {
	onChange: (newVal: Date | null) => void;
	label: string;
	disabled: boolean;
};

export const MonthYearSelect = ({ onChange, label, disabled }: MonthYearSelectProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	useEffect(() => {
		onChange(selectedDate);
	}, [selectedDate]);

	return (
		<DateSelectWrapper>
			{label && <DateSelectLabel>{label}</DateSelectLabel>}
			<DateSelect
				selected={selectedDate}
				onChange={(newDate: Date | null) => setSelectedDate(newDate)}
				dateFormat="MMMM yyyy"
				showMonthYearPicker
				showFullMonthYearPicker
				disabled={disabled}
			/>
		</DateSelectWrapper>
	);
};

export const DateSelectWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 50px;
`;

export const DateSelectLabel = styled.div`
	display: inline-block;
	margin: 10px;
	text-align: left;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.labelColor};
	font-weight: bold;
	white-space: nowrap;
`;

export const DateSelect = styled(DatePicker)`
	margin: 10px;
	padding: 5px;
	font-family: ${Theming.text.regularFont};
	font-size: 14px;
	font-color: ${Theming.text.primaryTextColor};
	border: 1px solid ${Theming.text.placeholderColor};
	border-radius: 5px;
`;
