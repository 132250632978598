export enum TabHash {
	list = '#list',
	test = '#test',
	all = '#all',
	messaging = '#messaging',
	contractor = '#contractor',
	facility = '#facility',
	overview = '#overview',
	locations = '#locations',
	permissions = '#permissions',
	messages = '#messages',
	account = '#account',
	settings = '#settings',
	data = '#data',
}

export enum UrlParams {
	contractorId = ':contractorId',
	facilityId = ':facilityId',
	userId = ':userId',
	passwordToken = ':passwordToken',
	locationId = ':locationId',
}

export type UrlParamsType = {
	contractorId: string;
	facilityId: string;
	userId: string;
	passwordToken: string;
	locationId: string;
};

export const AuthRouteNames = {
	//Facilities
	Facilities: '/facilities', //to manage active nav links (no component)
	ManageFacilities: '/facilities/manage',
	FacilityDetails: `/facilities/manage/${UrlParams.facilityId}`,
	FacilityInvites: '/facilities/invites',

	//Facility Location
	ManageLocation: `/facilities/manage/${UrlParams.facilityId}/locations/${UrlParams.locationId}`,

	//Contractors
	Contractors: '/contractors', //to manage active nav links (no component)
	ManageContractors: '/contractors/manage',
	ContractorDetails: `/contractors/manage/${UrlParams.contractorId}`,
	ContractorInvites: '/contractors/invites',

	//Contracts
	Contracts: '/contracts',

	//Deleted users
	DeletedUsers: '/user-archive',

	//Settings
	MobileAppSettings: '/mobile-settings',
	AdminSettings: '/admin-settings',
	Account: '/account',
};

export const NonAuthRouteNames = {
	Login: '/',
	Offline: '/offline',
	CreateAccount: '/create-account', //TODO: update path when backend sets up link (v2?)
	ForgotPassword: '/forgot-password',
	ResetPassword: `/password_resets/${UrlParams.passwordToken}/edit`,
	MobileRedirect: `/mobile`,
};
