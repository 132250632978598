import { PaginatedList } from '../types/sharedTypes';
import { AdaptedUser, adaptUserFromApi } from './userAdapter';
import { adaptPaginationFromApi } from './paginationAdapter';
import { GetAdminsResponse } from '../types/api/Auth';

export type AdaptedAdmin = AdaptedUser;
export type AdaptedAdminList = PaginatedList<AdaptedAdmin>;

//adapts list of contractors from api response
export const adaptAdminsFromApi = (admins: GetAdminsResponse): AdaptedAdminList => {
	return {
		pagination: adaptPaginationFromApi(admins.links),
		list: admins.data.map(admin => adaptUserFromApi(admin)),
	};
};
