import React, { useContext, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { NavTitle, NavTitleWrapper } from './NavTitle';
import { AuthRouteNames, UrlParamsType } from '../../../navigation/routeNames';
import { NavLinks } from './NavBar';
import { BackLink } from './BackLink';
import IconManager, { IconType } from '../../../components/IconManager';
import { HeaderNameContext } from '../../../context/HeaderNameContext';
import { adaptFacilityFromApi } from '../../../adapters/facilityAdapters';
import { webApiManager } from '../../../network/apiManager';
import { useAsync } from '../../../hooks/useAsync';
import { capitalizeString } from '../../../utils/capitalizeString';

type NavHeaderProps = {
	title: NavLinks | null;
	subNavOpen: boolean;
};

//to display in nav header
enum PathNames {
	CONTRACTS = 'Home \\ Contracts',
	FACILITIES = 'Home \\ Facilities \\ Manage Facilities',
	FACILITY_INVITES = 'Home \\ Facilities \\ Manage Invites',
	CONTRACTORS = 'Home \\ Contractors \\ Manage Contractors',
	CONTRACTOR_INVITES = 'Home \\ Contractors \\ Manage Invites',
	DELETED_USERS = 'Home \\ User Archive',
	MOBILE_APP_SETTINGS = 'Home \\ Mobile App Settings',
	ADMIN_SETTINGS = 'Home \\ Admin Settings',
	ACCOUNT = 'Home \\ My Account',
}

//get display text for nav path
const getPathName = (path: string) => {
	switch (path) {
		case AuthRouteNames.Contracts:
			return PathNames.CONTRACTS;
		case AuthRouteNames.ManageFacilities:
			return PathNames.FACILITIES;
		case AuthRouteNames.FacilityInvites:
			return PathNames.FACILITY_INVITES;
		case AuthRouteNames.ManageContractors:
			return PathNames.CONTRACTORS;
		case AuthRouteNames.ContractorInvites:
			return PathNames.CONTRACTOR_INVITES;
		case AuthRouteNames.DeletedUsers:
			return PathNames.DELETED_USERS;
		case AuthRouteNames.MobileAppSettings:
			return PathNames.MOBILE_APP_SETTINGS;
		case AuthRouteNames.AdminSettings:
			return PathNames.ADMIN_SETTINGS;
		case AuthRouteNames.Account:
			return PathNames.ACCOUNT;
	}
};

export const NavHeader = ({ subNavOpen, title }: NavHeaderProps) => {
	const currentPath = useLocation().pathname;
	const pathName = getPathName(currentPath);
	const { headerName } = useContext(HeaderNameContext);
	const [backLink, setBackLink] = useState<{ path: string; name: string } | null>(null);

	useEffect(() => {
		//match path
		const locationPath = matchPath(currentPath, { path: AuthRouteNames.ManageLocation });
		const contractorDetailsPath = matchPath(currentPath, {
			path: AuthRouteNames.ContractorDetails,
			exact: true,
		});
		const facilityDetailsPath = matchPath(currentPath, {
			path: AuthRouteNames.FacilityDetails,
			exact: true,
		});

		//for any location details page
		if (locationPath) {
			getFacility.execute(locationPath.params);
			//for any contractor details page
		} else if (contractorDetailsPath) {
			setBackLink({ path: AuthRouteNames.ManageContractors, name: 'Contractor List' });
			//for any facility details page
		} else if (facilityDetailsPath) {
			setBackLink({ path: AuthRouteNames.ManageFacilities, name: 'Facility List' });
		} else {
			setBackLink(null);
		}
	}, [currentPath]);

	//get facility id & name and set back link
	const onFetch = async (params: UrlParamsType) => {
		try {
			if (params.facilityId) {
				const facility = adaptFacilityFromApi(
					await webApiManager.Facilities.getFacility({ facilityId: params.facilityId })
				);
				setBackLink({
					name: capitalizeString(facility.name),
					path: `${AuthRouteNames.ManageFacilities}/${params.facilityId}`,
				});
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getFacility = useAsync<void>(onFetch);

	return (
		<NavHeaderContainer>
			{!subNavOpen && !!title && (
				<NavTitleWrapper showMenu={subNavOpen}>
					<NavTitle>{title}</NavTitle>
				</NavTitleWrapper>
			)}
			{!!backLink && <BackLink path={backLink.path} name={backLink.name} />}
			{!!headerName && <HeaderName>{headerName.toUpperCase()}</HeaderName>}
			{!!pathName && <NavPath>{pathName}</NavPath>}
			<NavHeaderLink href={'https://analysis.carefiller.io/login.php'} target={'blank'}>
				Pricing Management &nbsp;
				<IconManager type={IconType.EXTERNAL_LINK} stroke={'#FFF'} size={12} />
			</NavHeaderLink>
		</NavHeaderContainer>
	);
};

const NavHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: ${Theming.nav.navHeaderBackground};
	margin: 0;
	padding: 0;
	width: auto;
	box-sizing: border-box;
	height: ${Theming.nav.navHeaderHeight}px;
`;

const NavPath = styled.p`
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	margin: 0;
	padding: 0 20px;
	color: ${Theming.nav.navHeaderLink};
	white-space: nowrap;
`;

const NavHeaderLink = styled.a`
	flex: 1;
	color: ${Theming.nav.navHeaderLink};
	font-size: 14px;
	font-family: ${Theming.text.regularFont};
	text-decoration: none;
	text-align: right;
	padding: 0 20px;
	:hover& {
		cursor: pointer;
	}
`;

const HeaderName = styled.div`
	text-align: center;
	font-family: ${Theming.text.boldFont};
	font-size: 16px;
	margin: 0;
	padding: 0 20px;
	color: ${Theming.nav.navHeaderLink};
	white-space: nowrap;
	flex: 1;
`;
