import React from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';

type TextProps = {
	text: string;
	textAlign?: 'center' | 'left' | 'right' | 'justify';
	bold?: boolean;
};

export const ErrorMessage = ({ text, textAlign, bold }: TextProps) => {
	return (
		<StyledErrorMessage textAlign={textAlign} bold={bold}>
			{text}
		</StyledErrorMessage>
	);
};

export const SuccessMessage = ({ text }: TextProps) => {
	return <StyledSuccessMessage>{text}</StyledSuccessMessage>;
};

const StyledErrorMessage = styled.p<{
	textAlign?: 'center' | 'left' | 'right' | 'justify';
	bold?: boolean;
}>`
	color: ${Theming.errorColor};
	font-family: ${({ bold }) => (bold ? Theming.text.boldFont : Theming.text.regularFont)};
	font-weight: bold;
	font-size: 14px;
	margin: 5px auto;
	text-align: ${({ textAlign }) => textAlign ?? 'center'};
	white-space: pre-wrap;
`;

const StyledSuccessMessage = styled.p`
	color: ${Theming.successColor};
	font-family: ${Theming.text.regularFont};
	font-weight: bold;
	font-size: 14px;
	margin: 20px auto;
	text-align: center;
	white-space: pre-wrap;
`;

export const NonAuthFormTitle = styled.p<{ color?: string }>`
	text-transform: uppercase;
	margin: 0 auto 20px;
	text-align: center;
	font-family: ${Theming.text.boldFont};
	color: ${({ color }) => color ?? Theming.text.titleColor};
	font-size: 18px;
`;

export const InfoText = styled.p<{
	fontSize?: number;
	margin?: string;
	color?: string;
	textAlign?: string;
}>`
	margin: ${({ margin }) => margin ?? '10px auto'};
	text-align: ${({ textAlign }) => textAlign ?? 'center'};
	font-family: ${Theming.text.regularFont};
	color: ${({ color }) => color ?? Theming.text.primaryTextColor};
	font-size: ${({ fontSize }) => fontSize ?? 14}px;
`;

export const PageTitle = styled.p<{ textAlign?: string; fontSize?: number }>`
	font-size: ${({ fontSize }) => fontSize ?? 15}px;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.titleColor};
	text-align: ${({ textAlign }) => textAlign ?? 'left'};
`;
