import { Tag } from '../types/api/ContractorExperienceTags';
import { UUID } from '../types/sharedTypes';

export type AdaptedContractorExperienceTag = {
	id: UUID;
	name: string;
};

export const adaptContractorExperienceTagsFromApi = (
	contractorExperienceTags: Tag[]
): AdaptedContractorExperienceTag[] => {
	return contractorExperienceTags
		.map(tag => {
			return {
				id: tag.id,
				name: tag.attributes.name,
			};
		})
		.sort((a, b) => a.name.localeCompare(b.name));
};
