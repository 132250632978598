import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';

type RadioInputProps = {
	options: RadioInputOption[];
	onChange: (newVal: RadioInputOption['value']) => void;
	disabled?: boolean;
	initialValue?: RadioInputOption['value'];
	disableOption?: RadioInputOption['value'];
};

type RadioInputOption = {
	label: string;
	value: any;
};

export const RadioInputGroup = ({
	options,
	onChange,
	disabled,
	initialValue,
	disableOption,
}: RadioInputProps) => {
	const [selectedOption, setSelectedOption] = useState<string>(initialValue);

	useEffect(() => {
		onChange(selectedOption);
	}, [selectedOption]);

	return (
		<RadioGroupWrapper>
			{options.map((option, index) => {
				const optionDisabled = disabled || disableOption === option.value;
				return (
					<InputWrapper
						key={index}
						onClick={() => (!optionDisabled ? setSelectedOption(option.value) : null)}
						selected={!optionDisabled ? selectedOption === option.value : false}
						disabled={optionDisabled}
					>
						<RadioInput
							type={'radio'}
							value={option.value}
							checked={selectedOption === option.value}
							disabled={optionDisabled}
							readOnly
						/>
						<RadioInputLabel disabled={optionDisabled}>{option.label}</RadioInputLabel>
					</InputWrapper>
				);
			})}
		</RadioGroupWrapper>
	);
};

const RadioGroupWrapper = styled.div`
	margin: 30px auto;
`;

const InputWrapper = styled.div<{ selected: boolean; disabled: boolean }>`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border: ${({ selected }) => (selected ? '2px solid #2284f2' : '2px solid #B2AFC3')};
	border-radius: 5px;
	margin: 15px auto;
	width: 100%;
	padding: 8px 16px;
	:hover& {
		cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
	}
`;

const RadioInput = styled.input`
	box-sizing: border-box;
	display: inline-block;
	margin: 0 auto;
	width: 20px;
	height: 20px;
	:hover& {
		cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
	}
`;

const RadioInputLabel = styled.p<{ disabled: boolean }>`
	box-sizing: border-box;
	text-align: center;
	flex: 1;
	margin: 0 auto;
	font-family: ${Theming.text.boldFont};
	color: ${({ disabled }) => (!disabled ? Theming.text.titleColor : Theming.text.placeholderColor)};
	font-size: 18px;
	:hover& {
		cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
	}
`;
