import React, { useContext, useState } from 'react';
import { PageWrapperNoTabs } from '../../components/Wrappers';
import { ErrorMessage } from '../../components/Text';
import { UserContext } from '../../context/UserContext';
import styled from 'styled-components';
import { Theming } from '../../theming';
import { TableBody, TableRow } from '../../components/Table';
import { FormLink } from '../../components/Links';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { ControlledTextInput } from '../../components/TextInputs';
import { useForm } from 'react-hook-form';
import { useAsync } from '../../hooks/useAsync';
import { MiniLoadingSpinner } from '../../components/Loader';
import { stripSpecialChars } from '../../utils/stripSpecialCharacters';
import { BaseButton } from '../../components/Buttons';
import { SidePanel } from '../../components/SidePanel';
import { webApiManager } from '../../network/apiManager';
import { adaptUserFromApi } from '../../adapters/userAdapter';
import { UpdatePassword } from './components/UpdatePassword';

type FormValues = {
	phoneNumber: string;
};

export const Account = () => {
	const { currentUser, setCurrentUser } = useContext(UserContext);

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showSidebar, setShowSidebar] = useState<boolean>(false);

	const formMethods = useForm<FormValues>({
		defaultValues: {
			phoneNumber: formatPhoneNumber(currentUser?.phone) ?? '',
		},
		reValidateMode: 'onChange',
	});

	const onSave = async (data: { phone: string }) => {
		saveProfile.clearError();
		try {
			//disable editing
			setIsEditing(false);
			const updateUserResponse = adaptUserFromApi(await webApiManager.Users.updateProfile(data));
			// update user in context
			if (updateUserResponse) {
				setCurrentUser(updateUserResponse);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const saveProfile = useAsync<void>(onSave, 'Error saving phone number');

	return (
		<PageWrapperNoTabs padding={'25px 0'}>
			<AccountSection>
				<SectionTitle>Basic Information</SectionTitle>
				{!currentUser ? (
					<ErrorMessage text={'Could not find user'} />
				) : (
					<SmallTable>
						<TableBody>
							<TableRow hideHover>
								<RowTitle>Full Name</RowTitle>
								<SmallTableItem>{`${currentUser.firstName} ${currentUser.lastName}`}</SmallTableItem>
							</TableRow>
							<TableRow hideHover>
								<RowTitle>Email Address</RowTitle>
								<SmallTableItem>{currentUser.email}</SmallTableItem>
							</TableRow>
							<TableRow hideBorder hideHover>
								<RowTitle>Phone Number</RowTitle>
								{saveProfile.pending ? (
									<SmallTableItem rowSpan={2}>
										<MiniLoadingSpinner />
									</SmallTableItem>
								) : (
									<>
										{!isEditing ? (
											<>
												<SmallTableItem>{formatPhoneNumber(currentUser.phone)}</SmallTableItem>
												<SmallTableItem>
													<FormLink margin={'6px 10px 7px'} onClick={() => setIsEditing(true)}>
														Edit
													</FormLink>
												</SmallTableItem>
											</>
										) : (
											<>
												<SmallTableItem>
													<ControlledTextInput
														margin={'0'}
														padding={'5px'}
														register={() =>
															formMethods.register('phoneNumber', {
																required: 'Required',
																pattern: {
																	//prettier-ignore
																	value: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
																	message: 'Invalid phone number',
																},
															})
														}
														placeholder={'Phone Number'}
														disabled={!isEditing}
														error={formMethods.formState.errors.phoneNumber?.message}
													/>
												</SmallTableItem>
												<SmallTableItem>
													<FormLink
														margin={'5px 10px'}
														secondary
														onClick={() => {
															//on cancel, reset formState back to saved data
															formMethods.reset();
															setIsEditing(false);
														}}
													>
														Cancel
													</FormLink>
													<FormLink
														margin={'5px 10px'}
														onClick={formMethods.handleSubmit((data: FormValues) => {
															//remove special characters from phone number and add '1' prefix if needed
															const strippedPhone = stripSpecialChars(data.phoneNumber);
															const phoneWithPrefix =
																strippedPhone[0] === '1' ? strippedPhone : `1${strippedPhone}`;
															saveProfile.execute({ phone: phoneWithPrefix });
														})}
													>
														Save
													</FormLink>
												</SmallTableItem>
											</>
										)}
									</>
								)}
							</TableRow>
						</TableBody>
					</SmallTable>
				)}
				{saveProfile.error && <ErrorMessage text={saveProfile.error} textAlign={'left'} />}
			</AccountSection>
			<AccountSection>
				<SectionTitle>Password</SectionTitle>
				<SectionInfo>
					A strong password should contain a mix of numbers, letters, and symbols. It should be hard
					to guess and not resemble a real word.
				</SectionInfo>
				<BaseButton
					text={'Update Password'}
					disabled={!currentUser}
					onClick={() => setShowSidebar(true)}
				/>
			</AccountSection>
			<SidePanel isOpen={showSidebar} hide={() => setShowSidebar(false)}>
				<UpdatePassword />
			</SidePanel>
		</PageWrapperNoTabs>
	);
};

const AccountSection = styled.div`
	margin: 25px 20px 25px 100px;
	text-align: left;
`;

const SectionTitle = styled.p`
	font-size: 15px;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.titleColor};
	padding: 0 0 15px;
	text-align: left;
	margin: 0;
`;

const SectionInfo = styled.p`
	font-size: 13px;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.titleColor};
	padding: 5px 0;
	text-align: left;
	margin: 0;
	max-width: 500px;
`;

const SmallTable = styled.table`
	background-color: #fff;
	width: auto;
	padding: 0;
	border-collapse: collapse;
	color: ${Theming.text.primaryTextColor};
	text-align: left;
	margin: 0;
	box-shadow: 1px 1px 3px 1px rgba(0, 0, 41, 0.1);
	border-radius: 8px;
	overflow: hidden;
`;

const RowTitle = styled.th`
	background-color: #f5f4fa;
	padding: 15px;
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	white-space: nowrap;
`;

const SmallTableItem = styled.td`
	font-family: ${Theming.text.regularFont};
	padding: 15px;
	font-size: 14px;
	white-space: nowrap;
	color: inherit;
`;
