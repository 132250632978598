import { FacilityLocation, GetLocationsResponse } from '../types/api/Locations';
import { PaginatedList, StatusType, UUID } from '../types/sharedTypes';
import { adaptPaginationFromApi } from './paginationAdapter';
import { AdaptedFacilityType, adaptFacilityTypeFromApi } from './facilityTypesAdapter';
import { LocationUserRole } from '../types/api/LocationUsers';
import { AdaptedLocationUser, adaptLocationUserFromApi } from './locationUsersAdapters';
import {
	AdaptedPositiveFacilityLocationKudos,
	AdaptedRating,
	adaptLocationKudosFromApi,
	adaptRatingFromApi,
} from './ratingsAdapters';
import { AdaptedPause, adaptPauseFromApi } from './pauseAdapter';

export type AdaptedFacilityLocation = {
	locationId: UUID;
	facilityId: UUID;
	name: string;
	status: StatusType;
	streetAddress: string;
	city: string;
	state: string;
	zipCode: string;
	locationAdmin: AdaptedLocationUser | null;
	locationUsers: AdaptedLocationUser[];
	facilityType: AdaptedFacilityType | null;
	kudos: AdaptedPositiveFacilityLocationKudos | null;
	rating: AdaptedRating;
	completedContracts: number;
	canceledContracts: number;
	channelName: string;
	pause: AdaptedPause | null;
};

export type AdaptedLocationList = PaginatedList<AdaptedFacilityLocation>;

export const adaptLocationFromApi = (location: FacilityLocation): AdaptedFacilityLocation => {
	const users = location.relationships?.location_users;
	const admin = users
		? users.find(user => user.attributes.roles.includes(LocationUserRole.manager))
		: null;
	const facilityType = location.relationships?.facility_type;
	return {
		locationId: location.id,
		facilityId: location.attributes.facility_id,
		name: location.attributes.name,
		status: location.attributes.status,
		streetAddress: location.attributes.street_address,
		city: location.attributes.city,
		state: location.attributes.state,
		zipCode: location.attributes.zip_code,
		locationUsers: users?.map(user => adaptLocationUserFromApi(user)) ?? [],
		locationAdmin: admin ? adaptLocationUserFromApi(admin) : null,
		facilityType: facilityType ? adaptFacilityTypeFromApi(facilityType) : null,
		kudos: adaptLocationKudosFromApi(location.meta.kudos),
		rating: adaptRatingFromApi(location.meta.rating),
		completedContracts: location.meta.completed_contracts_count,
		canceledContracts: location.meta.cancelled_contracts_count,
		channelName: location.attributes.pubnub_channel_name,
		pause: adaptPauseFromApi(location.relationships?.resource_pauses),
	};
};

export const adaptLocationsFromApi = (locations: GetLocationsResponse): AdaptedLocationList => {
	return {
		pagination: adaptPaginationFromApi(locations.links),
		list: locations.data.map(location => adaptLocationFromApi(location)),
	};
};
