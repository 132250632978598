import React, {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { webApiManager } from '../network/apiManager';
import { AdaptedUser } from '../adapters/userAdapter';

type UserContextType = {
	currentUser: CurrentUser;
	userLoading: boolean;
	userAuthorized: boolean;
	setUserLoading: Dispatch<SetStateAction<boolean>>;
	setCurrentUser: Dispatch<SetStateAction<CurrentUser>>;
	setUserAuthorized: Dispatch<SetStateAction<boolean>>;
};

export type CurrentUser = AdaptedUser | null | undefined;

export const UserContext = createContext<UserContextType>({
	currentUser: undefined,
	userLoading: false,
	userAuthorized: false,
	setUserLoading: () => {},
	setCurrentUser: () => {},
	setUserAuthorized: () => {},
});

export default function UserContextProvider({ children }: { children: ReactNode }) {
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const [currentUser, setCurrentUser] = useState<CurrentUser>(undefined);
	const [userAuthorized, setUserAuthorized] = useState<boolean>(false);

	useEffect(() => {
		//login/refresh logic
		(async () => {
			try {
				if (currentUser) {
					setUserLoading(false);
				} else if (currentUser === undefined) {
					//refresh user if valid token exists
					// await webApiManager.Auth.refresh({ setCurrentUser, setUserAuthorized })
					await webApiManager.Auth.getCurrentUser({}, setCurrentUser, setUserAuthorized);
					setUserLoading(false);
				} else {
					setUserAuthorized(false);
					setUserLoading(false);
				}
			} catch (error) {
				console.error(error);
				setCurrentUser(null);
				setUserAuthorized(false);
				setUserLoading(false);
			}
		})();
	}, [currentUser]);

	return (
		<UserContext.Provider
			value={{
				currentUser,
				userLoading,
				setUserLoading,
				setCurrentUser,
				userAuthorized,
				setUserAuthorized,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}
