import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BaseButton } from '../../../../components/Buttons';
import { useAsync } from '../../../../hooks/useAsync';
import { ErrorMessage } from '../../../../components/Text';
import LoadingSpinner from '../../../../components/Loader';
import { Theming } from '../../../../theming';
import { Modal } from '../../../../components/Modal';
import { SelectOption } from '../../../../components/Select';
import { webApiManager } from '../../../../network/apiManager';
import { adaptFacilityTypesFromApi } from '../../../../adapters/facilityTypesAdapter';
import {
	AdaptedFacilityInviteRequest,
	adaptFacilityInviteFromApi,
} from '../../../../adapters/facilityInvitesAdapters';
import { FacilityInviteRequestForm } from './FacilityInviteRequestForm';
import { UUID } from '../../../../types/sharedTypes';

type FacilityInviteRequestProps = {
	selectedInviteId: AdaptedFacilityInviteRequest['id'] | null;
	setSelectedInviteId: Dispatch<SetStateAction<AdaptedFacilityInviteRequest['id'] | null>>;
};

type UpdateInviteData = {
	facilityName: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
	facilityTypeIds: UUID[];
};

export const FacilityInviteRequest = ({
	selectedInviteId,
	setSelectedInviteId,
}: FacilityInviteRequestProps) => {
	const [invite, setInvite] = useState<AdaptedFacilityInviteRequest | null>(null);
	const [facilityTypeOptions, setFacilityTypeOptions] = useState<SelectOption[]>([]);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(false);

	//fetch selected invite
	useEffect(() => {
		getSelectedInvite.execute();
	}, []);

	useEffect(() => {
		//clear any update or submit errors when re-editing
		if (isEditing) {
			updateInviteRequest.clearError();
			setupAccount.clearError();
		}
	}, [isEditing]);

	const onLoad = async () => {
		getSelectedInvite.clearError();
		try {
			if (selectedInviteId) {
				//get selected invite
				const selectedInvite = adaptFacilityInviteFromApi(
					await webApiManager.FacilityInviteRequests.getInviteRequest({
						facilityId: selectedInviteId,
					})
				);
				//get list of facility types
				const facilityTypesList = adaptFacilityTypesFromApi(
					await webApiManager.FacilityTypes.getFacilityTypes()
				);

				//format facility types into select options
				const facilityTypeSelectOptions = facilityTypesList.map(facilityType => {
					return {
						label: facilityType.name,
						value: facilityType.id,
					};
				});

				//set options to state
				setFacilityTypeOptions(facilityTypeSelectOptions);
				setInvite(selectedInvite);
			} else {
				throw new Error('Could not find selected invite request.');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onUpdate = async (data: UpdateInviteData) => {
		updateInviteRequest.clearError();
		try {
			//disable form
			setIsEditing(false);
			//send updates to server and update state on success
			if (invite) {
				const updatedInvite = adaptFacilityInviteFromApi(
					await webApiManager.FacilityInviteRequests.updateInviteRequest({
						...data,
						facilityId: invite.id,
					})
				);
				setInvite(updatedInvite);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onSubmit = async () => {
		setupAccount.clearError();
		try {
			if (invite) {
				await webApiManager.FacilityInviteRequests.approveInviteRequest({ facilityId: invite.id });
				//close sidebar
				setSelectedInviteId(null);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onDelete = async () => {
		deleteRequest.clearError();
		try {
			if (invite) {
				//send delete request to server
				await webApiManager.FacilityInviteRequests.deleteInviteRequest({ facilityId: invite.id });
				//close modal and sidebar
				setShowWarning(false);
				setSelectedInviteId(null);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getSelectedInvite = useAsync<void>(
		onLoad,
		'Error getting form data. Please check your connection and try again.'
	);
	const updateInviteRequest = useAsync<void>(
		(data: UpdateInviteData) => onUpdate(data),
		'Error: Data could not be saved.'
	);
	const setupAccount = useAsync<void>(onSubmit, 'Error: Account setup failed.');
	const deleteRequest = useAsync<void>(onDelete, 'Error: Could not delete invite request.');

	return (
		<>
			{getSelectedInvite.pending || updateInviteRequest.pending || setupAccount.pending ? (
				<LoadingSpinner />
			) : (
				<>
					{!invite ? (
						<ErrorMessage text={getSelectedInvite.error ?? 'Error: Invite request not found.'} />
					) : (
						<>
							<FacilityInviteRequestForm
								invite={invite}
								facilityTypeOptions={facilityTypeOptions}
								updateInvite={(formData: UpdateInviteData) => updateInviteRequest.execute(formData)}
								isEditing={isEditing}
								setIsEditing={setIsEditing}
							/>
							{updateInviteRequest.error && <ErrorMessage text={updateInviteRequest.error} />}
							{setupAccount.error && <ErrorMessage text={setupAccount.error} />}
							{deleteRequest.error && <ErrorMessage text={deleteRequest.error} />}
							<BaseButton
								text={'DELETE REQUEST'}
								margin={'40px 10px'}
								backgroundColor={Theming.errorColor}
								onClick={() => setShowWarning(true)}
							/>
							<BaseButton
								text={'SETUP ACCOUNT'}
								margin={'40px 10px'}
								disabled={isEditing || !invite.facilityName}
								onClick={setupAccount.execute}
							/>
							<Modal
								isOpen={showWarning}
								hide={() => setShowWarning(false)}
								onConfirm={deleteRequest.execute}
								title={`Delete invite request for ${invite.facilityName}?`}
								text={'This action cannot be undone.'}
								cancelButtonText={'Cancel'}
								confirmButtonText={'Confirm Delete'}
								confirmButtonColor={Theming.errorColor}
								pending={deleteRequest.pending}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
