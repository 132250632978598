import { webApiManager } from './apiManager';
import {
	CreateLocationRequest,
	CreateLocationResponse,
	GetLocationRequest,
	GetLocationResponse,
	GetLocationsRequest,
	GetLocationsResponse,
	SetLocationStatusRequest,
	SetLocationStatusResponse,
	UpdateLocationRequest,
	UpdateLocationResponse,
} from '../types/api/Locations';
import fetchWrapper from './fetchWrapper';
import { buildQueryString } from '../utils/buildQueryString';
import { ApiResponse } from '../types/api/SharedApiTypes';

export class LocationsApi {
	constructor(public apiManager: typeof webApiManager) {}

	//get all locations for a facility
	public async getLocations(request: GetLocationsRequest): Promise<GetLocationsResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request.query);

			const getLocationsResponse = (await fetchWrapper(
				{
					path: `facilities/${request.facilityId}/locations${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetLocationsResponse;

			return getLocationsResponse;
		} catch (error) {
			console.error('error in locations api', JSON.stringify(error));
			throw error;
		}
	}

	//get single location
	public async getLocation(request: GetLocationRequest): Promise<GetLocationResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getLocationResponse = (await fetchWrapper(
				{
					path: `/locations/${request.locationId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetLocationResponse>;

			return getLocationResponse.data;
		} catch (error) {
			console.error('error in locations api', JSON.stringify(error));
			throw error;
		}
	}

	public async updateLocation(request: UpdateLocationRequest): Promise<UpdateLocationResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const updateLocationResponse = (await fetchWrapper(
				{
					path: `/locations/${request.locationId}`,
					method: 'PUT',
					body: { location: request.updates },
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateLocationResponse>;

			return updateLocationResponse.data;
		} catch (error) {
			console.error('error in locations api', JSON.stringify(error));
			throw error;
		}
	}

	//change location status
	public async setLocationStatus(
		request: SetLocationStatusRequest
	): Promise<SetLocationStatusResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const updateStatusResponse = (await fetchWrapper(
				{
					path: `locations/${request.locationId}/update_status`,
					method: 'PATCH',
					body: { status: request.status },
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<SetLocationStatusResponse>;

			return updateStatusResponse.data;
		} catch (error) {
			console.error('error in locations api', JSON.stringify(error));
			throw error;
		}
	}

	//create new location
	public async createLocation(request: CreateLocationRequest): Promise<CreateLocationResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const createStatusResponse = (await fetchWrapper(
				{
					path: `facilities/${request.facilityId}/locations`,
					method: 'POST',
					body: { location: request.location },
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<CreateLocationResponse>;

			return createStatusResponse.data;
		} catch (error) {
			console.error('error in locations api', JSON.stringify(error));
			throw error;
		}
	}
}
