import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import {
	GetFacilityTypeProfessionExperienceRulesResponse,
	UpdateFacilityTypeProfessionExperienceRuleRequest,
	UpdateFacilityTypeProfessionExperienceRuleResponse,
} from '../types/api/FacilityTypeProfessionExperienceRules';

export class FacilityTypeProfessionExperienceRulesApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getFacilityTypeProfessionExperienceRules(): Promise<GetFacilityTypeProfessionExperienceRulesResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getFacilityTypeProfessionExperienceRulesResponse = (await fetchWrapper(
				{
					path: `facility_type_profession_experience_rules`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetFacilityTypeProfessionExperienceRulesResponse;

			return getFacilityTypeProfessionExperienceRulesResponse.data;
		} catch (error) {
			console.error(
				'error in facility type profession experience rules api',
				JSON.stringify(error)
			);
			throw error;
		}
	}

	//approve invite request (sends notification to user)
	public async updateFacilityTypeProfessionExperienceRule(
		request: UpdateFacilityTypeProfessionExperienceRuleRequest
	): Promise<UpdateFacilityTypeProfessionExperienceRuleResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				experience_threshold: request.experienceThreshold,
			};

			const response = await fetchWrapper(
				{
					path: `facility_type_profession_experience_rules/${request.id}`,
					method: 'PATCH',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			);

			return response.data;
		} catch (error) {
			console.error(
				'error in facility type profession experience rules api',
				JSON.stringify(error)
			);
			throw error;
		}
	}
}
