import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { ApiResponse } from '../types/api/SharedApiTypes';
import {
	ApproveContractorInviteRequest,
	DeleteContractorInviteRequest,
	GetContractorInviteRequest,
	GetContractorInviteResponse,
	GetContractorInvitesRequest,
	GetContractorInvitesResponse,
	UpdateContractorInviteRequest,
	UpdateContractorInviteResponse,
} from '../types/api/ContractorInviteRequests';
import { buildQueryString } from '../utils/buildQueryString';

export class ContractorInviteRequestsApi {
	constructor(public apiManager: typeof webApiManager) {}

	//list of all facility invite requests (default returns only unapproved requests- optionally can return all)
	public async getInviteRequests(
		request: GetContractorInvitesRequest
	): Promise<GetContractorInvitesResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getInvitesResponse = (await fetchWrapper(
				{
					path: `contractor_requests${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetContractorInvitesResponse;

			return getInvitesResponse;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//single contractor invite request
	public async getInviteRequest(
		request: GetContractorInviteRequest
	): Promise<GetContractorInviteResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getInviteResponse = (await fetchWrapper(
				{
					path: `contractor_requests/${request.contractorId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetContractorInviteResponse>;

			return getInviteResponse.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//update data from invite request before approval
	public async updateInviteRequest(
		request: UpdateContractorInviteRequest
	): Promise<UpdateContractorInviteResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				contractor_request: {
					first_name: request.firstName,
					last_name: request.lastName,
					email: request.emailAddress,
					phone: request.phoneNumber,
					professions: request.professions?.map(profession => {
						return {
							profession_id: profession.professionId,
							is_experienced: profession.isExperienced,
						};
					}),
				},
			};
			const updateInviteResponse = (await fetchWrapper(
				{
					path: `contractor_requests/${request.contractorId}`,
					method: 'PATCH',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdateContractorInviteResponse>;

			return updateInviteResponse.data;
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	// deny/remove invite request
	public async deleteInviteRequest(request: DeleteContractorInviteRequest): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();

			await fetchWrapper(
				{
					path: `contractor_requests/${request.contractorId}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			);
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}

	//approve invite request (sends notification to user)
	public async approveInviteRequest(request: ApproveContractorInviteRequest): Promise<void> {
		try {
			const token = await this.apiManager.getValidToken();

			await fetchWrapper(
				{
					path: `contractor_requests/${request.contractorId}/approve`,
					method: 'POST',
					token,
					body: {
						contractor_request: {
							professions: request.professions.map(professionExperience => ({
								profession_id: professionExperience.professionId,
								facility_type_id: professionExperience.facilityTypeId,
								experience_months: professionExperience.experienceMonths,
								reciprocity_state: professionExperience.reciprocityState,
							})),
						},
					},
				},
				this.apiManager.rootApiUrl
			);
		} catch (error) {
			console.error('error in contractor invite requests api', JSON.stringify(error));
			throw error;
		}
	}
}
