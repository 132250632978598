import {
	Contract,
	ContractStatus,
	ContractTime,
	GetContractsResponse,
	PaymentStatus,
} from '../types/api/Contracts';
import { PaginatedList, UUID } from '../types/sharedTypes';
import { adaptPaginationFromApi } from './paginationAdapter';
import getDay from 'date-fns/getDay';

export type AdaptedContract = {
	id: UUID;
	displayId: UUID;
	startDate: string | Date;
	endDate: string | Date;
	contractStatus: ContractStatus;
	postedDate: string | Date;
	reports: number;
	locationName: string;
	facilityId: UUID;
	contractorName: string;
	contractorId: UUID;
	hourlyRate: string;
	locationId: UUID;
	schedule: AdaptedContractSchedule;
	paymentStatus: PaymentStatus;
	channelName: string;
};

export type AdaptedContractList = PaginatedList<AdaptedContract>;

export type AdaptedContractSchedule = {
	monday: ScheduleTimes | null;
	tuesday: ScheduleTimes | null;
	wednesday: ScheduleTimes | null;
	thursday: ScheduleTimes | null;
	friday: ScheduleTimes | null;
	saturday: ScheduleTimes | null;
	sunday: ScheduleTimes | null;
};

type ScheduleTimes = {
	startTime: string | null;
	endTime: string | null;
};

export const adaptContractFromApi = (contract: Contract): AdaptedContract => {
	return {
		id: contract.id,
		displayId: contract.attributes.display_id,
		startDate: contract.attributes.start_date,
		endDate: contract.attributes.end_date,
		locationName: contract.relationships.location.attributes.name,
		locationId: contract.relationships.location.id,
		facilityId: contract.relationships.location.attributes.facility_id,
		contractorName: `${contract.relationships.contractor.relationships.user.attributes.first_name} ${contract.relationships.contractor.relationships.user.attributes.last_name}`,
		contractorId: contract.relationships.contractor.id,
		hourlyRate: Number(contract.attributes.hourly_rate).toFixed(),
		contractStatus: contract.meta.status,
		paymentStatus: contract.meta.payment_status,
		postedDate: contract.meta.posted_at,
		reports: !contract.relationships.reported_problems
			? 0
			: contract.relationships.reported_problems.length,
		schedule: adaptContractSchedule(contract.relationships.contract_instance_times),
		channelName: contract.attributes.pubnub_channel_name,
	};
};

export const adaptContractsFromApi = (contracts: GetContractsResponse): AdaptedContractList => {
	return {
		pagination: adaptPaginationFromApi(contracts.links),
		list: contracts.data.map(contract => adaptContractFromApi(contract)),
	};
};

const adaptContractSchedule = (contractTimes: ContractTime[]): AdaptedContractSchedule => {
	const shifts = contractTimes.reduce(
		(previousValue, currentValue) => {
			// new Date off by 1 day due to time zone stuff. Using '/' instead of '-'
			// puts the date into local time.
			// https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
			const dayOfWeek = getDay(new Date(currentValue.attributes.shift_date.replace(/-/g, '/')));
			const startTime = getTime(Number(currentValue.attributes.start_time));
			const endTime = getTime(Number(currentValue.attributes.end_time));
			return { ...previousValue, [dayOfWeek]: { startTime, endTime } };
		},
		{
			0: null,
			1: null,
			2: null,
			3: null,
			4: null,
			5: null,
			6: null,
		}
	);
	const schedule = {
		sunday: shifts[0],
		monday: shifts[1],
		tuesday: shifts[2],
		wednesday: shifts[3],
		thursday: shifts[4],
		friday: shifts[5],
		saturday: shifts[6],
	};
	return schedule;
};

//converts number 1-24 into time of day and formats string (ex. 14.5 => '2:30pm')
const getTime = (timeNumber: number) => {
	const timeOfDay = timeNumber > 12 ? 'PM' : 'AM';
	const decimal = timeNumber % 1;
	const integer = timeNumber - decimal;
	const minutes = Math.round(decimal * 60)
		.toString()
		.padStart(2, '0');
	const hours = integer > 12 ? integer - 12 : integer;
	return `${hours}${decimal ? `:${minutes}` : ''}${timeOfDay}`;
};
