import React, { Dispatch, SetStateAction, useState } from 'react';
import { ErrorMessage, PageTitle } from '../../../../components/Text';
import { adaptContractorFromApi, AdaptedContractor } from '../../../../adapters/contractorAdapters';
import { Separator } from '../../../../components/Separator';
import { UpdateContractorForm } from '../../components/UpdateContractorForm';
import { useAsync } from '../../../../hooks/useAsync';
import LoadingSpinner from '../../../../components/Loader';
import { webApiManager } from '../../../../network/apiManager';
import { EditStatus } from '../../../../components/EditStatus';

type ContractorAccountTabProps = {
	selectedContractor: AdaptedContractor;
	setSelectedContractor: Dispatch<SetStateAction<AdaptedContractor | null>>;
	refreshSelectedContractor: () => void;
	pendingRefresh: boolean;
};

export const ContractorAccountTab = ({
	selectedContractor,
	setSelectedContractor,
	refreshSelectedContractor,
	pendingRefresh,
}: ContractorAccountTabProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const onUpdate = async data => {
		updateContractor.clearError();
		try {
			//disable form
			setIsEditing(false);
			//send updates to server and update state on success
			const updateContractorResponse = adaptContractorFromApi(
				await webApiManager.Contractors.updateContractor(data)
			);
			setSelectedContractor(updateContractorResponse);
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const updateContractor = useAsync<void>(
		onUpdate,
		'Error updating contractor. Please check your connection and try again.'
	);

	return (
		<>
			<PageTitle>Edit Account Status</PageTitle>
			<EditStatus
				user={selectedContractor}
				isPending={pendingRefresh}
				onChange={refreshSelectedContractor}
			/>
			<Separator />
			{updateContractor.pending ? (
				<LoadingSpinner />
			) : (
				<UpdateContractorForm
					contractorData={selectedContractor}
					isEditing={isEditing}
					setIsEditing={setIsEditing}
					updateContractorData={formData => updateContractor.execute(formData)}
				/>
			)}
			{!!updateContractor.error && <ErrorMessage text={updateContractor.error} />}
		</>
	);
};
