import React, { useEffect, useState } from 'react';
import { useAsync } from '../../../hooks/useAsync';
import { PageWrapper } from '../../../components/Wrappers';
import { ErrorMessage, PageTitle } from '../../../components/Text';
import styled from 'styled-components';
import { adaptProfessionsFromApi } from '../../../adapters/professionsAdapter';
import { adaptFacilityTypesFromApi } from '../../../adapters/facilityTypesAdapter';
import LoadingSpinner from '../../../components/Loader';
import { Separator } from '../../../components/Separator';
import { webApiManager } from '../../../network/apiManager';
import { Theming } from '../../../theming';
import { UUID } from '../../../types/sharedTypes';

type TypesListProps = {
	listType: ListType;
};

export enum ListType {
	contractor = 'Contractor',
	facility = 'Facility',
}

export const TypesList = ({ listType }: TypesListProps) => {
	const [list, setList] = useState<Array<{ id: UUID; name: string; code?: string }> | null>(null);

	useEffect(() => {
		getTypes.execute();
	}, []);

	const onLoad = async () => {
		try {
			if (listType === ListType.contractor) {
				const adaptedProfessionsList = adaptProfessionsFromApi(
					await webApiManager.Professions.getProfessions()
				);
				setList(adaptedProfessionsList);
			} else if (listType === ListType.facility) {
				const adaptedFacilityTypesList = adaptFacilityTypesFromApi(
					await webApiManager.FacilityTypes.getFacilityTypes()
				);
				setList(adaptedFacilityTypesList);
			} else {
				throw new Error('Invalid request, list type does not exist.');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getTypes = useAsync<void>(
		onLoad,
		'Error getting types. Please check your connection and try again.'
	);

	return (
		<PageWrapper>
			<PageTitle fontSize={14}>{`Manage ${listType} Types`}</PageTitle>
			<ListWrapper>
				<ListHeader>
					{'Available Types'}
					<Separator margin={'10px auto 0'} />
				</ListHeader>
				{!list ? (
					<>
						{getTypes.pending ? (
							<LoadingSpinner />
						) : (
							<ErrorMessage text={getTypes.error ?? 'Error: Could not find types.'} />
						)}
					</>
				) : (
					<>
						{list.map((item, index) => (
							<ListItem key={index}>
								{item.code ? `${item?.code}: ` : ''}
								{item.name}
							</ListItem>
						))}
					</>
				)}
			</ListWrapper>
		</PageWrapper>
	);
};

const ListWrapper = styled.div`
	background-color: #fff;
	width: 50%;
	padding: 20px 0 0;
`;

const ListHeader = styled.div`
	padding: 0 20px;
	width: 100%;
	font-family: ${Theming.text.boldFont};
	font-size: 13px;
	color: ${Theming.text.titleColor};
`;

const ListItem = styled.p`
	text-transform: uppercase;
	font-family: ${Theming.text.regularFont};
	font-size: 13px;
	color: ${Theming.text.titleColor};
	width: 100%;
	padding: 10px 20px;
	margin: 0;
	:hover& {
		background-color: ${Theming.backgrounds.tableHover};
	}
`;
