import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { TableMultiSelect } from '../../../components/Select';
import DateFNSFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { TableRowSpacer } from '../../../components/Table';
import { BaseButton } from '../../../components/Buttons';
import { AdaptedFacility, AdaptedFacilityType } from '../../../adapters/facilityAdapters';
import { USStates, UUID } from '../../../types/sharedTypes';
import { AdaptedContractorExperienceSpecialization } from '../../../adapters/contractorExperienceSpecializationAdapter';
import { EditButton } from '../../../components/Buttons';
import { AdaptedContractorProfessionExperience } from '../../../adapters/contractorProfessionExperiencesAdapter';
import {
	ToggleEditableField,
	ToggleEditableFieldElementType,
	Placeholder,
} from '../../../components/ToggleEditableField';
import { AdaptedProfession } from '../../../adapters/professionsAdapter';

type ProfessionCardProps = {
	specializations: AdaptedContractorExperienceSpecialization[];
	specializationExperience;
	selectedState: USStates;
	facilityTypes: AdaptedFacilityType[];
	profession: AdaptedProfession;
	professionExperience?: AdaptedContractorProfessionExperience[];
	isEditing: boolean;
	isEditingAvailable: boolean;
	isInviteMode?: boolean;
	onEdit: (profession: AdaptedProfession) => void;
	onSave: (professionId: UUID) => void;
	onChange: (experienceUpdate: ExperienceUpdate) => void;
	onChangeSpecializations: (specializationIds: UUID[]) => void;
	onDelete: () => void;
};

type ExperienceUpdateForFacility = {
	facilityTypeId: UUID;
	facilityTypeProfessionExperienceRuleId?: UUID;
	experienceMonths?: number;
	approvalExpirationDate?: string;
	notes?: string;
};

export type ExperienceUpdate = ExperienceUpdateForFacility & {
	professionId: UUID;
	reciprocityState: USStates;
};

export const ProfessionCard = ({
	specializations = [],
	specializationExperience,
	selectedState,
	facilityTypes,
	profession,
	professionExperience,
	isInviteMode = false,
	isEditing,
	isEditingAvailable,
	onEdit,
	onChange,
	onChangeSpecializations = () => {},
	onSave,
	onDelete,
}: ProfessionCardProps) => {
	const allSpecializationsBySpecializationId = useMemo(() => {
		const byId = {};

		specializations.forEach(specialization => {
			byId[specialization.id] = specialization;
		});

		return byId;
	}, [specializations]);

	const allTagsBySpecializationAndTagId = useMemo(() => {
		const byId = {};

		specializations.forEach(specialization => {
			byId[specialization.id] = {
				specializationId: specialization.id,
				byTagId: {},
			};

			specialization.tags.forEach(tag => {
				byId[specialization.id].byTagId[tag.id] = {
					specializationId: specialization.id,
					specializationAbbreviation: specialization.abbreviation,
					tagId: tag.id,
					tagName: tag.name,
					combinedName: specialization.abbreviation + ': ' + tag.name,
					combinedId: specialization.id + ':' + tag.id,
				};
			});
		});

		return byId;
	}, [specializations]);

	const allSpecializationsByFacilityId = useMemo(() => {
		const byId = {};

		specializations.forEach(specialization => {
			if (!byId[specialization.facilityTypeId]) {
				byId[specialization.facilityTypeId] = [];
			}

			byId[specialization.facilityTypeId].push(specialization);
		});

		return byId;
	}, [specializations]);

	const getValue = (facility: AdaptedFacility, valueName: string) => {
		return professionExperience?.byState[selectedState]?.byFacilityTypeId[facility.id]?.[valueName];
	};

	const getContractorSpecializationValues = (facility: AdaptedFacility) => {
		const specializationIds =
			specializationExperience?.byState[selectedState]?.byFacilityTypeId[facility.id]
				?.specializationIds || [];

		return specializationIds.map(id => allSpecializationsBySpecializationId[id]);
	};

	const getContractorTagValues = (facility: AdaptedFacility) => {
		const contractorTagsBySpecializationId =
			specializationExperience?.byState[selectedState]?.byFacilityTypeId[facility.id]
				?.tagsBySpecializationId || null;

		if (contractorTagsBySpecializationId === null) {
			return [];
		}

		return Object.keys(contractorTagsBySpecializationId).flatMap(specializationId => {
			if (
				!allTagsBySpecializationAndTagId[specializationId] ||
				!contractorTagsBySpecializationId[specializationId]
			) {
				return [];
			}

			return contractorTagsBySpecializationId[specializationId].map(
				tagId => allTagsBySpecializationAndTagId[specializationId].byTagId[tagId]
			);
		});
	};

	// -2 because -1 already automatically updates to 0 in other functions, and the value needs
	// to be -1 for those functions to work correctly, hence why we can't update that value here.
	const updateExperience = (updatedExperience: ExperienceUpdateForFacility) => {
		if (updatedExperience.experienceMonths && updatedExperience.experienceMonths <= -2) {
			updatedExperience.experienceMonths = 0;
		}
		onChange({
			...updatedExperience,
			professionId: profession.id,
			reciprocityState: selectedState,
		});
	};

	const updateSpecializations = updatedSpecializations => {
		onChangeSpecializations({
			...updatedSpecializations,
			professionId: profession.id,
			reciprocityState: selectedState,
		});
	};

	const updateTags = updatedTags => {
		onChangeSpecializations({
			...updatedTags,
			professionId: profession.id,
			reciprocityState: selectedState,
		});
	};

	return (
		<>
			<TableRowSpacer height={20} backgroundColor={'#FFF'} colSpan={isInviteMode ? 2 : 6} />
			<CardHeader>
				<CardTitle colSpan={isInviteMode ? 2 : 6}>
					{`${selectedState} ${profession.name}`}
					{!isInviteMode ? (
						<CardFloating textAlign={'right'}>
							{!isEditing ? (
								<EditButton disabled={!isEditingAvailable} onClick={() => onEdit(profession)} />
							) : (
								<BaseButton margin={'0'} text={'SAVE'} onClick={() => onSave(profession.id)} />
							)}
							{!isEditing && !isInviteMode ? (
								<DeleteButton disabled={!isEditingAvailable} onClick={onDelete} />
							) : null}
						</CardFloating>
					) : null}
				</CardTitle>
			</CardHeader>
			<TableRowSpacer
				height={12}
				backgroundColor={Theming.table.cardBackgroundColor}
				colSpan={isInviteMode ? 2 : 6}
			/>
			{facilityTypes.map(facility => {
				const allSpecializationsForFacility = allSpecializationsByFacilityId[facility.id] || [];
				const contractorSpecializationValues = getContractorSpecializationValues(facility);
				const contractorTagValues = getContractorTagValues(facility);
				const tagOptions = contractorSpecializationValues.flatMap(specialization => {
					if (!specialization) {
						return [];
					}

					return specialization.tags.map(tag => ({
						label: specialization.abbreviation + ': ' + tag.name,
						value: specialization.id + ':' + tag.id,
					}));
				});
				const experienceMonthsValue = getValue(facility, 'experienceMonths');
				// Contractors can have an experience record even with zero hours of experience
				// To handle this we use "-1" to represent removing the experience record from the
				// contractor. We will convert this to null when sending to the API
				const experienceMonths = Number.isFinite(experienceMonthsValue)
					? experienceMonthsValue
					: -1;

				// isChecked represents that the contractor should have an experience record, or put
				// another way, the checkbox next to the facility type is checked!
				const isChecked = experienceMonths !== -1;

				// This means the user has entered edit mode AND they have selected this row item
				// (meaning the user wants this contractor to have this experience row)
				const isCheckedAndEditing = isEditing && isChecked;

				return (
					<CardRow key={facility.name}>
						<CardItem
							alignLeft
							bold
							color={isChecked ? Theming.text.primaryTextColor : Theming.text.placeholderColor}
						>
							<CheckboxWithText isEditing={isEditing || isInviteMode}>
								{isEditing || isInviteMode ? (
									<input
										type="checkbox"
										checked={isChecked}
										onChange={event =>
											updateExperience({
												facilityTypeId: facility.id,
												experienceMonths: event.target.checked ? 0 : -1,
											})
										}
									></input>
								) : null}
								<span>{facility.name}</span>
							</CheckboxWithText>
						</CardItem>
						<CardItem>
							<ToggleEditableField
								elementType={ToggleEditableFieldElementType.INPUT}
								isEditing={isCheckedAndEditing || (isChecked && isInviteMode)}
								value={experienceMonths || 0}
								renderText={value => {
									if (value === -1) {
										return <Placeholder>(None)</Placeholder>;
									}

									return `${value} Month${value === 1 ? '' : 's'}`;
								}}
								type="number"
								min={0}
								step={1}
								onChange={newValue =>
									updateExperience({
										facilityTypeId: facility.id,
										experienceMonths: parseInt(newValue) || 0,
									})
								}
							/>
						</CardItem>
						{!isInviteMode ? (
							<CardItem>
								<ToggleEditableField
									elementType={ToggleEditableFieldElementType.INPUT}
									isEditing={isCheckedAndEditing}
									value={getValue(facility, 'approvalExpirationDate') || ''}
									renderText={value => DateFNSFormat(parseISO(value), 'MM/dd/yyyy')}
									type="date"
									placeholder="(No expiration)"
									onChange={newValue =>
										updateExperience({
											facilityTypeId: facility.id,
											approvalExpirationDate: newValue || null,
										})
									}
								/>
							</CardItem>
						) : null}
						{!isInviteMode ? (
							<CardItem>
								{isCheckedAndEditing && allSpecializationsForFacility.length > 0 ? (
									<TableMultiSelect
										options={allSpecializationsForFacility.map(v => ({
											label: v.abbreviation,
											value: v.id,
										}))}
										value={contractorSpecializationValues.map(v => v.id)}
										placeholder={'Specializations...'}
										onChange={specializationIds => {
											updateSpecializations({
												facilityTypeId: facility.id,
												specializationIds,
											});
										}}
									/>
								) : (
									<>
										{contractorSpecializationValues.length > 0 ? (
											<span>
												{contractorSpecializationValues
													.map(v => v.abbreviation)
													.sort()
													.join(', ')}
											</span>
										) : (
											<Placeholder>(None)</Placeholder>
										)}
									</>
								)}
							</CardItem>
						) : null}
						{!isInviteMode ? (
							<CardItem>
								{isCheckedAndEditing && tagOptions.length > 0 ? (
									<TableMultiSelect
										placeholder={'Tags'}
										options={tagOptions}
										value={contractorTagValues.map(tag => tag.combinedId)}
										// value={['2:4']}
										onChange={combinedIds => {
											const tagsBySpecializationId = {};
											combinedIds.forEach(combinedId => {
												const [specializationId, tagId] = combinedId.split(':');

												if (!tagsBySpecializationId[specializationId]) {
													tagsBySpecializationId[specializationId] = [];
												}

												tagsBySpecializationId[specializationId].push(tagId);
											});

											updateTags({
												facilityTypeId: facility.id,
												tagsBySpecializationId,
											});
										}}
										disabled={!isCheckedAndEditing}
									/>
								) : contractorTagValues.length > 0 ? (
									<span>
										{contractorTagValues
											.map(tag => tag.combinedName)
											.sort()
											.join(', ')}
									</span>
								) : (
									<Placeholder>(None)</Placeholder>
								)}
							</CardItem>
						) : null}
						{!isInviteMode ? (
							<CardItem>
								{isCheckedAndEditing || !!getValue(facility, 'notes') ? (
									<ToggleEditableField
										elementType={ToggleEditableFieldElementType.INPUT}
										isEditing={isCheckedAndEditing || isInviteMode}
										value={getValue(facility, 'notes') || ''}
										placeholder="Notes..."
										onChange={newValue =>
											updateExperience({
												facilityTypeId: facility.id,
												notes: newValue || null,
											})
										}
									/>
								) : (
									<Placeholder>(None)</Placeholder>
								)}
							</CardItem>
						) : null}
					</CardRow>
				);
			})}
			<TableRowSpacer
				height={12}
				backgroundColor={Theming.table.cardBackgroundColor}
				colSpan={isInviteMode ? 2 : 6}
			/>
		</>
	);
};

const CardHeader = styled.tr`
	background-color: ${Theming.table.cardHeaderColor};
	border-bottom: 1px ${Theming.separatorColor} solid;
	padding: 10px 0px;
	height: 4em;
	position: relative;
`;

const CardRow = styled.tr`
	background-color: ${Theming.table.cardBackgroundColor};
	padding: 5px 10px;
	height: 2.25em;
`;

const CardTitle = styled.td<{ textAlign?: string }>`
	padding: 10px;
	border-bottom: 2px #707070 solid;
	font-family: ${Theming.text.boldFont};
	font-size: 15px;
	font-weight: normal;
	text-align: ${({ textAlign }) => textAlign ?? 'left'};
`;

const CardFloating = styled.div`
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translate(0, -50%);
`;

const CardItem = styled.td<{
	bold?: boolean;
	color?: string;
	alignLeft?: boolean;
	padding?: string;
}>`
	font-family: ${({ bold }) => (bold ? Theming.text.boldFont : Theming.text.regularFont)};
	padding: 3px 10px;
	font-size: 13px;
	color: ${({ color }) => color ?? Theming.table.cardTextColor};
	word-wrap: break-word;
	text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
`;

const DeleteButton = styled(props => (
	<button aria-label="Delete this row" {...props}>
		&times;
	</button>
))`
	color: ${Theming.text.placeholderColor};
	background: none;
	border: none;
	font-size: 1.5em;
	opacity: 0.5;
	:hover:not(:disabled)& {
		opacity: 1;
		cursor: pointer;
	}
`;

const CheckboxWithText = styled.label<{
	isEditing?: boolean;
}>`
	display: flex;
	align-items: flex-start;
	gap: 0.5em;
	cursor: ${({ isEditing }) => (isEditing ? 'pointer' : 'default')};
	:hover {
		color: ${({ isEditing }) => (isEditing ? Theming.buttons.primaryButtonColor : 'inherit')};
	}
`;
