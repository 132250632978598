import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import {
	PauseAction,
	CreatePauseRequest,
	CreatePauseResponse,
	UpdatePauseRequest,
	UpdatePauseResponse,
	DeletePauseRequest,
	DeletePauseResponse,
} from '../types/api/Pauses';
import { ApiResponse } from '../types/api/SharedApiTypes';

const getPauseParams = (action: PauseAction) => {
	switch (action) {
		case PauseAction.pause:
			return {
				active: true,
				deactivated: false,
			};

		case PauseAction.deactivate:
			return {
				active: true,
				deactivated: true,
			};

		case PauseAction.activate:
			return {
				active: false,
				deactivated: false,
			};
	}
};

export class PausesApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async createPause(request: CreatePauseRequest): Promise<CreatePauseResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				pausable_type: request.resourceType,
				pausable_id: request.resourceId,
				effective_date: request.effectiveDate,
				...getPauseParams(request.onActiveAction),
			};
			const response = (await fetchWrapper(
				{
					path: `resource_pauses/pause_resource`,
					method: 'POST',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<CreatePauseResponse>;

			return response.data;
		} catch (error) {
			console.error('error in pause api', JSON.stringify(error));
			throw error;
		}
	}

	public async updatePause(request: UpdatePauseRequest): Promise<UpdatePauseResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const body = {
				pausable_type: request.resourceType,
				pausable_id: request.resourceId,
				effective_date: request.effectiveDate,
				...getPauseParams(request.onActiveAction),
			};
			const response = (await fetchWrapper(
				{
					path: `resource_pauses/pause_resource`,
					method: 'POST',
					body,
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<UpdatePauseResponse>;

			return response.data;
		} catch (error) {
			console.error('error in pause api', JSON.stringify(error));
			throw error;
		}
	}

	public async deletePause(request: DeletePauseRequest): Promise<DeletePauseResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const response = (await fetchWrapper(
				{
					path: `resource_pauses/${request.id}`,
					method: 'DELETE',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<DeletePauseResponse>;

			return response.data;
		} catch (error) {
			console.error('error in pause api', JSON.stringify(error));
			throw error;
		}
	}
}
