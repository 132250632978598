import { UserDetails } from '../types/api/Auth';
import { StatusType, UUID } from '../types/sharedTypes';
import { adaptContractorFromApi, AdaptedContractor } from './contractorAdapters';
import { LocationUserRole } from '../types/api/LocationUsers';

export type AdaptedUser = {
	id: UUID;
	pubNubUserId: string | null;
	firstName: string;
	lastName: string;
	email: string;
	phone: string | null;
	status: StatusType;
	locations: Array<{
		locationId: UUID;
		locationUserId: UUID;
		userId: UUID;
		roles: LocationUserRole[];
	}>;
	facilities: Array<{
		facilityId: UUID;
		name: string;
		status: StatusType;
	}>;
	contractor: AdaptedContractor | null;
};

export enum UserType {
	admin = 1,
	contractor,
	facilityUser,
	facilityLocationUser,
}

export const adaptUserFromApi = (userData: UserDetails): AdaptedUser => {
	const metaData = userData.meta;
	return {
		id: userData.id,
		pubNubUserId: userData.attributes.pubnub_uuid,
		firstName: userData.attributes.first_name,
		lastName: userData.attributes.last_name,
		phone: userData.attributes.phone,
		email: userData.attributes.email,
		status: userData.attributes.status,
		locations: metaData
			? metaData.security.locations.map(location => {
					return {
						locationId: location.attributes.location_id,
						locationUserId: location.id,
						userId: location.attributes.user_id,
						roles: location.attributes.roles,
					};
			  })
			: [],
		facilities: metaData
			? metaData.security.facilities.map(facility => {
					return {
						facilityId: facility.id,
						name: facility.attributes.name,
						status: facility.attributes.status,
					};
			  })
			: [],
		contractor: !metaData?.security.contractor
			? null
			: adaptContractorFromApi(metaData.security.contractor),
	};
};

export const adaptUsersFromApi = (userList: UserDetails[]): AdaptedUser[] => {
	return userList.map(user => adaptUserFromApi(user));
};
