import React, { useEffect, useState } from 'react';
import { FlexWrapper, PageColumn, PageRow } from '../../../../components/Wrappers';
import { PageSection, PageSectionItem, SummaryItem } from '../../../../components/PageSection';
import { ErrorMessage, InfoText } from '../../../../components/Text';
import { AdaptedContractor } from '../../../../adapters/contractorAdapters';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { Kudos } from '../../../../components/Kudos';
import { Reviews } from '../../../../components/Reviews';
import { useAsync } from '../../../../hooks/useAsync';
import LoadingSpinner from '../../../../components/Loader';
import { webApiManager } from '../../../../network/apiManager';
import useSortAndPagination from '../../../../hooks/useSortFilterAndPagination';
import { LocationSortOptions } from '../../../../types/api/Locations';
import {
	adaptContractorReviewsFromApi,
	AdaptedReviewList,
} from '../../../../adapters/ratingsAdapters';
import { StatusDisplay } from '../../../../components/StatusDisplay';

type ContractorOverviewProps = {
	selectedContractor: AdaptedContractor;
};

export const ContractorOverviewTab = ({ selectedContractor }: ContractorOverviewProps) => {
	const [reviews, setReviews] = useState<AdaptedReviewList | null>(null);
	const approvedContractorTypes = [
		...new Set(selectedContractor.professions.map(profession => profession.professionCode)),
	].join(', ');
	const experiencedContractorTypes = [
		...new Set(
			selectedContractor.professions
				.filter(profession => profession.isExperienced)
				.map(profession => profession.professionCode)
		),
	].join(', ');

	useEffect(() => {
		//fetch reviews on load
		getReviews.execute();
	}, []);

	const onFetch = async (requestOptions: { page?: number } = {}) => {
		try {
			const request = { ...requestOptions, contractor_id: selectedContractor.id };

			const adaptedReviewList = adaptContractorReviewsFromApi(
				await webApiManager.Reviews.getReviews(request)
			);

			//if fetching next page, add to list and update pagination, otherwise replace
			setReviews(prevState => {
				if (adaptedReviewList.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...adaptedReviewList.list],
						pagination: adaptedReviewList.pagination,
					};
				} else {
					return adaptedReviewList;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getReviews = useAsync<void>(
		onFetch,
		'Error getting reviews. Please check your connection and try again.'
	);

	//handle pagination for reviews list
	const { getNextPage } = useSortAndPagination<LocationSortOptions>(reviews, getReviews);

	return (
		<PageRow>
			<PageColumn width={40}>
				<PageSection title={'Account Information'}>
					<PageSectionItem label={'Status'}>
						<StatusDisplay
							statusType={selectedContractor.status}
							pause={selectedContractor.pause}
						/>
					</PageSectionItem>
					<PageSectionItem label={'Email'} borderTop>
						{selectedContractor.emailAddress}
					</PageSectionItem>
					<PageSectionItem label={'Phone Number'} borderTop>
						{formatPhoneNumber(selectedContractor.phoneNumber)}
					</PageSectionItem>
					<PageSectionItem label={`Approved Contractor Types`} borderTop>
						{!approvedContractorTypes.length ? 'n/a' : approvedContractorTypes}
					</PageSectionItem>
					<PageSectionItem label={`Experienced Contractor Types`} borderTop>
						{!experiencedContractorTypes.length ? 'n/a' : experiencedContractorTypes}
					</PageSectionItem>
				</PageSection>
				<PageSection title={'Kudos'}>
					<Kudos kudos={selectedContractor.kudos} />
				</PageSection>
			</PageColumn>
			<PageColumn width={60}>
				<PageSection>
					<FlexWrapper>
						<SummaryItem
							label={'Completed Contracts'}
							amount={selectedContractor.completedContracts}
						/>
						<SummaryItem
							label={'Canceled Contracts'}
							amount={selectedContractor.canceledContracts}
							borderLeft
						/>
						<SummaryItem
							label={'Overall Rating'}
							amount={selectedContractor.rating.averageRating}
							borderLeft
						/>
					</FlexWrapper>
				</PageSection>
				<PageSection
					title={`Reviews (${selectedContractor.rating.numberReviews})`}
					maxHeight={'500px'}
					onScrollToBottom={getNextPage}
				>
					{!reviews ? (
						<PageSectionItem>
							{getReviews.pending ? (
								<LoadingSpinner />
							) : (
								<ErrorMessage text={getReviews.error ?? 'Error getting reviews data.'} />
							)}
						</PageSectionItem>
					) : (
						<>
							{reviews.list.length === 0 ? (
								<InfoText>No reviews found.</InfoText>
							) : (
								<Reviews reviews={reviews.list} />
							)}
						</>
					)}
				</PageSection>
			</PageColumn>
		</PageRow>
	);
};
