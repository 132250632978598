import React, { ReactNode, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAsync } from '../hooks/useAsync';
import { IdleTimeoutModal } from './IdleTimeoutModal';

type IdleTimeoutWrapperProps = {
	children: ReactNode;
	onLogout: () => void;
	onReset: () => void;
	countdownTime: number;
};

export const IdleTimeoutWrapper = ({
	children,
	onLogout,
	onReset,
	countdownTime,
}: IdleTimeoutWrapperProps) => {
	const [isTimedOut, setIsTimedOut] = useState<boolean>(false);

	useEffect(() => {
		//set short timeout for warning modal before logging out user
		const timeout = setTimeout(async () => {
			if (isTimedOut) {
				await handleLogout(); // leave modal open for 30 seconds and if they don't accept, then log the user out
			}
		}, 30000);

		return () => clearTimeout(timeout);
	}, [isTimedOut]);

	//called when user performs action
	const handleOnAction = () => {
		onReset();
	};

	//called when user is no longer idle
	const handleOnActive = () => {
		onReset();
	};

	//called when user becomes idle
	const handleOnIdle = () => {
		//opens warning modal and sets 30 second timeout before logging out
		setIsTimedOut(true);
	};

	//closes modal, resets expiration when user responds to warning modal
	const handleClose = () => {
		setIsTimedOut(false);
		onReset();
		reset();
	};

	//logs out user after inactivity, or when logout option is selected on warning modal
	const handleLogout = async () => {
		try {
			//close modal
			setIsTimedOut(false);
			//logout user through api and navigate back to login screen
			await onLogout();
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const logout = useAsync<void>(handleLogout);

	const { reset } = useIdleTimer({
		timeout: countdownTime,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500,
	});

	return (
		<>
			{/*Modal to warn inactive users before logging them out*/}
			<IdleTimeoutModal isOpen={isTimedOut} hide={logout.execute} onConfirm={handleClose} />

			{/*Components that need timeout functionality*/}
			{children}
		</>
	);
};
