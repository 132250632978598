import { useCallback, useState } from 'react';

export type UseAsyncReturn<T> = {
	execute: (values?: any) => void;
	pending: boolean;
	value: T | null;
	error: string | null;
	clearError: () => void;
};

export function useAsync<T>(
	asyncFunction: (values?: any) => Promise<T>,
	errorMessage = 'Error fetching your data'
): UseAsyncReturn<T> {
	const [pending, setPending] = useState(false);
	const [value, setValue] = useState<T | null>(null);
	const [error, setError] = useState<string | null>(null);

	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback(
		(values: any) => {
			setPending(true);
			setValue(null);
			setError(null);
			return asyncFunction(values)
				.then(response => setValue(response))
				.catch(err => {
					const errors = err.errors || {};

					return Object.prototype.hasOwnProperty.call(errors, 'phone')
						? setError(`Phone ${err.errors.phone[0] ?? 'is invalid'}`)
						: Object.prototype.hasOwnProperty.call(errors, 'email')
						? setError(`Email address ${err.errors.email[0] ?? 'is invalid'}`)
						: Object.prototype.hasOwnProperty.call(errors, 'password')
						? setError(`Password ${err.errors.password[0] ?? 'is invalid'}`)
						: Object.prototype.hasOwnProperty.call(errors, 'status')
						? setError(`Status ${err.errors.status[0] ?? 'Invalid status request'}`)
						: Object.prototype.hasOwnProperty.call(errors, 'base')
						? setError(err.errors.base[0])
						: setError(errorMessage);
				})
				.finally(() => setPending(false));
		},
		[asyncFunction]
	);

	return { execute, pending, value, error, clearError: () => setError(null) };
}

//reducer that would be coupled with the hook definition
// function asyncReducer(state, action) {
//   switch (action.type) {
//     case 'pending': {
//       return {status: 'pending', data: null, error: null}
//     }
//     case 'resolved': {
//       return {status: 'resolved', data: action.data, error: null}
//     }
//     case 'rejected': {
//       return {status: 'rejected', data: null, error: action.error}
//     }
//     default: {
//       throw new Error(`Unhandled action type: ${action.type}`)
//     }
//   }
// }
