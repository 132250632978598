import React from 'react';
import styled from 'styled-components';
import FacilityImage from '../assets/images/Building_Facility.png';

export const FacilityBuilding = () => {
	return <StyledFacilityImage src={FacilityImage} />;
};

const StyledFacilityImage = styled.img<{ backgroundColor?: string }>`
	width: 100%;
	margin: 0 auto;
	background-color: #e3e3e3;
	padding: 20px;
`;
