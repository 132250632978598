import React, { Dispatch, SetStateAction } from 'react';
import { CheckboxInput } from '../../../components/CheckboxInput';
import { FilterSection, FilterSectionTitle } from '../components/FilterSection';
import { FilterButton } from '../../../components/Buttons';
import { Separator } from '../../../components/Separator';
import {
	ContractFilterOptions,
	ContractFilterType,
	ContractSortOptions,
	ContractStatus,
	PaymentStatus,
} from '../../../types/api/Contracts';
import { UnlabeledSelect } from '../../../components/Select';
import { SearchInput } from '../../../components/SearchInput';
import { debounce } from 'lodash';
import IconManager, { IconType } from '../../../components/IconManager';
import { Theming } from '../../../theming';
import { AdaptedContractList } from '../../../adapters/contractAdapters';

type ContractFiltersProps = {
	contracts: AdaptedContractList | null;
	filters: ContractFilterOptions;
	setFilters: Dispatch<SetStateAction<ContractFilterOptions>>;
	resetFilters: () => void;
	setSortBy: Dispatch<SetStateAction<ContractSortOptions>>;
	shouldClearFilters: boolean;
	setShouldClearFilters;
};

export const ContractFilters = ({
	contracts,
	filters,
	setFilters,
	setSortBy,
	resetFilters,
	shouldClearFilters,
	setShouldClearFilters,
}: ContractFiltersProps) => {
	const getFilterCount = (): number => {
		return (
			filters[ContractFilterType.contractStatus].length +
			filters[ContractFilterType.paymentStatus].length +
			(filters[ContractFilterType.location] ? 1 : 0) +
			(filters[ContractFilterType.reported] ? 1 : 0) +
			(filters[ContractFilterType.contractId] ? 1 : 0)
		);
	};

	const handleStatusFilters = (
		filterType: ContractFilterType.paymentStatus | ContractFilterType.contractStatus,
		statusToUpdate: ContractStatus | PaymentStatus,
		shouldAdd: boolean
	) => {
		setFilters((prevState: ContractFilterOptions) => {
			if (shouldAdd) {
				return { ...prevState, [filterType]: [...prevState[filterType], statusToUpdate] };
			} else {
				//remove status
				//eslint-disable-next-line
				// @ts-ignore - it doesn't matter if the potential status types have different properties
				const updatedStatusFilters = prevState[filterType].filter(
					status => status !== statusToUpdate
				);
				return { ...prevState, [filterType]: [...updatedStatusFilters] };
			}
		});
	};

	const handleOtherFilters = (filterToUpdate: ContractFilterType, value: string | boolean) => {
		setFilters(prevState => {
			return { ...prevState, [filterToUpdate]: value };
		});
	};

	return (
		<>
			<FilterButton
				text={'Clear Filters'}
				disabled={!getFilterCount()}
				onClick={() => {
					//reset filter values and clear checkboxes
					resetFilters();
					setShouldClearFilters(true);
				}}
			/>
			<FilterSectionTitle>Active Filters: &nbsp; {getFilterCount()}</FilterSectionTitle>
			<Separator margin={'10px 0'} />
			<FilterSection title={'Sort by:'}>
				<UnlabeledSelect
					options={
						contracts
							? [
									{ value: ContractSortOptions.contractor, label: 'Contractor' },
									{ value: ContractSortOptions.facility, label: 'Facility' },
									{ value: ContractSortOptions.postedDate, label: 'Posted Date' },
									{ value: ContractSortOptions.startDate, label: 'Start Date' },
									{ value: ContractSortOptions.endDate, label: 'End Date' },
							  ]
							: []
					}
					onChange={newVal => setSortBy(newVal)}
					isClearable
				/>
			</FilterSection>
			<FilterSection title={'Contract Status'}>
				<CheckboxInput
					label={'Booked'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(ContractFilterType.contractStatus, ContractStatus.booked, newVal)
					}
					shouldClear={shouldClearFilters}
				/>
				<CheckboxInput
					label={'In Progress'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(
							ContractFilterType.contractStatus,
							ContractStatus.inProgress,
							newVal
						)
					}
					shouldClear={shouldClearFilters}
				/>
				<CheckboxInput
					label={'Completed'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(ContractFilterType.contractStatus, ContractStatus.completed, newVal)
					}
					shouldClear={shouldClearFilters}
				/>
				<CheckboxInput
					label={'Canceled'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(ContractFilterType.contractStatus, ContractStatus.canceled, newVal)
					}
					shouldClear={shouldClearFilters}
				/>
			</FilterSection>
			<FilterSection title={'Payment Status'}>
				<CheckboxInput
					label={'Paid'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(ContractFilterType.paymentStatus, PaymentStatus.paid, newVal)
					}
					shouldClear={shouldClearFilters}
				/>
				<CheckboxInput
					label={'Partially Paid'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(ContractFilterType.paymentStatus, PaymentStatus.partialPaid, newVal)
					}
					shouldClear={shouldClearFilters}
				/>
				<CheckboxInput
					label={'Not Paid'}
					alignLeft
					margin={'0'}
					onChange={newVal =>
						handleStatusFilters(ContractFilterType.paymentStatus, PaymentStatus.notPaid, newVal)
					}
					shouldClear={shouldClearFilters}
				/>
			</FilterSection>
			<FilterSection title={'Location'}>
				<SearchInput
					icon={
						<IconManager
							type={IconType.LOCATION}
							size={16}
							stroke={'none'}
							fill={Theming.text.placeholderColor}
						/>
					}
					iconPadding={'12px 8px'}
					placeholder={'City/State/Zip Code'}
					margin={'0 auto'}
					onSubmit={debounce(
						newVal => handleOtherFilters(ContractFilterType.location, newVal),
						500
					)}
					shouldClear={shouldClearFilters}
				/>
			</FilterSection>
			<FilterSection title={'Flagged'}>
				<CheckboxInput
					label={'Problem Reported'}
					alignLeft
					margin={'0'}
					onChange={newVal => handleOtherFilters(ContractFilterType.reported, newVal ?? undefined)} //prevent filtering by 'false' if unchecked
					shouldClear={shouldClearFilters}
				/>
			</FilterSection>
		</>
	);
};
