import { UserType } from './userAdapter';
import { PaginatedList } from '../types/sharedTypes';
import { adaptPaginationFromApi } from './paginationAdapter';
import {
	GetReviewsResponse,
	Kudos,
	LocationKudos,
	PositiveFacilityLocationKudos,
	PositiveKudos,
	Rating,
	Review,
} from '../types/api/Reviews';

export type AdaptedKudos = {
	[key in PositiveKudos]: number;
};
export type AdaptedPositiveFacilityLocationKudos = {
	[key in PositiveFacilityLocationKudos]: number;
};

export type AdaptedRating = {
	averageRating: number | string;
	numberReviews: number | string;
};

export type AdaptedReview = {
	name: string;
	score: number;
	review: string;
	reviewerType: UserType.contractor | UserType.facilityLocationUser;
	date: string | Date;
};

export type AdaptedReviewList = PaginatedList<AdaptedReview>;

export const adaptKudosFromApi = (kudos: Kudos): AdaptedKudos | null => {
	if (!kudos) {
		return null;
	}

	return {
		[PositiveKudos.caring]: calculateKudos(kudos.positives.Caring, kudos.negatives.Unfriendly),
		[PositiveKudos.experienced]: calculateKudos(
			kudos.positives.Experienced,
			kudos.negatives.Inexperienced
		),
		[PositiveKudos.problemSolver]: calculateKudos(
			kudos.positives['Problem Solver'],
			kudos.negatives['Not a Problem Solver']
		),
		[PositiveKudos.professional]: calculateKudos(
			kudos.positives.Professional,
			kudos.negatives['Unprofessional Environment']
		),
		[PositiveKudos.strongCommunication]: calculateKudos(
			kudos.positives['Strong Communication'],
			kudos.negatives['Poor Communication']
		),
	};
};

export const adaptLocationKudosFromApi = (
	kudos: LocationKudos
): AdaptedPositiveFacilityLocationKudos | null => {
	if (!kudos) {
		return null;
	}

	return {
		[PositiveFacilityLocationKudos.strongManagement]: calculateKudos(
			kudos.positives['Strong Management'],
			kudos.negatives['Weak Management']
		),
		[PositiveFacilityLocationKudos.professionalEnvironment]: calculateKudos(
			kudos.positives['Professional Environment'],
			kudos.negatives['Unprofessional Environment']
		),
		[PositiveFacilityLocationKudos.strongCommunication]: calculateKudos(
			kudos.positives['Strong Communication'],
			kudos.negatives['Poor Communication']
		),
		[PositiveFacilityLocationKudos.adequateResources]: calculateKudos(
			kudos.positives['Adequate Resources'],
			kudos.negatives['Inadequate resources']
		),
		[PositiveFacilityLocationKudos.friendlyStaff]: calculateKudos(
			kudos.positives['Friendly Staff'],
			kudos.negatives['Unfriendly Staff']
		),
	};
};

export const adaptRatingFromApi = (rating: Rating): AdaptedRating => {
	if (!rating) {
		return {
			averageRating: '-',
			numberReviews: 0,
		};
	}

	return {
		averageRating:
			typeof rating.average_rating !== 'number' ? '-' : rating.average_rating.toFixed(1),
		numberReviews: typeof rating.number_reviews !== 'number' ? 0 : rating.number_reviews,
	};
};

const calculateKudos = (
	positiveKudos: number | undefined,
	negativeKudos: number | undefined
): number => {
	const numberToAdd = typeof positiveKudos === 'number' ? positiveKudos : 0;
	const numberToSubtract = typeof negativeKudos === 'number' ? negativeKudos : 0;
	return numberToAdd - numberToSubtract;
};

export const adaptLocationReviewFromApi = (review: Review): AdaptedReview => {
	return {
		name: `${review.relationships.contract_instance.relationships.contractor.relationships.user.attributes.first_name} ${review.relationships.contract_instance.relationships.contractor.relationships.user.attributes.last_name}`,
		score: review.attributes.location_score,
		review: review.attributes.location_written_review,
		reviewerType: UserType.contractor,
		date: review.relationships.contract_instance.attributes.end_date,
	};
};

export const adaptLocationReviewsFromApi = (reviews: GetReviewsResponse): AdaptedReviewList => {
	return {
		pagination: adaptPaginationFromApi(reviews.links),
		list: reviews.data.map(review => adaptLocationReviewFromApi(review)), //filter out empty reviews
	};
};

export const adaptContractorReviewFromApi = (review: Review): AdaptedReview => {
	return {
		name: review.relationships.contract_instance.relationships.location.attributes.name,
		score: review.attributes.contractor_score,
		review: review.attributes.contractor_written_review,
		reviewerType: UserType.facilityLocationUser,
		date: review.relationships.contract_instance.attributes.end_date,
	};
};

export const adaptContractorReviewsFromApi = (reviews: GetReviewsResponse): AdaptedReviewList => {
	return {
		pagination: adaptPaginationFromApi(reviews.links),
		list: reviews.data.map(review => adaptContractorReviewFromApi(review)),
	};
};
