import React, { ReactNode, useContext } from 'react';
import { Route } from 'react-router-dom';
import { RFC } from '../types/sharedTypes';
import { Unauthorized } from '../pages/Unauthorized';
import { UserContext } from '../context/UserContext';

interface NonAuthRouteProps {
	component: ReactNode | (() => JSX.Element);
	path?: string;
	exact?: boolean;
}

export const NonAuthRoute = ({ component, path, exact = false }: NonAuthRouteProps) => (
	<Route exact={exact} path={path}>
		{component}
	</Route>
);

interface AuthRouteProps {
	component: ReactNode;
	path?: string;
	requiredRoles: [];
	exact?: boolean;
}

export const AuthRoute: RFC<AuthRouteProps> = ({ component, path, exact = false }) => {
	const { currentUser } = useContext(UserContext);

	return currentUser ? (
		<>
			{/*// requiredRoles.includes(currentUser.organizations[0].userRole) ?*/}
			<NonAuthRoute path={path} component={component} exact={exact} />
		</>
	) : (
		<NonAuthRoute component={Unauthorized} />
	);
};
