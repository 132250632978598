import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LocationUserForm } from './LocationUserForm';
import { useAsync } from '../../../../../hooks/useAsync';
import { LocationUserRole } from '../../../../../types/api/LocationUsers';
import {
	AdaptedLocationUser,
	adaptLocationUserFromApi,
} from '../../../../../adapters/locationUsersAdapters';
import LoadingSpinner from '../../../../../components/Loader';
import { webApiManager } from '../../../../../network/apiManager';
import { ErrorMessage } from '../../../../../components/Text';
import { BaseButton } from '../../../../../components/Buttons';
import { Theming } from '../../../../../theming';
import { Modal } from '../../../../../components/Modal';
import { AdaptedFacilityLocation } from '../../../../../adapters/locationsAdapters';
import { EditStatus, EditStatusAction } from '../../../../../components/EditStatus';
import { FormSectionTitle, SidePanelFormWrapper } from '../../../../../components/Form';
import { BoldSeparator } from '../../../../../components/Separator';
import { StatusType, UUID } from '../../../../../types/sharedTypes';

type LocationUserDetailsProps = {
	selectedLocationUserId: UUID | null;
	setSelectedLocationUserId: Dispatch<SetStateAction<UUID | null>>;
	selectedLocation: AdaptedFacilityLocation;
};

type UpdateLocationUserData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	roles: LocationUserRole[];
};

export const LocationUserDetails = ({
	selectedLocationUserId,
	setSelectedLocationUserId,
	selectedLocation,
}: LocationUserDetailsProps) => {
	const [selectedLocationUser, setSelectedLocationUser] = useState<AdaptedLocationUser | null>(
		null
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(false);

	//fetch selected user
	useEffect(() => {
		getSelectedUser.execute();
		return () => getSelectedUser.clearError();
	}, []);

	useEffect(() => {
		//clear any update or submit errors when re-editing
		if (isEditing) {
			updateUser.clearError();
		}
	}, [isEditing]);

	const onLoad = async () => {
		getSelectedUser.clearError();
		try {
			if (selectedLocationUserId) {
				//get selected user
				const selectedUser = adaptLocationUserFromApi(
					await webApiManager.LocationUsers.getLocationUser({
						locationUserId: selectedLocationUserId,
					})
				);
				//set adapted data to state
				setSelectedLocationUser(selectedUser);
			} else {
				throw new Error('Could not find selected invite request.');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onUpdate = async (formData: UpdateLocationUserData) => {
		setIsEditing(false);
		try {
			if (selectedLocationUser) {
				const updateRequest = {
					locationUserId: selectedLocationUser.id,
					updates: {
						first_name: formData.firstName,
						last_name: formData.lastName,
						email: formData.email,
						phone: formData.phone,
						roles: formData.roles,
					},
				};
				const updatedUser = adaptLocationUserFromApi(
					await webApiManager.LocationUsers.updateLocationUser(updateRequest)
				);
				setSelectedLocationUser(updatedUser);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const onRemove = async () => {
		try {
			if (selectedLocationUser) {
				await webApiManager.LocationUsers.removeLocationUser({
					locationId: selectedLocation.locationId,
					userId: selectedLocationUser.userId,
				});
				//close modal and side panel
				setShowWarning(false);
				setSelectedLocationUserId(null);
			} else {
				throw new Error('Could not find selected user');
			}
		} catch (error) {
			//close modal
			setShowWarning(false);
			console.error(error);
			throw error;
		}
	};

	const getSelectedUser = useAsync<void>(
		onLoad,
		'Error getting user data. Please check your connection and try again.'
	);
	const updateUser = useAsync<void>(
		onUpdate,
		'Error updating data. Please check your connection and try again.'
	);
	const removeUser = useAsync<void>(
		onRemove,
		'Error removing user from location. Please check your connection and try again.'
	);

	return (
		<SidePanelFormWrapper>
			{getSelectedUser.pending || updateUser.pending || removeUser.pending ? (
				<LoadingSpinner />
			) : (
				<>
					{!selectedLocationUser ? (
						<ErrorMessage text={getSelectedUser.error ?? 'Could not find user.'} />
					) : (
						<>
							<FormSectionTitle>Edit Account Status</FormSectionTitle>
							<BoldSeparator />
							<EditStatus
								user={selectedLocationUser}
								onChange={(action, newStatusType) => {
									if (
										action === EditStatusAction.statusChange &&
										newStatusType === StatusType.deleted
									) {
										setSelectedLocationUserId(null);
									} else {
										getSelectedUser.execute();
									}
								}}
							/>
							<LocationUserForm
								selectedLocationUser={selectedLocationUser}
								isEditing={isEditing}
								setIsEditing={setIsEditing}
								onSave={formData => updateUser.execute(formData)}
								hideEdit={selectedLocationUser.status === StatusType.deleted}
							/>
							{updateUser.error && <ErrorMessage text={updateUser.error} />}
							<BaseButton
								text={'Remove Access to Location'}
								margin={'40px 10px'}
								backgroundColor={Theming.errorColor}
								onClick={() => setShowWarning(true)}
							/>
							{removeUser.error && <ErrorMessage text={removeUser.error} />}
						</>
					)}
				</>
			)}
			<Modal
				isOpen={showWarning}
				hide={() => setShowWarning(false)}
				onConfirm={removeUser.execute}
				title={`Remove ${selectedLocationUser?.email} from ${selectedLocation.name}?`}
				text={'This will NOT remove them from any other locations they are associated with.'}
				cancelButtonText={'Cancel'}
				confirmButtonText={'Remove User'}
				confirmButtonColor={Theming.errorColor}
				pending={removeUser.pending}
			/>
		</SidePanelFormWrapper>
	);
};
