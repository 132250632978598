import { Profession } from '../types/api/Professions';
import { UUID } from '../types/sharedTypes';

//general profession
export type AdaptedProfession = {
	id: UUID;
	name: string;
	code: string;
};

export const adaptProfessionsFromApi = (professions: Profession[]): AdaptedProfession[] => {
	return professions.map(adaptProfessionFromApi);
};

export const adaptProfessionFromApi = (profession: Profession): AdaptedProfession => {
	return {
		id: profession.id,
		name: profession.attributes.name,
		code: profession.attributes.code,
	};
};
