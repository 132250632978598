import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import { useScrollToBottom } from '../hooks/useScrollToBottom';

type PageSectionProps = {
	title?: string | ReactNode;
	children: ReactNode;
	maxHeight?: string;
	onScrollToBottom?: () => void;
	margin?: string;
};

export const PageSection = ({
	title,
	children,
	maxHeight,
	onScrollToBottom,
	margin,
}: PageSectionProps) => {
	const handleScroll = useScrollToBottom(onScrollToBottom);

	return (
		<PageSectionWrapper onScroll={handleScroll} maxHeight={maxHeight} margin={margin}>
			{!!title && <PageSectionTitle>{title}</PageSectionTitle>}
			{children}
		</PageSectionWrapper>
	);
};

const PageSectionWrapper = styled.div<{ maxHeight?: string; margin?: string }>`
	width: 100%;
	margin: ${({ margin }) => margin ?? '0 auto 25px'};
	padding: 0;
	box-shadow: 0px 1px 4px rgba(0, 0, 41, 0.15);
	border-radius: 8px;
	background-color: ${Theming.backgrounds.pageSection};
	color: ${Theming.text.primaryTextColor};
	font-family: ${Theming.text.regularFont};
	overflow: auto;
	max-height: ${({ maxHeight }) => maxHeight ?? 'none'};
`;

const PageSectionTitle = styled.p`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	margin: 0;
	background-color: ${Theming.backgrounds.sectionTitle};
	color: ${Theming.text.titleColor};
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	width: 100%;
`;

type PageSectionItemProps = {
	label?: string;
	children: ReactNode;
	borderTop?: boolean;
	flex?: boolean;
	justifyContent?: string;
};

export const PageSectionItem = ({
	label,
	children,
	borderTop,
	flex,
	justifyContent,
}: PageSectionItemProps) => {
	return (
		<SectionItemWrapper borderTop={borderTop} flex={flex} justifyContent={justifyContent}>
			{!!label && <SectionItemLabel>{`${label}: `}</SectionItemLabel>}
			{children}
		</SectionItemWrapper>
	);
};

const SectionItemWrapper = styled.div<{
	borderTop?: boolean;
	flex?: boolean;
	justifyContent?: string;
}>`
	display: ${({ flex }) => (flex ? 'flex' : 'block')};
	align-items: center;
	justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
	overflow-wrap: break-word;
	width: 100%;
	padding: 10px 15px;
	margin: 0;
	border-top: ${({ borderTop }) => (!borderTop ? 'none' : `1px solid ${Theming.separatorColor}`)};
	color: ${Theming.text.primaryTextColor};
	font-family: ${Theming.text.regularFont};
	font-size: 13px;
`;

const SectionItemLabel = styled.span`
	color: ${Theming.text.primaryTextColor};
	font-family: ${Theming.text.boldFont};
	font-size: 13px;
	margin-right: 5px;
`;

export const PageSectionText = styled.p<{
	bold?: boolean;
	fontSize?: number;
	color?: string;
	padding?: string;
}>`
	display: inline-block;
	color: ${({ color }) => color ?? Theming.text.primaryTextColor};
	text-align: left;
	padding: ${({ padding }) => padding ?? '5px 0'};
	margin: 0;
	font-family: ${({ bold }) => (bold ? Theming.text.boldFont : Theming.text.regularFont)};
	font-size: ${({ fontSize }) => fontSize ?? 14}px;
`;

export const SummaryItem = ({
	label,
	amount,
	borderLeft,
	borderTop,
	borderBottom,
	fontSize,
}: {
	label: string;
	fontSize?: number;
	amount: number | string;
	borderLeft?: boolean;
	borderTop?: boolean;
	borderBottom?: boolean;
}) => {
	return (
		<SummaryItemWrapper borderLeft={borderLeft} borderTop={borderTop} borderBottom={borderBottom}>
			<SummaryItemText fontSize={fontSize}>{amount}</SummaryItemText>
			<SummaryItemText fontSize={fontSize}>{label}</SummaryItemText>
		</SummaryItemWrapper>
	);
};

const SummaryItemWrapper = styled.div<{
	borderLeft?: boolean;
	borderTop?: boolean;
	borderBottom?: boolean;
}>`
	padding: 10px 5px;
	margin: 0;
	text-align: center;
	flex: 1;
	border-left: ${({ borderLeft }) =>
		!borderLeft ? 'none' : `1px solid ${Theming.separatorColor}`};
	border-top: ${({ borderTop }) => (!borderTop ? 'none' : `1px solid ${Theming.separatorColor}`)};
	border-bottom: ${({ borderBottom }) =>
		!borderBottom ? 'none' : `1px solid ${Theming.separatorColor}`};
`;

const SummaryItemText = styled.p<{ fontSize?: number }>`
	text-transform: uppercase;
	font-size: ${({ fontSize }) => fontSize ?? 14}px;
	font-family: ${Theming.text.boldFont};
`;

export const MultiLineText = styled.p`
	padding: 0 2px;
	margin: 0;
`;
