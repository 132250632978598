import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { RadioInputGroup } from '../../../components/RadioInputGroup';
import { BaseButton } from '../../../components/Buttons';
import { maskEmail } from '../../../utils/maskEmail';
import { maskPhone } from '../../../utils/maskPhone';
import { UncontrolledTextInput } from '../../../components/TextInputs';
import { removeWhiteSpace } from '../../../utils/removeWhiteSpace';
import { LinkStyledButton } from '../../../components/Links';
import { CheckboxInput } from '../../../components/CheckboxInput';
import { SendCodeMethod } from '../../../types/api/Auth';
import { UserContext } from '../../../context/UserContext';
import { ErrorMessage } from '../../../components/Text';

type VerifyAccountProps = {
	codeSent: boolean;
	setCodeSent: (codeSent: boolean) => void;
	onSend: (type: SendCodeMethod) => void;
	onVerify: (code: string) => void;
	success: boolean;
	onContinue: (preference: boolean) => void;
	pending: boolean;
	error: string;
};

export const VerifyAccountForm = ({
	codeSent,
	setCodeSent,
	onSend,
	onVerify,
	onContinue,
	success,
	pending,
	error,
}: VerifyAccountProps) => {
	const { currentUser } = useContext(UserContext);

	const [sendCodeMethod, setSendCodeMethod] = useState<SendCodeMethod | null>(null);
	const [enteredCode, setEnteredCode] = useState<string>('');
	const [rememberDevice, setRememberDevice] = useState<boolean>(false);

	const userPhone = maskPhone(currentUser?.phone);
	const userEmail = maskEmail(currentUser?.email);

	return (
		<>
			{!success ? (
				<>
					{!codeSent ? (
						//Show 1st step - choose delivery method for verification code
						<>
							<FormTitle>{"Let's verify your account"}</FormTitle>
							<RadioInputGroup
								options={[
									{ label: 'Text', value: SendCodeMethod.TEXT },
									{ label: 'Email', value: SendCodeMethod.EMAIL },
								]}
								onChange={newVal => setSendCodeMethod(newVal)}
								disabled={pending}
								disableOption={!userPhone ? SendCodeMethod.TEXT : undefined}
								initialValue={userPhone ? SendCodeMethod.TEXT : SendCodeMethod.EMAIL}
							/>
							<Divider />
							<BaseButton
								text={'Send Code'}
								width={100}
								disabled={!sendCodeMethod}
								onClick={() => !!sendCodeMethod && onSend(sendCodeMethod)}
								pending={pending}
							/>
							<FormInfo>
								{
									"You'll receive a 6-digit verification code by text message. Text or rates may apply."
								}
							</FormInfo>
						</>
					) : (
						//Show 2nd step - enter code from text/email
						<>
							<FormTitle>Enter Verification Code</FormTitle>
							<FormInfo>
								{`We just sent ${
									sendCodeMethod === SendCodeMethod.TEXT ? 'a text message' : 'an email'
								} with a 6-digit code to `}
								<span style={{ whiteSpace: 'nowrap' }}>{`${
									sendCodeMethod === SendCodeMethod.TEXT ? userPhone : userEmail
								}`}</span>
							</FormInfo>
							<UncontrolledTextInput
								type={'text'}
								borderRadius={5}
								placeholder={''}
								value={enteredCode}
								onChange={e => {
									setEnteredCode(() => {
										//remove whitespace and trim string to 6 characters
										const newVal = removeWhiteSpace(e.target.value).substring(0, 6);
										return newVal;
									});
								}}
								error={!enteredCode || enteredCode.length >= 6 ? '' : 'Please enter 6-digit code.'}
								disabled={pending}
							/>
							<BaseButton
								text={'Verify'}
								width={100}
								disabled={enteredCode.length < 6}
								onClick={() => onVerify(enteredCode)}
								pending={pending}
							/>
							<LinkStyledButton
								//resend code via prev. selected method or set codeSent to false to show previous screen
								onClick={() => (sendCodeMethod ? onSend(sendCodeMethod) : setCodeSent(false))}
							>
								{'Resend Code'}
							</LinkStyledButton>
							<br />
							<LinkStyledButton
								//set codeSent to false to show previous screen
								onClick={() => setCodeSent(false)}
							>
								{'Try Another Way'}
							</LinkStyledButton>
							{!!error && <ErrorMessage text={error} />}
						</>
					)}
				</>
			) : (
				//Show success message on completion
				<>
					<FormTitle>{"You're all set"}</FormTitle>
					<FormInfo>
						{'When you log into your Carefiller account, we will ask you to verify your identity every time unless you choose to remember this device. ' +
							"We recommend this only if it's your personal or trusted device."}
					</FormInfo>
					<CheckboxInput
						label={'Remember this device'}
						onChange={newVal => setRememberDevice(newVal)}
					/>
					<BaseButton
						text={'Continue'}
						width={90}
						onClick={() => onContinue(rememberDevice)}
						pending={pending}
					/>
				</>
			)}
		</>
	);
};

const FormTitle = styled.p`
	font-size: 18px;
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.secondaryTextColor};
`;

const FormInfo = styled.p`
	font-size: 12px;
	font-family: ${Theming.text.regularFont};
	color: ${Theming.text.primaryTextColor};
	line-height: 1.7em;
	font-weight: bold;
`;

const Divider = styled.div`
	width: 100%;
	border-bottom: 1px solid ${Theming.separatorColor};
`;
