import { StatusType, UUID } from '../sharedTypes';
import { Contractor } from './Contractors';
import { LocationUserRole } from './LocationUsers';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';

export interface SignInRequest {
	email: string;
	password: string;
}

export interface SignInResponse {
	type: 'user_sessions';
	attributes: {
		auth_token: string;
		refresh_token: string;
		twofa_authenticated: boolean;
	};
	relationships: {
		user: {
			data: UserDetails;
		};
		type: 'user_sessions';
	};
}

export interface TwoFactorAuthRequest {
	notification_method: SendCodeMethod;
}

export enum SendCodeMethod {
	EMAIL = 'email',
	TEXT = 'sms',
}

export interface TwoFactorAuthValidate {
	code: number | string;
}

export type UserDetails = {
	type: 'users';
	id: UUID;
	meta?: {
		pauses: [];
		security: {
			is_contractor: boolean;
			is_employee: boolean;
			is_facility_user: boolean;
			is_facility_location_user: boolean;
			facilities: UserFacilityMetaData[];
			locations: UserLocationMetaData[];
			contractor: Contractor | null;
			facility_ids: UUID[];
		};
	};
	attributes: {
		first_name: string;
		last_name: string;
		email: string;
		phone: string | null;
		privacy_policy_accepted: boolean;
		sms_opted_in: boolean;
		terms_accepted: boolean;
		status: StatusType;
		pubnub_uuid: string | null;
	};
};

interface UserFacilityMetaData {
	id: UUID; //facility id
	type: 'facilities';
	attributes: {
		name: string;
		status: StatusType;
	};
}

interface UserLocationMetaData {
	id: UUID; //location user id
	type: 'location_users';
	attributes: {
		location_id: UUID;
		user_id: UUID;
		roles: LocationUserRole[];
	};
	relationships: {
		user: UserDetails;
	};
}

export enum LoginType {
	'ADMIN' = 1,
	'SUPER_ADMIN',
}

export interface RememberCurrentUserResponse {
	remember_me_auth_token: string;
}

export interface ForgotPasswordRequest {
	email: string;
}

export interface ResetPasswordRequest {
	auth_token: string;
	password: string;
	password_confirmation: string;
}

export type ResetPasswordResponse = UserDetails;

//TODO: setup sort type if sort gets implemented
export type GetAdminsRequest = PaginatedApiRequest<Record<string, never>>;
export type GetAdminsResponse = PaginatedApiResponse<UserDetails[]>;
