import React from 'react';
import { Modal, ModalProps } from './Modal';

export const IdleTimeoutModal = ({ isOpen, hide, onConfirm }: ModalProps) => {
	return (
		<Modal
			isOpen={isOpen}
			hide={hide}
			onConfirm={onConfirm}
			text={`Your session is about to expire.`}
			title={'Would you like to continue?'}
			cancelButtonText={'Sign Out'}
			confirmButtonText={'Continue'}
			showCloseButton={false}
		/>
	);
};
