import {
	adaptContractorProfessionExperiencesFromApi,
	AdaptedContractorProfessionExperience,
} from './contractorProfessionExperiencesAdapter';
import { PaginatedList, StatusType, UUID } from '../types/sharedTypes';
import { GetContractorResponse, GetContractorsResponse } from '../types/api/Contractors';
import { adaptPaginationFromApi } from './paginationAdapter';
import {
	AdaptedKudos,
	AdaptedRating,
	adaptKudosFromApi,
	adaptRatingFromApi,
} from './ratingsAdapters';
import { AdaptedPause, adaptPauseFromApi } from './pauseAdapter';
import { UserType } from './userAdapter';
import {
	adaptContractorSpecializationExperiencesFromApi,
	AdaptedContractorSpecializationExperience,
} from './contractorSpecializationExperiencesAdapter';

export type AdaptedContractor = {
	id: UUID; //contractor id
	userId: UUID; //user id associated with contractor
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
	professions: AdaptedContractorProfessionExperience[];
	specializations: AdaptedContractorSpecializationExperience[];
	status: StatusType;
	rating: AdaptedRating;
	kudos: AdaptedKudos | null;
	completedContracts: number;
	canceledContracts: number;
	channelName: string;
	pause: AdaptedPause | null;
	userType: UserType.contractor;
};

export type AdaptedContractorList = PaginatedList<AdaptedContractor>;

//adapts single contractor from api response
export const adaptContractorFromApi = (contractor: GetContractorResponse): AdaptedContractor => {
	//@TODO - This should use adaptUserFromApi
	return {
		id: contractor.id,
		userId: contractor.attributes.user_id,
		firstName: contractor.relationships.user.attributes.first_name,
		lastName: contractor.relationships.user.attributes.last_name,
		emailAddress: contractor.relationships.user.attributes.email,
		phoneNumber: contractor.relationships.user.attributes.phone ?? '',
		professions: adaptContractorProfessionExperiencesFromApi(
			contractor.relationships.contractor_profession_experiences
		),
		specializations: adaptContractorSpecializationExperiencesFromApi(
			contractor.relationships.contractor_specialization_experiences
		),
		status: contractor.relationships.user.attributes.status,
		rating: adaptRatingFromApi(contractor.meta.rating),
		kudos: adaptKudosFromApi(contractor.meta.kudos),
		completedContracts: contractor.meta.completed_contracts_count,
		canceledContracts: contractor.meta.cancelled_contracts_count,
		channelName: contractor.attributes.pubnub_channel_name,
		pause: adaptPauseFromApi(contractor.relationships.resource_pauses),
		userType: UserType.contractor,
	};
};

//adapts list of contractors from api response
export const adaptContractorsFromApi = (
	contractors: GetContractorsResponse
): AdaptedContractorList => {
	return {
		pagination: adaptPaginationFromApi(contractors.links),
		list: contractors.data.map(contractor => adaptContractorFromApi(contractor)),
	};
};
