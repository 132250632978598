import { webApiManager } from './apiManager';
import { buildQueryString } from '../utils/buildQueryString';
import fetchWrapper from './fetchWrapper';
import {
	CancelContractRequest,
	CancelContractResponse,
	GetContractRequest,
	GetContractResponse,
	GetContractsRequest,
	GetContractsResponse,
} from '../types/api/Contracts';
import { ApiResponse } from '../types/api/SharedApiTypes';

export class ContractsApi {
	constructor(public apiManager: typeof webApiManager) {}

	//get list of contracts for a facility location or a contractor
	public async getContracts(request: GetContractsRequest): Promise<GetContractsResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getContractsResponse = (await fetchWrapper(
				{
					path: `contract_instances${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as GetContractsResponse;

			return getContractsResponse;
		} catch (error) {
			console.error('error in contracts api', JSON.stringify(error));
			throw error;
		}
	}

	//get single contract
	public async getContract(request: GetContractRequest): Promise<GetContractResponse> {
		try {
			const token = await this.apiManager.getValidToken();

			const getContractResponse = (await fetchWrapper(
				{
					path: `contract_instances/${request.contractId}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetContractResponse>;

			return getContractResponse.data;
		} catch (error) {
			console.error('error in contracts api', JSON.stringify(error));
			throw error;
		}
	}

	//cancel instance of contract
	public async cancelContract(request: CancelContractRequest): Promise<CancelContractResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const cancelContractResponse = (await fetchWrapper(
				{
					path: `contract_instances/${request.contractId}/cancel`,
					method: 'POST',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<CancelContractResponse>;

			return cancelContractResponse.data;
		} catch (error) {
			console.error('error in contracts api', JSON.stringify(error));
			throw error;
		}
	}
}
