import { webApiManager } from './apiManager';
import fetchWrapper from './fetchWrapper';
import { ApiResponse } from '../types/api/SharedApiTypes';
import {
	GetMonthlyContractorReportRequest,
	GetMonthlyContractorReportResponse,
} from '../types/api/Reports';
import { buildQueryString } from '../utils/buildQueryString';

export class ReportsApi {
	constructor(public apiManager: typeof webApiManager) {}

	public async getMonthlyContractorReport(
		request: GetMonthlyContractorReportRequest
	): Promise<GetMonthlyContractorReportResponse> {
		try {
			const token = await this.apiManager.getValidToken();
			const query = buildQueryString(request);

			const getReportResponse = (await fetchWrapper(
				{
					path: `reports/monthly_contractor_report${query}`,
					method: 'GET',
					token,
				},
				this.apiManager.rootApiUrl
			)) as ApiResponse<GetMonthlyContractorReportResponse>;

			return getReportResponse.data;
		} catch (err) {
			console.error('error in reports api', JSON.stringify(err));
			throw err;
		}
	}
}
