import { USStates, UUID } from '../types/sharedTypes';
import { GetFacilityTypeProfessionExperienceRulesResponse } from '../types/api/FacilityTypeProfessionExperienceRules';

export type AdaptedFacilityTypeProfessionExperienceForFacility = {
	professionId: UUID;
	experienceThresholdMonths: number;
	reciprocityState: USStates;
};

export type AdaptedFacilityTypeProfessionExperienceRule = {
	id: UUID;
	facilityTypeId: UUID;
	items: AdaptedFacilityTypeProfessionExperienceForFacility[];
};

export const adaptFacilityTypeProfessionExperienceRulesFromApi = (
	rules: GetFacilityTypeProfessionExperienceRulesResponse
): AdaptedFacilityTypeProfessionExperienceRule[] => {
	const rulesByFacilityTypeId = {};

	rules.forEach(rule => {
		const facilityTypeId = rule.attributes.facility_type_id;
		const professionId = rule.attributes.profession_id;

		if (!rulesByFacilityTypeId[facilityTypeId]) {
			rulesByFacilityTypeId[facilityTypeId] = {
				id: rule.id,
				facilityTypeId,
				items: [],
			};
		}

		rulesByFacilityTypeId[facilityTypeId].items.push({
			professionId,
			experienceThresholdMonths: rule.attributes.experience_threshold,
			facilityTypeProfessionRuleId: rule.id,
		});
	});

	return Object.values(rulesByFacilityTypeId);
};
