import React from 'react';
import Logo from '../assets/logos/Carefiller_Icon_White@2x.png';
import LogoText from '../assets/logos/Carefiller_Wordmark_White@2x.png';
import styled from 'styled-components';
import { Theming } from '../theming';

export const MainLogo = ({ width }: { width?: string }) => {
	return (
		<MainLogoBackground width={width}>
			<LogoImageWrapper>
				<MainLogoImage src={Logo} width={'40%'} />
				<MainLogoImage src={LogoText} />
			</LogoImageWrapper>
		</MainLogoBackground>
	);
};

const LogoImageWrapper = styled.div`
	background-color: transparent;
	margin: 0 auto;
	padding: 0;
`;

const MainLogoImage = styled.img`
	display: block;
	margin: 30px auto;
`;

const MainLogoBackground = styled.div<{ width?: string }>`
	display: flex;
	width: 100%;
	background-image: linear-gradient(
		${Theming.gradients.lightGradient},
		${Theming.gradients.darkGradient}
	);
	justify-content: center;
	align-items: center;
	margin: 0;
	height: 100%;
`;
