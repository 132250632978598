import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import IconManager, { IconType } from './IconManager';
import { ErrorMessage } from './Text';

interface MessageInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onSubmit'> {
	error?: string;
	disabled?: boolean;
	onSubmit: (newVal: string) => void;
	clearInput?: boolean;
}

export const MessageInput = ({ error, disabled, onSubmit, clearInput }: MessageInputProps) => {
	const [message, setMessage] = useState<string>('');

	useEffect(() => {
		if (clearInput) {
			setMessage('');
		}
	}, [clearInput]);

	return (
		<>
			<MessageInputWrapper>
				<StyledMessageInput
					placeholder={'Write a message...'}
					value={message}
					onChange={e => setMessage(e.target.value)}
					onKeyPress={e => {
						if (e.key === 'Enter') {
							onSubmit(message);
							setMessage('');
						}
					}}
					error={!!error}
				/>
				<InputIcon
					onClick={() => {
						if (message) {
							onSubmit(message);
							setMessage('');
						}
					}}
					disabled={disabled || !message}
				>
					<IconManager
						type={IconType.UP_ARROW_CIRCLE}
						size={24}
						fill={
							disabled || !message
								? Theming.buttons.disabledButtonColor
								: Theming.icons.primaryIconColor
						}
						stroke={'#FFF'}
					/>
				</InputIcon>
			</MessageInputWrapper>
			{error && <ErrorMessage text={error} />}
		</>
	);
};

const MessageInputWrapper = styled.div<{ error?: boolean }>`
	position: relative;
	width: 100%;
	border: none;
	background-color: transparent;
	padding: 20px 15px;
	margin: 0 auto;
	border-top: 1px solid #ebeaf5;
`;

const StyledMessageInput = styled.input<{ error?: boolean }>`
	width: 100%;
	border: 1px solid #c9c8cf;
	border-radius: 5px;
	background-color: #fff;
	padding: 8px 35px 8px 8px;
	color: ${Theming.text.primaryTextColor};
	font-size: 15px;
	font-family: ${Theming.text.regularFont};
	::placeholder {
		color: ${Theming.text.placeholderColor};
	}
	&:focus {
		outline: none;
		border: ${({ error }) =>
			`1px solid ${error ? Theming.errorColor : Theming.gradients.darkGradient}`};
	}
`;

const InputIcon = styled.div<{ disabled: boolean }>`
	position: absolute;
	top: 20px;
	right: 15px;
	bottom: 0;
	padding: 6px 8px;
	:hover& {
		cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	}
`;
