import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { UUID } from '../../../types/sharedTypes';
import { PageWrapper } from '../../../components/Wrappers';
import { Table, TableRow, TableItem, ColumnTitle } from '../../../components/Table';
import { BaseButton } from '../../../components/Buttons';
import { FormSelect } from '../../../components/Select';
import { useAsync } from '../../../hooks/useAsync';
import {
	adaptFacilityTypeProfessionExperienceRulesFromApi,
	AdaptedFacilityTypeProfessionExperienceRule,
} from '../../../adapters/facilityTypeProfessionExperienceRulesAdapters';
import { adaptProfessionsFromApi, AdaptedProfession } from '../../../adapters/professionsAdapter';
import {
	adaptContractorExperienceSpecializationFromApi,
	AdaptedContractorExperienceSpecialization,
} from '../../../adapters/contractorExperienceSpecializationAdapter';
import {
	adaptContractorExperienceTagsFromApi,
	AdaptedContractorExperienceTag,
} from '../../../adapters/contractorExperienceTagsAdapter';
import {
	adaptFacilityTypesFromApi,
	AdaptedFacilityType,
} from '../../../adapters/facilityTypesAdapter';
import { webApiManager } from '../../../network/apiManager';
import LoadingSpinner from '../../../components/Loader';
import { Theming } from '../../../theming';
import {
	ToggleEditableField,
	ToggleEditableFieldElementType,
} from '../../../components/ToggleEditableField';
import { EditButton } from '../../../components/Buttons';

enum RowTypes {
	PROFESSION = 'profession',
	SPECIALIST = 'specialist',
	ADD_SPECIALIST = 'addSpecialist',
}

enum RowActions {
	UNCHANGED = 'unchanged',
	EDITED = 'edited',
	DELETED = 'deleted',
	CREATED = 'created',
}

type ContractorRowErrorsType = {
	experienceThresholdMonths?: string;
};

type SpecialistRowErrorsType = {
	description?: string;
	tags?: string;
	abbrev?: string;
};

type ProfessionTableRowType = {
	rowType: RowTypes.PROFESSION;
	id: UUID;
	action: RowActions;
	isEditing: boolean;
	errors: ContractorRowErrorsType;
	professionId: UUID;
	experienceThresholdMonths: number;
	type: string;
};

type SpecialistTableRowType = {
	rowType: RowTypes.SPECIALIST;
	id: UUID | null;
	action: RowActions;
	isEditing: boolean;
	errors: SpecialistRowErrorsType;
	professionId: UUID;
	description: string;
	tags: string;
	abbrev: string;
};

type AddSpecialistTableRowType = {
	rowType: RowTypes.ADD_SPECIALIST;
	id: null;
	action: RowActions.UNCHANGED;
	isEditing: boolean;
	errors: null;
	professionId: UUID;
};

type RowSuperType = ProfessionTableRowType | SpecialistTableRowType | AddSpecialistTableRowType;

type FacilityTableRowsByIdType = {
	[facilityTypeId: number]: RowSuperType[];
};

type TagDifferences = {
	tagsToDelete: AdaptedContractorExperienceTag[];
	tagsToCreate: string[];
};

const isRowContainingTags = row =>
	row.rowType === RowTypes.SPECIALIST &&
	row.action !== RowActions.DELETED &&
	row.tags &&
	row.tags.length > 0;

const getAllTagNamesFromState = (
	tableRowsByFacilityTypeId: FacilityTableRowsByIdType
): string[] => {
	return [
		...new Set(
			Object.values(tableRowsByFacilityTypeId)
				.flat()
				.filter(isRowContainingTags)
				.flatMap(row => (row as SpecialistTableRowType).tags.split(',').map(tag => tag.trim()))
		),
	];
};

const getTagDifferences = (
	oldTags: AdaptedContractorExperienceTag[],
	currentTagNames: string[]
): TagDifferences => {
	const oldTagsByName = {};
	const tagsToCreate: string[] = [];

	oldTags.forEach(tag => {
		oldTagsByName[tag.name] = tag;
	});
	currentTagNames.forEach(tagName => {
		if (oldTagsByName[tagName]) {
			delete oldTagsByName[tagName];
		} else {
			tagsToCreate.push(tagName);
		}
	});

	return {
		tagsToDelete: Object.values(oldTagsByName),
		tagsToCreate,
	};
};

const updateTags = async (
	tagDifferences: TagDifferences
): Promise<AdaptedContractorExperienceTag[]> => {
	const tagCreatePromises = tagDifferences.tagsToCreate.map(
		tag => async () => webApiManager.ContractorExperienceTags.createContractorTag({ name: tag })
	);
	const tagDeletePromises = tagDifferences.tagsToDelete.map(
		tag => async () => webApiManager.ContractorExperienceTags.destroyContractorExperienceTag(tag.id)
	);

	await Promise.all(tagDeletePromises.map(fn => fn()));

	const tagCreateResults = await Promise.all(tagCreatePromises.map(fn => fn()));

	return adaptContractorExperienceTagsFromApi(tagCreateResults);
};

// Converts API data of the settings for a facility type into an array of FacilityTableRowsByIdType
const createTableRowsForFacilityTypeFromAPIResponse = (
	professions,
	professionSettings,
	specialistSettings
) => {
	const professionSettingsByProfessionId = {};
	professionSettings.forEach(p => {
		professionSettingsByProfessionId[p.professionId] = p;
	});

	// Sort the specialist settings into an object keyed with the professionId (for looking up)
	const specialistSettingsByProfessionId = {};
	specialistSettings.sort((a, b) => a.id - b.id);
	specialistSettings.forEach(s => {
		if (!specialistSettingsByProfessionId[s.professionId]) {
			specialistSettingsByProfessionId[s.professionId] = [];
		}

		specialistSettingsByProfessionId[s.professionId].push(s);
	});

	// For every profession...
	return professions.flatMap(profession => {
		const professionSettingsForProfession = professionSettingsByProfessionId[profession.id];
		const specialistSettingsForProfession = specialistSettingsByProfessionId[profession.id];

		// Return a profession row (dark gray row), any specialist setting rows, and an
		// add specialist row (appears when you click the edit button on a profession row)
		return [
			{
				rowType: RowTypes.PROFESSION,
				action: RowActions.UNCHANGED,
				isEditing: false,
				professionId: profession.id,
				facilityTypeProfessionRuleId: professionSettingsForProfession.facilityTypeProfessionRuleId,
				type: profession.name,
				experienceThresholdMonths: professionSettingsForProfession.experienceThresholdMonths || 0,
				errors: {},
			},
			specialistSettingsForProfession
				? specialistSettingsForProfession.flatMap(values => {
						return {
							...values,
							rowType: RowTypes.SPECIALIST,
							action: RowActions.UNCHANGED,
							isEditing: false,
							professionId: profession.id,
							errors: {},
							abbrev: values.abbreviation,
							tags: values.tags.map(tag => tag.name).join(', '),
						};
				  })
				: null,
			{
				id: null,
				rowType: RowTypes.ADD_SPECIALIST,
				action: RowActions.UNCHANGED,
				isEditing: false,
				professionId: profession.id,
				errors: null,
			},
		]
			.filter(o => o)
			.flat();
	});
};

/*
The data structure that creates the table is what's stored in tableRowsByFacilityTypeId, which is of
the FacilityTableRowsByIdType type. It looks like this:

{
	// Items are keyed by the facility type id (0 is the ID for "Hospital"):
	0: [
		// Values are an array of rows, which can be one of three types.
		// Rows are grouped by professions.
		// You'll always see a 'profession' row, zero or more 'specialist' rows, then the
		// 'addSpecialist' row which just contains a button to add more specialist rows.

		// Here's the rows for "Physician Assistant", which has a professionId of "1":
		{ rowType: 'profession', isEditing: false, professionId: 1, experienceThresholdMonths: 60, type: 'Physician Assistant', errors: {} },
		{ rowType: 'specialist', isEditing: false, professionId: 1, description: '...', abbrev: 'ICU', tags: 'ER, Med Pass', errors: {} },
		{ rowType: 'addSpecialist', isEditing: false, professionId: 1 }

		// Here's the rows for "Registered Nurse", which has a professionId of "2"
		{ rowType: 'profession', isEditing: false, professionId: 2, experienceThresholdMonths: 32, type: 'Registered Nurse', errors: {} },
		{ rowType: 'addSpecialist', isEditing: false, professionId: 2 }

		// And so on...
	],
	// "1" would be the facility type id for "Skilled Nursing Facilities"
	1: [
		// More specialist rows here...
	],
	// And so on...
}
*/

export const ManageProfessionSettings = () => {
	const [facilityTypes, setFacilityTypes] = useState<AdaptedFacilityType[] | null>(null);
	const [professions, setProfessions] = useState<AdaptedProfession[] | null>(null);
	const [facilityTypeProfessionRules, setFacilityTypeProfessionRules] = useState<
		AdaptedFacilityTypeProfessionExperienceRule[] | null
	>(null);
	const [specializations, setSpecializations] = useState<
		AdaptedContractorExperienceSpecialization[] | null
	>(null);
	const [tags, setTags] = useState<AdaptedContractorExperienceTag[] | null>(null);
	const [selectedFacilityTypeId, setSelectedFacilityTypeId] = useState<UUID | null>(null);
	const [tableRowsByFacilityTypeId, setTableRowsByFacilityTypeId] =
		useState<FacilityTableRowsByIdType | null>(null);
	const [isUpdatingTable, setIsUpdatingTable] = useState<boolean>(false);

	// Upon loading fetch facility type and professions...
	useEffect(() => {
		getFacilityTypes.execute();
		getProfessions.execute();
	}, []);
	``;
	// On first load, or whenever we're updating the table afterwards, retrieve the state of
	// professions and specializations from the server...
	useEffect(() => {
		if (!isUpdatingTable) {
			//For lazy data consistency, retrieve all of the data again:
			getFacilityTypeProfessionExperienceRules.execute();
			getContractorExperienceSpecializations.execute();
			getContractorExperienceTags.execute();
		}
	}, [isUpdatingTable]);

	// When necessary data is loaded create the table row data structure...
	useEffect(() => {
		if (facilityTypes && professions && facilityTypeProfessionRules && specializations && tags) {
			createTableRowsFromAPIResponse();
		}
	}, [facilityTypes, professions, facilityTypeProfessionRules, specializations, tags]);

	// Converts the API Response into the FacilityTableRowsByIdType object
	const createTableRowsFromAPIResponse = () => {
		if (!facilityTypes || !facilityTypeProfessionRules || !specializations) {
			return;
		}

		// Create a lookup table from the API response to easily find facility settings by their id
		const settingsByFacilityTypeId = {};
		facilityTypeProfessionRules.forEach(facilitySettings => {
			settingsByFacilityTypeId[facilitySettings.facilityTypeId] = facilitySettings;
		});

		// Create a lookup table from the API response to easily find specialist settings by their id
		const specializationsByFacilityTypeId = {};
		specializations.forEach(specialization => {
			if (!specializationsByFacilityTypeId[specialization.facilityTypeId]) {
				specializationsByFacilityTypeId[specialization.facilityTypeId] = [];
			}

			specializationsByFacilityTypeId[specialization.facilityTypeId].push(specialization);
		});

		// Go through every facility type, find the facility settings from our lookup table and
		// build out the table rows
		const tableRowsByFacilityTypeId = {};
		facilityTypes.forEach(facilityType => {
			tableRowsByFacilityTypeId[facilityType.id] = createTableRowsForFacilityTypeFromAPIResponse(
				professions,
				settingsByFacilityTypeId[facilityType.id].items,
				specializationsByFacilityTypeId[facilityType.id] || []
			);
		});

		setTableRowsByFacilityTypeId(tableRowsByFacilityTypeId);
	};

	const getProfessions = useAsync<void>(async () => {
		const professions = adaptProfessionsFromApi(await webApiManager.Professions.getProfessions());
		const sortedProfessions = professions.sort((a, b) => a.name.localeCompare(b.name));
		setProfessions(sortedProfessions);
	}, 'Error getting types. Please check your connection and try again.');

	const getFacilityTypes = useAsync<void>(async () => {
		const types = adaptFacilityTypesFromApi(await webApiManager.FacilityTypes.getFacilityTypes());
		const sortedTypes = types.sort((a, b) => a.name.localeCompare(b.name));

		setFacilityTypes(sortedTypes);
		setSelectedFacilityTypeId(sortedTypes[0].id as number);
	}, 'Error getting facility types. Please check your connection and try again.');

	const getFacilityTypeProfessionExperienceRules = useAsync<void>(async () => {
		const rules = adaptFacilityTypeProfessionExperienceRulesFromApi(
			await webApiManager.FacilityTypeProfessionExperienceRules.getFacilityTypeProfessionExperienceRules()
		);
		setFacilityTypeProfessionRules(rules);
	}, 'Error getting facility type profession experience rules and contractor specializations. Please check your connection and try again.');

	const getContractorExperienceSpecializations = useAsync<void>(async () => {
		const specializations = adaptContractorExperienceSpecializationFromApi(
			await webApiManager.ContractorExperienceSpecialization.getContractorExperienceSpecializations()
		);
		setSpecializations(specializations);
	}, 'Error getting facility type profession experience rules. Please check your connection and try again.');

	const getContractorExperienceTags = useAsync<void>(async () => {
		const tags = adaptContractorExperienceTagsFromApi(
			await webApiManager.ContractorExperienceTags.getContractorExperienceTags()
		);

		setTags(tags);
	}, 'Error getting facility type profession experience rules. Please check your connection and try again.');

	// Updates a given table row for the currently selected facility type
	const setTableRowsByFacilityTypeIdAtIndex = (index, newAttributes) => {
		if (selectedFacilityTypeId === null) {
			return;
		}

		setTableRowsByFacilityTypeId(currentState => {
			if (!currentState) {
				return currentState;
			}

			const tableRowsForSelectedFacility = currentState[selectedFacilityTypeId];

			return {
				...currentState,
				[selectedFacilityTypeId]: [
					...tableRowsForSelectedFacility.slice(0, index),
					{
						...tableRowsForSelectedFacility[index],
						...newAttributes,
						action:
							tableRowsForSelectedFacility[index].action === RowActions.CREATED
								? RowActions.CREATED
								: RowActions.EDITED,
					},
					...tableRowsForSelectedFacility.slice(index + 1),
				],
			};
		});
	};

	const addNewSpecialist = professionId => {
		if (!tableRowsForSelectedFacility || selectedFacilityTypeId === null) {
			return;
		}

		// Find the index of the last item for a given profession id
		let curIndex = -1;
		for (let i = 0, len = tableRowsForSelectedFacility.length; i < len; i++) {
			if (tableRowsForSelectedFacility[i].professionId === professionId) {
				curIndex = i;
			}
		}

		// Create a new specialist row in editing mode
		setTableRowsByFacilityTypeId({
			...tableRowsByFacilityTypeId,
			[selectedFacilityTypeId]: [
				...tableRowsForSelectedFacility.slice(0, curIndex),
				{
					id: null,
					professionId,
					isEditing: true,
					action: RowActions.CREATED,
					rowType: RowTypes.SPECIALIST,
					errors: {},
					abbrev: '',
					description: '',
					tags: '',
				},
				...tableRowsForSelectedFacility.slice(curIndex),
			],
		});
	};

	// Set the "isEditing" attribute of all rows for a given profession id to true or false
	const setIsEditingSectionTo = (professionId, isEditing) => {
		if (!tableRowsByFacilityTypeId || selectedFacilityTypeId === null) {
			return;
		}

		setTableRowsByFacilityTypeId(currentState => {
			if (!currentState) {
				return currentState;
			}

			return {
				...currentState,
				[selectedFacilityTypeId]: currentState[selectedFacilityTypeId].map(row => ({
					...row,
					isEditing: row.professionId === professionId ? isEditing : row.isEditing,
					errors: row.rowType === RowTypes.ADD_SPECIALIST ? null : {},
				})),
			};
		});
	};

	const editSection = professionId => {
		setIsEditingSectionTo(professionId, true);
	};

	const updateSectionValidation = professionId => {
		if (!tableRowsByFacilityTypeId || selectedFacilityTypeId === null) {
			return false;
		}

		let hasErrors = false;

		setTableRowsByFacilityTypeId({
			...tableRowsByFacilityTypeId,
			[selectedFacilityTypeId]: tableRowsByFacilityTypeId[selectedFacilityTypeId].map(row => {
				const errors = {};

				if (row.professionId !== professionId || row.action === RowActions.DELETED) {
					return row;
				}

				switch (row.rowType) {
					case RowTypes.PROFESSION: {
						const n = parseInt(row.experienceThresholdMonths as unknown as string, 10);

						if (!Number.isFinite(n)) {
							hasErrors = true;
							(errors as ContractorRowErrorsType).experienceThresholdMonths =
								'Must be a valid number';
						} else if (n < 0) {
							hasErrors = true;
							(errors as ContractorRowErrorsType).experienceThresholdMonths =
								'Must be at least zero';
						} else if (n !== parseFloat(row.experienceThresholdMonths as unknown as string)) {
							hasErrors = true;
							(errors as ContractorRowErrorsType).experienceThresholdMonths =
								'Must be a whole number';
						}
						break;
					}

					case RowTypes.SPECIALIST:
						if (row.rowType === RowTypes.SPECIALIST && !row.abbrev) {
							hasErrors = true;
							(errors as SpecialistRowErrorsType).abbrev = 'Cannot be blank';
						}

						if (row.rowType === RowTypes.SPECIALIST && !row.description) {
							hasErrors = true;
							(errors as SpecialistRowErrorsType).description = 'Cannot be blank';
						}
						break;
				}

				return {
					...row,
					errors,
				};
			}),
		});

		return !hasErrors;
	};

	const saveSection = async professionId => {
		if (
			selectedFacilityTypeId === null ||
			!tableRowsByFacilityTypeId ||
			!tableRowsForSelectedFacility ||
			!tags ||
			!updateSectionValidation(professionId)
		) {
			return;
		}

		setIsEditingSectionTo(professionId, false);
		setIsUpdatingTable(true);

		const newTags = await updateTags(
			getTagDifferences(tags, getAllTagNamesFromState(tableRowsByFacilityTypeId))
		);

		const tagIdsByName = {};
		tags.concat(newTags).forEach(tag => {
			tagIdsByName[tag.name] = tag.id;
		});
		const getTagIdsFromTagsString = tags =>
			tags
				.split(',')
				.map(tagName => tagIdsByName[tagName.trim()])
				.filter(tag => tag);

		const promises = tableRowsForSelectedFacility.map(row => {
			switch (row.rowType) {
				case RowTypes.PROFESSION:
					switch (row.action) {
						case RowActions.EDITED:
							return async () =>
								webApiManager.FacilityTypeProfessionExperienceRules.updateFacilityTypeProfessionExperienceRule(
									{
										experienceThreshold: row.experienceThresholdMonths,
										id: row.facilityTypeProfessionRuleId,
									}
								);

						default:
							return null;
					}

				case RowTypes.SPECIALIST:
					switch (row.action) {
						case RowActions.CREATED:
							return async () =>
								webApiManager.ContractorExperienceSpecialization.createContractorSpecialization({
									...row,
									tagIds: getTagIdsFromTagsString(row.tags),
									abbreviation: row.abbrev,
									facilityTypeId: selectedFacilityTypeId,
									experienceThreshold: 0,
								});

						case RowActions.EDITED:
							return async () =>
								webApiManager.ContractorExperienceSpecialization.updateContractorSpecialization({
									...row,
									id: row.id as UUID,
									tagIds: getTagIdsFromTagsString(row.tags),
									abbreviation: row.abbrev,
								});

						case RowActions.DELETED:
							return async () =>
								webApiManager.ContractorExperienceSpecialization.destroyContractorExperienceSpecialization(
									row.id
								);

						default:
							return null;
					}
			}
		});

		await Promise.all(promises.map(fn => (fn ? fn() : Promise.resolve())));

		setIsUpdatingTable(false);
	};

	const deleteRow = rowIndex => {
		if (!tableRowsByFacilityTypeId || selectedFacilityTypeId === null) {
			return;
		}

		setTableRowsByFacilityTypeId(currentState => {
			if (!currentState) {
				return currentState;
			}

			const rowToDelete = currentState[selectedFacilityTypeId][rowIndex];

			return {
				...currentState,
				[selectedFacilityTypeId]: [
					...currentState[selectedFacilityTypeId].slice(0, rowIndex),
					{ ...rowToDelete, action: RowActions.DELETED },
					...currentState[selectedFacilityTypeId].slice(rowIndex + 1),
				],
			};
		});
	};

	// Holds the table rows for the currently selected facility type
	const tableRowsForSelectedFacility =
		tableRowsByFacilityTypeId && selectedFacilityTypeId !== null
			? tableRowsByFacilityTypeId[selectedFacilityTypeId] || null
			: null;

	// True if the user is editing at least one section
	const isEditingRows =
		tableRowsForSelectedFacility && tableRowsForSelectedFacility.some(row => row.isEditing);

	const isLoading =
		isUpdatingTable ||
		getFacilityTypeProfessionExperienceRules.pending ||
		getContractorExperienceSpecializations.pending ||
		getContractorExperienceTags.pending;

	return (
		<FullHeightFlexPageWrapper>
			{!tableRowsForSelectedFacility || !facilityTypes ? (
				<LoadingSpinner />
			) : (
				<TableContainer>
					<OrganizationSelectContainer>
						<StyledFormSelect
							initialValue={selectedFacilityTypeId}
							label={'Organization Settings For:'}
							options={facilityTypes.map(facilityType => ({
								value: facilityType.id,
								label: facilityType.name,
							}))}
							onChange={setSelectedFacilityTypeId}
							disabled={!!isEditingRows || isLoading}
						/>
					</OrganizationSelectContainer>

					<StyledTable
						isLoading={isLoading}
						fixed
						tableHeader={
							<>
								<ColumnTitle padding="20px 15px" textAlign="left">
									Contractor Type
								</ColumnTitle>
								<ColumnTitle padding="20px 15px" textWrap>
									Experience Threshold (MONTHS)
								</ColumnTitle>
								<ColumnTitle
									padding="20px 15px"
									textWrap
									aria-label="Specialists Abbreviation"
									textAlign="left"
								>
									Specialists Abbrev.
								</ColumnTitle>
								<ColumnTitle padding="20px 15px" textWrap textAlign="left">
									Specialist Description
								</ColumnTitle>
								<ColumnTitle padding="20px 15px" textWrap textAlign="left">
									Tags (Optional)
								</ColumnTitle>
								<FixedWidthColumnTitle padding="20px 15px" textWrap>
									&nbsp;
								</FixedWidthColumnTitle>
							</>
						}
						tableBody={
							<>
								{[
									...tableRowsForSelectedFacility.map((row, index) => {
										if (row.action === RowActions.DELETED) {
											return null;
										}

										const key = `${selectedFacilityTypeId}.${index}`;

										switch (row.rowType) {
											case RowTypes.PROFESSION:
												return (
													<ProfessionRow
														key={key}
														isEditingAvailable={!isEditingRows}
														isEditingRow={row.isEditing}
														onChange={newAttributes => {
															setTableRowsByFacilityTypeIdAtIndex(index, newAttributes);
														}}
														onEditRow={() => editSection(row.professionId)}
														onSaveRow={() => saveSection(row.professionId)}
														{...row}
													/>
												);

											case RowTypes.SPECIALIST:
												return (
													<SpecialistRow
														key={key}
														isEditingRow={row.isEditing}
														onChange={newAttributes => {
															setTableRowsByFacilityTypeIdAtIndex(index, newAttributes);
														}}
														onDeleteRow={() => deleteRow(index)}
														{...row}
													/>
												);

											case RowTypes.ADD_SPECIALIST:
												if (row.isEditing) {
													return (
														<AddSpecialistRow
															key={key}
															onAddItem={() => addNewSpecialist(row.professionId)}
														/>
													);
												}
											// falls through

											default:
												return null;
										}
									}),
								]}
							</>
						}
					></StyledTable>
					{isLoading ? (
						<CenteredDiv>
							<LoadingSpinner />
						</CenteredDiv>
					) : null}
				</TableContainer>
			)}
		</FullHeightFlexPageWrapper>
	);
};

const TableContainer = styled.div`
	position: relative;
`;

const CenteredDiv = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const DeleteButton = styled(props => (
	<button aria-label="Delete this row" {...props}>
		&times;
	</button>
))`
	color: ${Theming.text.placeholderColor};
	background: none;
	border: none;
	font-size: 1.5em;
	cursor: pointer;
	opacity: 0.5;
	:hover& {
		opacity: 1;
	}
`;

const ProfessionRow = ({
	isEditingRow,
	isEditingAvailable,
	onChange,
	onEditRow,
	onSaveRow,
	...props
}) => {
	const selfRef = useRef(null);

	// Focus on the input element when this component is loaded:
	useEffect(() => {
		if (isEditingRow) {
			const el =
				selfRef.current && (selfRef.current as HTMLElement).querySelector
					? (selfRef.current as HTMLElement).querySelector('input')
					: null;

			if (el) {
				el.focus();
				el.select();
			}
		}
	}, [isEditingRow]);

	return (
		<DarkerTableRow ref={selfRef}>
			<TableItem textWrap bold textAlign="left">
				{props.type}
			</TableItem>
			<TableItem textWrap>
				<ToggleEditableField
					elementType={ToggleEditableFieldElementType.INPUT}
					isEditing={isEditingRow}
					error={props.errors.experienceThresholdMonths}
					value={props.experienceThresholdMonths}
					type="number"
					min={0}
					step={1}
					onChange={newValue => onChange({ experienceThresholdMonths: newValue })}
				/>
			</TableItem>
			<TableItem textWrap colSpan={3}>
				&nbsp;
			</TableItem>
			<TableItem textWrap textAlign="right">
				{isEditingRow ? (
					<BaseButton margin="" text="Save" onClick={onSaveRow} />
				) : (
					<EditButton disabled={!isEditingAvailable} onClick={onEditRow} />
				)}
			</TableItem>
		</DarkerTableRow>
	);
};

const SpecialistRow = ({ isEditingRow, onChange, onDeleteRow, errors, ...props }) => {
	return (
		<StyledTableRow>
			<TableItem textWrap colSpan={2}>
				&nbsp;
			</TableItem>
			<TableItem textWrap textAlign="left">
				<ToggleEditableField
					elementType={ToggleEditableFieldElementType.TEXTAREA}
					isEditing={isEditingRow}
					error={errors.abbrev}
					onChange={newValue => onChange({ abbrev: newValue })}
					value={props.abbrev}
					placeholder="Abbreviation(s)"
				/>
			</TableItem>
			<TableItem textWrap textAlign="left">
				<ToggleEditableField
					elementType={ToggleEditableFieldElementType.TEXTAREA}
					isEditing={isEditingRow}
					error={errors.description}
					onChange={newValue => onChange({ description: newValue })}
					value={props.description}
					placeholder="Description"
				/>
			</TableItem>
			<TableItem textWrap textAlign="left">
				<ToggleEditableField
					elementType={ToggleEditableFieldElementType.TEXTAREA}
					isEditing={isEditingRow}
					error={errors.tags}
					onChange={newValue => onChange({ tags: newValue })}
					value={props.tags}
					placeholder="Tag1, Tag2, ..."
				/>
			</TableItem>
			<TableItem textWrap textAlign="right">
				{isEditingRow ? <DeleteButton onClick={onDeleteRow} /> : null}
			</TableItem>
		</StyledTableRow>
	);
};

const AddSpecialistRow = ({ onAddItem }) => {
	return (
		<AddSpecialistTableRow>
			<TableItem colSpan={6} padding="0">
				<BaseButton
					margin="0"
					width={100}
					height={60}
					backgroundColor="transparent"
					color={Theming.buttons.primaryButtonColor}
					onClick={onAddItem}
					text="+ Add specialist type"
				/>
			</TableItem>
		</AddSpecialistTableRow>
	);
};

const FullHeightFlexPageWrapper = styled(PageWrapper)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledTableRow = styled(TableRow)`
	vertical-align: top;
`;

const DarkerTableRow = styled(TableRow)`
	background: #f0f0f0;
	border-top: 1.1px solid rgba(0, 0, 0, 0.25);
`;

const AddSpecialistTableRow = styled(TableRow)`
	background-color: #f0f6ff;
`;

const StyledTable = styled(Table)<{ isLoading?: boolean }>`
	border-radius: 20px;
	padding: 0;
	background-color: #d9d9d9;
	min-width: 850px;
	opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;

const FixedWidthColumnTitle = styled(ColumnTitle)`
	width: 160px;
`;

const OrganizationSelectContainer = styled.div`
	& > div {
		display: flex;
		gap: 20px;
	}
`;

const StyledFormSelect = styled(FormSelect)`
	min-width: 300px;

	& > div {
		background-color: ${Theming.backgrounds.mainBackground};
		border: 1px solid ${Theming.buttons.primaryButtonColor};
	}
`;
