import React, { Dispatch, SetStateAction } from 'react';
import { AdaptedFacilityUser } from '../../../../../adapters/facilityAdapters';
import { FlexWrapper } from '../../../../../components/Wrappers';
import { FormRow, FormSectionTitle, SidePanelFormWrapper } from '../../../../../components/Form';
import { FormLink } from '../../../../../components/Links';
import { BoldSeparator } from '../../../../../components/Separator';
import { ControlledTextInput } from '../../../../../components/TextInputs';
import { BaseButton } from '../../../../../components/Buttons';
import { useForm } from 'react-hook-form';
import { AdaptedUser } from '../../../../../adapters/userAdapter';

type FacilityUserFormProps = {
	selectedFacilityUser: AdaptedFacilityUser | AdaptedUser | null;
	isEditing: boolean;
	setIsEditing: Dispatch<SetStateAction<boolean>>;
	onSave: (formData: FormValues) => void;
	hideEdit?: boolean;
	hideButton?: boolean;
};

type FormValues = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
};

export const FacilityUserForm = ({
	selectedFacilityUser,
	isEditing,
	onSave,
	setIsEditing,
	hideEdit,
	hideButton,
}: FacilityUserFormProps) => {
	const initialFormState = {
		firstName: selectedFacilityUser?.firstName ?? '',
		lastName: selectedFacilityUser?.lastName ?? '',
		email: selectedFacilityUser?.email ?? '',
		phone: selectedFacilityUser?.phone ?? '',
	};

	const formMethods = useForm<FormValues>({
		defaultValues: initialFormState,
		reValidateMode: 'onChange',
	});

	return (
		<SidePanelFormWrapper>
			<FlexWrapper>
				<FormSectionTitle>Account Information</FormSectionTitle>
				{!hideEdit && (
					<>
						{isEditing ? (
							<>
								<FormLink
									onClick={() => {
										//on cancel, reset formState back to saved invite
										formMethods.reset();
										//toggle editing status
										setIsEditing(prevState => !prevState);
									}}
									secondary={isEditing} //controls link color
								>
									Cancel
								</FormLink>
								<FormLink
									onClick={formMethods.handleSubmit((formData: FormValues) => onSave(formData))}
								>
									Save
								</FormLink>
							</>
						) : (
							<FormLink
								onClick={() => {
									//toggle editing status
									setIsEditing(prevState => !prevState);
								}}
								secondary={isEditing} //controls link color
							>
								Edit
							</FormLink>
						)}
					</>
				)}
			</FlexWrapper>
			<BoldSeparator margin={'0 0 5px'} />
			<FormRow>
				<ControlledTextInput
					register={() => formMethods.register('firstName', { required: 'Required' })}
					placeholder={'First Name'}
					disabled={!isEditing}
					error={formMethods.formState.errors.firstName?.message}
				/>
				<ControlledTextInput
					register={() => formMethods.register('lastName', { required: 'Required' })}
					placeholder={'Last Name'}
					disabled={!isEditing}
					error={formMethods.formState.errors.lastName?.message}
				/>
			</FormRow>
			<FormRow>
				<ControlledTextInput
					register={() =>
						formMethods.register('email', {
							required: 'Required',
							pattern: {
								//prettier-ignore
								value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'Invalid email address',
							},
						})
					}
					placeholder={'Email Address'}
					disabled={!isEditing}
					error={formMethods.formState.errors.email?.message}
				/>
				<ControlledTextInput
					register={() =>
						formMethods.register('phone', {
							pattern: {
								value: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
								message: 'Invalid phone number',
							},
						})
					}
					placeholder={'Phone Number'}
					disabled={!isEditing}
					error={formMethods.formState.errors.phone?.message}
				/>
			</FormRow>
			{hideButton ? null : (
				<BaseButton
					text={`${selectedFacilityUser ? 'ADD' : 'CREATE'} USER`}
					margin={'40px 10px'}
					onClick={formMethods.handleSubmit((formData: FormValues) => onSave(formData))}
				/>
			)}
		</SidePanelFormWrapper>
	);
};
