import { ContractorExperienceSpecialization } from '../types/api/ContractorExperienceSpecialization';
import { adaptContractorExperienceTagsFromApi } from './contractorExperienceTagsAdapter';
import { UUID } from '../types/sharedTypes';

export type AdaptedContractorExperienceSpecialization = {
	id: UUID;
	professionId: UUID;
	facilityTypeId: UUID;
	abbreviation: string;
	description: string;
};

export const adaptContractorExperienceSpecializationFromApi = (
	contractorExperienceSpecializations: ContractorExperienceSpecialization[]
): AdaptedContractorExperienceSpecialization[] => {
	return contractorExperienceSpecializations.map(specialization => {
		return {
			id: specialization.id,
			professionId: specialization.attributes.profession_id,
			facilityTypeId: specialization.attributes.facility_type_id,
			abbreviation: specialization.attributes.abbreviation,
			description: specialization.attributes.description,
			tags: adaptContractorExperienceTagsFromApi(specialization.relationships.tags || []),
		};
	});
};
