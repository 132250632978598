import React, { useState } from 'react';
import { useAsync } from '../../../hooks/useAsync';
import { FormRow, FormTitle, SidePanelFormWrapper } from '../../../components/Form';
import { useForm } from 'react-hook-form';
import { BaseButton } from '../../../components/Buttons';
import { ControlledTextInput } from '../../../components/TextInputs';
import { ErrorMessage, SuccessMessage } from '../../../components/Text';
import { webApiManager } from '../../../network/apiManager';

type FormValues = {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
};

export const UpdatePassword = () => {
	const [success, setSuccess] = useState<boolean>(false);

	const initialFormState = {
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	};

	const formMethods = useForm<FormValues>({
		defaultValues: initialFormState,
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: FormValues) => {
		try {
			const request = {
				user: {
					current_password: data.currentPassword,
					password: data.newPassword,
					password_confirmation: data.confirmPassword,
				},
			};
			const updatePasswordResponse = await webApiManager.Users.updatePassword(request);

			if (updatePasswordResponse.success) {
				setSuccess(true);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const updatePassword = useAsync(onSubmit, 'Error updating password');

	return (
		<SidePanelFormWrapper>
			<FormTitle>Update Password</FormTitle>
			{success ? (
				<FormRow>
					<SuccessMessage text={'Your password has been successfully updated.'} />
				</FormRow>
			) : (
				<>
					<FormRow>
						<ControlledTextInput
							width={'50%'}
							type={'password'}
							register={() =>
								formMethods.register('currentPassword', {
									required: 'Required',
									minLength: { value: 8, message: 'Password must be at least 8 characters.' },
								})
							}
							label={'Current Password'}
							placeholder={'Enter Current Password'}
							disabled={updatePassword.pending}
							error={formMethods.formState.errors.currentPassword?.message}
						/>
					</FormRow>
					<FormRow>
						<ControlledTextInput
							width={'50%'}
							type={'password'}
							register={() =>
								formMethods.register('newPassword', {
									required: 'Required',
									minLength: { value: 8, message: 'Password must be at least 8 characters.' },
									pattern: {
										value: /^\S*$/,
										message: 'Password must not contain any spaces.',
									},
								})
							}
							label={'New Password'}
							placeholder={'Enter New Password'}
							disabled={updatePassword.pending}
							error={formMethods.formState.errors.newPassword?.message}
						/>
					</FormRow>
					<FormRow>
						<ControlledTextInput
							width={'50%'}
							type={'password'}
							register={() =>
								formMethods.register('confirmPassword', {
									required: 'Required',
									validate: value =>
										value === formMethods.getValues().newPassword || 'The passwords do not match.',
								})
							}
							label={'Confirm Password'}
							placeholder={'Confirm Password'}
							disabled={updatePassword.pending}
							error={formMethods.formState.errors.confirmPassword?.message}
						/>
					</FormRow>
					{updatePassword.error && <ErrorMessage text={updatePassword.error} />}
					<BaseButton
						text={'Submit'}
						margin={'25px 10px'}
						pending={updatePassword.pending}
						onClick={formMethods.handleSubmit((data: FormValues) => {
							updatePassword.execute(data);
						})}
					/>
				</>
			)}
		</SidePanelFormWrapper>
	);
};
