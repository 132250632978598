import React from 'react';
import { InfoText, NonAuthFormTitle } from '../components/Text';
import {
	NonAuthPageColumn,
	NonAuthPageContainer,
	NonAuthPageContentWrapper,
} from '../components/Wrappers';
import { MainLogo } from '../components/Logos';
import { ReturnHomeLink } from '../components/Links';

export const Unauthorized = () => {
	return (
		<NonAuthPageContainer>
			<NonAuthPageColumn width={60}>
				<MainLogo width={'60%'} />
			</NonAuthPageColumn>

			<NonAuthPageColumn width={40}>
				<NonAuthPageContentWrapper>
					<NonAuthFormTitle>Unauthorized</NonAuthFormTitle>
					<InfoText fontSize={16} margin={'30px auto'}>
						You are not authorized to view this page
					</InfoText>
					<ReturnHomeLink />
				</NonAuthPageContentWrapper>
			</NonAuthPageColumn>
		</NonAuthPageContainer>
	);
};
