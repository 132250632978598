import { UUID } from '../sharedTypes';
import { PaginatedApiRequest, PaginatedApiResponse } from './SharedApiTypes';
import { Contract } from './Contracts';

export enum PositiveKudos {
	caring = 'Caring',
	experienced = 'Experienced',
	professional = 'Professional',
	problemSolver = 'Problem Solver',
	strongCommunication = 'Strong Communication',
}

export enum NegativeKudos {
	unfriendly = 'Unfriendly',
	inexperienced = 'Inexperienced',
	unprofessionalEnvironment = 'Unprofessional Environment',
	notAProblemSolver = 'Not a Problem Solver',
	poorCommunication = 'Poor Communication',
}

export enum PositiveFacilityLocationKudos {
	strongManagement = 'Strong Management',
	professionalEnvironment = 'Professional Environment',
	strongCommunication = 'Strong Communication',
	adequateResources = 'Adequate Resources',
	friendlyStaff = 'Friendly Staff',
}

export enum NegativeFacilityLocationKudos {
	weakManagement = 'Weak Management',
	inadequateResources = 'Inadequate resources',
	unprofessionalEnvironment = 'Unprofessional Environment',
	unfriendlyStaff = 'Unfriendly Staff',
	poorCommunication = 'Poor Communication',
}

export interface Kudos {
	negatives: {
		[value in NegativeKudos]?: number;
	};
	positives: {
		[value in PositiveKudos]?: number;
	};
}

export interface LocationKudos {
	negatives: {
		[value in NegativeFacilityLocationKudos]?: number;
	};
	positives: {
		[value in PositiveFacilityLocationKudos]?: number;
	};
}

export interface Rating {
	average_rating: number | 'Insufficient';
	number_reviews: number | 'N/A';
}

export interface GetReviewsRequest extends PaginatedApiRequest<void> {
	location_id?: UUID;
	contractor_id?: UUID;
}

export type GetReviewsResponse = PaginatedApiResponse<Review[]>;

export interface Review {
	type: 'contract_instance_reviews';
	id: UUID;
	attributes: {
		contractor_feelings: number; //contractor's feelings towards the location
		contractor_kudos: Kudos[]; //kudos given to contractor by location
		contractor_score: number; //rating (number of stars) given to contractor by location
		contractor_written_review: string; //review written by location for contractor
		location_feelings: number; //location's feelings towards the contractor
		location_kudos: Kudos[]; //kudos given to location by contractor
		location_score: number; //rating (number of stars) given to location by contractor
		location_written_review: string; //review written by contractor for location
	};
	relationships: {
		contract_instance: Contract; //contract that review was submitted on
	};
}
